import arr from './arr-stats'
import sampleMeta from '../refs/sampleMetaNasal'
import trackDetails from '../refs/trackDetailsNasal'
import categoryTracks from '../refs/categoryTracksNasal'
import cloneDeep from 'lodash/cloneDeep'

let trackCategory = {}
Object.entries(categoryTracks).forEach(([category, trackList]) => {
    trackList.forEach((track) => trackCategory[track] = category)
})

export const transform_k_track_v_data = (k_track_v_data) => {
    let samples = Object.keys(sampleMeta)
    const sampleMetaTransformed = transformSampleMeta()
    let res = sampleMetaTransformed
    Object.entries(k_track_v_data).forEach(([track, data]) => {
        if (track.includes('mut')) { return }
        const gene = track.split(' ')[0]
        // const dataType = track.split(' ')[1]
        const dataType = ''
        const raw_z_tumor = samples
            .map(sample => k_track_v_data[track][`${sample}-T`])
        const raw_z_normal = samples
            .map(sample => k_track_v_data[track][`${sample}-N`])
        const raw_z = [...raw_z_tumor, ...raw_z_normal]
            .filter(el => el && !(typeof el === 'string'))
        const z_scores = arr.zScores(raw_z)
        const zScoreDict = Object.fromEntries(raw_z.map((v, i) => [v, z_scores[i]]))
        const tumorKey = `${track} T`
        const normalKey = `${track} N`
        let tumorData = createTissueObj({
            trackObj: k_track_v_data[track], 
            tissueStr: '-T',
            zScoreDict,
        })
        let normalData = createTissueObj({
            trackObj: k_track_v_data[track], 
            tissueStr: '-N',
            zScoreDict,
        })
        res[tumorKey] = {
            title: tumorKey,
            data: tumorData,
            clinical: false,
            clinicalData: null,
            omics: gene,
            dataType,
            // tissue: 'tumor',
            label: 'AR-DX',
            parent: track,
        }
        res[normalKey] = {
            title: normalKey,
            data: normalData,
            clinical: false,
            clinicalData: null,
            omics: gene,
            dataType,
            // tissue: 'normal',
            label: 'Non AR-DX',
            parent: track,
        }
    })
    
    return res
}


function createTissueObj({
    trackObj,
    tissueStr,
    zScoreDict,
  }) {
    return Object.fromEntries(Object.keys(trackObj)
      .filter(sample => sample.includes(tissueStr))
      .map(sample => [
          sample.split(tissueStr)[0], 
          zScoreDict[trackObj[sample]]
        ]
      )
    )
  }

function createScatterObj({
    trackObj,
    // tissueStr,
    zScoreDict,
  }) {
    return Object.fromEntries(Object.keys(trackObj)
      .map(sample => [
          sample, 
          zScoreDict[trackObj[sample]]
        ]
      )
    )
  }

function createDXObj({
    trackObj,
    dxStr,
    zScoreDict,
  }) {
    return Object.fromEntries(Object.keys(trackObj)
      .filter(sample => sample.includes(dxStr))
      .map(sample => [
          sample.split(dxStr)[0], 
          zScoreDict[trackObj[sample]]
        ]
      )
    )
  }

function renameKeys(obj, newKeys) {
    const keyValues = Object.keys(obj).map(key => {
        const newKey = newKeys[key] || key;
        return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
}

export const transformSampleMeta = () => {
    let res = {}
    
    Object.entries(sampleMeta).forEach(([sample, meta]) => {
    Object.entries(meta).forEach(([track, val]) => {
        if (!(track in res)) { res[track] = {
            title: track,
            data: {},
            clinical: true,
            clinicalData: {
                trackDetails: trackDetails[track],
                group: trackCategory[track],
                numerical: trackCategory[track] === 'Numerical',
                show: true,
            },
            omics: null,
            dataType: null,
            label: null,
        } }
        res[track].data[sample] = val
    })
    })
    return res
}


export const combine_k_track_v_data_NOT_normalized = (k_track_v_data) => {
    const sampleMetaTransformed = transformSampleMeta()
    let res = sampleMetaTransformed
    Object.entries(k_track_v_data).forEach(([track, data]) => {
        if (track.includes('mut')) { return }
        const gene = track.split(' ')[0]
        // const dataType = track.split(' ')[1]
        const dataType = ''
        console.log('testcombineraw')
        const tumorKey = `${track} T`
        const normalKey = `${track} N`

        let tumorData = Object.fromEntries(Object.entries(k_track_v_data[track])
            .filter(([sample, v]) => sample.includes('-T'))
            .map(([sample, v]) => [sample.split('-T')[0], v]))

        let normalData = Object.fromEntries(Object.entries(k_track_v_data[track])
            .filter(([sample, v]) => sample.includes('-N'))
            .map(([sample, v]) => [sample.split('-N')[0], v]))

        res[tumorKey] = {
            title: tumorKey,
            data: tumorData,
            clinical: false,
            clinicalData: null,
            omics: gene,
            dataType,
            label: 'AR-DX',
            parent: track,
        }
        res[normalKey] = {
            title: normalKey,
            data: normalData,
            clinical: false,
            clinicalData: null,
            omics: gene,
            dataType,
            label: 'Non AR-DX',
            parent: track,
        }
    })
    
    return res
}

export const transform_1_track_v_data = (k_track_v_data, z_bool) => {
    let samples = Object.keys(sampleMeta)
    const sampleMetaTransformed = transformSampleMeta()
    let res = sampleMetaTransformed
    // res[k_track_v_data.keys[0]] = 
    Object.entries(k_track_v_data).forEach(([track, data]) => {
        if (track.includes('mut')) { return }
        // console.log('test scatter 5')
        console.log(track)
        console.log(data)
        const gene = track.split(' ')[0]
        // const dataType = track.split(' ')[1]
        const dataType = ''
        const raw_z_tumor = samples
            .map(sample => k_track_v_data[track][`${sample}-T`])
        const raw_z_normal = samples
            .map(sample => k_track_v_data[track][`${sample}-N`])
        const raw_z = [...raw_z_tumor, ...raw_z_normal]
            .filter(el => el && !(typeof el === 'string'))
        const z_scores = arr.zScores(raw_z)
        let zScoreDict = null
        let wholeDict = null
        if (z_bool){
            zScoreDict = Object.fromEntries(raw_z.map((v, i) => [v, z_scores[i]]))
            // wholeDict = z_scores
        } else {
            zScoreDict = Object.fromEntries(raw_z.map((v, i) => [v, raw_z[i]]))
        }
        
        const tumorKey = `${track} T`
        const normalKey = `${track} N`
        let tumorData = createTissueObj({
            trackObj: k_track_v_data[track], 
            tissueStr: '-T',
            zScoreDict,
        })
        let normalData = createTissueObj({
            trackObj: k_track_v_data[track], 
            tissueStr: '-N',
            zScoreDict,
        })

        let wholeData = {...tumorData, ...normalData}
        res[tumorKey] = {
            title: tumorKey,
            data: tumorData,
            clinical: false,
            clinicalData: null,
            omics: gene,
            dataType,
            // tissue: 'tumor',
            label: 'AR-DX',
            parent: track,
        }
        res[normalKey] = {
            title: normalKey,
            data: normalData,
            clinical: false,
            clinicalData: null,
            gene,
            dataType,
            // tissue: 'normal',
            label: 'Non AR-DX',
            parent: track,
        }
        res[track] = {
            title: track,
            data: wholeData,
            clinical: false,
            clinicalData: null,
            gene,
            dataType,
            // tissue: 'normal',
            label: 'All patients',
            parent: track,
        }

    })
    
    return res
}

export const transform_heatmap_track_v_data = (k_track_v_data, z_bool) => {
    let samples = Object.keys(sampleMeta)
    const sampleMetaTransformed = transformSampleMeta()
    let res = sampleMetaTransformed
    // res[k_track_v_data.keys[0]] = 
    Object.entries(k_track_v_data).forEach(([track, data]) => {
        if (track.includes('mut')) { return }
        console.log('test heatmap 100')
        console.log(track)
        console.log(data)
        const gene = track.split(' ')[0]
        // const dataType = track.split(' ')[1]
        const dataType = ''

        var new_samples_name = {}
        let raw_zkeys = Object.keys(data)
        // console.log
        // console.log('test heatmap10')
        // console.log(raw_zkeys)
        
        const raw_z_tumor = samples
            .map(sample => k_track_v_data[track][`${sample}-T`])
        const raw_z_normal = samples
            .map(sample => k_track_v_data[track][`${sample}-N`])
        const raw_z = [...raw_z_tumor, ...raw_z_normal]
            .filter(el => el && !(typeof el === 'string'))
        const z_scores = arr.zScores(raw_z)
        let zScoreDict = null
        let wholeDict = null
        if (z_bool){
            zScoreDict = Object.fromEntries(raw_z.map((v, i) => [v, z_scores[i]]))
            // wholeDict = z_scores
        } else {
            zScoreDict = Object.fromEntries(raw_z.map((v, i) => [v, raw_z[i]]))
        }
        
        const tumorKey = `${track} T`
        const normalKey = `${track} N`
        let tumorData = createTissueObj({
            trackObj: k_track_v_data[track], 
            tissueStr: '-T',
            zScoreDict,
        })
        let normalData = createTissueObj({
            trackObj: k_track_v_data[track], 
            tissueStr: '-N',
            zScoreDict,
        })

        let wholeData = {...tumorData, ...normalData}
        var renamed_track = renameKeys(wholeData, new_samples_name)
        // let raw_z = samples.map(sample => renamed_track[sample])
        // const z_scores = arr.zScores(raw_z)
        // var zScoreDict;
        // if (z_bool){
        //     zScoreDict = Object.fromEntries(raw_z.map((v, i) => [v, z_scores[i]]))
        //     // wholeDict = z_scores
        // } else {
        //     zScoreDict = Object.fromEntries(raw_z.map((v, i) => [v, raw_z[i]]))
        // }
        // var renamed_track = renameKeys(cloneDeep(k_track_v_data[track]), new_samples_name)

        console.log('heatmap z')
        console.log(z_scores)
        // console.log('test heatmap11')
        // console.log(new_samples_name)
        // console.log('test heatmap13')
        // console.log(renamed_track)


        // let wholeData = createScatterObj({
        //     trackObj: k_track_v_data[track],
        // })

        res[track] = {
            title: track,
            data: renamed_track,
            clinical: false,
            clinicalData: null,
            gene,
            dataType,
            // tissue: 'normal',
            label: track,
            parent: track,
        }
        console.log('heatmap 14')
        console.log(res)

    })
    
    return res
}

export const cartesianProduct = (arr) => {
    return arr.reduce(function(a,b){
        return a.map(function(x){
            return b.map(function(y){
                return x.concat([y]);
            })
        }).reduce(function(a,b){ return a.concat(b) },[])
    }, [[]])
}



// export const combine_1_track_v_data_NOT_normalized = (k_track_v_data) => {
//     let samples = Object.keys(sampleMeta)
//     const sampleMetaTransformed = transformSampleMeta()
//     let res = sampleMetaTransformed
//     Object.entries(k_track_v_data).forEach(([track, data]) => {
//         if (track.includes('mut')) { return }
//         const gene = track.split(' ')[0]
//         // const dataType = track.split(' ')[1]
//         const dataType = ''

//         const tumorKey = `${track} T`
//         const normalKey = `${track} N`

//         // let tumorData = Object.fromEntries(Object.entries(k_track_v_data[track])
//         //     .filter(([sample, v]) => sample.includes('-T'))
//         //     .map(([sample, v]) => [sample.split('-T')[0], v]))

//         // let normalData = Object.fromEntries(Object.entries(k_track_v_data[track])
//         //     .filter(([sample, v]) => sample.includes('-N'))
//         //     .map(([sample, v]) => [sample.split('-N')[0], v]))

//         const raw_z_tumor = samples
//             .map(sample => k_track_v_data[track][`${sample}-T`])
//         const raw_z_normal = samples
//             .map(sample => k_track_v_data[track][`${sample}-N`])
//         const raw_z = [...raw_z_tumor, ...raw_z_normal]
//             .filter(el => el && !(typeof el === 'string'))

//         res[tumorKey] = {
//             title: tumorKey,
//             data: tumorData,
//             clinical: false,
//             clinicalData: null,
//             gene,
//             dataType,
//             label: 'AR-DX',
//             parent: track,
//         }
//         res[normalKey] = {
//             title: normalKey,
//             data: normalData,
//             clinical: false,
//             clinicalData: null,
//             gene,
//             dataType,
//             label: 'Non AR-DX',
//             parent: track,
//         }
//     })
    
//     return res
// }