import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import cloneDeep from 'lodash/cloneDeep'

import {getTrackData, getTracks, getTracksExposure, getTrackExposureData,
        getTrackExposureQuantileData, getTracksExposureQuantile,
      getTracksCpm, getTrackCpmData} from '../firebase'
import { transform_k_track_v_data, transform_1_track_v_data, 
  combine_k_track_v_data_NOT_normalized,
  transform_heatmap_track_v_data, cartesianProduct,
} from '../helpers'
// import { transform_exposure_data, combine_exposure_data_raw } from '../helpers'

import categoryTracks from '../refs/categoryTracksNasal'
import trackDetails from '../refs/trackDetailsNasal'
import sampleHisto from '../refs/sampleHisto'
import sampleMeta from '../refs/sampleMetaNasal'
import availableExposure from '../refs/availableExposure'
import availableGene from '../refs/availableGene'
import availableExposureQuantile from '../refs/availableExposureQuantile'
import availableExposurePred from '../refs/availableExposurePred'
import availableGenePred from '../refs/availableGenePred'
import availableKS from '../refs/availableKS'
import availableCPG from '../refs/availableCPG'
import allNodes from "./../refs/ks_proteins.json"
import allEdges from "./../refs/ksi_edges.json"
import cpgPredExposure from '../refs/exposure_adjCPG.json'
import exposurePred from '../refs/exposuresPred'
import exposureQ from '../refs/exposuresQ'

import genePred from '../refs/genePred'

import sortFn from '../sortFn'


export default new Vuex.Store({
  state: {
    availableExposure, availableGene,
    availableExposureQuantile, availableExposurePred,
    availableGenePred, availableCPG,
    exposurePred, genePred, exposureQ,
    cpgPredExposure, availableKS,
    Boxplot_tab: 'tab-1',
    Boxplot_multiomics_ZscoreData: {},
    Boxplot_multiomics_RawData: {},
    Boxplot_multiomics_Datatypes: ['Genes', 'Exposures'],
    Boxplot_multiomics_Dataformat: {'Genes': ['cpm', 'raw'],
                                    'Exposures': ['raw']},
    Boxplot_gene_data: {},
    Boxplot_gene_data_raw: {},
    Boxplot_exposure_data: {},
    Boxplot_exposure_data_raw: {},
    Boxplot_dataType: 'All',
    Boxplot_exposure_exposures: ['1-1-1-TRICHLOROETHANE'],
    Boxplot_gene_genes: ['A1BG'],
    // Boxplot_exposures: ['1-1-1-TRICHLOROETHANE'],
    // Boxplot_genes
    Boxplot_stratifyBy: null,
    Boxplot_stratify: false,
    Boxplot_useZScore: false,
    Boxplot_logScale: true,
    Boxplot_geneCpm: true,

    BoxplotCPG_cpg: [],
    BoxplotCPG_cpgData: [],
    // HistologyViewer_mutationGene: '',
    // HistologyViewer_Sample: 'C3N-00831',
    Heatmap_categoryTracks: cloneDeep(categoryTracks),
    Heatmap_categoryTracksFiltered: cloneDeep(categoryTracks),
    Heatmap_data: {},
    Heatmap_genes_data: {},
    Heatmap_exposure_data: {},
    Heatmap_dataTypes: [''],
    Heatmap_shownDataTypes: [''],
    Heatmap_trackGroups: [],
    Heatmap_genes: [],
    Heatmap_exposures: [],
    Heatmap_sampleOrder: Object.keys(sampleMeta),
    Heatmap_sampleShow: {},
    Heatmap_tab: 'tab-1',
    // Heatmap_landingGenes: [''],
    Heatmap_ready: false,
    Heatmap_sortTracks: ['cohort'],
    SampleDashboard_data: cloneDeep(sampleMeta),
    sampleDashboard_graphs: [
      'Age',
      'Sex',
      'Race_ethnicity'
    ],
    SampleDashboard_percentView: true,
    sampleHisto,
    sampleMeta,
    Scatterplot_data: {},
    Scatterplot_data_raw: {},
    Scatterplot_genes: [],
    Scatterplot_exposures: [],
    Scatterplot_genetracks: [],
    Scatterplot_exposuretracks: [],
    Scatterplot_useZScore: false,
    Scatterplot_dataX_DT: '',
    Scatterplot_dataY_DT: '',

    Barplot_data: [],
    Barplot_data_raw: [],
    Barplot_exposures: [],

    BarplotPred_data: [],
    BarplotPred_data_raw: [],
    BarplotPred_exposures: [],
    BarplotPred_genes: [],

    NetworkVis_nodes: [],
    NetworkVis_tab: 'tab-1',
    NetworkVis_subsetNodes: allNodes,
    NetworkVis_subsetEdges: allEdges,

    Correlation_data: {},
    Correlation_data_raw: {},
    Correlation_tracks: {},
    Correlation_gene_data: {},
    Correlation_gene_data_raw: {},

    Correlation_exposure_data: {},
    Correlation_exposure_data_raw: {},
    Correlation_genes: [],
    Correlation_exposures: [],
    Correlation_genetracks: [],
    Correlation_exposuretracks: [],
    Correlation_useZscore: false,
    availableDataTracks: ['Air Toxics', 'Genes'], // Adding the new state variable
    view: 'Content page',
    views: [
      'Content page',
      'Sample dashboard',
      // 'BoxplotExposure',
      // 'BoxplotGene', 
      'Heatmap',
      'Boxplot',
      'BoxplotCPG', 
      'Barplot',
      'BarplotPred',
      'Tasklist',
      'Network',
      'Correlation',
      // 'Histology viewer',
      // 'Quality control',
      'Scatterplot',
    ]
  },
  mutations: {
    SET_VIEW_ATTR(state, { view, attrKey, attrVal}) {
      state[`${view}_${attrKey}`] = attrVal
    },
    SET_BOXPLOT_DATA_TYPE(state, { view, Boxplot_dataType }) {
      state[`${view}_dataType`] = Boxplot_dataType
    },

    SET_BOXPLOT_STRATIFY_BY(state, { view, Boxplot_stratifyBy }) {
      state[`${view}_stratifyBy`] = Boxplot_stratifyBy
    },
    SET_VIEW_TAB(state, { view, tab }) {
      state[`${view}_tab`]= tab
    },
    SET_GENES(state, { view, genes }) {
      state[`${view}_genes`] = genes
    },
    SET_NODES(state, { view, nodes }) {
      state[`${view}_nodes`] = nodes
    },
    SET_EXPOSURES(state, { view, exposures }) {
      // console.log('test set')
      // console.log(view)
      // // console.log(exposures)
      // console.log(genes)
      // console.log(`${view}_exposures`)
      state[`${view}_exposures`] = exposures
    },
    SET_HEATMAP_CATEGORY_TRACKS_FILTERED(state, { Heatmap_categoryTracksFiltered }) {
      state.Heatmap_categoryTracksFiltered = cloneDeep(Heatmap_categoryTracksFiltered)
    },
    SET_HEATMAP_SHOWN_DATA_TYPES(state, { Heatmap_shownDataTypes }) {
      state.Heatmap_shownDataTypes = Heatmap_shownDataTypes
    },
    SET_HEATMAP_SORT_TRACKS(state, { Heatmap_sortTracks }) {
      state.Heatmap_sortTracks = Heatmap_sortTracks
    },
    SET_HEATMAP_TAB(state, { Heatmap_tab }) {
      state.Heatmap_tab = Heatmap_tab
    },
    SET_HEATMAP_TRACK_GROUPS(state, { Heatmap_trackGroups }) {
      state.Heatmap_trackGroups = Heatmap_trackGroups
    },
    SET_HISTOLOGY_VIEWER_SAMPLE(state, { sample }) {
      state.HistologyViewer_Sample = sample
    },
    SET_SAMPLE_DASHBOARD_GRAPHS(state, { sampleDashboard_graphs }) {
      state.sampleDashboard_graphs = sampleDashboard_graphs
    },
    SET_SAMPLE_DASHBOARD_PERCENT_VIEW(state, { SampleDashboard_percentView }) {
      state.SampleDashboard_percentView = SampleDashboard_percentView
    },
    SET_SAMPLE_ORDER(state, { view, sampleOrder }) {
      state[`${view}_sampleOrder`] = sampleOrder
    },
    SET_SCATTERPLOT_TRACKS(state, { Scatterplot_tracks }) {
      // state.Scatterplot_tracks = Scatterplot_tracks
      state.Scatterplot_genetracks = Scatterplot_tracks[0]
      state.Scatterplot_exposuretracks = Scatterplot_tracks[1]
    },
    SET_SCATTERPLOT_DT(state, { Scatterplot_datatypes}) {
      // state.Scatterplot_tracks = Scatterplot_tracks
      // console.log('test6Dec')
      state.Scatterplot_dataX_DT = Scatterplot_datatypes[0]
      state.Scatterplot_dataY_DT = Scatterplot_datatypes[1]
    },
    SET_CORRELATION_TRACKS(state, { Correlation_tracks }) {
      // state.Scatterplot_tracks = Scatterplot_tracks
      state.Correlation_tracks = Correlation_tracks
      // state.Correlation_exposures = Correlation_tracks[1]
    },
    SET_USE_Z_SCORE(state, { view, useZScore }) {
      state[`${view}_useZScore`] = useZScore
    },
    SET_LOGSCALE(state, { view, logScale }) {
      state[`${view}_logScale`] = logScale
    },
    SET_GENECPM(state, { view, geneCpm }) {
      state[`${view}_geneCpm`] = geneCpm
    },
    SET_STRATIFY(state, { view, stratify }) {
      state[`${view}_stratify`] = stratify
    },
    SET_VIEW(state, { view }) {
      state.view = view
    },
    SET_VIEW_DATA(state, { view, data }) {
      state[`${view}_data`] = data
    },
    SET_VIEW_DATA_RAW(state, { view, data }) {
      state[`${view}_data_raw`] = data
    },
    SET_VIEW_READY(state, { view, ready }) {
      state[`${view}_ready`] = ready
    },
  },
  actions: {

    // getAvailableDataTracks(state) {
    //   return state.availableDataTracks;
    // },
    async getTrackData(store, { view, tracks }) {
      const trackPromises = [...tracks].map((track) => {
        return getTrackData(track)
      })

      const trackRes = await Promise.all(trackPromises)

      const k_track_v_data = Object.fromEntries(trackRes)
      // console.log('test5')
      const combinedDataNotNormalized = combine_k_track_v_data_NOT_normalized(k_track_v_data)
      const normalizedCombinedData = transform_k_track_v_data(k_track_v_data)

      // store.commit('SET_VIEW_DATA', { view, data: normalizedCombinedData })
      // store.commit('SET_VIEW_DATA_RAW', { view, data: combinedDataNotNormalized })
      store.commit('SET_VIEW_ATTR', {view: view, 
        attrKey: 'data', attrVal: normalizedCombinedData})
      store.commit('SET_VIEW_ATTR', {view: view, 
        attrKey: 'data_raw', attrVal: combinedDataNotNormalized})
      
      // console.log(view)
      // console.log(store.state.BoxplotGene_data)
      // }
      if ( view === 'Heatmap') { 
        store.dispatch('sortSamples', { view })
      }
      // console.log('test7')
    },
    
    async getTracks(store, { view, genes }) {
      const trackPromises = [...genes].map((gene) => {
        return getTracks(gene)
      })
      
      
      const trackRes = await Promise.all(trackPromises)
      
      let k_gene_v_tracks = Object.fromEntries(trackRes)
      // console.log('test4')
      // console.log(trackRes)
      const tracks = Object.values(k_gene_v_tracks).flat()
      // console.log(tracks)
      // console.log(view)
      store.dispatch('getTrackData', { view, tracks })
      // store.commit('SET_GENES', { view, genes })
      store.commit('SET_VIEW_ATTR', {view: view, 
                  attrKey: 'genes', attrVal: genes})
    },

    async getTrackCpmData(store, { view, tracks }) {
      const trackPromises = [...tracks].map((track) => {
        return getTrackCpmData(track)
      })

      const trackRes = await Promise.all(trackPromises)

      const k_track_v_data = Object.fromEntries(trackRes)
      // console.log('test5')
      const combinedDataNotNormalized = combine_k_track_v_data_NOT_normalized(k_track_v_data)
      const normalizedCombinedData = transform_k_track_v_data(k_track_v_data)

      // store.commit('SET_VIEW_DATA', { view, data: normalizedCombinedData })
      // store.commit('SET_VIEW_DATA_RAW', { view, data: combinedDataNotNormalized })
      store.commit('SET_VIEW_ATTR', {view: view, 
        attrKey: 'data', attrVal: normalizedCombinedData})
      store.commit('SET_VIEW_ATTR', {view: view, 
        attrKey: 'data_raw', attrVal: combinedDataNotNormalized})
      
      // console.log(view)
      // console.log(store.state.BoxplotGene_data)
      // }
      if ( view === 'Heatmap') { 
        store.dispatch('sortSamples', { view })
      }
      // console.log('test7')
    },
    
    async getTracksCpm(store, { view, genes }) {
      const trackPromises = [...genes].map((gene) => {
        return getTracksCpm(gene)
      })
      
      
      const trackRes = await Promise.all(trackPromises)
      
      let k_gene_v_tracks = Object.fromEntries(trackRes)
      const tracks = Object.values(k_gene_v_tracks).flat()
      store.dispatch('getTrackCpmData', { view, tracks })
      // store.commit('SET_GENES', { view, genes })
      store.commit('SET_VIEW_ATTR', {view: view, 
        attrKey: 'genes', attrVal: genes})
    },
    async getTracksExposure(store, { view, exposures }) {
      const trackPromises = [...exposures].map((exposure) => {
        return getTracksExposure(exposure)
      })
      
      const trackRes = await Promise.all(trackPromises)
      
      let k_exposure_v_tracks = Object.fromEntries(trackRes)
      // console.log('test4')
      // console.log(trackRes)
      const tracks = Object.values(k_exposure_v_tracks).flat()
      // console.log(tracks)
      // console.log(view)
      store.dispatch('getTrackExposureData', { view, tracks })
      // let exposures = exposures
      store.commit('SET_VIEW_ATTR', {view: view, 
        attrKey: 'exposures', attrVal: exposures})
      // store.commit('SET_EXPOSURES', { view, exposures })
    },
    async getTrackExposureData(store, { view, tracks }) {
      const trackPromises = [...tracks].map((track) => {
        return getTrackExposureData(track)
      })

      const trackRes = await Promise.all(trackPromises)

      const k_track_v_data = Object.fromEntries(trackRes)
      // console.log('test5exposure')
      const combinedDataNotNormalized = combine_k_track_v_data_NOT_normalized(k_track_v_data)
      const normalizedCombinedData = transform_k_track_v_data(k_track_v_data)
      // console.log(combinedDataNotNormalized['A1BG N'])
      // const combinedDataNotNormalized = combine_k_track_v_data_NOT_normalized(k_track_v_data)
      // const normalizedCombinedData = transform_exposure_data(k_track_v_data)
      // console.log('test6exposure')
      // store.commit('SET_VIEW_DATA', { view, data: normalizedCombinedData })
      // store.commit('SET_VIEW_DATA_RAW', { view, data: combinedDataNotNormalized })
      store.commit('SET_VIEW_ATTR', {view: view, 
        attrKey: 'data', attrVal: normalizedCombinedData})
      store.commit('SET_VIEW_ATTR', {view: view, 
          attrKey: 'data_raw', attrVal: combinedDataNotNormalized})
      // console.log(view)
      // console.log(store.state.BoxplotExposure_data)
      if ( view === 'Heatmap ') { 
        store.dispatch('sortSamples', { view })
      }
      // console.log('test7')
    },

    async getTracksExposureQuantile(store, { view, exposures }) {
      const trackPromises = [...exposures].map((exposure) => {
        return getTracksExposureQuantile(exposure)
      })
      
      const trackRes = await Promise.all(trackPromises)
      
      let k_exposure_v_tracks = Object.fromEntries(trackRes)

      const tracks = Object.values(k_exposure_v_tracks).flat()
      console.log('barplot2')
      console.log(trackRes)
      store.dispatch('getTrackExposureQuantileData', { view, tracks })
      // let exposures = exposures
      // store.commit('SET_EXPOSURES', { view, exposures })
      store.commit('SET_VIEW_ATTR', {view: view, 
        attrKey: 'exposures', attrVal: exposures})
    },
    async getTrackExposureQuantileData(store, { view, tracks }) {
      const trackPromises = [...tracks].map((track) => {
        return getTrackExposureQuantileData(track)
      })

      const trackRes = await Promise.all(trackPromises)

      const k_track_v_data = Object.fromEntries(trackRes)
      // console.log('test5exposure')
      const combinedDataNotNormalized = combine_k_track_v_data_NOT_normalized(k_track_v_data)
      
      // store.commit('SET_VIEW_DATA_RAW', { view, data: combinedDataNotNormalized })
      store.commit('SET_VIEW_ATTR', {view: view, 
        attrKey: 'data_raw', attrVal: combinedDataNotNormalized})
      // console.log(view)
      // console.log(store.state.BoxplotExposure_data)
      if ( view === 'Heatmap ') { 
        store.dispatch('sortSamples', { view })
      }
      // console.log('test7')
    },

    async getSubnetwork(store, { view }){
      let submittedNodes = store.state[`${view}_nodes`]
      let submittedNodesID = []
      allNodes.forEach((node) => {
        if (submittedNodes.includes(node['label'])) {
          submittedNodesID.push(node['id'])
        }
      })
      console.log('getting submit nodeID', submittedNodesID)  
      console.log('getting subnet', submittedNodes.includes('STK11'))  
      
      let subEdges =  []
      
      allEdges.forEach((edge) => {
        // console.log('edge', edge['from'])
          if (submittedNodesID.includes(edge['from'])) {
            subEdges.push(edge)
          } else if (submittedNodesID.includes(edge['to'])) {
            subEdges.push(edge)
          }
        })
      console.log('got subedge', subEdges)  

      let subNodeList = []
      
      subEdges.forEach((edge) => {
        subNodeList.push(edge['from'])
        subNodeList.push(edge['to'])
      })
      let subNodeSet = new Set(subNodeList)
      let subNodes = []
      
      allNodes.forEach((node) => {
        if (subNodeSet.has(node['id'])){
          subNodes.push(node)
        }
      })
      store.commit('SET_VIEW_ATTR', {
        view: view,
        attrKey: 'subsetEdges',
        attrVal: subEdges
      })
      store.commit('SET_VIEW_ATTR', {
        view: view,
        attrKey: 'subsetNodes',
        attrVal: subNodes
      })
        
    },

    async getCPGTracks(store, { view, cpgs }){
      store.commit('SET_VIEW_ATTR', {
        view: view,
        attrKey: 'cpg',
        attrVal: cpgs
      })
      let subpreds = []
      console.log('cpg data 0', cpgs)
      console.log('cpg data', cpgPredExposure)
      cpgPredExposure.forEach((cpg_exp_pair) => {
        console.log('cpg data 1', cpgs.includes(cpg_exp_pair['CpGName']))
        if (cpgs.includes(cpg_exp_pair['CpGName'])){
          subpreds.push(cpg_exp_pair)
        }
      })

      console.log('cpg data 2', subpreds)
      store.commit('SET_VIEW_ATTR', {
        view: view,
        attrKey: 'cpgData',
        attrVal: subpreds
      })
        
    },
    async getTrackGNEData(store, { view, gne , x_type, y_type}) {
      // const trackPromises = [...tracks].map((track) => {
      //   return getTrackExposureData(track)
      // })
      // const genetrackPromises = getTrackData(gne[0])
      // const exposuretrackPromises = getTrackExposureData(gne[1])
      // const xkeys = Object.elements(gne[0])

      let xtrackPromises = []
      let ytrackPromises = []
      let xtrackInput = gne[0][x_type]
      let ytrackInput = gne[1][y_type]

      if (x_type == 'Genes') {
        xtrackPromises = xtrackInput.map(s => getTrackData(s));
      } else if (x_type == 'Air Toxics') {
        xtrackPromises = xtrackInput.map(s => getTrackExposureData(s));
      }

      if (y_type == 'Genes') {
        ytrackPromises = ytrackInput.map(s => getTrackData(s));
      } else if (y_type == 'Air Toxics') {
        ytrackPromises = ytrackInput.map(s => getTrackExposureData(s));
      }
      // const xtrackPromises = gne[0].map(s => getTrackData(s))
      // // const genetrackPromises = getTrackData(gne[0])
      // const ytrackPromises = gne[1].map(s => getTrackExposureData(s))
      console.log('testscatter4')
      // console.log(gne[0])
      // console.log(gne[1])
      // console.log(xkeys)
      console.log(xtrackPromises)
      console.log(ytrackPromises)
      // const trackPromises = [genetrackPromises[0], exposuretrackPromises[0]]
      console.log('scatter 41')
      const casterianPromises = cartesianProduct([xtrackPromises, 
                                ytrackPromises])
      console.log('scatter 42')
      // console.log(trackPromises)
      console.log(casterianPromises)
      let normalizedCombinedDataList = []
      let combinedDataNotNormalizedList = []
      for (let i = 0; i < casterianPromises.length; i++){
        let trackPromises = casterianPromises[i]
        // if (trackPromises[0])
        console.log(trackPromises)
        let trackRes = await Promise.all(trackPromises)
        let k_track_v_data = Object.fromEntries(trackRes)
        // console.log('test scatter 2')
        // console.log(k_track_v_data)
        let combinedDataNotNormalized = transform_1_track_v_data(k_track_v_data, false)
        let normalizedCombinedData = transform_1_track_v_data(k_track_v_data, true)
        // console.log('test scatter 21')
        console.log(combinedDataNotNormalized)
        normalizedCombinedDataList.push(normalizedCombinedData)
        combinedDataNotNormalizedList.push(combinedDataNotNormalized)
      }

      // store.commit('SET_VIEW_DATA', { view, data: normalizedCombinedDataList })
      // store.commit('SET_VIEW_DATA_RAW', { view, data: combinedDataNotNormalizedList })
      store.commit('SET_VIEW_ATTR', {view: view, 
        attrKey: 'data', attrVal: normalizedCombinedDataList})
      store.commit('SET_VIEW_ATTR', {view: view, 
        attrKey: 'data_raw', attrVal: combinedDataNotNormalizedList})
      
      if ( view === 'Heatmap ') { 
        store.dispatch('sortSamples', { view })
      }
      console.log('test7')
    },

    async getTrackGNECorrData(store, { view, gne , x_type, y_type}) {
      let xtrackPromises = []
      let ytrackPromises = []
      let xtrackInput = gne[0]
      let ytrackInput = gne[1]

      console.log('******* view is as follows **********',view)
      console.log('*********** gene as follows',gne)
      console.log('******* x_type as follows **********',x_type)
      console.log('******* y_type as follows **********',y_type)

      console.log(xtrackInput)
      if (x_type == 'Genes') {
        xtrackPromises = xtrackInput.map(s => getTrackData(s));
      } else if (x_type == 'Air Toxics') {
        xtrackPromises = xtrackInput.map(s => getTrackExposureData(s));
      }

      if (y_type == 'Genes') {
        ytrackPromises = ytrackInput.map(s => getTrackData(s));
      } else if (y_type == 'Air Toxics') {
        ytrackPromises = ytrackInput.map(s => getTrackExposureData(s));
      }
      console.log('testcorr4')

      console.log('******* x track promises are as follows *******')
      console.log(xtrackPromises)

      console.log('******* y track promises are as follows *******')
      console.log(ytrackPromises)

      let xtrackRes = await Promise.all(xtrackPromises)

      console.log('***** x track res are as follows ****')
      console.log(xtrackRes)

      let xk_track_v_data = Object.fromEntries(xtrackRes)
      let xCombinedDataNotNormalized = transform_1_track_v_data(xk_track_v_data, false)
      let xNormalizedCombinedData = transform_1_track_v_data(xk_track_v_data, true)
      console.log('testcorr5')
      // console.log(xNormalizedCombinedData)

      let ytrackRes = await Promise.all(ytrackPromises)
      let yk_track_v_data = Object.fromEntries(ytrackRes)
      let yCombinedDataNotNormalized = transform_1_track_v_data(yk_track_v_data, false)
      let yNormalizedCombinedData = transform_1_track_v_data(yk_track_v_data, true)
      // console.log('testcorr5')
      // console.log(xNormalizedCombinedData)

      let normalizedCombinedDataList = {'x': xNormalizedCombinedData,
                                        'y': yNormalizedCombinedData,
                                        }
      let combinedDataNotNormalizedList = {'x': xCombinedDataNotNormalized,
                                            'y': yCombinedDataNotNormalized,
                                            }
      // store.commit('SET_VIEW_DATA', { view, data: normalizedCombinedDataList })
      // store.commit('SET_VIEW_DATA_RAW', { view, data: combinedDataNotNormalizedList })
      store.commit('SET_VIEW_ATTR', {view: view, 
        attrKey: 'data', attrVal: normalizedCombinedDataList})
      store.commit('SET_VIEW_ATTR', {view: view, 
        attrKey: 'data_raw', attrVal: combinedDataNotNormalizedList})

      if ( view === 'Heatmap ') { 
        store.dispatch('sortSamples', { view })
      }
      console.log('test7')
      console.log('testcorrelation-1')
    },

    async getTrackHeatmapData(store, { view, genes, exposures }) {
      const genetrackPromises = [...genes].map((track) => {
        return getTrackData(track)
      })
      const exposuretrackPromises = [...exposures].map((track) => {
        return getTrackExposureData(track)
      })
      // console.log('test heatmap1')
      console.log(genetrackPromises, exposuretrackPromises)
      // store.commit('SET_EXPOSURES', { view, exposures })
      // store.commit('SET_GENES', { view, genes })
      // view, attrKey, attrVal
      store.commit('SET_VIEW_ATTR', {view: view, 
        attrKey: 'exposures', attrVal: exposures})
      store.commit('SET_VIEW_ATTR', {view: view, 
        attrKey: 'genes', attrVal: genes})

      //  Set genes in one var
      const trackPromises = [...genetrackPromises, ...exposuretrackPromises]
      const trackRes = await Promise.all(trackPromises)
      const k_track_v_data = Object.fromEntries(trackRes)
      const combinedDataNotNormalized = transform_heatmap_track_v_data(k_track_v_data, false)
      const normalizedCombinedData = transform_heatmap_track_v_data(k_track_v_data, true)
      
      store.commit('SET_VIEW_DATA', { view: 'Heatmap', data: normalizedCombinedData })
      store.commit('SET_VIEW_DATA_RAW', { view: 'Heatmap', data: combinedDataNotNormalized })
      store.commit('SET_VIEW_ATTR', {view: view, 
        attrKey: 'data', attrVal: normalizedCombinedData})
      store.commit('SET_VIEW_ATTR', {view: view, 
        attrKey: 'data_raw', attrVal: combinedDataNotNormalized})
      
      if ( view === 'Heatmap ') { 
        store.dispatch('sortSamples', { view })
      }
      console.log('test7')
    },
    
    setViewAttr(store, { view, attrKey, attrVal }) {
      store.commit('SET_VIEW_ATTR', { view, attrKey, attrVal })
    },

    setBarplotExposure(store, { view, exposures }) {
      store.commit('SET_EXPOSURES', { view, exposures })
    },
    setBarplotGene(store, { view, genes }) {
      store.commit('SET_GENES', { view, genes })
    },
    setViewNode(store, { view, nodes }) {
      store.commit('SET_NODES', { view, nodes })
    },
    setViewTab(store, { view, tab }) {
      store.commit('SET_VIEW_TAB', { view, tab })
    },
    setBoxplotDataType(store, { view, Boxplot_dataType }) {
      store.commit('SET_BOXPLOT_DATA_TYPE', { view, Boxplot_dataType })
    },
    setBoxplotStratifyBy(store, { view, Boxplot_stratifyBy }) {
      store.commit('SET_BOXPLOT_STRATIFY_BY', { view, Boxplot_stratifyBy })
    },
    setHeatmapCategoryTracksFiltered(store, { Heatmap_categoryTracksFiltered }) {
      store.commit('SET_HEATMAP_CATEGORY_TRACKS_FILTERED', { Heatmap_categoryTracksFiltered })
    },
    setHeatmapShownDataTypes(store, { Heatmap_shownDataTypes }) {
      store.commit('SET_HEATMAP_SHOWN_DATA_TYPES', { Heatmap_shownDataTypes })
    },
    setHeatmapSortTracks(store, { Heatmap_sortTracks }) {
      store.commit('SET_HEATMAP_SORT_TRACKS', { Heatmap_sortTracks })
      store.dispatch('sortSamples', { view: 'Heatmap' })
    },
    setHeatmapTab(store, { Heatmap_tab }) {
      store.commit('SET_HEATMAP_TAB', { Heatmap_tab })
    },
    setHeatmapTrackGroups(store, { Heatmap_trackGroups }) {
      store.commit('SET_HEATMAP_TRACK_GROUPS', { Heatmap_trackGroups })
    },
    setHistologyViewerSample(store, { sample }) {
      store.commit('SET_HISTOLOGY_VIEWER_SAMPLE', { sample })
    },
    setSampleDashboardGraphs(store, { sampleDashboard_graphs }) {
      store.commit('SET_SAMPLE_DASHBOARD_GRAPHS', { sampleDashboard_graphs })
    },
    setSampleDashboardPercentView(store, { SampleDashboard_percentView }) {
      store.commit('SET_SAMPLE_DASHBOARD_PERCENT_VIEW', { SampleDashboard_percentView })
    },
    setScatterplotTracks(store, { Scatterplot_tracks }) {
      store.commit('SET_SCATTERPLOT_TRACKS', { Scatterplot_tracks })
    },
    setScatterplotDT(store, { Scatterplot_datatypes }) {
      console.log('test6Dec')
      store.commit('SET_SCATTERPLOT_DT', { Scatterplot_datatypes })
    },
    setCorrelationTracks(store, { Correlation_tracks }) {
      store.commit('SET_CORRELATION_TRACKS', { Correlation_tracks })
    },
    setView(store, { view }) {
      store.commit('SET_VIEW', { view })
    },
    
    sortSamples(store, { view }) {
      const sortTracks = store.state[`${view}_sortTracks`].map(el => [el])
      // https://jsfiddle.net/L9x8ao0r/20
      console.log('heatmap 40')
      const Heatmap_data =  store.state.Heatmap_data
      let combinedData = {}
      let sampleMetaVals = {}
      console.log('heatmap 50')
      Object.entries(trackDetails).forEach(([category, trackDetail]) => {
        sampleMetaVals[category] = {}
        trackDetail.forEach((track) => {
          sampleMetaVals[category][track.label] = track.value
        })
      })

      Object.keys(sampleMeta).forEach((sample) => {
        let sampleMetaSample = {}
        Object.entries(sampleMeta[sample]).forEach(([track, val]) => {
          sampleMetaSample[track] = track in sampleMetaVals ? sampleMetaVals[track][val] : val
        })
        let res = {
          id: sample,
        }
        Object.keys(Heatmap_data).forEach(track => {
          res[track] = Heatmap_data[track].data[sample]
        })
        combinedData[sample] = {
          ...res,
          ...sampleMetaSample
        }
      })
      // Fix sort function
      var sortedTrackFunc = sortFn(sortTracks)
      const sorted = Object.values(combinedData).sort(sortedTrackFunc)
      const sampleOrder = sorted.map(el => el.id)
      
      store.commit('SET_SAMPLE_ORDER', { view, sampleOrder })
    },
    setUseZScore(store, { view, useZScore }) {
      store.commit('SET_USE_Z_SCORE', { view, useZScore })
    },
    setLogScale(store, { view, logScale }) {
      store.commit('SET_LOGSCALE', { view, logScale })
    },
    setGeneCpm(store, { view, geneCpm }) {
      store.commit('SET_GENECPM', { view, geneCpm })
    },
    setStratify(store, { view, stratify }) {
      store.commit('SET_STRATIFY', { view, stratify })
    },
  },
})
