<template>
  <v-app>
    <!-- <sidebar-menu :menu="menu" v-model="drawer" /> -->
    <introduction />
    <main class="main">
      
      <tabsheader/>
      <!-- <Tabs /> -->
      <!-- <testvis/> -->
      <!-- Google tag (gtag.js) -->
<!-- <script async src="https://www.googletagmanager.com/gtag/js?id=G-1NEMFM15C3"></script> -->

        <!-- <input-container class="input-container-main" />
        <sample-dashboard :style="showSampleDashboard" view="SampleDashboard" /> 
        <heatmap-container :style="showHeatmap" view="Heatmap"  />
        <boxplot-container :style="showBoxplot" view="Boxplot" />
        <scatterplot-container :style="showScatterplot" view="Scatterplot" />
        <quality-control-container :style="showQualityControl" view="Quality control" />
        <histology-viewer-container :style="showHistologyViewer" view="Histology viewer" /> -->
    </main>
    


    <!-- <VueSidebarMenuAkahon/> -->
    <!-- <Sidebar/> -->
    

  </v-app>
</template>

<script>
// import BoxplotContainer from './components/BoxplotContainer.vue';
// import HeatmapContainer from './components/HeatmapContainer.vue';
// import HistologyViewerContainer from './components/HistologyViewerContainer.vue';
// import InputContainer from './components/InputContainer.vue';
import Introduction from './components/Introduction.vue';
// import ScatterplotContainer from './components/ScatterplotContainer.vue';
// import SampleDashboard from './components/SampleDashboard.vue';
// import QualityControlContainer from './components/QualityControlContainer.vue';
// import VueSidebarMenuAkahon from "vue-sidebar-menu-akahon";
// import vueAwesomeSidebar from 'vue-awesome-sidebar'
// import 'vue-awesome-sidebar/dist/vue-awesome-sidebar.css'

// import testvis from "./views/Test.vue"
import {Tabs} from "vue-material-tabs";



import VueMeta from 'vue-meta'

import vueTabz from 'vue-tabz'


// window.dataLayer = window.dataLayer || [];
// function gtag(){dataLayer.push(arguments);}
// gtag('js', new Date());

// gtag('config', 'G-1NEMFM15C3');


// import Sidebar from './components/Sidebar.vue';

import tabsheader from './components/Tabs.vue';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faUserSecret)

/* add font awesome icon component */
// Vue.component('font-awesome-icon', FontAwesomeIcon)

export default {
  name: 'App',

  components: {
    // testvis,
    // BoxplotContainer,
    // HeatmapContainer,
    // HistologyViewerContainer,
    // InputContainer,
    Introduction,
    // SampleDashboard,
    // ScatterplotContainer,
    // QualityControlContainer,
    // VueSidebarMenuAkahon,
    // vueAwesomeSidebar
    // Sidebar,
    tabsheader,
    // vueTabz
    // Tabs
  },

  data: () => ({
  }),

  computed: {
    // view() { return this.$store.state.view },
    // showBoxplot() { return this.view === 'Boxplot' ? "" : "display: none;" },
    // showDemo() { return this.view === 'Demo' ? "" : "display: none;" },
    // showHeatmap() { return this.view === 'Heatmap' ? "" : "display: none;" },
    // showIprofun() { return this.view === 'Iprofun' ? "" : "display: none;" },
    // showSampleDashboard() { return this.view === 'Sample dashboard' ? "" : "display: none;" },
    // showScatterplot() { return this.view === 'Scatterplot' ? "" : "display: none;" },
    // showQualityControl() { return this.view === 'Quality control' ? "" : "display: none;" },
    // showHistologyViewer() { return this.view === 'Histology viewer' ? "" : "display: none;" },
  },
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'SunBEAm-ABC web portal',
      // all titles will be injected into this template
      // titleTemplate: '%s | SunBEAm-ABC web portal',
      meta: [{ charset: 'utf-8'},
        { name: 'description', content: 'SunBEAm-ABC web portal' },
        // { name: 'google-site-verification', content: 'LFlfTk58i2PSxVcx-KbWN8pYPeRCPVZtQAONO6YpzpY' }
      ]
    },
  
    mounted() {
      window.addEventListener('resize', () => {         
        this.windowHeight = window.innerHeight
        this.windowWidth = window.innerWidth
      });
      // this.$store.dispatch('getTrackData', 
      //     { 
      //       view: 'Heatmap', 
      //       tracks: ['1-1-1-TRICHLOROETHANE', '1-1-2-2-TETRACHLOROETHANE']
      //     }
      // );
    },

  // mounted() { 

  // }
};
</script>

<style scoped>
html {
    box-sizing: border-box;
    /* font-size: 100vw; */
    /* font-size: 16px; */
    /* scroll-padding-top: 70px; height of sticky header */

}

/* *, *:before, *:after {
    box-sizing: inherit;
} */

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-size: max(0.8vw, 16px);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    
}


.main {
    /* display: flex;
    flex-direction: row; */
    /* overflow-x: flex-wrap; */
    /* overflow-y: hidden; */
    /* white-space: nowrap; */
    align-items: center;
    /* width: 100%; */
    padding-left: 1%;
    padding-right: 1%;
    /* overflow: scroll; */
    /* margin-left: auto;
    margin-right: auto; */

  }


</style>

<!-- <style>
::selection{
  background: #FE7C2A;
  color: #D9EDF7;
}

</style> -->

<!-- <style>
 /* .md-tabs-navigation .md-icon-label .md-tab-label {
       font-size: 32px;
} */
 .md-tab-label {
  font-size: 32px !important;
}
</style> -->