export default {
  Ungrouped: [
    // "fromCohort",
    // "tumorClass",
    "cohort",
    "Age",
    "Sex",
    "Race_ethnicity",
    // "Vital status",
    // "Hypertension",
    // "Tumor grade",
    // "BMI",
    // "Weight_at_surgery",
    // "Alcohol consumption",
    // "Smoking status",
    // "Country",
    // "Tumor stage",
    // "methyl_type2",
    // "im_type",
    // "im_type_discovery",
    // "wgii high",
  ],
  // Mutation: [
  //   // "BAP1",
  //   // "FLCN",
  //   // "MET",
  //   // "MTOR",
  //   // "PBRM1",
  //   // "SETD2",
  //   // "TP53",
  //   // "TSC1",
  //   // "TSC2",
  //   // "VHL",
  //   // "CCND1",
  // ],
  // Chromosome_gain: ["gain_chr7", "gain_chr17"],
  // Chromosome_loss: ["loss_chr1", "loss_chr3p", "loss_chr14q"],
  // Numerical: ["purity", "ploidy", "wgii"],
};
