<template>
    <div class="header-block">
    <div class="introduction">
        <div class="sunbeam-abc">
        <div class="logo-ABC">
        
            <!-- SunBEAm - ABC  -->
            <!-- <div class="logo"> -->
                <img src="../assets/SunBEAm_logo.png" class="img-logo"/>
            <!-- </div> -->
        </div>
        <!-- &nbsp;&nbsp; -->
        <div class="sunbeam-abc-description">
         <!-- <div>   -->
            Analysis & Bioinformatics Center (ABC)
            <!-- <p>Systems Biology of Early Atopy (SunBEAm) - a pre-birth cohort study of allergic diseases, Food allergy (FA) and atopic dermatitis (AD)</p>
            <p>Analysis & Bioinformatics Center (ABC) - assaying SunBEAm biosamples using omics, and applying integrative systems biology to identify novel determinants of FA and AD</p>
            <p>This interactive data portal supports query, visualization, and downloads of multi-omics data </p> -->
        </div>
        </div>
    </div>
</div>
</template>

<script>
    export default {
        name: "introduction"
    }
</script>

<style scoped>

    .div {
        font-size: 10vw;
    }

    /* .header-block {
        display: block;
    } */
    .introduction {
        /* background: #91818a; */
        /* background: #D9EDF7; */
        background: #D9EDF7;
        /* padding-top: 1vm; */
        color: white;
        /* display: block; */
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        padding-top: 1vh;
        padding-bottom: 1vh;
        /* height: max(15vh, 200px); */
        /* text-align: center; */
    }

    .sunbeam-abc {
        

        display: flex;
        flex-wrap: wrap;
        /* height: 20%; */
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }



    /* .logo {
        border-radius: 50%;
        border:1px solid black;
    } */
    
    .sunbeam-abc-description {
        /* color: rgb(248, 223, 111); */
        /* color: #FFBF04; */
        /* color: #FE7C2A; */
        color: #1F497E;
        font-size: max(2.2vw, 27px);
        
        font-weight: bold;
        display: flex;
        flex-direction: row wrap;
        flex-wrap: wrap;
        padding-left: 2vw;
        /* padding-right: 1vw; */
        /* justify-content: space-evenly; */
        /* text-align: center; */
        
        /* margin-right: auto; */
        /* min-width: 70%;
        max-width: 70%; */
        /* margin-left: -0.2em; */
    }

    .sunbeam-abc-description a {
        color: white;
        
        /* text-decoration: underline; */
    }
    
    .logo-ABC {
        font-weight: bold;
        /* font-size: 1.5em; */
        height: 20%;
        
        /* margin-right: auto; */
        /* margin-left: 1em; */
        margin-top: 1vm;
        margin-right: 1vm;
        /* width: 20%; */
        /* display: block; */
        display: flex;
        flex-direction: row wrap;
        flex-wrap: wrap;
        /* text-align: center; */
        /* margin-left: 5em; */
        /* max-height: 25%;
        min-height: 25%; */
        /* min-width: 20%;
        max-width: 20%; */
        /* text-align: right; */
        /* padding-left: 1em; */
    }

    .img-logo {
        /* height: 1%; */
        /* min-height: 20%; */
        width: max(25vw, 450px);
        /* margin-top: 1vm; */
        /* min-width: 500px; */
        /* height: auto; */
        padding-left: 3vw;
        /* padding-right: 1vw; */
        /* max-height: 100px; */
        /* border-radius: 2vw; */
        /* max-height: 100px; */
    }
</style>