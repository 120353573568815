<template>
  <div class="center">
    <!-- <div style="width:80%"> -->
        <Tabs ripple="false" noTouch="false" slide="false">
        <TabItem>
          <template #name>
     <span class="custom-name">About</span>
  </template>
          <about/>
        </TabItem>
        <TabItem>
          <template #name>
     <span class="custom-name">&nbsp;Aims&nbsp;</span>
  </template>
          <aims/>
        </TabItem>
        <TabItem>
          <template #name>
     <span class="custom-name">&nbsp;Team&nbsp;</span>
  </template>
          <div align="center">
          <team/>
          </div>
        </TabItem>
        <TabItem>
          <template #name>
     <span class="custom-name">Resources</span>
  </template>
          <resources/>
        </TabItem>
        <TabItem>
          <template #name>
     <span class="custom-name">Contact Us</span>
  </template>
          <contact/>
        </TabItem>
        <!-- <TabItem>
          <template #name>
     <span class="custom-name">Visualization</span>
  </template>
          <testvis/>
        </TabItem> -->
      </Tabs>
      <!-- <md-tabs class="md-transparent" md-alignment="fixed">
    <md-tab id="tab-about" md-label="About"> <about/> </md-tab>
    <md-tab id="tab-aims" md-label="Aims"> <aims/> </md-tab>
    <md-tab id="tab-team" md-label="Team"> <team/> </md-tab>
    <md-tab id="tab-resources" md-label="Resources"><resources/></md-tab>
  </md-tabs> -->
  <!-- <v-tabs>
  <v-tab> About <about/> </v-tab>
  <v-tab> Aims <aims/> </v-tab>
  <v-tab> Team <team/> </v-tab>
  <v-tab> Resources <resources/> </v-tab>
</v-tabs> -->
  </div>
<!-- </div> -->
</template>

<script>
//   import VueSidebarMenuAkahon from "vue-sidebar-menu-akahon";
import { Tabs, TabItem } from "vue-material-tabs";
// import home from "../views/Home.vue"
import about from "../views/About.vue"
import team from "../views/Team.vue"
import resources from "../views/Resources.vue"
import aims from "../views/Aims.vue"
import contact from "../views/Contact.vue"
// import testvis from "../views/Visualization.vue"
//   var barlist = [{link: "#",name: "Dashboard", tooltip: "Dashboard", icon: "bx-grid-alt" }];

  export default {
  components: {
      Tabs,
  TabItem, team, about, resources, aims, 
  contact, 
  // testvis
  },
  name: 'TabAlignments',
  // data () {
  //   return {
  //     tab: null,
  //     items: [
  //       { tab: 'About', content: 'about' },
  //       { tab: 'Aims', content: 'aims' },
  //       { tab: 'Team', content: 'team' },
  //       { tab: 'Resources', content: 'resources' },
  //       // { tab: 'Five', content: 'Tab 5 Content' },
  //       // { tab: 'Six', content: 'Tab 6 Content' },
  //       // { tab: 'Seven', content: 'Tab 7 Content' },
  //       // { tab: 'Eight', content: 'Tab 8 Content' },
  //       // { tab: 'Nine', content: 'Tab 9 Content' },
  //       // { tab: 'Ten', content: 'Tab 10 Content' },
  //     ],
  //   }
  // },
};
</script>

<style scoped>
.center {
display: flex;
justify-content: center;
align-items: center;
margin-left: auto;
margin-right: auto;
/* gap: 40px; */

/* display: flex; */
/* overflow-x: auto; */
      /* overflow-y: hidden;
      white-space: nowrap; */
}

.larger-font {
font-size: max(2.5vw, 30px);
}

.custom-name {
font-family: Avenir, Helvetica, Arial, sans-serif;
font-weight: bolder;
font-size: max(1.3vw, 20px);
width: max(9vw, 180px);
/* height: max(13vw, 250px); */
text-transform: none;
}


</style>


<!-- <style>
.md-button-content {
font-size: 1.2rem;
font-family: Avenir, Helvetica, Arial, sans-serif;
font-weight: bold;
text-transform: none;
} 

.md-tabs-navigation {
/* position: fixed; */
/* width: 700px; */
/* z-index: 1; */
display: inline-block;
/* position: ; */
overflow: visible;
}

.md-tabs-content {
position: relative;
overflow-y: scroll;
/* overflow: visible; */
/* overflow-x: scroll; */
overflow-y: scroll;
}


</style> -->