export default {
  cg26609741: true,
  cg05872506: true,
  cg02622890: true,
  cg25429578: true,
  cg11106252: true,
  cg22880094: true,
  cg27143824: true,
  cg08608730: true,
  cg18914874: true,
  cg07888205: true,
  cg26105128: true,
  cg00807122: true,
  cg15720832: true,
  cg08651730: true,
  cg26702929: true,
  cg00801069: true,
  cg04461859: true,
  cg09854449: true,
  cg05765482: true,
  cg22964918: true,
  cg10309340: true,
  cg26304141: true,
  cg02954141: true,
  cg07146231: true,
  cg02811197: true,
  cg00851320: true,
  cg22134370: true,
  cg04100696: true,
  cg08530041: true,
  cg23383871: true,
  cg27362103: true,
  cg21454760: true,
  cg05970777: true,
  cg26531923: true,
  cg08013923: true,
  cg22228556: true,
  cg15400652: true,
  cg25936385: true,
  cg04217988: true,
  cg13747162: true,
  cg04827020: true,
  cg00209622: true,
  cg07891473: true,
  cg19282586: true,
  cg21043716: true,
  cg21964928: true,
  cg24789562: true,
  cg23858189: true,
  cg05656876: true,
  cg10683875: true,
  cg07812849: true,
  cg13863396: true,
  cg13707768: true,
  cg25436618: true,
  cg19459212: true,
  cg05239841: true,
  cg21223983: true,
  cg00437311: true,
  cg04806794: true,
  cg06939404: true,
  cg15616148: true,
  cg11028770: true,
  cg18028470: true,
  cg05650711: true,
  cg04235361: true,
  cg17872763: true,
  cg04699482: true,
  cg27409650: true,
  cg19380808: true,
  cg04293275: true
}
