export default {
  AR001: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR003: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR008: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR012: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR018: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR019: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR026: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR028: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR029: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR031: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR033: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR035: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR037: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR042: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR044: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR046: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR048: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR050: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR054: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR056: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR057: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR059: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR061: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR063: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR064: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR066: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR068: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR070: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR071: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR073: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR075: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR077: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR079: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR081: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR083: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR085: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR088: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR090: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR098: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR100: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR102: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR104: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR108: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR110: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR112: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR115: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR118: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR122: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR124: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR128: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR130: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR132: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR136: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR138: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR140: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR142: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR144: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR145: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR146: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR147: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR148: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR150: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR152: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR154: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR155: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR157: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR158: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR160: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR162: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR164: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR165: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR167: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR169: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR170: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR172: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR174: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR177: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR178: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR180: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR182: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR183: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR185: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR186: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR187: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR188: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR189: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR190: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR192: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR193: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR197: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR198: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR200: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR202: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR204: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR206: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR208: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR209: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR210: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR211: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR213: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR215: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR217: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR219: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR220: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR222: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR224: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR226: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR228: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR229: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR230: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR231: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR232: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR233: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR237: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR238: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR239: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR242: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR243: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR245: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR247: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR249: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR252: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR254: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR256: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR258: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR259: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR261: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR262: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR264: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR266: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR268: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR271: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR273: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR275: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR279: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR281: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR283: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR285: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR287: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR289: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR293: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR297: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR299: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR300: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR302: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR304: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR306: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR309: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR311: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR313: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR315: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR319: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR321: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR323: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR327: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR329: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR331: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR339: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR341: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR343: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR345: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR347: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR349: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR351: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR353: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR357: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR363: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR365: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR367: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR368: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR370: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR371: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR372: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR373: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR376: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR381: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR382: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR384: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR387: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR389: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR391: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR392: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR394: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR398: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR400: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR402: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR404: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR406: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR407: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR408: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR409: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR411: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR412: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR413: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR414: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR416: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR418: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR420: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR421: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR422: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR423: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR424: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR425: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR426: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR427: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR428: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR429: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR430: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR431: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR432: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR433: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR434: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR435: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR436: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR437: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR438: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR439: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR440: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR441: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR442: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR443: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR444: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR445: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR446: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR447: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR448: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR449: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR450: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR451: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR452: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR453: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR454: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR455: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR457: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR458: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR459: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR461: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR462: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR463: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR464: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR465: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR466: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR467: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR468: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 3.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR469: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR470: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR471: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR472: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR473: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR474: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 2.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR475: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR477: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR478: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR479: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 4.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR482: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 3.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR483: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 2.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 1.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR484: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR485: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR486: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 1.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 4.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR488: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 1.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 3.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'Non AR-DX',
    PHOSPHINE_PHOSPHINE: 3.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'Non AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 1.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR489: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 1.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 3.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 2.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 2.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 3.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'AR-DX'
  },
  AR490: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 4.0,
    'COBALT-COMPOUNDS_cohort': 'AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  },
  AR491: {
    'COBALT-COMPOUNDS_COBALT-COMPOUNDS': 2.0,
    'COBALT-COMPOUNDS_cohort': 'Non AR-DX',
    'COBALT-COMPOUNDS_pred_cls': 'Non AR-DX',
    'ACRYLIC-ACID_ACRYLIC-ACID': 4.0,
    'ACRYLIC-ACID_cohort': 'Non AR-DX',
    'ACRYLIC-ACID_pred_cls': 'AR-DX',
    'QUINONE--P-BENZOQUINONE-_QUINONE--P-BENZOQUINONE-': 1.0,
    'QUINONE--P-BENZOQUINONE-_cohort': 'Non AR-DX',
    'QUINONE--P-BENZOQUINONE-_pred_cls': 'AR-DX',
    PHOSPHINE_PHOSPHINE: 4.0,
    PHOSPHINE_cohort: 'Non AR-DX',
    PHOSPHINE_pred_cls: 'AR-DX',
    'BENZYL-CHLORIDE_BENZYL-CHLORIDE': 4.0,
    'BENZYL-CHLORIDE_cohort': 'Non AR-DX',
    'BENZYL-CHLORIDE_pred_cls': 'AR-DX',
    'ANTIMONY-COMPOUNDS_ANTIMONY-COMPOUNDS': 2.0,
    'ANTIMONY-COMPOUNDS_cohort': 'Non AR-DX',
    'ANTIMONY-COMPOUNDS_pred_cls': 'Non AR-DX'
  }
}
