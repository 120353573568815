export default {
  AAK1: true,
  AURKA: true,
  AURKB: true,
  AURKC: true,
  BMP2K: true,
  BUB1: true,
  BUB1B: true,
  CAMKK1: true,
  CAMKK2: true,
  CDC7: true,
  CHUK: true,
  EIF2AK1: true,
  EIF2AK2: true,
  EIF2AK3: true,
  EIF2AK4: true,
  ERN1: true,
  GAK: true,
  HASPIN: true,
  IKBKB: true,
  IKBKE: true,
  MOS: true,
  NEK1: true,
  NEK2: true,
  NEK3: true,
  NEK5: true,
  NEK6: true,
  NEK7: true,
  NEK8: true,
  NEK9: true,
  NEK11: true,
  PBK: true,
  PDIK1L: true,
  PEAK1: true,
  PINK1: true,
  PKMYT1: true,
  PLK1: true,
  PLK2: true,
  PLK3: true,
  PLK4: true,
  SCYL1: true,
  STK16: true,
  TBK1: true,
  TEX14: true,
  TLK1: true,
  TLK2: true,
  TP53RK: true,
  TTK: true,
  UHMK1: true,
  ULK1: true,
  ULK2: true,
  ULK3: true,
  WEE1: true,
  WEE2: true,
  WNK1: true,
  WNK2: true,
  WNK3: true,
  WNK4: true,
  AANAT: true,
  AARS2: true,
  AATF: true,
  ABCA1: true,
  ABCB1: true,
  ABCC1: true,
  ABCF1: true,
  ABCG2: true,
  ABI1: true,
  ABRAXAS1: true,
  ACACA: true,
  ACACB: true,
  ACADVL: true,
  ACAP1: true,
  ACAT1: true,
  ACBD5: true,
  ACD: true,
  ACE: true,
  ACE2: true,
  ACIN1: true,
  ACLY: true,
  ACO1: true,
  ACOT4: true,
  ACP1: true,
  ACSL4: true,
  ACSS2: true,
  ACTN1: true,
  ACTR2: true,
  ADAM15: true,
  ADAM17: true,
  ADAP1: true,
  ADAR: true,
  ADARB1: true,
  ADCY3: true,
  ADD1: true,
  ADD2: true,
  ADD3: true,
  ADIPOR1: true,
  ADRA1B: true,
  ADRA1D: true,
  ADRA2A: true,
  ADRB1: true,
  ADRB2: true,
  AFAP1: true,
  AFAP1L2: true,
  AFDN: true,
  AGAP2: true,
  AGER: true,
  AGO2: true,
  AGTR1: true,
  AHNAK: true,
  AHR: true,
  AHSA1: true,
  AICDA: true,
  AIFM1: true,
  AIMP1: true,
  AIMP2: true,
  AIP: true,
  AIRE: true,
  AJUBA: true,
  AKAP4: true,
  AKAP5: true,
  AKAP8: true,
  AKAP9: true,
  AKAP11: true,
  AKAP12: true,
  AKAP13: true,
  AKIRIN2: true,
  AKT1S1: true,
  AKTIP: true,
  ALDH1A1: true,
  ALDH2: true,
  ALDH5A1: true,
  ALOX5: true,
  ALYREF: true,
  AMBRA1: true,
  AMFR: true,
  AMOT: true,
  AMOTL1: true,
  AMOTL2: true,
  AMPH: true,
  ANAPC1: true,
  ANAPC4: true,
  ANAPC7: true,
  ANKLE2: true,
  ANKRD2: true,
  ANKRD12: true,
  ANKRD17: true,
  ANKRD28: true,
  ANP32A: true,
  ANP32B: true,
  ANXA1: true,
  ANXA2: true,
  ANXA2P2: true,
  AP1M1: true,
  AP2B1: true,
  AP2M1: true,
  APAF1: true,
  APBA3: true,
  APBB1: true,
  APC: true,
  APEX1: true,
  APLF: true,
  APLP2: true,
  APOBEC3B: true,
  APOBEC3G: true,
  APOE: true,
  APP: true,
  APPL1: true,
  APTX: true,
  AQP1: true,
  AQP2: true,
  AQP4: true,
  AQP5: true,
  AQP9: true,
  AR: true,
  ARAP1: true,
  AREL1: true,
  ARFGEF1: true,
  ARFIP1: true,
  ARFIP2: true,
  ARHGAP5: true,
  ARHGAP8: true,
  ARHGAP15: true,
  ARHGAP17: true,
  ARHGAP19: true,
  ARHGAP21: true,
  ARHGAP22: true,
  ARHGAP23: true,
  ARHGAP24: true,
  ARHGAP31: true,
  ARHGAP33: true,
  ARHGAP35: true,
  ARHGDIA: true,
  ARHGDIB: true,
  ARHGEF1: true,
  ARHGEF2: true,
  ARHGEF4: true,
  ARHGEF6: true,
  ARHGEF7: true,
  ARHGEF17: true,
  ARID1A: true,
  ARID4A: true,
  ARMC10: true,
  ARNT: true,
  ARPC1B: true,
  ARPC5: true,
  ARPP19: true,
  ARRB1: true,
  ARRB2: true,
  ARX: true,
  ASAP3: true,
  ASB2: true,
  ASB8: true,
  ASF1A: true,
  ASF1B: true,
  ASIC1: true,
  ASIC3: true,
  ATAD5: true,
  ATAT1: true,
  ATF1: true,
  ATF2: true,
  ATF4: true,
  ATF6: true,
  ATF7: true,
  ATG4B: true,
  ATG5: true,
  ATG9A: true,
  ATG13: true,
  ATG14: true,
  ATG16L1: true,
  ATG101: true,
  ATIC: true,
  ATN1: true,
  ATP1A1: true,
  ATP2A2: true,
  ATP2B1: true,
  ATP2B2: true,
  ATP2B4: true,
  ATP5F1C: true,
  ATP5IF1: true,
  ATP7A: true,
  ATP7B: true,
  ATRIP: true,
  ATXN1: true,
  ATXN2L: true,
  ATXN3: true,
  ATXN10: true,
  AURKAIP1: true,
  AVEN: true,
  AVPR2: true,
  AXIN1: true,
  AXIN2: true,
  BABAM1: true,
  BACE1: true,
  BACH2: true,
  BAD: true,
  BAG2: true,
  BAG3: true,
  BAG6: true,
  BAIAP2: true,
  BAIAP2L1: true,
  BAK1: true,
  BANF1: true,
  BANP: true,
  BARD1: true,
  BAX: true,
  BAZ2A: true,
  BBC3: true,
  BCAM: true,
  BCAR1: true,
  BCL2: true,
  BCL2L1: true,
  BCL2L11: true,
  BCL2L12: true,
  BCL3: true,
  BCL6: true,
  BCL9: true,
  BCL10: true,
  BCL11B: true,
  BCLAF1: true,
  BDKRB2: true,
  BDNF: true,
  BECN1: true,
  BEST1: true,
  BHLHE40: true,
  BICD1: true,
  BID: true,
  BIK: true,
  BIN1: true,
  BIRC5: true,
  BLM: true,
  BLNK: true,
  BMAL1: true,
  BMF: true,
  BMI1: true,
  BNC1: true,
  BNIP3: true,
  BNIP3L: true,
  BOD1L1: true,
  BORA: true,
  BRCA1: true,
  BRCA2: true,
  BRD9: true,
  BRF1: true,
  BRMS1: true,
  BSG: true,
  BTG1: true,
  BTLA: true,
  BTRC: true,
  BUB3: true,
  BUD13: true,
  C1orf198: true,
  C1R: true,
  C2CD5: true,
  C2orf49: true,
  C5AR1: true,
  C9orf40: true,
  C17orf49: true,
  CA9: true,
  CAAP1: true,
  CABLES1: true,
  CABP1: true,
  CABYR: true,
  CACNA1C: true,
  CACNA1H: true,
  CACNB2: true,
  CACNG2: true,
  CAD: true,
  CALD1: true,
  CALM1: true,
  CALU: true,
  CAMSAP1: true,
  CAMSAP2: true,
  CANX: true,
  CAP1: true,
  CAPN1: true,
  CAPN2: true,
  CAPZA1: true,
  CAPZB: true,
  CARD9: true,
  CARD11: true,
  CARHSP1: true,
  CARM1: true,
  CASP1: true,
  CASP2: true,
  CASP3: true,
  CASP6: true,
  CASP7: true,
  CASP8: true,
  CASP9: true,
  CASQ2: true,
  CASR: true,
  CAST: true,
  CASTOR1: true,
  CAT: true,
  CAV1: true,
  CAV2: true,
  CAVIN1: true,
  CBL: true,
  CBLB: true,
  CBLC: true,
  CBS: true,
  CBX1: true,
  CBX3: true,
  CBX4: true,
  CBX5: true,
  CBX8: true,
  CBY1: true,
  CC2D1A: true,
  CCAR2: true,
  CCDC6: true,
  CCDC50: true,
  CCDC80: true,
  CCDC82: true,
  CCDC86: true,
  CCDC88A: true,
  CCDC88C: true,
  CCM2: true,
  CCNA2: true,
  CCNB1: true,
  CCND1: true,
  CCND2: true,
  CCND3: true,
  CCNE1: true,
  CCNF: true,
  CCNH: true,
  CCNL2: true,
  CCNO: true,
  CCNT1: true,
  CCNY: true,
  CCP110: true,
  CCR2: true,
  CCR5: true,
  CCR7: true,
  CCT2: true,
  CD3E: true,
  CD3G: true,
  CD4: true,
  CD5: true,
  CD6: true,
  CD19: true,
  CD28: true,
  CD33: true,
  CD36: true,
  CD44: true,
  CD46: true,
  CD79A: true,
  CD79B: true,
  CD93: true,
  CD163: true,
  CD226: true,
  CD247: true,
  CD274: true,
  CD300LB: true,
  CD300LF: true,
  CDC5L: true,
  CDC6: true,
  CDC14A: true,
  CDC16: true,
  CDC20: true,
  CDC23: true,
  CDC25A: true,
  CDC25B: true,
  CDC25C: true,
  CDC26: true,
  CDC27: true,
  CDC34: true,
  CDC37: true,
  CDC42: true,
  CDC42EP4: true,
  CDC73: true,
  CDCA2: true,
  CDCA5: true,
  CDCA7: true,
  CDCA8: true,
  CDCP1: true,
  CDH1: true,
  CDH2: true,
  CDH5: true,
  CDK2AP1: true,
  CDK5R1: true,
  CDK5RAP2: true,
  CDK11A: true,
  CDKN1A: true,
  CDKN1B: true,
  CDKN1C: true,
  CDKN2A: true,
  CDKN2D: true,
  CDT1: true,
  CDX2: true,
  CEACAM1: true,
  CEBPA: true,
  CEBPB: true,
  CEBPD: true,
  CELF1: true,
  CENPA: true,
  CENPC: true,
  CENPE: true,
  CENPF: true,
  CENPJ: true,
  CENPQ: true,
  CENPU: true,
  CEP55: true,
  CEP68: true,
  CEP76: true,
  CEP83: true,
  CEP131: true,
  CEP164: true,
  CEP170B: true,
  CEP192: true,
  CEP250: true,
  CERS4: true,
  CERS5: true,
  CERS6: true,
  CETN1: true,
  CETN2: true,
  CFL1: true,
  CFL2: true,
  CFLAR: true,
  CFTR: true,
  CGAS: true,
  CGN: true,
  CHAT: true,
  CHCHD2: true,
  CHCHD3: true,
  CHD4: true,
  CHD7: true,
  CHKA: true,
  CHKB: true,
  CHMP4C: true,
  CHMP7: true,
  CHN2: true,
  CHRM2: true,
  CHRNA7: true,
  CHTF18: true,
  CHTOP: true,
  CIB1: true,
  CIC: true,
  CIITA: true,
  CIP2A: true,
  CITED2: true,
  CKAP2: true,
  CKMT1A: true,
  CKMT1B: true,
  CLASP2: true,
  CLCN3: true,
  CLDN1: true,
  CLDN3: true,
  CLDN4: true,
  CLDN7: true,
  CLDN11: true,
  CLIC5: true,
  CLIP1: true,
  CLOCK: true,
  CLSPN: true,
  CLTB: true,
  CLTC: true,
  CNKSR1: true,
  CNN1: true,
  CNN2: true,
  CNN3: true,
  CNOT2: true,
  CNTROB: true,
  COBLL1: true,
  COIL: true,
  COL4A3: true,
  COL17A1: true,
  COP1: true,
  COPS5: true,
  COPS6: true,
  CORO1A: true,
  CORO1B: true,
  CORO1C: true,
  CORO7: true,
  CREB1: true,
  CREB3: true,
  CREBBP: true,
  CREM: true,
  CRHR1: true,
  CRIP2: true,
  CRK: true,
  CRKL: true,
  CRMP1: true,
  CROCC: true,
  CRTC1: true,
  CRTC2: true,
  CRTC3: true,
  CRYAB: true,
  CSF2RB: true,
  CSF3R: true,
  CSNK2B: true,
  CTBP1: true,
  CTBP2: true,
  CTDP1: true,
  CTDSPL2: true,
  CTH: true,
  CTLA4: true,
  CTNNA1: true,
  CTNNB1: true,
  CTNND1: true,
  CTPS1: true,
  CTPS2: true,
  CTR9: true,
  CTTN: true,
  CTTNBP2NL: true,
  CUEDC2: true,
  CUL5: true,
  CUX1: true,
  CWC25: true,
  CXCR4: true,
  CYBA: true,
  CYCS: true,
  CYLD: true,
  CYP3A4: true,
  CYP17A1: true,
  CYP19A1: true,
  CYSLTR1: true,
  CYTH1: true,
  CYTH2: true,
  DAAM1: true,
  DAB1: true,
  DAB2: true,
  DAB2IP: true,
  DACT1: true,
  DAG1: true,
  DAGLA: true,
  DAP: true,
  DAP3: true,
  DAPP1: true,
  DAXX: true,
  DAZAP1: true,
  DAZL: true,
  DBF4: true,
  DBN1: true,
  DBNL: true,
  DCBLD1: true,
  DCBLD2: true,
  DCK: true,
  DCLRE1C: true,
  DCP1A: true,
  DCTN1: true,
  DCTN6: true,
  DCX: true,
  DDA1: true,
  DDAH2: true,
  DDB1: true,
  DDHD1: true,
  DDIT3: true,
  DDX1: true,
  DDX3X: true,
  DDX3Y: true,
  DDX5: true,
  DDX19A: true,
  DDX19B: true,
  DDX24: true,
  DDX25: true,
  DDX41: true,
  DDX52: true,
  DEF6: true,
  DEK: true,
  DENND3: true,
  DENR: true,
  DEPDC1: true,
  DEPDC5: true,
  DEPTOR: true,
  DES: true,
  DGCR8: true,
  DGKA: true,
  DGKD: true,
  DGKZ: true,
  DHFR: true,
  DHPS: true,
  DHX38: true,
  DIABLO: true,
  DIAPH1: true,
  DIAPH3: true,
  DISC1: true,
  DIXDC1: true,
  DLC1: true,
  DLD: true,
  DLG1: true,
  DLG2: true,
  DLG3: true,
  DLG4: true,
  DLG5: true,
  DLGAP1: true,
  DLGAP5: true,
  DLX3: true,
  DMAP1: true,
  DMTN: true,
  DNAJB1: true,
  DNAJB2: true,
  DNAJC5: true,
  DNAJC6: true,
  DNM1: true,
  DNM1L: true,
  DNM2: true,
  DNMT1: true,
  DNMT3A: true,
  DNPEP: true,
  DOC2B: true,
  DOCK1: true,
  DOCK2: true,
  DOCK6: true,
  DOCK7: true,
  DOCK8: true,
  DOK1: true,
  DOK2: true,
  DPF2: true,
  DPF3: true,
  DPYSL2: true,
  DPYSL3: true,
  DPYSL5: true,
  DRD2: true,
  DRG1: true,
  DRG2: true,
  DROSHA: true,
  DSN1: true,
  DSP: true,
  DSTN: true,
  DTD1: true,
  DTL: true,
  DTNB: true,
  DTNBP1: true,
  DTYMK: true,
  DUOX1: true,
  DUSP1: true,
  DUSP3: true,
  DUSP4: true,
  DUSP5: true,
  DUSP6: true,
  DUSP10: true,
  DUSP16: true,
  DUT: true,
  DVL2: true,
  DVL3: true,
  DYNLL1: true,
  DYNLRB1: true,
  E2F1: true,
  E2F3: true,
  E2F4: true,
  E2F6: true,
  EBNA1BP2: true,
  ECD: true,
  ECE1: true,
  ECT2: true,
  EDC3: true,
  EDC4: true,
  EDF1: true,
  EDNRA: true,
  EEA1: true,
  EEF1A1: true,
  EEF1A2: true,
  EEF1B2: true,
  EEF1D: true,
  EEF2: true,
  EFHD2: true,
  EFNB2: true,
  EGLN1: true,
  EGLN2: true,
  EGR1: true,
  EHMT2: true,
  EIF2A: true,
  EIF2B5: true,
  EIF2S1: true,
  EIF2S2: true,
  EIF2S3: true,
  EIF3F: true,
  EIF3J: true,
  EIF4A3: true,
  EIF4B: true,
  EIF4E: true,
  EIF4EBP1: true,
  EIF4ENIF1: true,
  EIF4G1: true,
  EIF4G2: true,
  EIF4G3: true,
  EIF5: true,
  EIF6: true,
  ELAVL1: true,
  ELF3: true,
  ELF4: true,
  ELK1: true,
  ELK3: true,
  ELK4: true,
  ELL: true,
  ELMO1: true,
  ELMO2: true,
  ELOA: true,
  ELP1: true,
  ELP3: true,
  EMD: true,
  EML3: true,
  EML4: true,
  EMSY: true,
  ENDOG: true,
  ENG: true,
  ENO1: true,
  ENOX2: true,
  ENSA: true,
  EP300: true,
  EP400: true,
  EPAS1: true,
  EPB41: true,
  EPB41L3: true,
  EPM2A: true,
  EPN1: true,
  EPOR: true,
  EPRS1: true,
  EPS8: true,
  EPS15: true,
  ERAL1: true,
  ERCC3: true,
  ERCC6: true,
  ERCC6L: true,
  ERF: true,
  ERG: true,
  ERH: true,
  ERRFI1: true,
  ESPL1: true,
  ESR1: true,
  ESR2: true,
  ETAA1: true,
  ETS1: true,
  ETS2: true,
  ETV1: true,
  ETV5: true,
  ETV6: true,
  EWSR1: true,
  EXO1: true,
  EXOC7: true,
  EXOSC9: true,
  EYA1: true,
  EYA3: true,
  EZH2: true,
  EZR: true,
  F3: true,
  F5: true,
  F11R: true,
  FABP4: true,
  FADD: true,
  FAF1: true,
  FAM83G: true,
  FAM168B: true,
  FANCA: true,
  FANCD2: true,
  FANCE: true,
  FANCG: true,
  FANCI: true,
  FANCL: true,
  FANCM: true,
  FARSA: true,
  FBP1: true,
  FBXL2: true,
  FBXL20: true,
  FBXL21P: true,
  FBXO4: true,
  FBXO5: true,
  FBXO22: true,
  FBXO25: true,
  FBXO31: true,
  FBXO43: true,
  FBXW5: true,
  FBXW7: true,
  FCAR: true,
  FCER1G: true,
  FCGR2A: true,
  FCGR2B: true,
  FCGR2C: true,
  FCRL3: true,
  FEN1: true,
  FERMT2: true,
  FERMT3: true,
  FEZ1: true,
  FFAR4: true,
  FGA: true,
  FGF1: true,
  FGF2: true,
  FGF14: true,
  FH: true,
  FHIP2B: true,
  FHIT: true,
  FHL1: true,
  FHL2: true,
  FHOD1: true,
  FIP1L1: true,
  FIRRM: true,
  FIS1: true,
  FKBP4: true,
  FKBP5: true,
  FLCN: true,
  FLI1: true,
  FLII: true,
  FLNA: true,
  FLNC: true,
  FLOT1: true,
  FLOT2: true,
  FMNL2: true,
  FMR1: true,
  FN1: true,
  FNIP1: true,
  FOS: true,
  FOSB: true,
  FOSL1: true,
  FOSL2: true,
  FOXA1: true,
  FOXA2: true,
  FOXC1: true,
  FOXC2: true,
  FOXG1: true,
  FOXK2: true,
  FOXL2: true,
  FOXM1: true,
  FOXN2: true,
  FOXO1: true,
  FOXO3: true,
  FOXO4: true,
  FOXP3: true,
  FPR1: true,
  FRAT1: true,
  FRS3: true,
  FSCN1: true,
  FTO: true,
  FUBP1: true,
  FUNDC1: true,
  FURIN: true,
  FUS: true,
  FXN: true,
  FXR1: true,
  FXYD1: true,
  FYB1: true,
  FYTTD1: true,
  FZD3: true,
  FZD6: true,
  FZR1: true,
  G3BP1: true,
  G6PD: true,
  GAB1: true,
  GAB2: true,
  GABARAPL2: true,
  GABRG2: true,
  GABRR1: true,
  GAD1: true,
  GADD45GIP1: true,
  GAP43: true,
  GAPDH: true,
  GAPVD1: true,
  GAS2L1: true,
  GAST: true,
  GATA1: true,
  GATA2: true,
  GATA3: true,
  GATA4: true,
  GATA6: true,
  GATAD2A: true,
  GBF1: true,
  GCH1: true,
  GCM1: true,
  GCSAM: true,
  GDI2: true,
  GEM: true,
  GFAP: true,
  GFI1: true,
  GFPT1: true,
  GGA1: true,
  GHR: true,
  GHRL: true,
  GIGYF2: true,
  GINS2: true,
  GIT1: true,
  GIT2: true,
  GJA1: true,
  GJA5: true,
  GJB1: true,
  GKAP1: true,
  GLE1: true,
  GLI1: true,
  GLI2: true,
  GLI3: true,
  GLO1: true,
  GLRA1: true,
  GLRB: true,
  GLS: true,
  GMFB: true,
  GMFG: true,
  GMNN: true,
  GMPR: true,
  GMPS: true,
  GNA13: true,
  GNA15: true,
  GNAI1: true,
  GNAI2: true,
  GNAT1: true,
  GNAZ: true,
  GNMT: true,
  GOLGA2: true,
  GOLGA4: true,
  GOLPH3: true,
  GORASP1: true,
  GORASP2: true,
  GP1BB: true,
  GPI: true,
  GPKOW: true,
  GPM6A: true,
  GPR15: true,
  GPRC5A: true,
  GPS1: true,
  GPSM2: true,
  GPSM3: true,
  GPX1: true,
  GRAP2: true,
  GRB2: true,
  GRB7: true,
  GRB10: true,
  GRB14: true,
  GRHL1: true,
  GRHL3: true,
  GRIA1: true,
  GRIA2: true,
  GRIA4: true,
  GRIK2: true,
  GRIN1: true,
  GRIN2A: true,
  GRIN2B: true,
  GRM1: true,
  GRM5: true,
  GRN: true,
  GSN: true,
  GSR: true,
  GSTA4: true,
  GSTP1: true,
  GTF2A1: true,
  GTF2A1L: true,
  GTF2F1: true,
  GTF2I: true,
  GTF3C1: true,
  GTSE1: true,
  GUCY1B1: true,
  GYS1: true,
  'H1-1': true,
  'H1-2': true,
  'H1-4': true,
  'H1-5': true,
  H2AC1: true,
  H2AC6: true,
  H2AC11: true,
  H2AC13: true,
  H2AC15: true,
  H2AC16: true,
  H2AC17: true,
  H2AC12: true,
  H2AC14: true,
  H2AC18: true,
  H2AC19: true,
  H2AC21: true,
  H2AC25: true,
  H2AX: true,
  H2BC3: true,
  H2BC4: true,
  H2BC6: true,
  H2BC7: true,
  H2BC8: true,
  H2BC10: true,
  H2BC5: true,
  H2BC9: true,
  H2BC11: true,
  H2BC12: true,
  H2BC12L: true,
  H2BC13: true,
  H2BC14: true,
  H2BC15: true,
  H2BC17: true,
  H2BC18: true,
  H2BC19P: true,
  H2BC20P: true,
  H2BC21: true,
  H2BC26: true,
  'H3-3A': true,
  'H3-3B': true,
  'H3-4': true,
  H3C1: true,
  H3C2: true,
  H3C3: true,
  H3C4: true,
  H3C6: true,
  H3C7: true,
  H3C8: true,
  H3C10: true,
  H3C11: true,
  H3C12: true,
  H3C13: true,
  H3C14: true,
  H3C15: true,
  H4C1: true,
  H4C2: true,
  H4C3: true,
  H4C4: true,
  H4C5: true,
  H4C6: true,
  H4C8: true,
  H4C9: true,
  H4C11: true,
  H4C12: true,
  H4C13: true,
  H4C14: true,
  H4C15: true,
  H4C16: true,
  HABP4: true,
  HACE1: true,
  HAND1: true,
  HAS2: true,
  HAT1: true,
  HAUS8: true,
  HAVCR2: true,
  HAX1: true,
  HBP1: true,
  HCLS1: true,
  HCN4: true,
  HCRTR1: true,
  HDAC1: true,
  HDAC2: true,
  HDAC3: true,
  HDAC4: true,
  HDAC5: true,
  HDAC6: true,
  HDAC7: true,
  HDAC8: true,
  HDAC9: true,
  HDLBP: true,
  HECTD3: true,
  HELLS: true,
  HES1: true,
  HES5: true,
  HES6: true,
  HEXIM1: true,
  HGS: true,
  HHEX: true,
  HIC1: true,
  HIF1A: true,
  HIP1: true,
  HIRA: true,
  HIVEP1: true,
  HJURP: true,
  HK1: true,
  HK2: true,
  'HLA-A': true,
  'HLA-B': true,
  HMGA1: true,
  HMGA2: true,
  HMGB1: true,
  HMGCR: true,
  HMGCS1: true,
  HMGN1: true,
  HMGN2: true,
  HMGN5: true,
  HMOX1: true,
  HMOX2: true,
  HNF1A: true,
  HNF4A: true,
  HNRNPA0: true,
  HNRNPA1: true,
  HNRNPA2B1: true,
  HNRNPC: true,
  HNRNPD: true,
  HNRNPF: true,
  HNRNPH1: true,
  HNRNPH2: true,
  HNRNPK: true,
  HNRNPL: true,
  HNRNPM: true,
  HNRNPU: true,
  HNRNPUL1: true,
  HOXA11: true,
  HOXB7: true,
  HRAS: true,
  HRH1: true,
  HSD17B4: true,
  HSF1: true,
  HSF4: true,
  HSP90AA1: true,
  HSP90AB1: true,
  HSP90B1: true,
  HSPA1A: true,
  HSPA1L: true,
  HSPA9: true,
  HSPB1: true,
  HSPB6: true,
  HSPB8: true,
  HSPH1: true,
  HTR1A: true,
  HTR6: true,
  HTRA2: true,
  HTT: true,
  HUWE1: true,
  HVCN1: true,
  IBTK: true,
  ICAM3: true,
  ICOSLG: true,
  ID1: true,
  ID2: true,
  ID3: true,
  IDH1: true,
  IDI1: true,
  IER3: true,
  IFI16: true,
  IFIH1: true,
  IFITM3: true,
  IFNAR1: true,
  IFNGR1: true,
  IGF2R: true,
  IGFBP1: true,
  IGFBP3: true,
  IKBKG: true,
  IKZF1: true,
  IKZF3: true,
  IL2RA: true,
  IL2RB: true,
  IL6ST: true,
  IL10RA: true,
  IL15RA: true,
  IL16: true,
  IL17RA: true,
  IL17RB: true,
  ILF3: true,
  ILRUN: true,
  IMMT: true,
  IMPDH2: true,
  INCA1: true,
  INCENP: true,
  INF2: true,
  ING1: true,
  ING5: true,
  INO80E: true,
  INPPL1: true,
  INSIG1: true,
  INVS: true,
  IP6K2: true,
  IQGAP1: true,
  IQGAP2: true,
  IRAG1: true,
  IREB2: true,
  IRF1: true,
  IRF3: true,
  IRF5: true,
  IRF7: true,
  IRS1: true,
  IRS2: true,
  IRS4: true,
  ISCU: true,
  ISL1: true,
  ISX: true,
  ITCH: true,
  ITGA2: true,
  ITGA4: true,
  ITGB1: true,
  ITGB1BP1: true,
  ITGB2: true,
  ITGB3: true,
  ITGB3BP: true,
  ITGB4: true,
  ITGB5: true,
  ITGB7: true,
  ITM2A: true,
  ITPKA: true,
  ITPKB: true,
  ITPR1: true,
  IWS1: true,
  JADE1: true,
  JDP2: true,
  JPH2: true,
  JPT2: true,
  JUN: true,
  JUNB: true,
  JUND: true,
  JUP: true,
  KARS1: true,
  KAT2A: true,
  KAT2B: true,
  KAT5: true,
  KAT6A: true,
  KAT7: true,
  KAT8: true,
  KATNA1: true,
  KCNA1: true,
  KCNA3: true,
  KCNA4: true,
  KCNA5: true,
  KCNAB2: true,
  KCNB1: true,
  KCNC4: true,
  KCND1: true,
  KCND2: true,
  KCND3: true,
  KCNE1: true,
  KCNE3: true,
  KCNH2: true,
  KCNIP3: true,
  KCNJ1: true,
  KCNJ2: true,
  KCNJ3: true,
  KCNJ4: true,
  KCNJ5: true,
  KCNJ11: true,
  KCNJ12: true,
  KCNJ13: true,
  KCNK3: true,
  KCNK9: true,
  KCNMA1: true,
  KCNN1: true,
  KCNN2: true,
  KCNN4: true,
  KCNQ1: true,
  KCNQ2: true,
  KCNQ5: true,
  KCTD12: true,
  KDELR1: true,
  KDM1A: true,
  KDM2A: true,
  KDM3A: true,
  KDM4B: true,
  KDM4C: true,
  KDM5A: true,
  KDM5B: true,
  KEAP1: true,
  KHDC3L: true,
  KHDRBS1: true,
  KHSRP: true,
  KIDINS220: true,
  KIF1C: true,
  KIF2A: true,
  KIF2B: true,
  KIF2C: true,
  KIF3A: true,
  KIF4A: true,
  KIF11: true,
  KIF13B: true,
  KIF14: true,
  KIF20A: true,
  KIF20B: true,
  KIF22: true,
  KIF23: true,
  KIFC1: true,
  KIR3DL1: true,
  KIZ: true,
  KLC1: true,
  KLC2: true,
  KLC4: true,
  KLF1: true,
  KLF3: true,
  KLF4: true,
  KLF5: true,
  KLF8: true,
  KLF10: true,
  KLF16: true,
  KLHL3: true,
  KMT2A: true,
  KMT2D: true,
  KMT2E: true,
  KMT5A: true,
  KNL1: true,
  KNSTRN: true,
  KPNA1: true,
  KPNA2: true,
  KRAS: true,
  KRT8: true,
  KRT18: true,
  KRT19: true,
  KRT20: true,
  L1CAM: true,
  L3MBTL2: true,
  LAMC1: true,
  LAMP2: true,
  LAMTOR4: true,
  LAMTOR5: true,
  LARP1: true,
  LARP6: true,
  LARP7: true,
  LARS1: true,
  LASP1: true,
  LAT: true,
  LAT2: true,
  LAX1: true,
  LBR: true,
  LCP1: true,
  LCP2: true,
  LDHA: true,
  LDHB: true,
  LEF1: true,
  LETM1: true,
  LGALS3: true,
  LGMN: true,
  LIFR: true,
  LIG1: true,
  LIG3: true,
  LIG4: true,
  LIMA1: true,
  LIMD1: true,
  LIN9: true,
  LIN28A: true,
  LIN28B: true,
  LIN52: true,
  LIPE: true,
  LLGL1: true,
  LMNA: true,
  LMNB1: true,
  LMNB2: true,
  LMO7: true,
  LONP1: true,
  LPIN1: true,
  LPP: true,
  LPXN: true,
  LRCH3: true,
  LRP1: true,
  LRP6: true,
  LRRC4C: true,
  LRRC7: true,
  LRRC8A: true,
  LRRC45: true,
  LRWD1: true,
  LSP1: true,
  LTB4R: true,
  LTB4R2: true,
  LTC4S: true,
  LY96: true,
  LYL1: true,
  LZTS2: true,
  MACC1: true,
  MACF1: true,
  MAD1L1: true,
  MAD2L1: true,
  MAD2L1BP: true,
  MAF1: true,
  MAFA: true,
  MAFG: true,
  MAG: true,
  MAGEA11: true,
  MAGI1: true,
  MALT1: true,
  MAOA: true,
  MAP1A: true,
  MAP1LC3A: true,
  MAP1LC3B: true,
  MAP1LC3C: true,
  MAP1S: true,
  MAP2: true,
  MAP4: true,
  MAP7: true,
  MAP9: true,
  MAPK8IP1: true,
  MAPK8IP3: true,
  MAPKAP1: true,
  MAPRE1: true,
  MAPRE2: true,
  MAPRE3: true,
  MAPT: true,
  MARCKS: true,
  MARCKSL1: true,
  MARS1: true,
  MAT1A: true,
  MATR3: true,
  MAVS: true,
  MAX: true,
  MAZ: true,
  MBD3: true,
  MBD4: true,
  MBP: true,
  MC4R: true,
  MCC: true,
  MCL1: true,
  MCM2: true,
  MCM3: true,
  MCM4: true,
  MCM5: true,
  MCM7: true,
  MCOLN1: true,
  MCPH1: true,
  MCRIP1: true,
  MCRS1: true,
  MCTS1: true,
  MCU: true,
  MDC1: true,
  MDM2: true,
  MDM4: true,
  ME1: true,
  MECOM: true,
  MECP2: true,
  MED1: true,
  MED14: true,
  MED28: true,
  MEF2A: true,
  MEF2B: true,
  MEF2C: true,
  MEF2D: true,
  MEFV: true,
  MEGF10: true,
  MEN1: true,
  MEP1B: true,
  METTL1: true,
  METTL3: true,
  MFAP1: true,
  MFF: true,
  MFN2: true,
  MICAL2: true,
  MICALL1: true,
  MIIP: true,
  MIP: true,
  MIS18A: true,
  MISP: true,
  MITF: true,
  MKI67: true,
  MLH1: true,
  MLLT1: true,
  MLXIPL: true,
  MME: true,
  MMP2: true,
  MMP14: true,
  MOB1A: true,
  MOB1B: true,
  MORC2: true,
  MPHOSPH10: true,
  MPLKIP: true,
  MPZL1: true,
  MRE11: true,
  MRPS18B: true,
  MRTFA: true,
  MRTO4: true,
  MS4A1: true,
  MSN: true,
  MSX2: true,
  MTA1: true,
  MTA2: true,
  MTF1: true,
  MTHFD1L: true,
  MTHFR: true,
  MTMR2: true,
  MTSS1: true,
  MTURN: true,
  MUC1: true,
  MUS81: true,
  MVD: true,
  MXD1: true,
  MYB: true,
  MYBBP1A: true,
  MYBL1: true,
  MYBL2: true,
  MYBPC3: true,
  MYC: true,
  MYCBP2: true,
  MYCL: true,
  MYCN: true,
  MYF5: true,
  MYH2: true,
  MYH9: true,
  MYH10: true,
  MYL2: true,
  MYL9: true,
  MYL12A: true,
  MYL12B: true,
  MYO6: true,
  MYOCD: true,
  MYOD1: true,
  MYOM2: true,
  MYT1: true,
  MZF1: true,
  NABP2: true,
  NACA: true,
  NADK: true,
  NAGK: true,
  NANOG: true,
  NASP: true,
  NBN: true,
  NBR1: true,
  NCAM1: true,
  NCAPD3: true,
  NCAPG: true,
  NCAPH: true,
  NCAPH2: true,
  NCBP1: true,
  NCBP3: true,
  NCF1: true,
  NCF2: true,
  NCF4: true,
  NCK1: true,
  NCKAP5L: true,
  NCKIPSD: true,
  NCL: true,
  NCOA1: true,
  NCOA2: true,
  NCOA3: true,
  NCOA6: true,
  NCOR1: true,
  NCOR2: true,
  NCSTN: true,
  NDC80: true,
  NDE1: true,
  NDEL1: true,
  NDFIP2: true,
  NDRG1: true,
  NDRG2: true,
  NDUFA12: true,
  NDUFB6: true,
  NDUFS2: true,
  NDUFS4: true,
  NDUFV1: true,
  NDUFV2: true,
  NDUFV3: true,
  NEDD1: true,
  NEDD4: true,
  NEDD4L: true,
  NEDD8: true,
  NEDD9: true,
  NEFH: true,
  NEFL: true,
  NEIL1: true,
  NELFA: true,
  NELFCD: true,
  NELFE: true,
  NES: true,
  NET1: true,
  NF1: true,
  NF2: true,
  NFAT5: true,
  NFATC1: true,
  NFATC3: true,
  NFATC4: true,
  NFE2: true,
  NFE2L1: true,
  NFE2L2: true,
  NFKB1: true,
  NFKB2: true,
  NFKBIA: true,
  NFKBIB: true,
  NFYA: true,
  NGEF: true,
  NGFR: true,
  NHEJ1: true,
  NHERF1: true,
  NHERF2: true,
  NHS: true,
  NIBAN1: true,
  NIBAN2: true,
  NIFK: true,
  NINL: true,
  NKD2: true,
  'NKX2-5': true,
  'NKX3-1': true,
  NLGN4X: true,
  NLRP3: true,
  NME1: true,
  NME2: true,
  NMNAT1: true,
  NMT1: true,
  NOB1: true,
  NOL3: true,
  NOLC1: true,
  NOP53: true,
  NOS1: true,
  NOS2: true,
  NOS3: true,
  NOSIP: true,
  NOTCH1: true,
  NOTCH2: true,
  NOVA2: true,
  NOX4: true,
  NOX5: true,
  NOXA1: true,
  NOXO1: true,
  NPAT: true,
  NPFFR2: true,
  NPHP1: true,
  NPHS1: true,
  NPM1: true,
  NQO1: true,
  NR1D1: true,
  NR1H2: true,
  NR1H3: true,
  NR1H4: true,
  NR1I2: true,
  NR1I3: true,
  NR2F6: true,
  NR3C1: true,
  NR3C2: true,
  NR4A1: true,
  NR4A2: true,
  NR4A3: true,
  NR5A1: true,
  NR5A2: true,
  NRAS: true,
  NR0B2: true,
  NRBF2: true,
  NREP: true,
  NRF1: true,
  NRG1: true,
  NRGN: true,
  NRXN1: true,
  NSD2: true,
  NSF: true,
  NSFL1C: true,
  NSUN2: true,
  NUCKS1: true,
  NUDC: true,
  NUFIP2: true,
  NUMA1: true,
  NUMB: true,
  NUP50: true,
  NUP62: true,
  NUP98: true,
  NUP107: true,
  NUP133: true,
  NUP153: true,
  NUP210: true,
  NUP214: true,
  NUSAP1: true,
  OCLN: true,
  ODF2: true,
  OFD1: true,
  OGFOD1: true,
  OGFR: true,
  OGT: true,
  OPRD1: true,
  OPRK1: true,
  OPRL1: true,
  OPRM1: true,
  OPTN: true,
  ORAI1: true,
  ORC1: true,
  ORC2: true,
  ORC6: true,
  OSBP: true,
  OSBP2: true,
  OSBPL11: true,
  OSTF1: true,
  OTUB1: true,
  OTUD5: true,
  OTULIN: true,
  OXTR: true,
  PA2G4: true,
  PABIR1: true,
  PACS1: true,
  PACS2: true,
  PACSIN1: true,
  PACSIN2: true,
  PAG1: true,
  PAGE4: true,
  PAH: true,
  PAICS: true,
  PALLD: true,
  PAM: true,
  PAN2: true,
  PANX1: true,
  PAQR3: true,
  PARD3: true,
  PARD6A: true,
  PARK7: true,
  PARN: true,
  PARP1: true,
  PARP10: true,
  PARVA: true,
  PATL1: true,
  PAWR: true,
  PAX3: true,
  PAX6: true,
  PAX8: true,
  PBRM1: true,
  PBXIP1: true,
  PCBP1: true,
  PCBP2: true,
  PCF11: true,
  PCGF2: true,
  PCGF6: true,
  PCK1: true,
  PCM1: true,
  PCNA: true,
  PCNT: true,
  PDC: true,
  PDCD4: true,
  PDCD5: true,
  PDCD6IP: true,
  PDCD10: true,
  PDCL: true,
  PDE3A: true,
  PDE3B: true,
  PDE4A: true,
  PDE4B: true,
  PDE4C: true,
  PDE4D: true,
  PDE5A: true,
  PDE6G: true,
  PDE8A: true,
  PDE10A: true,
  PDE11A: true,
  PDHA1: true,
  PDHA2: true,
  PDIA3: true,
  PDIA6: true,
  PDP1: true,
  PDS5B: true,
  PDX1: true,
  PDZK1: true,
  PEA15: true,
  PEBP1: true,
  PECAM1: true,
  PELI1: true,
  PELI2: true,
  PELP1: true,
  PER2: true,
  PER3: true,
  PEX5: true,
  PEX14: true,
  PFKFB2: true,
  PFKFB3: true,
  PFKM: true,
  PFKP: true,
  PFN1: true,
  PGAM1: true,
  PGD: true,
  PGK1: true,
  PGM1: true,
  PGR: true,
  PHACTR3: true,
  PHB1: true,
  PHB2: true,
  PHF2: true,
  PHF6: true,
  PHF8: true,
  PHF14: true,
  PHF20: true,
  PHF23: true,
  PHGDH: true,
  PHKA1: true,
  PHLDA1: true,
  PHLPP1: true,
  PHOX2A: true,
  PI4KB: true,
  PIAS1: true,
  PIAS4: true,
  PICK1: true,
  PIDD1: true,
  PIK3AP1: true,
  PIK3C2A: true,
  PIK3C3: true,
  PIK3CA: true,
  PIK3CB: true,
  PIK3CD: true,
  PIK3CG: true,
  PIK3R1: true,
  PIK3R3: true,
  PIK3R6: true,
  PIKFYVE: true,
  PIMREG: true,
  PIN1: true,
  PIN4: true,
  PINX1: true,
  PIP4K2A: true,
  PIP4K2B: true,
  PIP4K2C: true,
  PIP5K1B: true,
  PIP5K1C: true,
  PITPNA: true,
  PITPNC1: true,
  PITPNM1: true,
  PJA2: true,
  PKD1: true,
  PKD2: true,
  PKD2L1: true,
  PKIA: true,
  PKM: true,
  PKP1: true,
  PKP2: true,
  PKP3: true,
  PLA2G4A: true,
  PLAU: true,
  PLCB1: true,
  PLCB3: true,
  PLCG1: true,
  PLCG2: true,
  PLD1: true,
  PLD2: true,
  PLEC: true,
  PLEK: true,
  PLEKHG1: true,
  PLEKHG2: true,
  PLEKHG6: true,
  PLIN1: true,
  PLIN2: true,
  PLIN3: true,
  PLN: true,
  PLRG1: true,
  PLSCR1: true,
  PLSCR3: true,
  PLSCR4: true,
  PLXNA1: true,
  PLXNA2: true,
  PLXNB1: true,
  PMAIP1: true,
  PML: true,
  PNKP: true,
  PNPLA8: true,
  PNPT1: true,
  POLD2: true,
  POLD3: true,
  POLDIP3: true,
  POLH: true,
  POLL: true,
  POLM: true,
  POLR1G: true,
  POLR2A: true,
  POP4: true,
  POU2F1: true,
  POU3F2: true,
  POU5F1: true,
  POU6F1: true,
  PPARA: true,
  PPARD: true,
  PPARG: true,
  PPARGC1A: true,
  PPAT: true,
  PPFIA1: true,
  PPHLN1: true,
  PPIF: true,
  PPM1B: true,
  PPM1D: true,
  PPM1G: true,
  PPME1: true,
  PPP1CA: true,
  PPP1CC: true,
  PPP1R1A: true,
  PPP1R1B: true,
  PPP1R1C: true,
  PPP1R2: true,
  PPP1R2B: true,
  PPP1R3A: true,
  PPP1R3C: true,
  PPP1R7: true,
  PPP1R8: true,
  PPP1R9A: true,
  PPP1R9B: true,
  PPP1R12A: true,
  PPP1R12B: true,
  PPP1R12C: true,
  PPP1R13L: true,
  PPP1R14A: true,
  PPP1R14B: true,
  PPP1R14C: true,
  PPP1R14D: true,
  PPP1R16B: true,
  PPP1R17: true,
  PPP1R26: true,
  PPP1R35: true,
  PPP2CA: true,
  PPP2R2B: true,
  PPP2R5A: true,
  PPP2R5C: true,
  PPP2R5D: true,
  PPP5C: true,
  PPP6R2: true,
  PQBP1: true,
  PRC1: true,
  PRDX1: true,
  PRDX2: true,
  PRDX6: true,
  PREB: true,
  PREX1: true,
  PREX2: true,
  PRH1: true,
  PRH2: true,
  PRKAB1: true,
  PRKAG1: true,
  PRKAR1A: true,
  PRKAR2B: true,
  PRKN: true,
  PRL: true,
  PRLR: true,
  PRMT5: true,
  PRNP: true,
  PROM1: true,
  PROSER2: true,
  PRPF3: true,
  PRPF19: true,
  PRPF31: true,
  PRPS1: true,
  PRPS2: true,
  PRR5: true,
  PRRC2A: true,
  PRRC2B: true,
  PRRC2C: true,
  PSEN1: true,
  PSEN2: true,
  PSIP1: true,
  PSMA3: true,
  PSMA5: true,
  PSMA7: true,
  PSMC4: true,
  PSMC5: true,
  PSMD2: true,
  PSMD3: true,
  PSMD11: true,
  PSME3: true,
  PSTPIP1: true,
  PTBP1: true,
  PTEN: true,
  PTGES3: true,
  PTGIR: true,
  PTGS2: true,
  PTH1R: true,
  PTHLH: true,
  PTOV1: true,
  PTPN1: true,
  PTPN2: true,
  PTPN3: true,
  PTPN6: true,
  PTPN7: true,
  PTPN11: true,
  PTPN12: true,
  PTPN18: true,
  PTPN22: true,
  PTPRA: true,
  PTPRC: true,
  PTPRCAP: true,
  PTPRF: true,
  PTPRJ: true,
  PTPRR: true,
  PTPRT: true,
  PTRH2: true,
  PTS: true,
  PTTG1: true,
  PTTG1IP: true,
  PTTG2: true,
  PXN: true,
  PYCARD: true,
  PYCR1: true,
  PYCR2: true,
  PYGB: true,
  PYGO2: true,
  QTRT1: true,
  RAB1A: true,
  RAB3IP: true,
  RAB4A: true,
  RAB5A: true,
  RAB5B: true,
  RAB8A: true,
  RAB10: true,
  RAB11A: true,
  RAB11FIP1: true,
  RAB11FIP2: true,
  RAB11FIP3: true,
  RAB11FIP5: true,
  RAB34: true,
  RAB37: true,
  RABEP1: true,
  RAC1: true,
  RACGAP1: true,
  RACK1: true,
  RAD1: true,
  RAD9A: true,
  RAD17: true,
  RAD18: true,
  RAD21: true,
  RAD50: true,
  RAD51: true,
  RAD52: true,
  RAD54L: true,
  RAG2: true,
  RALA: true,
  RALB: true,
  RALBP1: true,
  RALGAPA2: true,
  RALY: true,
  RAMAC: true,
  RAN: true,
  RANBP2: true,
  RANBP3: true,
  RANBP9: true,
  RANGAP1: true,
  RAP1A: true,
  RAP1B: true,
  RAP1GAP: true,
  RAP1GAP2: true,
  RAPGEF1: true,
  RAPGEF2: true,
  RAPGEF3: true,
  RAPH1: true,
  RARA: true,
  RARG: true,
  RASA1: true,
  RASAL2: true,
  RASD2: true,
  RASGRF1: true,
  RASGRF2: true,
  RASGRP1: true,
  RASGRP2: true,
  RASGRP3: true,
  RASSF1: true,
  RAVER1: true,
  RB1: true,
  RB1CC1: true,
  RBBP5: true,
  RBBP7: true,
  RBBP8: true,
  RBL1: true,
  RBL2: true,
  RBM3: true,
  RBM4: true,
  RBM7: true,
  RBM8A: true,
  RBM10: true,
  RBM14: true,
  RBM17: true,
  RBM26: true,
  RBM27: true,
  RBM28: true,
  RBM38: true,
  RBM39: true,
  RBM42: true,
  RBMX: true,
  RBMXL1: true,
  RBPJ: true,
  RBSN: true,
  RCAN1: true,
  RCC1: true,
  RCHY1: true,
  RCSD1: true,
  RDX: true,
  RECQL4: true,
  RECQL5: true,
  REEP1: true,
  REEP2: true,
  REL: true,
  RELA: true,
  RELB: true,
  REM1: true,
  REPS1: true,
  REPS2: true,
  REST: true,
  RETREG1: true,
  REV3L: true,
  RFC1: true,
  RFWD3: true,
  RFX1: true,
  RGCC: true,
  RGPD3: true,
  RGPD4: true,
  RGPD5: true,
  RGPD6: true,
  RGPD8: true,
  RGS2: true,
  RGS7: true,
  RGS10: true,
  RGS13: true,
  RGS14: true,
  RGS16: true,
  RGS18: true,
  RGS19: true,
  RHBG: true,
  RHEB: true,
  RHO: true,
  RHOA: true,
  RHOB: true,
  RHOT1: true,
  RHOU: true,
  RICTOR: true,
  RIF1: true,
  RIGI: true,
  RIN1: true,
  RIT1: true,
  RMDN3: true,
  RMI2: true,
  RND3: true,
  RNF4: true,
  RNF7: true,
  RNF11: true,
  RNF20: true,
  RNF40: true,
  RNF41: true,
  RNF138: true,
  RNMT: true,
  RNPS1: true,
  ROBO1: true,
  ROBO3: true,
  RORA: true,
  RORC: true,
  RPA2: true,
  RPAP3: true,
  RPL7A: true,
  RPL8: true,
  RPL10: true,
  RPL11: true,
  RPL12: true,
  RPL13: true,
  RPL13A: true,
  RPL19: true,
  RPL21: true,
  RPLP2: true,
  RPRD1B: true,
  RPS3: true,
  RPS3A: true,
  RPS6: true,
  RPS10: true,
  RPS11: true,
  RPS15: true,
  RPS19: true,
  RPS27: true,
  RPS27L: true,
  RPTOR: true,
  RRAD: true,
  RRAGC: true,
  RRAS: true,
  RRAS2: true,
  RRM1: true,
  RRM2: true,
  RRM2B: true,
  RRN3: true,
  RRP12: true,
  RSC1A1: true,
  RSF1: true,
  RSL1D1: true,
  RSPH3: true,
  RTCB: true,
  RTKN: true,
  RTN4: true,
  RUBCNL: true,
  RUFY1: true,
  RUNX1: true,
  RUNX2: true,
  RUNX3: true,
  RUVBL1: true,
  RXRA: true,
  RYR1: true,
  RYR2: true,
  S1PR1: true,
  S100A9: true,
  SAMHD1: true,
  SARM1: true,
  SARS1: true,
  SASH1: true,
  SAT1: true,
  SATB1: true,
  SAV1: true,
  SCAF11: true,
  SCAMP1: true,
  SCAMP3: true,
  SCD: true,
  SCML2: true,
  SCN5A: true,
  SCN8A: true,
  SCN9A: true,
  SCNN1G: true,
  SCRIB: true,
  SDC1: true,
  SDC2: true,
  SDC3: true,
  SDC4: true,
  SDCBP: true,
  SDHA: true,
  SEC14L2: true,
  SEC16A: true,
  SEC23A: true,
  SEC23B: true,
  SEC63: true,
  SECISBP2L: true,
  SENP1: true,
  SENP2: true,
  SENP3: true,
  SEPTIN1: true,
  SEPTIN2: true,
  SEPTIN3: true,
  SEPTIN5: true,
  SEPTIN7: true,
  SEPTIN8: true,
  SEPTIN9: true,
  SERINC3: true,
  SERINC5: true,
  SERPINF1: true,
  SESN2: true,
  SET: true,
  SETDB1: true,
  SETMAR: true,
  SF1: true,
  SF3B1: true,
  SF3B2: true,
  SFN: true,
  SFPQ: true,
  SGO1: true,
  SH2B1: true,
  SH2B2: true,
  SH2B3: true,
  SH2D2A: true,
  SH2D4A: true,
  SH3BP2: true,
  SH3BP4: true,
  SH3BP5: true,
  SH3GL1: true,
  SH3GL2: true,
  SH3GLB1: true,
  SH3PXD2B: true,
  SH3RF1: true,
  SHARPIN: true,
  SHC1: true,
  SHC3: true,
  SHC4: true,
  SHCBP1: true,
  SHOC2: true,
  SHOX: true,
  SI: true,
  SIAH1: true,
  SIAH2: true,
  SIGIRR: true,
  SIGLEC10: true,
  SIKE1: true,
  SIM2: true,
  SIN3A: true,
  SIRPA: true,
  SIRT1: true,
  SIRT2: true,
  SIRT3: true,
  SIRT6: true,
  SIRT7: true,
  SIVA1: true,
  SKA3: true,
  SKAP1: true,
  SKI: true,
  SKP2: true,
  SLA: true,
  SLAIN2: true,
  SLAMF1: true,
  SLBP: true,
  SLC1A3: true,
  SLC2A1: true,
  SLC2A2: true,
  SLC2A4: true,
  SLC3A2: true,
  SLC4A1: true,
  SLC4A3: true,
  SLC4A4: true,
  SLC6A2: true,
  SLC6A3: true,
  SLC6A4: true,
  SLC6A9: true,
  SLC7A11: true,
  SLC8B1: true,
  SLC9A1: true,
  SLC9A3: true,
  SLC9A5: true,
  SLC11A1: true,
  SLC12A1: true,
  SLC12A2: true,
  SLC12A3: true,
  SLC12A4: true,
  SLC12A5: true,
  SLC12A6: true,
  SLC12A7: true,
  SLC18A2: true,
  SLC25A4: true,
  SLC25A5: true,
  SLC26A4: true,
  SLC29A1: true,
  SLCO1B1: true,
  SLITRK1: true,
  SLN: true,
  SLTM: true,
  SLX4: true,
  SMAD1: true,
  SMAD2: true,
  SMAD3: true,
  SMAD4: true,
  SMAD6: true,
  SMAD9: true,
  SMARCA4: true,
  SMARCAL1: true,
  SMARCC2: true,
  SMARCD3: true,
  SMC1A: true,
  SMC3: true,
  SMCR8: true,
  SMN1: true,
  SMN2: true,
  SMPD1: true,
  SMPD3: true,
  SMTN: true,
  SMURF2: true,
  SNAI1: true,
  SNAI2: true,
  SNAP23: true,
  SNAP25: true,
  SNAP29: true,
  SNAPIN: true,
  SNCA: true,
  SNCAIP: true,
  SNCB: true,
  SNCG: true,
  SND1: true,
  SNPH: true,
  SNRNP70: true,
  SNTA1: true,
  SNW1: true,
  SNX8: true,
  SNX17: true,
  SNX27: true,
  SOCS1: true,
  SOCS3: true,
  SOD1: true,
  SOD2: true,
  SORBS2: true,
  SORBS3: true,
  SORL1: true,
  SORT1: true,
  SOS1: true,
  SOX2: true,
  SOX8: true,
  SOX9: true,
  SP1: true,
  SP3: true,
  SPAG1: true,
  SPAG5: true,
  SPAG9: true,
  SPAST: true,
  SPDEF: true,
  SPDL1: true,
  SPEN: true,
  SPHK1: true,
  SPHK2: true,
  SPI1: true,
  SPIB: true,
  SPIC: true,
  SPIN1: true,
  SPN: true,
  SPOP: true,
  SPP1: true,
  SPR: true,
  SPRED1: true,
  SPRY2: true,
  SPTAN1: true,
  SPTBN1: true,
  SPTBN4: true,
  SPTLC1: true,
  SQSTM1: true,
  SRCIN1: true,
  SREBF1: true,
  SREBF2: true,
  SRF: true,
  SRI: true,
  SRRM1: true,
  SRRM2: true,
  SRRT: true,
  SRSF1: true,
  SRSF2: true,
  SRSF3: true,
  SRSF9: true,
  SRSF11: true,
  SSB: true,
  SSBP1: true,
  SSBP3: true,
  SSH1: true,
  SSH3: true,
  SSR1: true,
  SSRP1: true,
  SSTR2: true,
  SSTR5: true,
  SSU72: true,
  ST13: true,
  STAM2: true,
  STAMBP: true,
  STAP2: true,
  STAR: true,
  STARD10: true,
  STAT1: true,
  STAT2: true,
  STAT3: true,
  STAT4: true,
  STAT5A: true,
  STAT5B: true,
  STAT6: true,
  STBD1: true,
  STC2: true,
  STIL: true,
  STIM1: true,
  STING1: true,
  STK11IP: true,
  STMN1: true,
  STMN2: true,
  STMN3: true,
  STOML2: true,
  STRAP: true,
  STX1A: true,
  STX17: true,
  STXBP1: true,
  STXBP2: true,
  SUB1: true,
  SUCLA2: true,
  SUDS3: true,
  SUFU: true,
  SUGP2: true,
  SUGT1: true,
  SULT4A1: true,
  SUN1: true,
  SUN2: true,
  SUPT5H: true,
  SUPT6H: true,
  SUPT16H: true,
  SUV39H1: true,
  SUZ12: true,
  SV2A: true,
  SVIL: true,
  SYMPK: true,
  SYN1: true,
  SYN2: true,
  SYN3: true,
  SYNCRIP: true,
  SYNGAP1: true,
  SYNJ2: true,
  SYT4: true,
  SYTL1: true,
  TAB1: true,
  TAB3: true,
  TACC1: true,
  TACC3: true,
  TACSTD2: true,
  TAF7: true,
  TAGAP: true,
  TAGLN: true,
  TAGLN2: true,
  TAL1: true,
  TAL2: true,
  TANC1: true,
  TARBP2: true,
  TARDBP: true,
  TAX1BP1: true,
  TBC1D1: true,
  TBC1D4: true,
  TBC1D7: true,
  TBCB: true,
  TBCE: true,
  TBL1XR1: true,
  TBL2: true,
  TBX3: true,
  TBX21: true,
  TBXA2R: true,
  TCAP: true,
  TCF3: true,
  TCF7L2: true,
  TCOF1: true,
  TDP1: true,
  TDP2: true,
  TELO2: true,
  TENT2: true,
  TERF1: true,
  TERF2: true,
  TERF2IP: true,
  TERT: true,
  TET2: true,
  TFAM: true,
  TFAP2A: true,
  TFCP2: true,
  TFCP2L1: true,
  TFDP1: true,
  TFE3: true,
  TFEB: true,
  TFG: true,
  TFPI: true,
  TFPT: true,
  TFRC: true,
  TGFB1I1: true,
  TGFBR3: true,
  TGM2: true,
  TGS1: true,
  TH: true,
  THEMIS: true,
  THOC5: true,
  THOP1: true,
  THRAP3: true,
  THRB: true,
  TIAM1: true,
  TICAM1: true,
  TICAM2: true,
  TICRR: true,
  TIFA: true,
  TIGIT: true,
  TIMP2: true,
  TINF2: true,
  TIRAP: true,
  TK1: true,
  TKT: true,
  TLE1: true,
  TLN1: true,
  TLR3: true,
  TLR4: true,
  TLR5: true,
  TMIGD2: true,
  TMOD1: true,
  TMPO: true,
  TNF: true,
  TNFAIP1: true,
  TNFAIP3: true,
  TNFAIP8L2: true,
  TNFRSF1A: true,
  TNKS: true,
  TNKS1BP1: true,
  TNNI3: true,
  TNNT2: true,
  TNRC18: true,
  TNS3: true,
  TOB1: true,
  TOB2: true,
  TOM1L1: true,
  TOP1: true,
  TOP2A: true,
  TOP2B: true,
  TOPBP1: true,
  TOPORS: true,
  TOR1AIP1: true,
  TP53: true,
  TP53BP1: true,
  TP53BP2: true,
  TP63: true,
  TP73: true,
  TPD52: true,
  TPH1: true,
  TPH2: true,
  TPI1: true,
  TPM1: true,
  TPM2: true,
  TPP1: true,
  TPPP: true,
  TPR: true,
  TPT1: true,
  TPX2: true,
  TRAF2: true,
  TRAF3: true,
  TRAF4: true,
  TRAF6: true,
  TRAP1: true,
  TRIM9: true,
  TRIM25: true,
  TRIM29: true,
  TRIM32: true,
  TRIM56: true,
  TRIM59: true,
  TRIM71: true,
  TRIOBP: true,
  TRIP6: true,
  TRIP10: true,
  TRIP12: true,
  TRIP13: true,
  TRPA1: true,
  TRPC1: true,
  TRPC3: true,
  TRPC4: true,
  TRPC5: true,
  TRPC6: true,
  TRPM2: true,
  TRPM4: true,
  TRPM8: true,
  TRPV1: true,
  TRPV3: true,
  TRPV4: true,
  TRPV5: true,
  TRPV6: true,
  TSC1: true,
  TSC2: true,
  TSPY1: true,
  TSPYL2: true,
  TSPYL5: true,
  TSR1: true,
  TTC3: true,
  TTI1: true,
  TTLL4: true,
  TUBA1A: true,
  TUBA1B: true,
  TUBA1C: true,
  TUBA4A: true,
  TUBB: true,
  TUBB2A: true,
  TUBB3: true,
  TUBG1: true,
  TUBG2: true,
  TUFM: true,
  TUT1: true,
  TWIST1: true,
  TXN: true,
  TXNIP: true,
  TYMS: true,
  TYR: true,
  TYROBP: true,
  U2AF2: true,
  UBA1: true,
  UBA52: true,
  UBAP2L: true,
  UBE2A: true,
  UBE2I: true,
  UBE2O: true,
  UBE2R2: true,
  UBE2S: true,
  UBE3A: true,
  UBQLN4: true,
  UBR4: true,
  UBR5: true,
  UBTF: true,
  UBXN1: true,
  UBXN2B: true,
  UCHL3: true,
  UFD1: true,
  UFL1: true,
  UGDH: true,
  UGT1A3: true,
  UGT1A7: true,
  UGT2B7: true,
  UHRF1: true,
  UIMC1: true,
  UNC5B: true,
  UNG: true,
  UPF1: true,
  URI1: true,
  USF1: true,
  USF2: true,
  USP1: true,
  USP2: true,
  USP4: true,
  USP7: true,
  USP8: true,
  USP9X: true,
  USP10: true,
  USP11: true,
  USP13: true,
  USP14: true,
  USP15: true,
  USP16: true,
  USP20: true,
  USP21: true,
  USP24: true,
  USP25: true,
  USP28: true,
  USP34: true,
  USP35: true,
  USP37: true,
  UTP3: true,
  UTRN: true,
  UVRAG: true,
  VAMP4: true,
  VAMP7: true,
  VASP: true,
  VAV1: true,
  VAV2: true,
  VAV3: true,
  VCL: true,
  VCP: true,
  VCPIP1: true,
  VDAC1: true,
  VDAC2: true,
  VDR: true,
  VGLL1: true,
  VGLL4: true,
  VHL: true,
  VIL1: true,
  VIM: true,
  VIPAS39: true,
  VIRMA: true,
  VTN: true,
  WAC: true,
  WAS: true,
  WASF1: true,
  WASF2: true,
  WASF3: true,
  WASHC2A: true,
  WASL: true,
  WBP2: true,
  WDCP: true,
  WDHD1: true,
  WDR5: true,
  WDR62: true,
  WDR77: true,
  WIPF1: true,
  WIZ: true,
  WRAP53: true,
  WRN: true,
  WT1: true,
  WTAP: true,
  WWC1: true,
  WWOX: true,
  WWTR1: true,
  XBP1: true,
  XDH: true,
  XIAP: true,
  XPA: true,
  XPC: true,
  XPO1: true,
  XRCC1: true,
  XRCC2: true,
  XRCC3: true,
  XRCC4: true,
  XRCC5: true,
  XRCC6: true,
  XRN2: true,
  YAP1: true,
  YBX1: true,
  YBX2: true,
  YBX3: true,
  YKT6: true,
  YLPM1: true,
  YTHDF2: true,
  YWHAB: true,
  YWHAE: true,
  YWHAG: true,
  YWHAQ: true,
  YWHAZ: true,
  YY1: true,
  ZBTB2: true,
  ZBTB4: true,
  ZBTB11: true,
  ZBTB16: true,
  ZC3H4: true,
  ZC3H8: true,
  ZC3H11A: true,
  ZC3H14: true,
  ZC3H18: true,
  ZC3HC1: true,
  ZDHHC13: true,
  ZEB1: true,
  ZFP36: true,
  ZFP36L1: true,
  ZFP64: true,
  ZFYVE19: true,
  ZHX1: true,
  ZKSCAN8: true,
  ZMYM2: true,
  ZMYM3: true,
  ZNF148: true,
  ZNF207: true,
  ZNF281: true,
  ZNF322: true,
  ZNF395: true,
  ZNF420: true,
  ZNF592: true,
  ZNF609: true,
  ZNF622: true,
  ZNF638: true,
  ZNF692: true,
  ZNF746: true,
  ZNF768: true,
  ZNHIT1: true,
  ZNRF2: true,
  ZRANB1: true,
  ZSCAN5A: true,
  ZWINT: true,
  ZYX: true,
  ABL1: true,
  ABL2: true,
  ALK: true,
  AXL: true,
  BLK: true,
  BMX: true,
  BTK: true,
  CSF1R: true,
  CSK: true,
  DDR1: true,
  DDR2: true,
  EGFR: true,
  EPHA1: true,
  EPHA2: true,
  EPHA3: true,
  EPHA4: true,
  EPHA5: true,
  EPHA6: true,
  EPHA7: true,
  EPHA8: true,
  EPHB1: true,
  EPHB2: true,
  EPHB3: true,
  EPHB4: true,
  ERBB2: true,
  ERBB3: true,
  ERBB4: true,
  FER: true,
  FES: true,
  FGFR1: true,
  FGFR2: true,
  FGFR3: true,
  FGFR4: true,
  FGR: true,
  FLT1: true,
  FLT3: true,
  FLT4: true,
  FRK: true,
  FYN: true,
  HCK: true,
  IGF1R: true,
  INSR: true,
  ITK: true,
  JAK1: true,
  JAK2: true,
  JAK3: true,
  KDR: true,
  KIT: true,
  LCK: true,
  LMTK2: true,
  LTK: true,
  LYN: true,
  MATK: true,
  MERTK: true,
  MET: true,
  MST1R: true,
  NTRK1: true,
  NTRK2: true,
  PDGFRA: true,
  PDGFRB: true,
  PTK2: true,
  PTK2B: true,
  PTK6: true,
  RET: true,
  ROR1: true,
  SRC: true,
  SRMS: true,
  STYK1: true,
  SYK: true,
  TEC: true,
  TEK: true,
  TNK1: true,
  TNK2: true,
  TXK: true,
  TYK2: true,
  TYRO3: true,
  YES1: true,
  ZAP70: true,
  ACVR1: true,
  ACVR1B: true,
  ACVRL1: true,
  ARAF: true,
  BMPR1B: true,
  BRAF: true,
  ILK: true,
  IRAK1: true,
  IRAK4: true,
  KSR1: true,
  LIMK1: true,
  LIMK2: true,
  LRRK1: true,
  LRRK2: true,
  MAP3K7: true,
  MAP3K9: true,
  MAP3K10: true,
  MAP3K11: true,
  MAP3K12: true,
  MAP3K13: true,
  MAP3K20: true,
  MAP3K21: true,
  MLKL: true,
  RAF1: true,
  RIPK1: true,
  RIPK2: true,
  RIPK3: true,
  TESK1: true,
  TESK2: true,
  TGFBR1: true,
  TGFBR2: true,
  ADCK5: true,
  ALPK1: true,
  ATM: true,
  ATR: true,
  BAZ1A: true,
  BAZ1B: true,
  BCKDK: true,
  BCR: true,
  BLVRA: true,
  BRD4: true,
  CERT1: true,
  EEF2K: true,
  MTOR: true,
  PDK1: true,
  PDK2: true,
  PDK3: true,
  PDK4: true,
  PRKDC: true,
  RIOK1: true,
  RIOK2: true,
  RIOK3: true,
  SMG1: true,
  STK19: true,
  TAF1: true,
  TRIM24: true,
  TRIM28: true,
  TRIM33: true,
  TRPM6: true,
  TRPM7: true,
  AKT1: true,
  AKT2: true,
  AKT3: true,
  CDC42BPA: true,
  CDC42BPB: true,
  CIT: true,
  DMPK: true,
  GRK1: true,
  GRK2: true,
  GRK3: true,
  GRK4: true,
  GRK5: true,
  GRK6: true,
  GRK7: true,
  LATS1: true,
  LATS2: true,
  MAST3: true,
  MASTL: true,
  PDPK1: true,
  PKN1: true,
  PKN2: true,
  PKN3: true,
  PRKACA: true,
  PRKACB: true,
  PRKCA: true,
  PRKCB: true,
  PRKCD: true,
  PRKCE: true,
  PRKCG: true,
  PRKCH: true,
  PRKCI: true,
  PRKCQ: true,
  PRKCZ: true,
  PRKG1: true,
  PRKG2: true,
  PRKX: true,
  ROCK1: true,
  ROCK2: true,
  RPS6KA1: true,
  RPS6KA2: true,
  RPS6KA3: true,
  RPS6KA4: true,
  RPS6KA5: true,
  RPS6KA6: true,
  RPS6KB1: true,
  RPS6KB2: true,
  RPS6KC1: true,
  SGK1: true,
  SGK2: true,
  SGK3: true,
  STK38: true,
  STK38L: true,
  BRSK1: true,
  BRSK2: true,
  CAMK1: true,
  CAMK1D: true,
  CAMK1G: true,
  CAMK2A: true,
  CAMK2B: true,
  CAMK2D: true,
  CAMK2G: true,
  CAMK4: true,
  CASK: true,
  CHEK1: true,
  CHEK2: true,
  DAPK1: true,
  DAPK2: true,
  DAPK3: true,
  HUNK: true,
  KALRN: true,
  MAPKAPK2: true,
  MAPKAPK3: true,
  MAPKAPK5: true,
  MARK1: true,
  MARK2: true,
  MARK3: true,
  MARK4: true,
  MELK: true,
  MKNK1: true,
  MKNK2: true,
  MYLK: true,
  MYLK2: true,
  MYLK3: true,
  NIM1K: true,
  NUAK1: true,
  NUAK2: true,
  PASK: true,
  PHKG1: true,
  PIM1: true,
  PIM2: true,
  PIM3: true,
  PNCK: true,
  PRKAA1: true,
  PRKAA2: true,
  PRKD1: true,
  PRKD2: true,
  PRKD3: true,
  SIK1: true,
  SIK2: true,
  SIK3: true,
  SNRK: true,
  STK11: true,
  STK17A: true,
  STK33: true,
  TRIB2: true,
  TRIO: true,
  TSSK4: true,
  TTN: true,
  CDK1: true,
  CDK2: true,
  CDK3: true,
  CDK4: true,
  CDK5: true,
  CDK6: true,
  CDK7: true,
  CDK8: true,
  CDK9: true,
  CDK10: true,
  CDK11B: true,
  CDK12: true,
  CDK13: true,
  CDK14: true,
  CDK15: true,
  CDK16: true,
  CDK18: true,
  CDK19: true,
  CDK20: true,
  CDKL2: true,
  CDKL5: true,
  CILK1: true,
  CLK1: true,
  CLK2: true,
  CLK3: true,
  CLK4: true,
  CSNK2A1: true,
  CSNK2A2: true,
  DYRK1A: true,
  DYRK1B: true,
  DYRK2: true,
  DYRK3: true,
  DYRK4: true,
  GSK3A: true,
  GSK3B: true,
  HIPK1: true,
  HIPK2: true,
  HIPK3: true,
  HIPK4: true,
  MAK: true,
  MAPK1: true,
  MAPK3: true,
  MAPK4: true,
  MAPK6: true,
  MAPK7: true,
  MAPK8: true,
  MAPK9: true,
  MAPK10: true,
  MAPK11: true,
  MAPK12: true,
  MAPK13: true,
  MAPK14: true,
  MAPK15: true,
  NLK: true,
  PRPF4B: true,
  SRPK1: true,
  SRPK2: true,
  CSNK1A1: true,
  CSNK1A1L: true,
  CSNK1D: true,
  CSNK1E: true,
  CSNK1G1: true,
  CSNK1G2: true,
  CSNK1G3: true,
  TTBK1: true,
  TTBK2: true,
  VRK1: true,
  VRK2: true,
  VRK3: true,
  MAP2K1: true,
  MAP2K2: true,
  MAP2K3: true,
  MAP2K4: true,
  MAP2K5: true,
  MAP2K6: true,
  MAP2K7: true,
  MAP3K1: true,
  MAP3K2: true,
  MAP3K3: true,
  MAP3K4: true,
  MAP3K5: true,
  MAP3K6: true,
  MAP3K8: true,
  MAP3K14: true,
  MAP4K1: true,
  MAP4K2: true,
  MAP4K3: true,
  MAP4K4: true,
  MAP4K5: true,
  MINK1: true,
  MYO3A: true,
  NRK: true,
  OXSR1: true,
  PAK1: true,
  PAK2: true,
  PAK3: true,
  PAK4: true,
  PAK5: true,
  PAK6: true,
  SLK: true,
  STK3: true,
  STK4: true,
  STK10: true,
  STK24: true,
  STK25: true,
  STK26: true,
  STK39: true,
  STRADA: true,
  TAOK1: true,
  TAOK2: true,
  TAOK3: true,
  TNIK: true,
  NPR1: true
}
