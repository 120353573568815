<template>
	<main id="Text">
        <div class="container"> 
      <div>
        <br>
		<h1>Welcome to the SunBEAm-ABC web portal!</h1>
  </div>
    <br>
    <div class="description">
   The 
    <a href="#sunbeam" class="link-container">SunBEAm</a>
     Analysis & Bioinformatics Center (SunBEAm-ABC) is assaying SunBEAm biosamples using omics and will apply integrative systems biology to identify novel determinants of food allergy and atopic dermatitis.
    </div> 
    <!-- <div align="center"> -->
      <img src="../assets/img/study_overview.png" class="img-overview" >
    <!-- </div> -->
    <table></table>
    <!-- Link from above text -->
    <div class="description">
      <div id="sunbeam" class="jump-to"> </div>
		<a target="_blank" href="https://www.jaci-global.org/article/S2772-8293(23)00049-8/fulltext" class="link-container">Systems Biology of Early Atopy (SunBEAm)</a>
       is a general population birth cohort 
      funded by the National Institute of Allergy and Infectious Diseases (NIAID) and spearheaded by the Consortium for Food Allergy Research to transform approaches to studying allergy by recruiting and phenotyping a large, 
      well-characterized multicenter cohort where systems biology can be applied to identify mechanisms and biomarkers underlying the development of food allergy, atopic dermatitis, and other allergic diseases.
      <br>
  </div>
  <br>
  <div>
    <img src="../assets/img/primary_endpoint.png" class="img-top-sub">
    <img src="../assets/img/study_site.jpg" class="img-top-sub">
    <!-- <p> -->
    <br>
      <img src="../assets/img/sunbeam_goal.png" class="img-bottom">
    <!-- </p> -->
    </div>
    
  </div>
		<!-- <h1>More about SunBEAm-ABC</h1>
		<p id="intro"> Our efforts will create a discovery infrastructure for unprecedented systems allergy research during and beyond the SunBEAm-ABC award period that will elucidate mechanisms, biomarkers, and predictors of FA and AD development.
       </p> -->
       
      <!-- <img src="../assets/img/multiomics.png" width="300px"> -->
      
	</main>
</template>

<script>
export default {
    name: "about",
}
</script>

<style scoped>
.description {
  /* width: 90%; */
  font-size: max(0.9vw, 20px);
  /* font-size: 1.3rem; */
  /* display: inline-block; */
  /* margin-left: auto;
  margin-right: auto; */
}

.container {
  font-size: max(0.7vw, 15px);
  position: center;
  text-align: center;
  /* align-items: center; */
  width: 90%;
  display: block;
}

/* *[sunbeam]:before{
  margin-top: 500px;
} */



.img-overview {
  width: 60vw;
  min-width: 500px;
  max-width: 900px;
}

.img-top-sub {
  width: 22vw;
  min-width: 260px;
  max-width: 400px;
}
.img-bottom {
  width: 40vw;
  min-width: 480px;
  max-width: 760px;
}

.link-container{
  color: #219EBC;

}

</style>

