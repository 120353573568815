export default {
    
    cohort: [
      { value: 1001, label: "AR-DX", color: "#29ECEA", show: true },
      { value: 1002, label: "Non AR-DX", color: "#D81B60", show: true },
    ],
    Age: [
      { value: 4001, label: "< 11 (25%)", color: "#A9D6E5", show: true },
      { value: 4002, label: "11-14 (25%-50%)", color: "#61A5C2", show: true },
      { value: 4003, label: "14-18 (50%-75%)", color: "#2C7DA0", show: true },
      { value: 4004, label: ">= 18 (>=75%)", color: "#014F86", show: true },
    ],
    Sex: [
      { value: 5001, label: "Female", color: "#d55e00", show: true },
      { value: 5002, label: "Male", color: "#0072b2", show: true },
  ],
  "Race_ethnicity": [
    { value: 6001, label: "Asian", color: "#1081CF", show: true },
    { value: 6002, label: "Black", color: "#37FC24", show: true },
    { value: 6003, label: "Latino", color: "#29ECEA", show: true },
    { value: 6004, label: "White", color: "#E20A23", show: true },
    { value: 6005, label: "Multiple_races", color: "#F3B084", show: true },
    { value: 6006, label: "Unknown", color: "#ebebeb", show: true },
  ],
  };
  