export default {
  N001: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N002: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N003: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N004: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N005: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N006: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N007: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N008: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N009: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N010: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N011: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N012: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N016: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N017: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N018: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N019: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N020: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N021: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N022: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N023: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N024: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N026: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N027: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N028: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N029: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N030: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N031: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N032: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N033: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N034: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N035: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N037: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N038: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N039: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N040: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N041: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N042: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N043: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N044: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N045: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N047: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N048: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N050: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N051: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N053: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N054: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N055: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N056: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N057: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N058: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N059: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N060: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N061: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N062: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N063: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N067: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N070: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N071: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N074: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N075: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N076: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N077: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N078: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N079: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N080: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N081: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N082: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N083: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N084: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N085: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N086: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N087: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N088: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N089: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N090: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N091: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N092: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N093: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N094: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N095: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N096: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N097: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N098: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N099: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N100: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N101: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N102: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N103: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N104: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N105: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N106: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N107: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N108: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N109: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N110: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N111: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N112: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N113: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N114: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N115: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N116: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N117: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N118: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N119: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N120: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N121: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N122: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N124: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N125: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N126: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N127: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N128: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N129: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N130: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N131: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N132: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N133: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N134: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N135: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N136: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N137: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N138: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N139: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N140: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N142: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N143: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N144: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N145: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N146: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N147: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N148: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N149: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N150: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N151: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N152: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N153: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N154: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N155: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N156: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N157: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N158: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N159: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N160: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N161: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N162: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N163: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N164: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N165: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N167: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N168: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N169: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N170: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N171: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N172: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N173: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N174: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N175: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N176: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N177: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N178: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N179: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N180: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N181: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N182: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N183: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N184: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N185: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N187: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N188: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N189: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N190: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N191: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N192: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N193: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N194: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N195: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N196: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N197: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N198: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N199: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N200: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N201: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N202: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N203: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N204: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N205: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N206: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N207: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N208: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N209: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N212: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N217: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N218: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N220: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N221: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N222: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N223: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N224: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N225: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N226: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N227: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N228: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N229: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N230: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N231: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N232: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N234: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N236: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N237: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N239: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N240: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N241: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N242: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N243: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N244: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N245: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N246: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N247: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N248: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N249: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N250: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N251: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N252: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N253: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N254: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N255: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N256: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N257: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N258: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N259: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N260: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N261: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N262: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N263: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N264: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N265: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  'N265..F1.': {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N268: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N269: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N270: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N272: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N274: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N275: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N276: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N277: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N278: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N279: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N280: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N281: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N282: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N283: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N284: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N285: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N286: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N288: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N289: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N290: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N291: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N292: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N293: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N294: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N295: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N296: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N297: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N298: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N299: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N300: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N301: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N302: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N303: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N305: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N306: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N307: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N309: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N310: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N313: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N314: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N315: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N316: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N317: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N319: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N322: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N323: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N325: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N326: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N327: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  N332: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N336: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  N337: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T001: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T003: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T004: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T005: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T006: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T007: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T008: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T009: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T011: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T012: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T013: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T014: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T015: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T016: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T018: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T019: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T020: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T021: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T022: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T024: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T025: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  'T025..F3.': {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T026: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T027: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T028: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T029: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T030: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T031: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T033: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  'T033..F12.': {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  'T033..F6.': {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  'T033..F9.': {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  'T033.2': {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  'T033.F7': {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T034: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T035: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T037: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T038: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T041: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T042: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T043: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T044: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T045: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T046: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T047: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T048: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T049: {
    NUPR1_NUPR1: 4,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 1,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 1,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T050: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 2,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T051: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T052: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T053: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T054: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T056: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T057: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T058: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T059: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T060: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  'T060..F1.': {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T061: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T062: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  'T062..F2.': {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T063: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T064: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T066: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T067: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T070: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T071: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T072: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T073: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T074: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T075: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  'T075..F1.': {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T076: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T077: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T078: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  'T078..F1.': {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T079: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T080: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T081: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T089: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T091: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  'T091..F1.': {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T093: {
    NUPR1_NUPR1: 3,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  'T093..F1.': {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T122: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T128: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T130: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T136: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 2,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  'T136.2': {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T145: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  'T145..F2.': {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T148: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 3,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  'T148..F1.': {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  'T148..F2.': {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T152: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T153: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'Non AR-DX',
    NUPR1_pred_cls: 'Non AR-DX',
    CD74_CD74: 2,
    CD74_cohort: 'Non AR-DX',
    CD74_pred_cls: 'Non AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'Non AR-DX',
    THBS1_pred_cls: 'Non AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'Non AR-DX',
    RHOH_pred_cls: 'Non AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'Non AR-DX',
    CD69_pred_cls: 'Non AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'Non AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T154: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 2,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T156: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 1,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 3,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 1,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'Non AR-DX'
  },
  T160: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 4,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T162: {
    NUPR1_NUPR1: 1,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 4,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 4,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 4,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 4,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  },
  T163: {
    NUPR1_NUPR1: 2,
    NUPR1_cohort: 'AR-DX',
    NUPR1_pred_cls: 'AR-DX',
    CD74_CD74: 3,
    CD74_cohort: 'AR-DX',
    CD74_pred_cls: 'AR-DX',
    THBS1_THBS1: 2,
    THBS1_cohort: 'AR-DX',
    THBS1_pred_cls: 'AR-DX',
    RHOH_RHOH: 3,
    RHOH_cohort: 'AR-DX',
    RHOH_pred_cls: 'AR-DX',
    CD69_CD69: 3,
    CD69_cohort: 'AR-DX',
    CD69_pred_cls: 'AR-DX',
    ADGRE4P_ADGRE4P: 1,
    ADGRE4P_cohort: 'AR-DX',
    ADGRE4P_pred_cls: 'AR-DX'
  }
}
