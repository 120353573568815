<template>
	<main id="Text">
        <div align="center">
		<!-- <h1>Goals of SunBEAm-ABC</h1> -->
    <!-- <div style="width:80%"> -->
    <!-- <p> The  -->
      <!-- <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        ><b>SunBEAm</b></span>
      </template>
      <span>Tooltip</span>
    </v-tooltip>  -->
    <!-- <a href="#sunbeam">SunBEAm</a>
     Analysis & Bioinformatics Center (SunBEAm-ABC) is assaying SunBEAm biosamples using omics and will apply integrative systems biology to identify novel determinants of food allergy (FA) and atopic dermatitis (AD). </p> -->
    <!-- </div>  -->
    <!-- <div style="width:80%" align="center">
      <img src="../assets/img/study_overview.png" height="450px">
    </div> -->
    <br>
    <div class="aims-header">
    <h1>Aims of SunBEAm-ABC</h1>
  </div>
    <div class="content">
      
    <div class="goal-text" >
    <!-- <br> -->
    
    <div class="bullets" align="left">
      
    <ul>
     <li>Generate longitudinal and multi-scale omic data from SunBEAm participants developing food allergy and atopic dermatitis. <br><br>
     </li><li>Identify novel biomarkers, endotypes, and mechanisms of food allergy and atopic dermatitis development.<br><br>
     </li><li>Identify key drivers and predictors of food allergy and atopic dermatitis development across diverse molecular dimensions.<br><br>
     </li><li>Share all generated data in a centralized platform for SunBEAm data and bioinformatic innovations.<br><br>
     <!-- </li><li>Develop infrastructure for interactions with the SunBEAm Steering Group, the NIAID Statistical and Clinical Coordinating Center, and NIAID. <br><br> -->
      </li>
    </ul>
  </div>
<!-- (5) Develop infrastructure for interactions with the SunBEAm Steering Group, the NIAID Statistical and Clinical Coordinating Center, and NIAID.<br><br> -->
  </div>
  <div style="flex-grow: 1" align="left">
    <img src="../assets/img/multiomics_development.jpg" class="aim-image">
  </div>
  </div>
  <!-- <div style="flex-grow: 1" align="center">
    <img src="../assets/img/figureB1_gears.jpg" width="350px">
  </div> -->
    
    <!-- <font-awesome-icon :icon="['fas', 'dna']" />
    <font-awesome-icon icon="dna" />
    <i class="fa-solid fa-dna"></i> -->
    <!-- <img src="../assets/img/study_site.jpg" width="280px"> -->
    <!-- <p>
      <img src="../assets/img/sunbeam_goal.png" width="550px">
    </p> -->
  
    
    
  </div>
		<!-- <h1>More about SunBEAm-ABC</h1>
		<p id="intro"> Our efforts will create a discovery infrastructure for unprecedented systems allergy research during and beyond the SunBEAm-ABC award period that will elucidate mechanisms, biomarkers, and predictors of FA and AD development.
       </p> -->
       
      <!-- <img src="../assets/img/multiomics.png" width="300px"> -->
      
	</main>
</template>

<script>
export default {
    name: "goals",
}
</script>
<style scoped>
.content {
  display: flex;

  /* justify-content: space-around; */
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  
}

.content>div{
  flex: 2 1 0;
}

.goal-text {
  display: flex;
  font-size: max(0.9vw, 20px);
  /* font-size: max(1.1vw, 20px); */
/* justify-content: space-around; */
flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
/* align-items: center; */
  padding-left: 3%;
}

.aims-header {
  font-size: max(0.7vw, 15px);
  text-align: left;
  padding-left: 3%;
  padding-bottom: 1vh;
}



.bullets {
  /* display: flex; */
  margin-top: max(2vh, 25px);
  font-size: max(0.9vw, 20px);
  /* align-items: flex-start; */
  /* font-size: max(1.2vw, 20px); */
/* justify-content: space-around;
flex-direction: row;
  flex-wrap: wrap; */
/* align-items: center;
  padding-left: 3%; */
}

.small {
  flex-grow: 1;
  /* border: 3px solid rgba(0, 0, 0, 0.2); */
}

.double {
  flex-grow: 1;
  /* border: 3px solid rgba(0, 0, 0, 0.2); */
}


.aim-image {
  width: 26vw;
  min-width: 400px;
  height: auto;
  /* border: 3px solid rgba(0, 0, 0, 0.2); */
}

.md-tab {
  width: 50%;
  flex: 1 0 30%;
}

</style>


