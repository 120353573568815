import Vue from 'vue'
import App from './App.vue'
import store from './store'
// import vuetify from './plugins/vuetify'
import vuetify from '@/plugins/vuetify' 

import AsyncComputed from 'vue-async-computed'
import VModal from 'vue-js-modal'

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
// import 'vue-material/dist/theme/default.css'

Vue.use(VueMaterial)

import VueMeta from 'vue-meta'

Vue.use(VueMeta)
const { generate } = VueMeta

const rawMetaInfo = {
  meta: [{ charset: 'utf-8'},
        { name: 'description', content: 'SunBEAm-ABC web portal' },
        { name: 'google-site-verification', content: 'LFlfTk58i2PSxVcx-KbWN8pYPeRCPVZtQAONO6YpzpY' }]
}

const metaInfo = generate(rawMetaInfo /*, yourOptions*/)

const HEAD = metaInfo.script.text() + metaInfo.meta.text()
// import router from './router'

// import VueSidebarMenu from 'vue-sidebar-menu'
// import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
// Vue.use(VueSidebarMenu)

import VueSidebarMenuAkahon from "vue-sidebar-menu-akahon";
Vue.component('vue-sidebar-menu-akahon', VueSidebarMenuAkahon);



// import vueAwesomeSidebar from 'vue-awesome-sidebar'
// import 'vue-awesome-sidebar/dist/vue-awesome-sidebar.css'

// Vue.use(vueAwesomeSidebar)
// import vueTabz from 'vue-tabz'
// Vue.use(vueTabz)

import Tabs from "vue-material-tabs"

import VueGtag from 'vue-gtag'
Vue.use(VueGtag, {
  config: {
      id: 'G-5MQGXWZEE3',
      params: {
          send_page_view: false
      }
  }
})

Vue.use(Tabs)
Vue.use(VModal)
Vue.use(AsyncComputed)
Vue.config.productionTip = false

new Vue({
  store,
  vuetify,
  render: h => h(App)
  
}).$mount('#app')
