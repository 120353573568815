import { result } from "lodash";
import { firstBy, th } from "thenby"


// let nonasort = function(a,b){
//   if(isNaN(a)) { 
//     return isNaN(b) ? 1 : b;
//   } else {
//     return isNaN(b) ? -1 : a-b; 
//   }
// }

// var nonasort = (a,b) =>{
//   if(isNaN(a)) { 
//     return isNaN(b) ? 1 : b;
//   } else {
//     return isNaN(b) ? -1 : a-b; 
//   }
// }

// var nonasort = (a,b) =>{
//   // if(isNaN(a)) { 
//   //   return isNaN(b) ? 1 : b;
//   // } else {
//   //   return isNaN(b) ? -1 : a-b; 
//   // }
//   if (a === b){
//     return 0;
//   }
//   if ((a === null) || (a === undefined)) {
//       return 1;
//   }
//   if ((b === null) || (b === undefined)) {
//       return -1;
//   }

//     // otherwise, if we're ascending, lowest sorts first
//   // if (ascending) {
//   //     return a < b ? -1 : 1;
//   // }

//     // if descending, highest sorts first
//   return a < b ? 1 : -1;
// }

var nonasort = (v1,v2) =>{
  console.log('check a & b', typeof v1, typeof v2)
  // a = (a == null) || (a === undefined) ? Infinity: a;
  // b = (b == null) || (b === undefined) ? Infinity: b;
  var a_prime = isNaN(v1) ? Infinity: v1;
  var b_prime = isNaN(v2) ? Infinity: v2;
  return a_prime - b_prime;
  // return 0;
}

let nonasort2 = function(a, b) {
  return (a==="undefined")-(b ==="undefined") || +(a>b)||-(a<b);
}

export default (sortArgs) => {
  // Old codes but cant sort nan
  // return sortArgs.reduce((sortFn, args, idx) => {
  //   if (idx === 0) {
  //     console.log('sample order', ...args, args, sortFn)
  //     return firstBy(...args, {cmp: nonasort})
  //   } else {
  //     console.log('sample order next', ...args, args, sortFn)
  //     return sortFn.thenBy(...args, {cmp: nonasort})
  //   }
  // }, () => 0)


    function dynamicSort(property) {
      var sortOrder = 1;
      // if(property[0] === "-") {
      //     sortOrder = -1;
      //     property = property.substr(1);
      // }
      return function (a,b) {
          /* next line works with strings and numbers, 
           * and you may want to customize it to your needs
           */
          var v1 = a[property];
          var v2 = b[property];
          var a_prime = isNaN(v1) ? Infinity: v1;
          var b_prime = isNaN(v2) ? Infinity: v2;
          var result = a_prime - b_prime;
          // var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
          return result * sortOrder;
      }
    }

    return function (obj1, obj2) {
        var i = 0, result = 0, numberOfProperties = sortArgs.length;
        /* try getting a different result from 0 (equal)
         * as long as we have extra properties to compare
         */
        while(result === 0 && i < numberOfProperties) {
            result = dynamicSort(...sortArgs[i])(obj1, obj2);
            i++;
        }
        return result;
    }
}

