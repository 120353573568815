export default {
  '1-1-1-TRICHLOROETHANE': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 4.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 3.0,
    'AR035-T': 2.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 3.0,
    'AR046-N': 3.0,
    'AR048-N': 2.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 3.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 2.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 3.0,
    'AR073-T': 3.0,
    'AR075-N': 3.0,
    'AR077-N': 2.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 3.0,
    'AR090-N': 2.0,
    'AR098-T': 3.0,
    'AR100-N': 4.0,
    'AR102-N': 1.0,
    'AR104-T': 2.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 3.0,
    'AR118-T': 1.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 2.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 2.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 4.0,
    'AR165-T': 3.0,
    'AR167-T': 2.0,
    'AR169-T': 1.0,
    'AR170-N': 4.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 2.0,
    'AR185-T': 2.0,
    'AR186-T': 3.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 4.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 3.0,
    'AR204-T': 4.0,
    'AR206-T': 1.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 4.0,
    'AR237-T': 2.0,
    'AR238-T': 2.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 2.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 1.0,
    'AR302-T': 3.0,
    'AR304-T': 3.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 3.0,
    'AR321-N': 3.0,
    'AR323-T': 2.0,
    'AR324-N': 2.0,
    'AR327-N': 2.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 4.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 3.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 3.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 2.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 3.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 3.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 1.0,
    'AR455-T': 3.0,
    'AR456-N': 2.0,
    'AR457-T': 3.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 3.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 4.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 4.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 2.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 2.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 2.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  '1-1-2-TRICHLOROETHANE': {
    'AR001-N': 2.0,
    'AR003-T': 3.0,
    'AR008-N': 2.0,
    'AR012-T': 3.0,
    'AR014-N': 2.0,
    'AR018-T': 1.0,
    'AR019-N': 1.0,
    'AR021-N': 3.0,
    'AR026-T': 2.0,
    'AR028-N': 1.0,
    'AR029-N': 2.0,
    'AR031-T': 3.0,
    'AR033-N': 2.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 3.0,
    'AR044-N': 1.0,
    'AR046-N': 2.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 3.0,
    'AR056-T': 1.0,
    'AR057-T': 4.0,
    'AR059-N': 2.0,
    'AR061-T': 1.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 3.0,
    'AR068-N': 3.0,
    'AR070-N': 1.0,
    'AR071-T': 2.0,
    'AR073-T': 3.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 2.0,
    'AR081-T': 3.0,
    'AR083-T': 3.0,
    'AR085-N': 1.0,
    'AR088-T': 2.0,
    'AR090-N': 4.0,
    'AR098-T': 3.0,
    'AR100-N': 2.0,
    'AR102-N': 4.0,
    'AR104-T': 1.0,
    'AR108-T': 3.0,
    'AR110-T': 4.0,
    'AR112-T': 3.0,
    'AR115-T': 2.0,
    'AR118-T': 4.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 3.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 1.0,
    'AR142-T': 3.0,
    'AR144-N': 3.0,
    'AR145-N': 4.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 1.0,
    'AR150-T': 3.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 3.0,
    'AR158-T': 2.0,
    'AR160-T': 2.0,
    'AR162-N': 4.0,
    'AR164-T': 2.0,
    'AR165-T': 2.0,
    'AR167-T': 2.0,
    'AR169-T': 4.0,
    'AR170-N': 3.0,
    'AR172-N': 2.0,
    'AR174-T': 3.0,
    'AR177-T': 4.0,
    'AR178-T': 2.0,
    'AR180-T': 4.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 4.0,
    'AR186-T': 1.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 1.0,
    'AR190-N': 3.0,
    'AR192-T': 3.0,
    'AR193-T': 3.0,
    'AR197-T': 3.0,
    'AR198-T': 4.0,
    'AR200-T': 1.0,
    'AR202-T': 3.0,
    'AR204-T': 2.0,
    'AR206-T': 4.0,
    'AR208-T': 1.0,
    'AR209-T': 4.0,
    'AR210-N': 2.0,
    'AR211-N': 1.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 2.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 4.0,
    'AR224-N': 4.0,
    'AR226-T': 2.0,
    'AR228-T': 4.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 2.0,
    'AR232-T': 4.0,
    'AR233-T': 2.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 1.0,
    'AR243-T': 2.0,
    'AR245-N': 1.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 3.0,
    'AR252-T': 4.0,
    'AR254-T': 1.0,
    'AR256-T': 2.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 4.0,
    'AR264-N': 2.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 3.0,
    'AR273-T': 3.0,
    'AR275-T': 1.0,
    'AR277-T': 2.0,
    'AR279-T': 4.0,
    'AR281-N': 2.0,
    'AR283-T': 4.0,
    'AR285-T': 4.0,
    'AR287-T': 1.0,
    'AR289-T': 4.0,
    'AR293-T': 3.0,
    'AR297-N': 3.0,
    'AR299-N': 2.0,
    'AR300-T': 4.0,
    'AR302-T': 3.0,
    'AR304-T': 2.0,
    'AR306-N': 4.0,
    'AR309-N': 3.0,
    'AR311-N': 1.0,
    'AR313-N': 2.0,
    'AR315-N': 2.0,
    'AR319-N': 3.0,
    'AR321-N': 2.0,
    'AR323-T': 4.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 2.0,
    'AR333-N': 3.0,
    'AR335-N': 2.0,
    'AR339-N': 2.0,
    'AR341-N': 3.0,
    'AR343-N': 2.0,
    'AR345-N': 3.0,
    'AR347-T': 4.0,
    'AR349-N': 4.0,
    'AR351-N': 2.0,
    'AR353-N': 2.0,
    'AR357-N': 4.0,
    'AR363-N': 1.0,
    'AR365-T': 3.0,
    'AR367-N': 3.0,
    'AR368-N': 3.0,
    'AR370-N': 1.0,
    'AR371-T': 4.0,
    'AR372-N': 2.0,
    'AR373-N': 2.0,
    'AR374-N': 3.0,
    'AR376-N': 3.0,
    'AR381-N': 1.0,
    'AR382-N': 1.0,
    'AR384-N': 1.0,
    'AR387-N': 2.0,
    'AR389-N': 3.0,
    'AR391-T': 2.0,
    'AR392-N': 3.0,
    'AR394-N': 1.0,
    'AR398-N': 1.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 3.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 4.0,
    'AR409-N': 2.0,
    'AR411-N': 3.0,
    'AR412-N': 2.0,
    'AR413-N': 2.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 4.0,
    'AR420-N': 1.0,
    'AR421-N': 4.0,
    'AR422-T': 4.0,
    'AR423-N': 1.0,
    'AR424-N': 4.0,
    'AR425-T': 3.0,
    'AR426-N': 3.0,
    'AR427-N': 4.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 4.0,
    'AR432-N': 2.0,
    'AR433-T': 1.0,
    'AR434-N': 3.0,
    'AR435-N': 4.0,
    'AR436-N': 1.0,
    'AR437-N': 1.0,
    'AR438-T': 1.0,
    'AR439-T': 4.0,
    'AR440-N': 2.0,
    'AR441-T': 2.0,
    'AR442-T': 3.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 1.0,
    'AR446-N': 2.0,
    'AR447-N': 4.0,
    'AR448-T': 3.0,
    'AR449-T': 2.0,
    'AR450-N': 1.0,
    'AR451-N': 3.0,
    'AR452-N': 2.0,
    'AR453-N': 1.0,
    'AR454-T': 4.0,
    'AR455-T': 3.0,
    'AR456-N': 1.0,
    'AR457-T': 1.0,
    'AR458-T': 2.0,
    'AR459-T': 4.0,
    'AR460-T': 4.0,
    'AR461-T': 4.0,
    'AR462-T': 4.0,
    'AR463-T': 4.0,
    'AR464-T': 3.0,
    'AR465-T': 3.0,
    'AR466-N': 3.0,
    'AR467-N': 4.0,
    'AR468-T': 3.0,
    'AR469-N': 1.0,
    'AR470-T': 4.0,
    'AR471-T': 2.0,
    'AR472-T': 3.0,
    'AR473-N': 2.0,
    'AR474-N': 1.0,
    'AR475-T': 4.0,
    'AR476-T': 4.0,
    'AR477-T': 2.0,
    'AR478-T': 4.0,
    'AR479-T': 2.0,
    'AR480-N': 4.0,
    'AR481-': 2.0,
    'AR482-T': 4.0,
    'AR483-N': 2.0,
    'AR484-T': 4.0,
    'AR485-N': 2.0,
    'AR486-N': 3.0,
    'AR488-N': 3.0,
    'AR489-T': 2.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  '1-1-2-2-TETRACHLOROETHANE': {
    'AR001-N': 4.0,
    'AR003-T': 3.0,
    'AR008-N': 2.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 4.0,
    'AR019-N': 4.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 4.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 2.0,
    'AR035-T': 3.0,
    'AR037-T': 3.0,
    'AR042-T': 4.0,
    'AR044-N': 4.0,
    'AR046-N': 2.0,
    'AR048-N': 3.0,
    'AR050-N': 4.0,
    'AR052-T': 4.0,
    'AR054-N': 4.0,
    'AR056-T': 4.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 4.0,
    'AR063-T': 3.0,
    'AR064-T': 3.0,
    'AR066-T': 3.0,
    'AR068-N': 2.0,
    'AR070-N': 4.0,
    'AR071-T': 2.0,
    'AR073-T': 2.0,
    'AR075-N': 3.0,
    'AR077-N': 3.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 4.0,
    'AR088-T': 2.0,
    'AR090-N': 1.0,
    'AR098-T': 4.0,
    'AR100-N': 3.0,
    'AR102-N': 1.0,
    'AR104-T': 4.0,
    'AR108-T': 2.0,
    'AR110-T': 1.0,
    'AR112-T': 2.0,
    'AR115-T': 2.0,
    'AR118-T': 1.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 3.0,
    'AR130-T': 4.0,
    'AR132-T': 3.0,
    'AR136-T': 1.0,
    'AR138-T': 2.0,
    'AR140-N': 3.0,
    'AR142-T': 3.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 4.0,
    'AR147-N': 3.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 4.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 3.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 2.0,
    'AR183-T': 2.0,
    'AR185-T': 1.0,
    'AR186-T': 4.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 4.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 3.0,
    'AR198-T': 1.0,
    'AR200-T': 4.0,
    'AR202-T': 3.0,
    'AR204-T': 3.0,
    'AR206-T': 1.0,
    'AR208-T': 3.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 4.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 4.0,
    'AR219-T': 4.0,
    'AR220-T': 3.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 4.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 3.0,
    'AR237-T': 4.0,
    'AR238-T': 4.0,
    'AR239-T': 4.0,
    'AR242-N': 2.0,
    'AR243-T': 2.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 3.0,
    'AR256-T': 2.0,
    'AR258-T': 3.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 2.0,
    'AR264-N': 2.0,
    'AR266-T': 2.0,
    'AR268-N': 3.0,
    'AR271-T': 3.0,
    'AR273-T': 3.0,
    'AR275-T': 3.0,
    'AR277-T': 2.0,
    'AR279-T': 1.0,
    'AR281-N': 2.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 4.0,
    'AR289-T': 1.0,
    'AR293-T': 2.0,
    'AR297-N': 3.0,
    'AR299-N': 4.0,
    'AR300-T': 1.0,
    'AR302-T': 2.0,
    'AR304-T': 2.0,
    'AR306-N': 1.0,
    'AR309-N': 2.0,
    'AR311-N': 4.0,
    'AR313-N': 2.0,
    'AR315-N': 4.0,
    'AR319-N': 2.0,
    'AR321-N': 4.0,
    'AR323-T': 2.0,
    'AR324-N': 4.0,
    'AR327-N': 3.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 2.0,
    'AR335-N': 2.0,
    'AR339-N': 3.0,
    'AR341-N': 3.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 4.0,
    'AR365-T': 3.0,
    'AR367-N': 4.0,
    'AR368-N': 3.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 2.0,
    'AR373-N': 2.0,
    'AR374-N': 4.0,
    'AR376-N': 2.0,
    'AR381-N': 2.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 4.0,
    'AR389-N': 3.0,
    'AR391-T': 2.0,
    'AR392-N': 4.0,
    'AR394-N': 4.0,
    'AR398-N': 2.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 3.0,
    'AR406-N': 4.0,
    'AR407-N': 2.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 3.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 2.0,
    'AR433-T': 3.0,
    'AR434-N': 3.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 4.0,
    'AR438-T': 2.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 2.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 4.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 2.0,
    'AR450-N': 2.0,
    'AR451-N': 2.0,
    'AR452-N': 4.0,
    'AR453-N': 2.0,
    'AR454-T': 2.0,
    'AR455-T': 2.0,
    'AR456-N': 4.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 3.0,
    'AR466-N': 3.0,
    'AR467-N': 1.0,
    'AR468-T': 2.0,
    'AR469-N': 2.0,
    'AR470-T': 1.0,
    'AR471-T': 4.0,
    'AR472-T': 4.0,
    'AR473-N': 2.0,
    'AR474-N': 4.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 2.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 1.0,
    'AR483-N': 2.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 2.0,
    'AR490-T': 1.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  '1-2-DIBROMO-3-CHLOROPROPANE': {
    'AR001-N': 3.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 3.0,
    'AR018-T': 3.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 2.0,
    'AR029-N': 3.0,
    'AR031-T': 4.0,
    'AR033-N': 3.0,
    'AR035-T': 3.0,
    'AR037-T': 4.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 3.0,
    'AR048-N': 3.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 3.0,
    'AR063-T': 4.0,
    'AR064-T': 3.0,
    'AR066-T': 4.0,
    'AR068-N': 3.0,
    'AR070-N': 2.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 4.0,
    'AR077-N': 3.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 3.0,
    'AR088-T': 2.0,
    'AR090-N': 1.0,
    'AR098-T': 4.0,
    'AR100-N': 3.0,
    'AR102-N': 2.0,
    'AR104-T': 2.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 3.0,
    'AR118-T': 1.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 3.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 3.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 2.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 1.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 4.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 3.0,
    'AR169-T': 1.0,
    'AR170-N': 4.0,
    'AR172-N': 3.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 2.0,
    'AR183-T': 2.0,
    'AR185-T': 1.0,
    'AR186-T': 2.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 4.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 1.0,
    'AR208-T': 3.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 2.0,
    'AR220-T': 3.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 1.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 3.0,
    'AR237-T': 2.0,
    'AR238-T': 3.0,
    'AR239-T': 3.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 4.0,
    'AR249-T': 2.0,
    'AR251-T': 4.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 4.0,
    'AR268-N': 3.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 3.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 3.0,
    'AR300-T': 1.0,
    'AR302-T': 3.0,
    'AR304-T': 2.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 3.0,
    'AR319-N': 4.0,
    'AR321-N': 3.0,
    'AR323-T': 1.0,
    'AR324-N': 2.0,
    'AR327-N': 3.0,
    'AR329-N': 4.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 3.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 3.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 3.0,
    'AR381-N': 2.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 3.0,
    'AR389-N': 4.0,
    'AR391-T': 2.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 2.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 4.0,
    'AR406-N': 2.0,
    'AR407-N': 2.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 4.0,
    'AR416-N': 4.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 2.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 2.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 2.0,
    'AR439-T': 2.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 4.0,
    'AR452-N': 3.0,
    'AR453-N': 2.0,
    'AR454-T': 1.0,
    'AR455-T': 3.0,
    'AR456-N': 2.0,
    'AR457-T': 2.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 4.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 2.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 4.0,
    'AR473-N': 2.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 2.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 3.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 1.0,
    'AR483-N': 2.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 1.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  '1-2-4-TRICHLOROBENZENE': {
    'AR001-N': 3.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 3.0,
    'AR014-N': 3.0,
    'AR018-T': 2.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 2.0,
    'AR029-N': 3.0,
    'AR031-T': 4.0,
    'AR033-N': 3.0,
    'AR035-T': 2.0,
    'AR037-T': 3.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 3.0,
    'AR048-N': 2.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 3.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 2.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 4.0,
    'AR077-N': 2.0,
    'AR079-T': 3.0,
    'AR081-T': 3.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 1.0,
    'AR098-T': 4.0,
    'AR100-N': 3.0,
    'AR102-N': 1.0,
    'AR104-T': 2.0,
    'AR108-T': 4.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 4.0,
    'AR118-T': 1.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 2.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 3.0,
    'AR142-T': 4.0,
    'AR144-N': 3.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 2.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 1.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 4.0,
    'AR158-T': 3.0,
    'AR160-T': 4.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 4.0,
    'AR169-T': 2.0,
    'AR170-N': 3.0,
    'AR172-N': 3.0,
    'AR174-T': 3.0,
    'AR177-T': 1.0,
    'AR178-T': 3.0,
    'AR180-T': 1.0,
    'AR182-N': 4.0,
    'AR183-T': 2.0,
    'AR185-T': 1.0,
    'AR186-T': 2.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 2.0,
    'AR190-N': 3.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 4.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 1.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 4.0,
    'AR211-N': 2.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 3.0,
    'AR219-T': 2.0,
    'AR220-T': 3.0,
    'AR222-N': 2.0,
    'AR224-N': 1.0,
    'AR226-T': 3.0,
    'AR228-T': 1.0,
    'AR229-N': 1.0,
    'AR230-N': 2.0,
    'AR231-T': 3.0,
    'AR232-T': 1.0,
    'AR233-T': 3.0,
    'AR237-T': 2.0,
    'AR238-T': 2.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 4.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 3.0,
    'AR251-T': 4.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 4.0,
    'AR258-T': 2.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 4.0,
    'AR266-T': 3.0,
    'AR268-N': 2.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 3.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 3.0,
    'AR300-T': 1.0,
    'AR302-T': 4.0,
    'AR304-T': 4.0,
    'AR306-N': 2.0,
    'AR309-N': 4.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 3.0,
    'AR319-N': 4.0,
    'AR321-N': 3.0,
    'AR323-T': 1.0,
    'AR324-N': 3.0,
    'AR327-N': 2.0,
    'AR329-N': 4.0,
    'AR331-T': 3.0,
    'AR333-N': 4.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 3.0,
    'AR345-N': 4.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 3.0,
    'AR353-N': 3.0,
    'AR357-N': 2.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 4.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 2.0,
    'AR408-N': 2.0,
    'AR409-N': 4.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 2.0,
    'AR414-N': 4.0,
    'AR416-N': 4.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 2.0,
    'AR424-N': 1.0,
    'AR425-T': 3.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 3.0,
    'AR437-N': 2.0,
    'AR438-T': 2.0,
    'AR439-T': 2.0,
    'AR440-N': 3.0,
    'AR441-T': 3.0,
    'AR442-T': 4.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 4.0,
    'AR452-N': 3.0,
    'AR453-N': 2.0,
    'AR454-T': 2.0,
    'AR455-T': 4.0,
    'AR456-N': 2.0,
    'AR457-T': 2.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 3.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 2.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 4.0,
    'AR473-N': 3.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 3.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 1.0,
    'AR483-N': 4.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 4.0,
    'AR490-T': 1.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  '1-3-BUTADIENE': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 3.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 2.0,
    'AR028-N': 3.0,
    'AR029-N': 4.0,
    'AR031-T': 1.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 2.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 3.0,
    'AR056-T': 1.0,
    'AR057-T': 1.0,
    'AR059-N': 3.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 3.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 2.0,
    'AR075-N': 2.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 3.0,
    'AR083-T': 1.0,
    'AR085-N': 1.0,
    'AR088-T': 2.0,
    'AR090-N': 4.0,
    'AR098-T': 2.0,
    'AR100-N': 2.0,
    'AR102-N': 3.0,
    'AR104-T': 1.0,
    'AR108-T': 1.0,
    'AR110-T': 2.0,
    'AR112-T': 3.0,
    'AR115-T': 2.0,
    'AR118-T': 4.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 2.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 3.0,
    'AR145-N': 3.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 4.0,
    'AR154-T': 3.0,
    'AR155-N': 4.0,
    'AR157-N': 3.0,
    'AR158-T': 3.0,
    'AR160-T': 1.0,
    'AR162-N': 2.0,
    'AR164-T': 2.0,
    'AR165-T': 2.0,
    'AR167-T': 2.0,
    'AR169-T': 3.0,
    'AR170-N': 1.0,
    'AR172-N': 4.0,
    'AR174-T': 3.0,
    'AR177-T': 4.0,
    'AR178-T': 3.0,
    'AR180-T': 3.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 3.0,
    'AR186-T': 3.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 1.0,
    'AR190-N': 3.0,
    'AR192-T': 1.0,
    'AR193-T': 2.0,
    'AR197-T': 4.0,
    'AR198-T': 4.0,
    'AR200-T': 1.0,
    'AR202-T': 2.0,
    'AR204-T': 4.0,
    'AR206-T': 4.0,
    'AR208-T': 1.0,
    'AR209-T': 4.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 2.0,
    'AR224-N': 2.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 3.0,
    'AR232-T': 4.0,
    'AR233-T': 2.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 2.0,
    'AR249-T': 1.0,
    'AR251-T': 2.0,
    'AR252-T': 4.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 2.0,
    'AR281-N': 3.0,
    'AR283-T': 2.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 3.0,
    'AR293-T': 2.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 4.0,
    'AR302-T': 3.0,
    'AR304-T': 2.0,
    'AR306-N': 2.0,
    'AR309-N': 2.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 2.0,
    'AR321-N': 1.0,
    'AR323-T': 4.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 1.0,
    'AR331-T': 3.0,
    'AR333-N': 4.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 3.0,
    'AR349-N': 4.0,
    'AR351-N': 3.0,
    'AR353-N': 2.0,
    'AR357-N': 2.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 1.0,
    'AR371-T': 2.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 3.0,
    'AR376-N': 2.0,
    'AR381-N': 2.0,
    'AR382-N': 2.0,
    'AR384-N': 3.0,
    'AR387-N': 3.0,
    'AR389-N': 2.0,
    'AR391-T': 2.0,
    'AR392-N': 3.0,
    'AR394-N': 1.0,
    'AR398-N': 2.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 1.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 2.0,
    'AR409-N': 3.0,
    'AR411-N': 3.0,
    'AR412-N': 4.0,
    'AR413-N': 2.0,
    'AR414-N': 1.0,
    'AR416-N': 1.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 3.0,
    'AR422-T': 4.0,
    'AR423-N': 3.0,
    'AR424-N': 4.0,
    'AR425-T': 3.0,
    'AR426-N': 2.0,
    'AR427-N': 3.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 2.0,
    'AR432-N': 3.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 3.0,
    'AR436-N': 1.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 2.0,
    'AR440-N': 3.0,
    'AR441-T': 4.0,
    'AR442-T': 3.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 1.0,
    'AR446-N': 2.0,
    'AR447-N': 4.0,
    'AR448-T': 4.0,
    'AR449-T': 2.0,
    'AR450-N': 2.0,
    'AR451-N': 1.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 2.0,
    'AR455-T': 3.0,
    'AR456-N': 3.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 2.0,
    'AR460-T': 4.0,
    'AR461-T': 2.0,
    'AR462-T': 2.0,
    'AR463-T': 4.0,
    'AR464-T': 1.0,
    'AR465-T': 3.0,
    'AR466-N': 4.0,
    'AR467-N': 4.0,
    'AR468-T': 3.0,
    'AR469-N': 2.0,
    'AR470-T': 4.0,
    'AR471-T': 4.0,
    'AR472-T': 1.0,
    'AR473-N': 2.0,
    'AR474-N': 1.0,
    'AR475-T': 2.0,
    'AR476-T': 2.0,
    'AR477-T': 4.0,
    'AR478-T': 4.0,
    'AR479-T': 3.0,
    'AR480-N': 4.0,
    'AR481-': 1.0,
    'AR482-T': 4.0,
    'AR483-N': 2.0,
    'AR484-T': 4.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  '1-3-DICHLOROPROPENE': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 4.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 3.0,
    'AR019-N': 2.0,
    'AR021-N': 3.0,
    'AR026-T': 4.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 4.0,
    'AR035-T': 2.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 3.0,
    'AR048-N': 2.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 3.0,
    'AR063-T': 4.0,
    'AR064-T': 2.0,
    'AR066-T': 4.0,
    'AR068-N': 3.0,
    'AR070-N': 2.0,
    'AR071-T': 4.0,
    'AR073-T': 3.0,
    'AR075-N': 3.0,
    'AR077-N': 2.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 3.0,
    'AR090-N': 1.0,
    'AR098-T': 3.0,
    'AR100-N': 4.0,
    'AR102-N': 2.0,
    'AR104-T': 2.0,
    'AR108-T': 3.0,
    'AR110-T': 2.0,
    'AR112-T': 4.0,
    'AR115-T': 3.0,
    'AR118-T': 1.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 2.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 2.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 1.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 4.0,
    'AR165-T': 3.0,
    'AR167-T': 2.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 2.0,
    'AR185-T': 1.0,
    'AR186-T': 3.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 4.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 3.0,
    'AR204-T': 4.0,
    'AR206-T': 1.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 2.0,
    'AR229-N': 1.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 4.0,
    'AR237-T': 1.0,
    'AR238-T': 2.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 4.0,
    'AR258-T': 2.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 2.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 4.0,
    'AR279-T': 1.0,
    'AR281-N': 4.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 1.0,
    'AR302-T': 4.0,
    'AR304-T': 3.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 3.0,
    'AR313-N': 4.0,
    'AR315-N': 4.0,
    'AR319-N': 3.0,
    'AR321-N': 3.0,
    'AR323-T': 1.0,
    'AR324-N': 2.0,
    'AR327-N': 2.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 4.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 3.0,
    'AR353-N': 4.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 4.0,
    'AR373-N': 4.0,
    'AR374-N': 4.0,
    'AR376-N': 3.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 3.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 2.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 2.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 2.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 3.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 4.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 3.0,
    'AR439-T': 2.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 4.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 4.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 2.0,
    'AR455-T': 4.0,
    'AR456-N': 2.0,
    'AR457-T': 3.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 3.0,
    'AR466-N': 3.0,
    'AR467-N': 1.0,
    'AR468-T': 3.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 4.0,
    'AR478-T': 1.0,
    'AR479-T': 3.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 1.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  '1-4-DICHLOROBENZENE': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 4.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 3.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 4.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 4.0,
    'AR035-T': 2.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 3.0,
    'AR048-N': 2.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 3.0,
    'AR063-T': 4.0,
    'AR064-T': 2.0,
    'AR066-T': 4.0,
    'AR068-N': 3.0,
    'AR070-N': 2.0,
    'AR071-T': 4.0,
    'AR073-T': 3.0,
    'AR075-N': 3.0,
    'AR077-N': 2.0,
    'AR079-T': 4.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 3.0,
    'AR088-T': 3.0,
    'AR090-N': 1.0,
    'AR098-T': 3.0,
    'AR100-N': 4.0,
    'AR102-N': 1.0,
    'AR104-T': 2.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 3.0,
    'AR118-T': 1.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 2.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 2.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 1.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 4.0,
    'AR165-T': 3.0,
    'AR167-T': 3.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 2.0,
    'AR185-T': 2.0,
    'AR186-T': 3.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 4.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 3.0,
    'AR204-T': 4.0,
    'AR206-T': 1.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 1.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 4.0,
    'AR237-T': 2.0,
    'AR238-T': 2.0,
    'AR239-T': 3.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 2.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 4.0,
    'AR279-T': 1.0,
    'AR281-N': 4.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 1.0,
    'AR302-T': 3.0,
    'AR304-T': 3.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 3.0,
    'AR313-N': 4.0,
    'AR315-N': 4.0,
    'AR319-N': 3.0,
    'AR321-N': 2.0,
    'AR323-T': 1.0,
    'AR324-N': 2.0,
    'AR327-N': 2.0,
    'AR329-N': 2.0,
    'AR331-T': 3.0,
    'AR333-N': 4.0,
    'AR335-N': 4.0,
    'AR339-N': 4.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 3.0,
    'AR353-N': 4.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 4.0,
    'AR373-N': 4.0,
    'AR374-N': 4.0,
    'AR376-N': 3.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 3.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 2.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 2.0,
    'AR422-T': 2.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 3.0,
    'AR427-N': 2.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 4.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 3.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 1.0,
    'AR455-T': 3.0,
    'AR456-N': 2.0,
    'AR457-T': 3.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 3.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 2.0,
    'AR473-N': 3.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 4.0,
    'AR478-T': 2.0,
    'AR479-T': 3.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 1.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  '1-4-DIOXANE': {
    'AR001-N': 2.0,
    'AR003-T': 3.0,
    'AR008-N': 2.0,
    'AR012-T': 2.0,
    'AR014-N': 2.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 2.0,
    'AR026-T': 2.0,
    'AR028-N': 1.0,
    'AR029-N': 2.0,
    'AR031-T': 3.0,
    'AR033-N': 2.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 2.0,
    'AR044-N': 1.0,
    'AR046-N': 2.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 3.0,
    'AR056-T': 1.0,
    'AR057-T': 3.0,
    'AR059-N': 2.0,
    'AR061-T': 2.0,
    'AR063-T': 3.0,
    'AR064-T': 2.0,
    'AR066-T': 3.0,
    'AR068-N': 2.0,
    'AR070-N': 1.0,
    'AR071-T': 2.0,
    'AR073-T': 3.0,
    'AR075-N': 3.0,
    'AR077-N': 2.0,
    'AR079-T': 2.0,
    'AR081-T': 2.0,
    'AR083-T': 3.0,
    'AR085-N': 1.0,
    'AR088-T': 2.0,
    'AR090-N': 4.0,
    'AR098-T': 3.0,
    'AR100-N': 2.0,
    'AR102-N': 4.0,
    'AR104-T': 1.0,
    'AR108-T': 3.0,
    'AR110-T': 4.0,
    'AR112-T': 3.0,
    'AR115-T': 2.0,
    'AR118-T': 4.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 3.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 1.0,
    'AR142-T': 3.0,
    'AR144-N': 2.0,
    'AR145-N': 4.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 3.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 3.0,
    'AR158-T': 2.0,
    'AR160-T': 3.0,
    'AR162-N': 4.0,
    'AR164-T': 2.0,
    'AR165-T': 3.0,
    'AR167-T': 2.0,
    'AR169-T': 4.0,
    'AR170-N': 3.0,
    'AR172-N': 2.0,
    'AR174-T': 2.0,
    'AR177-T': 4.0,
    'AR178-T': 2.0,
    'AR180-T': 4.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 4.0,
    'AR186-T': 1.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 1.0,
    'AR190-N': 2.0,
    'AR192-T': 3.0,
    'AR193-T': 3.0,
    'AR197-T': 3.0,
    'AR198-T': 4.0,
    'AR200-T': 1.0,
    'AR202-T': 3.0,
    'AR204-T': 1.0,
    'AR206-T': 4.0,
    'AR208-T': 1.0,
    'AR209-T': 4.0,
    'AR210-N': 2.0,
    'AR211-N': 1.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 2.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 4.0,
    'AR224-N': 4.0,
    'AR226-T': 2.0,
    'AR228-T': 3.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 2.0,
    'AR232-T': 4.0,
    'AR233-T': 2.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 1.0,
    'AR243-T': 2.0,
    'AR245-N': 1.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 3.0,
    'AR252-T': 4.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 2.0,
    'AR262-T': 3.0,
    'AR264-N': 3.0,
    'AR266-T': 3.0,
    'AR268-N': 1.0,
    'AR271-T': 3.0,
    'AR273-T': 3.0,
    'AR275-T': 1.0,
    'AR277-T': 2.0,
    'AR279-T': 4.0,
    'AR281-N': 2.0,
    'AR283-T': 4.0,
    'AR285-T': 3.0,
    'AR287-T': 1.0,
    'AR289-T': 4.0,
    'AR293-T': 3.0,
    'AR297-N': 3.0,
    'AR299-N': 2.0,
    'AR300-T': 4.0,
    'AR302-T': 3.0,
    'AR304-T': 2.0,
    'AR306-N': 4.0,
    'AR309-N': 3.0,
    'AR311-N': 1.0,
    'AR313-N': 2.0,
    'AR315-N': 2.0,
    'AR319-N': 3.0,
    'AR321-N': 2.0,
    'AR323-T': 4.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 1.0,
    'AR333-N': 3.0,
    'AR335-N': 2.0,
    'AR339-N': 2.0,
    'AR341-N': 3.0,
    'AR343-N': 2.0,
    'AR345-N': 3.0,
    'AR347-T': 4.0,
    'AR349-N': 4.0,
    'AR351-N': 2.0,
    'AR353-N': 2.0,
    'AR357-N': 4.0,
    'AR363-N': 1.0,
    'AR365-T': 3.0,
    'AR367-N': 2.0,
    'AR368-N': 3.0,
    'AR370-N': 1.0,
    'AR371-T': 4.0,
    'AR372-N': 2.0,
    'AR373-N': 2.0,
    'AR374-N': 3.0,
    'AR376-N': 3.0,
    'AR381-N': 1.0,
    'AR382-N': 1.0,
    'AR384-N': 1.0,
    'AR387-N': 2.0,
    'AR389-N': 3.0,
    'AR391-T': 1.0,
    'AR392-N': 3.0,
    'AR394-N': 1.0,
    'AR398-N': 1.0,
    'AR400-T': 1.0,
    'AR402-N': 1.0,
    'AR404-N': 3.0,
    'AR406-N': 1.0,
    'AR407-N': 2.0,
    'AR408-N': 4.0,
    'AR409-N': 2.0,
    'AR411-N': 3.0,
    'AR412-N': 3.0,
    'AR413-N': 2.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 4.0,
    'AR420-N': 1.0,
    'AR421-N': 4.0,
    'AR422-T': 4.0,
    'AR423-N': 1.0,
    'AR424-N': 4.0,
    'AR425-T': 2.0,
    'AR426-N': 3.0,
    'AR427-N': 4.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 4.0,
    'AR432-N': 2.0,
    'AR433-T': 1.0,
    'AR434-N': 3.0,
    'AR435-N': 4.0,
    'AR436-N': 2.0,
    'AR437-N': 1.0,
    'AR438-T': 1.0,
    'AR439-T': 4.0,
    'AR440-N': 2.0,
    'AR441-T': 1.0,
    'AR442-T': 3.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 4.0,
    'AR446-N': 2.0,
    'AR447-N': 4.0,
    'AR448-T': 2.0,
    'AR449-T': 1.0,
    'AR450-N': 1.0,
    'AR451-N': 3.0,
    'AR452-N': 2.0,
    'AR453-N': 1.0,
    'AR454-T': 4.0,
    'AR455-T': 3.0,
    'AR456-N': 1.0,
    'AR457-T': 1.0,
    'AR458-T': 3.0,
    'AR459-T': 4.0,
    'AR460-T': 4.0,
    'AR461-T': 4.0,
    'AR462-T': 4.0,
    'AR463-T': 4.0,
    'AR464-T': 3.0,
    'AR465-T': 3.0,
    'AR466-N': 3.0,
    'AR467-N': 4.0,
    'AR468-T': 3.0,
    'AR469-N': 1.0,
    'AR470-T': 4.0,
    'AR471-T': 1.0,
    'AR472-T': 3.0,
    'AR473-N': 1.0,
    'AR474-N': 1.0,
    'AR475-T': 4.0,
    'AR476-T': 4.0,
    'AR477-T': 1.0,
    'AR478-T': 4.0,
    'AR479-T': 2.0,
    'AR480-N': 4.0,
    'AR481-': 4.0,
    'AR482-T': 4.0,
    'AR483-N': 2.0,
    'AR484-T': 4.0,
    'AR485-N': 4.0,
    'AR486-N': 4.0,
    'AR488-N': 3.0,
    'AR489-T': 3.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  '2-CHLOROACETOPHENONE': {
    'AR001-N': 3.0,
    'AR003-T': 4.0,
    'AR008-N': 2.0,
    'AR012-T': 3.0,
    'AR014-N': 3.0,
    'AR018-T': 1.0,
    'AR019-N': 4.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 1.0,
    'AR029-N': 3.0,
    'AR031-T': 1.0,
    'AR033-N': 2.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 2.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 4.0,
    'AR059-N': 3.0,
    'AR061-T': 1.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 2.0,
    'AR070-N': 3.0,
    'AR071-T': 2.0,
    'AR073-T': 3.0,
    'AR075-N': 2.0,
    'AR077-N': 4.0,
    'AR079-T': 2.0,
    'AR081-T': 3.0,
    'AR083-T': 1.0,
    'AR085-N': 1.0,
    'AR088-T': 3.0,
    'AR090-N': 4.0,
    'AR098-T': 2.0,
    'AR100-N': 3.0,
    'AR102-N': 4.0,
    'AR104-T': 1.0,
    'AR108-T': 4.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 4.0,
    'AR118-T': 4.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 3.0,
    'AR145-N': 3.0,
    'AR146-T': 2.0,
    'AR147-N': 2.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 4.0,
    'AR154-T': 3.0,
    'AR155-N': 4.0,
    'AR157-N': 3.0,
    'AR158-T': 3.0,
    'AR160-T': 1.0,
    'AR162-N': 4.0,
    'AR164-T': 3.0,
    'AR165-T': 1.0,
    'AR167-T': 2.0,
    'AR169-T': 2.0,
    'AR170-N': 2.0,
    'AR172-N': 3.0,
    'AR174-T': 3.0,
    'AR177-T': 4.0,
    'AR178-T': 3.0,
    'AR180-T': 3.0,
    'AR182-N': 2.0,
    'AR183-T': 4.0,
    'AR185-T': 4.0,
    'AR186-T': 2.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 4.0,
    'AR190-N': 3.0,
    'AR192-T': 1.0,
    'AR193-T': 2.0,
    'AR197-T': 4.0,
    'AR198-T': 4.0,
    'AR200-T': 4.0,
    'AR202-T': 3.0,
    'AR204-T': 2.0,
    'AR206-T': 4.0,
    'AR208-T': 2.0,
    'AR209-T': 3.0,
    'AR210-N': 2.0,
    'AR211-N': 1.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 2.0,
    'AR220-T': 1.0,
    'AR222-N': 2.0,
    'AR224-N': 2.0,
    'AR226-T': 1.0,
    'AR228-T': 1.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 3.0,
    'AR232-T': 4.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 1.0,
    'AR243-T': 3.0,
    'AR245-N': 1.0,
    'AR247-T': 1.0,
    'AR249-T': 2.0,
    'AR251-T': 2.0,
    'AR252-T': 4.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 2.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 1.0,
    'AR277-T': 2.0,
    'AR279-T': 4.0,
    'AR281-N': 2.0,
    'AR283-T': 2.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 3.0,
    'AR293-T': 2.0,
    'AR297-N': 4.0,
    'AR299-N': 3.0,
    'AR300-T': 4.0,
    'AR302-T': 3.0,
    'AR304-T': 3.0,
    'AR306-N': 2.0,
    'AR309-N': 2.0,
    'AR311-N': 1.0,
    'AR313-N': 2.0,
    'AR315-N': 3.0,
    'AR319-N': 3.0,
    'AR321-N': 2.0,
    'AR323-T': 4.0,
    'AR324-N': 1.0,
    'AR327-N': 1.0,
    'AR329-N': 1.0,
    'AR331-T': 2.0,
    'AR333-N': 3.0,
    'AR335-N': 2.0,
    'AR339-N': 2.0,
    'AR341-N': 4.0,
    'AR343-N': 3.0,
    'AR345-N': 3.0,
    'AR347-T': 3.0,
    'AR349-N': 4.0,
    'AR351-N': 3.0,
    'AR353-N': 3.0,
    'AR357-N': 2.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 3.0,
    'AR368-N': 4.0,
    'AR370-N': 1.0,
    'AR371-T': 2.0,
    'AR372-N': 2.0,
    'AR373-N': 2.0,
    'AR374-N': 4.0,
    'AR376-N': 2.0,
    'AR381-N': 1.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 1.0,
    'AR389-N': 2.0,
    'AR391-T': 2.0,
    'AR392-N': 4.0,
    'AR394-N': 1.0,
    'AR398-N': 1.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 1.0,
    'AR406-N': 2.0,
    'AR407-N': 2.0,
    'AR408-N': 2.0,
    'AR409-N': 2.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 1.0,
    'AR414-N': 1.0,
    'AR416-N': 1.0,
    'AR418-T': 3.0,
    'AR420-N': 1.0,
    'AR421-N': 3.0,
    'AR422-T': 3.0,
    'AR423-N': 1.0,
    'AR424-N': 4.0,
    'AR425-T': 3.0,
    'AR426-N': 2.0,
    'AR427-N': 3.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 3.0,
    'AR432-N': 2.0,
    'AR433-T': 1.0,
    'AR434-N': 4.0,
    'AR435-N': 2.0,
    'AR436-N': 1.0,
    'AR437-N': 1.0,
    'AR438-T': 1.0,
    'AR439-T': 1.0,
    'AR440-N': 3.0,
    'AR441-T': 2.0,
    'AR442-T': 3.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 1.0,
    'AR446-N': 2.0,
    'AR447-N': 3.0,
    'AR448-T': 3.0,
    'AR449-T': 2.0,
    'AR450-N': 1.0,
    'AR451-N': 1.0,
    'AR452-N': 3.0,
    'AR453-N': 1.0,
    'AR454-T': 4.0,
    'AR455-T': 3.0,
    'AR456-N': 1.0,
    'AR457-T': 1.0,
    'AR458-T': 4.0,
    'AR459-T': 2.0,
    'AR460-T': 3.0,
    'AR461-T': 4.0,
    'AR462-T': 3.0,
    'AR463-T': 3.0,
    'AR464-T': 1.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 4.0,
    'AR468-T': 2.0,
    'AR469-N': 2.0,
    'AR470-T': 3.0,
    'AR471-T': 2.0,
    'AR472-T': 1.0,
    'AR473-N': 2.0,
    'AR474-N': 3.0,
    'AR475-T': 4.0,
    'AR476-T': 3.0,
    'AR477-T': 2.0,
    'AR478-T': 3.0,
    'AR479-T': 3.0,
    'AR480-N': 3.0,
    'AR481-': 4.0,
    'AR482-T': 4.0,
    'AR483-N': 2.0,
    'AR484-T': 4.0,
    'AR485-N': 4.0,
    'AR486-N': 3.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  '2-NITROPROPANE': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 3.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 4.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 3.0,
    'AR035-T': 2.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 3.0,
    'AR048-N': 2.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 3.0,
    'AR063-T': 4.0,
    'AR064-T': 2.0,
    'AR066-T': 4.0,
    'AR068-N': 3.0,
    'AR070-N': 2.0,
    'AR071-T': 3.0,
    'AR073-T': 3.0,
    'AR075-N': 3.0,
    'AR077-N': 2.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 1.0,
    'AR098-T': 3.0,
    'AR100-N': 4.0,
    'AR102-N': 1.0,
    'AR104-T': 2.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 3.0,
    'AR118-T': 1.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 2.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 2.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 1.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 2.0,
    'AR169-T': 2.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 2.0,
    'AR185-T': 1.0,
    'AR186-T': 3.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 4.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 3.0,
    'AR204-T': 4.0,
    'AR206-T': 1.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 2.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 1.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 4.0,
    'AR237-T': 2.0,
    'AR238-T': 2.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 2.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 1.0,
    'AR302-T': 3.0,
    'AR304-T': 4.0,
    'AR306-N': 2.0,
    'AR309-N': 3.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 3.0,
    'AR321-N': 3.0,
    'AR323-T': 1.0,
    'AR324-N': 2.0,
    'AR327-N': 2.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 3.0,
    'AR353-N': 4.0,
    'AR357-N': 2.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 3.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 3.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 2.0,
    'AR408-N': 2.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 3.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 2.0,
    'AR455-T': 3.0,
    'AR456-N': 2.0,
    'AR457-T': 3.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 3.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 4.0,
    'AR478-T': 1.0,
    'AR479-T': 3.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 1.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  '2-2-4-TRIMETHYLPENTANE': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 3.0,
    'AR029-N': 4.0,
    'AR031-T': 1.0,
    'AR033-N': 3.0,
    'AR035-T': 2.0,
    'AR037-T': 1.0,
    'AR042-T': 4.0,
    'AR044-N': 3.0,
    'AR046-N': 3.0,
    'AR048-N': 2.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 3.0,
    'AR057-T': 1.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 2.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 3.0,
    'AR075-N': 2.0,
    'AR077-N': 2.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 3.0,
    'AR090-N': 2.0,
    'AR098-T': 2.0,
    'AR100-N': 3.0,
    'AR102-N': 2.0,
    'AR104-T': 2.0,
    'AR108-T': 2.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 3.0,
    'AR118-T': 1.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 3.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 3.0,
    'AR147-N': 1.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 1.0,
    'AR155-N': 2.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 2.0,
    'AR169-T': 2.0,
    'AR170-N': 2.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 1.0,
    'AR185-T': 1.0,
    'AR186-T': 3.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 4.0,
    'AR198-T': 2.0,
    'AR200-T': 1.0,
    'AR202-T': 2.0,
    'AR204-T': 4.0,
    'AR206-T': 2.0,
    'AR208-T': 1.0,
    'AR209-T': 3.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 3.0,
    'AR220-T': 1.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 3.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 3.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 4.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 2.0,
    'AR252-T': 1.0,
    'AR254-T': 1.0,
    'AR256-T': 4.0,
    'AR258-T': 1.0,
    'AR259-T': 2.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 2.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 1.0,
    'AR293-T': 2.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 2.0,
    'AR302-T': 4.0,
    'AR304-T': 3.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 3.0,
    'AR321-N': 3.0,
    'AR323-T': 2.0,
    'AR324-N': 3.0,
    'AR327-N': 2.0,
    'AR329-N': 2.0,
    'AR331-T': 3.0,
    'AR333-N': 4.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 3.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 3.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 4.0,
    'AR389-N': 2.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 3.0,
    'AR398-N': 3.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 2.0,
    'AR406-N': 3.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 3.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 3.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 3.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 2.0,
    'AR430-N': 1.0,
    'AR431-T': 2.0,
    'AR432-N': 3.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 2.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 3.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 4.0,
    'AR443-T': 2.0,
    'AR444-T': 1.0,
    'AR445-N': 1.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 2.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 2.0,
    'AR455-T': 4.0,
    'AR456-N': 3.0,
    'AR457-T': 3.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 3.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 4.0,
    'AR472-T': 2.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 1.0,
    'AR476-T': 2.0,
    'AR477-T': 4.0,
    'AR478-T': 2.0,
    'AR479-T': 3.0,
    'AR480-N': 1.0,
    'AR481-': 2.0,
    'AR482-T': 2.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 2.0,
    'AR486-N': 2.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 2.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  '2-4-DINITROTOLUENE': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 3.0,
    'AR033-N': 3.0,
    'AR035-T': 2.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 4.0,
    'AR048-N': 2.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 2.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 2.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 1.0,
    'AR098-T': 4.0,
    'AR100-N': 3.0,
    'AR102-N': 1.0,
    'AR104-T': 2.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 3.0,
    'AR118-T': 1.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 2.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 2.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 1.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 3.0,
    'AR169-T': 2.0,
    'AR170-N': 4.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 2.0,
    'AR185-T': 1.0,
    'AR186-T': 3.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 3.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 1.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 2.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 1.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 3.0,
    'AR237-T': 2.0,
    'AR238-T': 2.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 4.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 2.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 1.0,
    'AR302-T': 4.0,
    'AR304-T': 4.0,
    'AR306-N': 2.0,
    'AR309-N': 4.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 2.0,
    'AR323-T': 1.0,
    'AR324-N': 2.0,
    'AR327-N': 2.0,
    'AR329-N': 4.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 2.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 4.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 3.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 2.0,
    'AR408-N': 2.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 3.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 4.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 2.0,
    'AR446-N': 4.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 2.0,
    'AR455-T': 4.0,
    'AR456-N': 2.0,
    'AR457-T': 3.0,
    'AR458-T': 3.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 3.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 4.0,
    'AR473-N': 3.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 1.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  '2-4-TOLUENE-DIISOCYANATE': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 3.0,
    'AR035-T': 2.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 4.0,
    'AR048-N': 2.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 2.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 2.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 1.0,
    'AR098-T': 3.0,
    'AR100-N': 3.0,
    'AR102-N': 1.0,
    'AR104-T': 2.0,
    'AR108-T': 4.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 4.0,
    'AR118-T': 1.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 2.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 2.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 3.0,
    'AR169-T': 1.0,
    'AR170-N': 4.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 2.0,
    'AR185-T': 1.0,
    'AR186-T': 3.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 4.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 4.0,
    'AR204-T': 4.0,
    'AR206-T': 1.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 3.0,
    'AR237-T': 2.0,
    'AR238-T': 2.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 2.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 1.0,
    'AR302-T': 3.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 4.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 3.0,
    'AR323-T': 1.0,
    'AR324-N': 2.0,
    'AR327-N': 2.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 4.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 3.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 2.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 2.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 3.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 3.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 1.0,
    'AR455-T': 3.0,
    'AR456-N': 2.0,
    'AR457-T': 3.0,
    'AR458-T': 3.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 4.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 4.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 4.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 3.0,
    'AR486-N': 4.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 1.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  '4-NITROPHENOL': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 4.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 4.0,
    'AR035-T': 2.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 3.0,
    'AR046-N': 3.0,
    'AR048-N': 2.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 3.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 2.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 4.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 2.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 2.0,
    'AR098-T': 3.0,
    'AR100-N': 3.0,
    'AR102-N': 2.0,
    'AR104-T': 2.0,
    'AR108-T': 4.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 4.0,
    'AR118-T': 1.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 2.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 1.0,
    'AR154-T': 1.0,
    'AR155-N': 2.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 3.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 1.0,
    'AR185-T': 1.0,
    'AR186-T': 3.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 3.0,
    'AR198-T': 2.0,
    'AR200-T': 2.0,
    'AR202-T': 3.0,
    'AR204-T': 3.0,
    'AR206-T': 1.0,
    'AR208-T': 1.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 1.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 2.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 4.0,
    'AR279-T': 1.0,
    'AR281-N': 4.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 3.0,
    'AR289-T': 1.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 1.0,
    'AR302-T': 4.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 3.0,
    'AR313-N': 4.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 3.0,
    'AR323-T': 2.0,
    'AR324-N': 2.0,
    'AR327-N': 2.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 4.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 2.0,
    'AR351-N': 3.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 4.0,
    'AR373-N': 4.0,
    'AR374-N': 4.0,
    'AR376-N': 3.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 3.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 2.0,
    'AR423-N': 2.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 2.0,
    'AR429-T': 2.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 4.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 2.0,
    'AR439-T': 2.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 4.0,
    'AR443-T': 2.0,
    'AR444-T': 1.0,
    'AR445-N': 1.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 2.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 3.0,
    'AR455-T': 4.0,
    'AR456-N': 2.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 3.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 2.0,
    'AR479-T': 3.0,
    'AR480-N': 1.0,
    'AR481-': 4.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 4.0,
    'AR486-N': 4.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 2.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  '4-4--METHYLENEDIPHENYL-DIISOCYANATE--MDI-': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 3.0,
    'AR035-T': 2.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 4.0,
    'AR048-N': 2.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 2.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 3.0,
    'AR073-T': 3.0,
    'AR075-N': 3.0,
    'AR077-N': 2.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 1.0,
    'AR098-T': 3.0,
    'AR100-N': 3.0,
    'AR102-N': 1.0,
    'AR104-T': 2.0,
    'AR108-T': 4.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 4.0,
    'AR118-T': 1.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 2.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 2.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 3.0,
    'AR169-T': 1.0,
    'AR170-N': 4.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 2.0,
    'AR185-T': 1.0,
    'AR186-T': 3.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 4.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 4.0,
    'AR204-T': 4.0,
    'AR206-T': 1.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 1.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 3.0,
    'AR237-T': 2.0,
    'AR238-T': 2.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 2.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 1.0,
    'AR302-T': 3.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 4.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 3.0,
    'AR323-T': 2.0,
    'AR324-N': 2.0,
    'AR327-N': 2.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 4.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 3.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 2.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 2.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 3.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 3.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 1.0,
    'AR455-T': 3.0,
    'AR456-N': 2.0,
    'AR457-T': 3.0,
    'AR458-T': 3.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 4.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 4.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 4.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 4.0,
    'AR486-N': 4.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 2.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  ACETALDEHYDE: {
    'AR001-N': 3.0,
    'AR003-T': 4.0,
    'AR008-N': 2.0,
    'AR012-T': 3.0,
    'AR014-N': 3.0,
    'AR018-T': 1.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 2.0,
    'AR028-N': 2.0,
    'AR029-N': 3.0,
    'AR031-T': 1.0,
    'AR033-N': 2.0,
    'AR035-T': 2.0,
    'AR037-T': 2.0,
    'AR042-T': 3.0,
    'AR044-N': 3.0,
    'AR046-N': 2.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 2.0,
    'AR054-N': 3.0,
    'AR056-T': 2.0,
    'AR057-T': 1.0,
    'AR059-N': 2.0,
    'AR061-T': 1.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 3.0,
    'AR070-N': 1.0,
    'AR071-T': 2.0,
    'AR073-T': 3.0,
    'AR075-N': 2.0,
    'AR077-N': 2.0,
    'AR079-T': 2.0,
    'AR081-T': 3.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 2.0,
    'AR090-N': 4.0,
    'AR098-T': 3.0,
    'AR100-N': 2.0,
    'AR102-N': 3.0,
    'AR104-T': 1.0,
    'AR108-T': 2.0,
    'AR110-T': 2.0,
    'AR112-T': 4.0,
    'AR115-T': 3.0,
    'AR118-T': 4.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 2.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 3.0,
    'AR145-N': 3.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 1.0,
    'AR150-T': 1.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 3.0,
    'AR158-T': 2.0,
    'AR160-T': 2.0,
    'AR162-N': 3.0,
    'AR164-T': 2.0,
    'AR165-T': 2.0,
    'AR167-T': 2.0,
    'AR169-T': 3.0,
    'AR170-N': 3.0,
    'AR172-N': 3.0,
    'AR174-T': 3.0,
    'AR177-T': 4.0,
    'AR178-T': 2.0,
    'AR180-T': 3.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 3.0,
    'AR186-T': 1.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 1.0,
    'AR190-N': 3.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 4.0,
    'AR198-T': 4.0,
    'AR200-T': 1.0,
    'AR202-T': 3.0,
    'AR204-T': 2.0,
    'AR206-T': 4.0,
    'AR208-T': 1.0,
    'AR209-T': 4.0,
    'AR210-N': 3.0,
    'AR211-N': 1.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 2.0,
    'AR220-T': 1.0,
    'AR222-N': 3.0,
    'AR224-N': 3.0,
    'AR226-T': 1.0,
    'AR228-T': 1.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 2.0,
    'AR232-T': 4.0,
    'AR233-T': 2.0,
    'AR237-T': 1.0,
    'AR238-T': 2.0,
    'AR239-T': 1.0,
    'AR242-N': 1.0,
    'AR243-T': 3.0,
    'AR245-N': 1.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 3.0,
    'AR252-T': 4.0,
    'AR254-T': 1.0,
    'AR256-T': 4.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 2.0,
    'AR279-T': 3.0,
    'AR281-N': 2.0,
    'AR283-T': 3.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 4.0,
    'AR293-T': 2.0,
    'AR297-N': 4.0,
    'AR299-N': 3.0,
    'AR300-T': 4.0,
    'AR302-T': 4.0,
    'AR304-T': 2.0,
    'AR306-N': 3.0,
    'AR309-N': 3.0,
    'AR311-N': 1.0,
    'AR313-N': 2.0,
    'AR315-N': 3.0,
    'AR319-N': 3.0,
    'AR321-N': 3.0,
    'AR323-T': 4.0,
    'AR324-N': 1.0,
    'AR327-N': 2.0,
    'AR329-N': 2.0,
    'AR331-T': 2.0,
    'AR333-N': 4.0,
    'AR335-N': 2.0,
    'AR339-N': 2.0,
    'AR341-N': 4.0,
    'AR343-N': 3.0,
    'AR345-N': 3.0,
    'AR347-T': 4.0,
    'AR349-N': 4.0,
    'AR351-N': 3.0,
    'AR353-N': 2.0,
    'AR357-N': 3.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 3.0,
    'AR368-N': 4.0,
    'AR370-N': 1.0,
    'AR371-T': 2.0,
    'AR372-N': 2.0,
    'AR373-N': 2.0,
    'AR374-N': 3.0,
    'AR376-N': 3.0,
    'AR381-N': 1.0,
    'AR382-N': 1.0,
    'AR384-N': 2.0,
    'AR387-N': 2.0,
    'AR389-N': 3.0,
    'AR391-T': 1.0,
    'AR392-N': 3.0,
    'AR394-N': 1.0,
    'AR398-N': 1.0,
    'AR400-T': 1.0,
    'AR402-N': 1.0,
    'AR404-N': 2.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 3.0,
    'AR409-N': 3.0,
    'AR411-N': 3.0,
    'AR412-N': 4.0,
    'AR413-N': 2.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 2.0,
    'AR420-N': 1.0,
    'AR421-N': 4.0,
    'AR422-T': 4.0,
    'AR423-N': 2.0,
    'AR424-N': 4.0,
    'AR425-T': 3.0,
    'AR426-N': 3.0,
    'AR427-N': 4.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 3.0,
    'AR432-N': 2.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 3.0,
    'AR436-N': 1.0,
    'AR437-N': 1.0,
    'AR438-T': 1.0,
    'AR439-T': 2.0,
    'AR440-N': 2.0,
    'AR441-T': 3.0,
    'AR442-T': 4.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 1.0,
    'AR446-N': 4.0,
    'AR447-N': 4.0,
    'AR448-T': 3.0,
    'AR449-T': 2.0,
    'AR450-N': 1.0,
    'AR451-N': 2.0,
    'AR452-N': 3.0,
    'AR453-N': 1.0,
    'AR454-T': 2.0,
    'AR455-T': 4.0,
    'AR456-N': 2.0,
    'AR457-T': 1.0,
    'AR458-T': 4.0,
    'AR459-T': 2.0,
    'AR460-T': 4.0,
    'AR461-T': 3.0,
    'AR462-T': 2.0,
    'AR463-T': 4.0,
    'AR464-T': 1.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 4.0,
    'AR468-T': 2.0,
    'AR469-N': 1.0,
    'AR470-T': 4.0,
    'AR471-T': 3.0,
    'AR472-T': 2.0,
    'AR473-N': 1.0,
    'AR474-N': 1.0,
    'AR475-T': 3.0,
    'AR476-T': 3.0,
    'AR477-T': 2.0,
    'AR478-T': 4.0,
    'AR479-T': 3.0,
    'AR480-N': 4.0,
    'AR481-': 1.0,
    'AR482-T': 4.0,
    'AR483-N': 2.0,
    'AR484-T': 4.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  ACETAMIDE: {
    'AR001-N': 3.0,
    'AR003-T': 3.0,
    'AR008-N': 3.0,
    'AR012-T': 3.0,
    'AR014-N': 3.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 2.0,
    'AR026-T': 3.0,
    'AR028-N': 1.0,
    'AR029-N': 3.0,
    'AR031-T': 1.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 3.0,
    'AR044-N': 1.0,
    'AR046-N': 2.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 3.0,
    'AR056-T': 1.0,
    'AR057-T': 1.0,
    'AR059-N': 3.0,
    'AR061-T': 2.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 3.0,
    'AR068-N': 2.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 2.0,
    'AR075-N': 2.0,
    'AR077-N': 1.0,
    'AR079-T': 2.0,
    'AR081-T': 3.0,
    'AR083-T': 1.0,
    'AR085-N': 1.0,
    'AR088-T': 3.0,
    'AR090-N': 4.0,
    'AR098-T': 2.0,
    'AR100-N': 3.0,
    'AR102-N': 4.0,
    'AR104-T': 1.0,
    'AR108-T': 2.0,
    'AR110-T': 3.0,
    'AR112-T': 3.0,
    'AR115-T': 2.0,
    'AR118-T': 4.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 3.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 1.0,
    'AR142-T': 3.0,
    'AR144-N': 3.0,
    'AR145-N': 4.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 2.0,
    'AR158-T': 3.0,
    'AR160-T': 1.0,
    'AR162-N': 4.0,
    'AR164-T': 2.0,
    'AR165-T': 2.0,
    'AR167-T': 1.0,
    'AR169-T': 4.0,
    'AR170-N': 2.0,
    'AR172-N': 3.0,
    'AR174-T': 3.0,
    'AR177-T': 4.0,
    'AR178-T': 3.0,
    'AR180-T': 4.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 4.0,
    'AR186-T': 2.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 1.0,
    'AR190-N': 3.0,
    'AR192-T': 1.0,
    'AR193-T': 2.0,
    'AR197-T': 3.0,
    'AR198-T': 4.0,
    'AR200-T': 1.0,
    'AR202-T': 2.0,
    'AR204-T': 4.0,
    'AR206-T': 4.0,
    'AR208-T': 1.0,
    'AR209-T': 4.0,
    'AR210-N': 2.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 4.0,
    'AR224-N': 4.0,
    'AR226-T': 1.0,
    'AR228-T': 1.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 3.0,
    'AR232-T': 4.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 1.0,
    'AR243-T': 2.0,
    'AR245-N': 1.0,
    'AR247-T': 2.0,
    'AR249-T': 1.0,
    'AR251-T': 2.0,
    'AR252-T': 4.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 2.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 3.0,
    'AR273-T': 3.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 4.0,
    'AR281-N': 3.0,
    'AR283-T': 4.0,
    'AR285-T': 2.0,
    'AR287-T': 1.0,
    'AR289-T': 4.0,
    'AR293-T': 2.0,
    'AR297-N': 3.0,
    'AR299-N': 3.0,
    'AR300-T': 4.0,
    'AR302-T': 3.0,
    'AR304-T': 3.0,
    'AR306-N': 4.0,
    'AR309-N': 2.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 3.0,
    'AR319-N': 2.0,
    'AR321-N': 2.0,
    'AR323-T': 4.0,
    'AR324-N': 1.0,
    'AR327-N': 1.0,
    'AR329-N': 1.0,
    'AR331-T': 2.0,
    'AR333-N': 2.0,
    'AR335-N': 3.0,
    'AR339-N': 2.0,
    'AR341-N': 3.0,
    'AR343-N': 3.0,
    'AR345-N': 2.0,
    'AR347-T': 4.0,
    'AR349-N': 4.0,
    'AR351-N': 2.0,
    'AR353-N': 3.0,
    'AR357-N': 4.0,
    'AR363-N': 1.0,
    'AR365-T': 3.0,
    'AR367-N': 3.0,
    'AR368-N': 3.0,
    'AR370-N': 1.0,
    'AR371-T': 4.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 3.0,
    'AR376-N': 2.0,
    'AR381-N': 1.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 1.0,
    'AR389-N': 2.0,
    'AR391-T': 2.0,
    'AR392-N': 3.0,
    'AR394-N': 1.0,
    'AR398-N': 2.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 2.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 4.0,
    'AR409-N': 2.0,
    'AR411-N': 3.0,
    'AR412-N': 4.0,
    'AR413-N': 2.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 4.0,
    'AR420-N': 1.0,
    'AR421-N': 4.0,
    'AR422-T': 4.0,
    'AR423-N': 2.0,
    'AR424-N': 4.0,
    'AR425-T': 3.0,
    'AR426-N': 2.0,
    'AR427-N': 4.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 4.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 3.0,
    'AR435-N': 4.0,
    'AR436-N': 1.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 3.0,
    'AR440-N': 3.0,
    'AR441-T': 2.0,
    'AR442-T': 3.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 1.0,
    'AR446-N': 2.0,
    'AR447-N': 4.0,
    'AR448-T': 3.0,
    'AR449-T': 3.0,
    'AR450-N': 1.0,
    'AR451-N': 2.0,
    'AR452-N': 3.0,
    'AR453-N': 2.0,
    'AR454-T': 4.0,
    'AR455-T': 3.0,
    'AR456-N': 1.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 3.0,
    'AR460-T': 4.0,
    'AR461-T': 4.0,
    'AR462-T': 4.0,
    'AR463-T': 4.0,
    'AR464-T': 1.0,
    'AR465-T': 2.0,
    'AR466-N': 2.0,
    'AR467-N': 4.0,
    'AR468-T': 1.0,
    'AR469-N': 2.0,
    'AR470-T': 4.0,
    'AR471-T': 2.0,
    'AR472-T': 2.0,
    'AR473-N': 2.0,
    'AR474-N': 1.0,
    'AR475-T': 4.0,
    'AR476-T': 4.0,
    'AR477-T': 4.0,
    'AR478-T': 4.0,
    'AR479-T': 2.0,
    'AR480-N': 4.0,
    'AR481-': 4.0,
    'AR482-T': 4.0,
    'AR483-N': 2.0,
    'AR484-T': 4.0,
    'AR485-N': 4.0,
    'AR486-N': 4.0,
    'AR488-N': 2.0,
    'AR489-T': 2.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  ACETONITRILE: {
    'AR001-N': 3.0,
    'AR003-T': 3.0,
    'AR008-N': 2.0,
    'AR012-T': 3.0,
    'AR014-N': 3.0,
    'AR018-T': 1.0,
    'AR019-N': 1.0,
    'AR021-N': 3.0,
    'AR026-T': 2.0,
    'AR028-N': 1.0,
    'AR029-N': 3.0,
    'AR031-T': 3.0,
    'AR033-N': 2.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 3.0,
    'AR044-N': 1.0,
    'AR046-N': 2.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 3.0,
    'AR056-T': 1.0,
    'AR057-T': 1.0,
    'AR059-N': 3.0,
    'AR061-T': 1.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 3.0,
    'AR068-N': 3.0,
    'AR070-N': 1.0,
    'AR071-T': 2.0,
    'AR073-T': 3.0,
    'AR075-N': 2.0,
    'AR077-N': 1.0,
    'AR079-T': 2.0,
    'AR081-T': 3.0,
    'AR083-T': 4.0,
    'AR085-N': 1.0,
    'AR088-T': 3.0,
    'AR090-N': 4.0,
    'AR098-T': 3.0,
    'AR100-N': 2.0,
    'AR102-N': 4.0,
    'AR104-T': 1.0,
    'AR108-T': 3.0,
    'AR110-T': 4.0,
    'AR112-T': 2.0,
    'AR115-T': 2.0,
    'AR118-T': 4.0,
    'AR122-N': 2.0,
    'AR124-N': 2.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 3.0,
    'AR136-T': 1.0,
    'AR138-T': 2.0,
    'AR140-N': 1.0,
    'AR142-T': 3.0,
    'AR144-N': 3.0,
    'AR145-N': 4.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 1.0,
    'AR150-T': 3.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 2.0,
    'AR158-T': 3.0,
    'AR160-T': 1.0,
    'AR162-N': 4.0,
    'AR164-T': 2.0,
    'AR165-T': 2.0,
    'AR167-T': 2.0,
    'AR169-T': 4.0,
    'AR170-N': 2.0,
    'AR172-N': 3.0,
    'AR174-T': 3.0,
    'AR177-T': 4.0,
    'AR178-T': 3.0,
    'AR180-T': 4.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 4.0,
    'AR186-T': 2.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 1.0,
    'AR190-N': 3.0,
    'AR192-T': 3.0,
    'AR193-T': 3.0,
    'AR197-T': 2.0,
    'AR198-T': 4.0,
    'AR200-T': 1.0,
    'AR202-T': 3.0,
    'AR204-T': 2.0,
    'AR206-T': 4.0,
    'AR208-T': 1.0,
    'AR209-T': 4.0,
    'AR210-N': 2.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 4.0,
    'AR224-N': 4.0,
    'AR226-T': 1.0,
    'AR228-T': 4.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 3.0,
    'AR232-T': 4.0,
    'AR233-T': 2.0,
    'AR237-T': 1.0,
    'AR238-T': 2.0,
    'AR239-T': 1.0,
    'AR242-N': 1.0,
    'AR243-T': 2.0,
    'AR245-N': 1.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 3.0,
    'AR252-T': 4.0,
    'AR254-T': 1.0,
    'AR256-T': 2.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 3.0,
    'AR264-N': 2.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 3.0,
    'AR273-T': 3.0,
    'AR275-T': 1.0,
    'AR277-T': 2.0,
    'AR279-T': 4.0,
    'AR281-N': 2.0,
    'AR283-T': 4.0,
    'AR285-T': 4.0,
    'AR287-T': 1.0,
    'AR289-T': 4.0,
    'AR293-T': 3.0,
    'AR297-N': 3.0,
    'AR299-N': 3.0,
    'AR300-T': 4.0,
    'AR302-T': 2.0,
    'AR304-T': 3.0,
    'AR306-N': 4.0,
    'AR309-N': 3.0,
    'AR311-N': 2.0,
    'AR313-N': 2.0,
    'AR315-N': 3.0,
    'AR319-N': 3.0,
    'AR321-N': 1.0,
    'AR323-T': 4.0,
    'AR324-N': 1.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 2.0,
    'AR333-N': 2.0,
    'AR335-N': 2.0,
    'AR339-N': 2.0,
    'AR341-N': 3.0,
    'AR343-N': 3.0,
    'AR345-N': 2.0,
    'AR347-T': 4.0,
    'AR349-N': 4.0,
    'AR351-N': 2.0,
    'AR353-N': 2.0,
    'AR357-N': 4.0,
    'AR363-N': 1.0,
    'AR365-T': 3.0,
    'AR367-N': 3.0,
    'AR368-N': 3.0,
    'AR370-N': 1.0,
    'AR371-T': 4.0,
    'AR372-N': 2.0,
    'AR373-N': 2.0,
    'AR374-N': 3.0,
    'AR376-N': 3.0,
    'AR381-N': 1.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 1.0,
    'AR389-N': 3.0,
    'AR391-T': 2.0,
    'AR392-N': 3.0,
    'AR394-N': 1.0,
    'AR398-N': 1.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 2.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 4.0,
    'AR409-N': 2.0,
    'AR411-N': 3.0,
    'AR412-N': 2.0,
    'AR413-N': 1.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 4.0,
    'AR420-N': 1.0,
    'AR421-N': 4.0,
    'AR422-T': 4.0,
    'AR423-N': 1.0,
    'AR424-N': 4.0,
    'AR425-T': 3.0,
    'AR426-N': 3.0,
    'AR427-N': 4.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 4.0,
    'AR432-N': 2.0,
    'AR433-T': 1.0,
    'AR434-N': 3.0,
    'AR435-N': 4.0,
    'AR436-N': 1.0,
    'AR437-N': 1.0,
    'AR438-T': 1.0,
    'AR439-T': 4.0,
    'AR440-N': 3.0,
    'AR441-T': 2.0,
    'AR442-T': 2.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 1.0,
    'AR446-N': 2.0,
    'AR447-N': 4.0,
    'AR448-T': 3.0,
    'AR449-T': 2.0,
    'AR450-N': 1.0,
    'AR451-N': 2.0,
    'AR452-N': 3.0,
    'AR453-N': 2.0,
    'AR454-T': 4.0,
    'AR455-T': 2.0,
    'AR456-N': 1.0,
    'AR457-T': 1.0,
    'AR458-T': 2.0,
    'AR459-T': 4.0,
    'AR460-T': 4.0,
    'AR461-T': 4.0,
    'AR462-T': 4.0,
    'AR463-T': 4.0,
    'AR464-T': 3.0,
    'AR465-T': 3.0,
    'AR466-N': 3.0,
    'AR467-N': 4.0,
    'AR468-T': 3.0,
    'AR469-N': 2.0,
    'AR470-T': 4.0,
    'AR471-T': 2.0,
    'AR472-T': 3.0,
    'AR473-N': 2.0,
    'AR474-N': 1.0,
    'AR475-T': 4.0,
    'AR476-T': 4.0,
    'AR477-T': 2.0,
    'AR478-T': 4.0,
    'AR479-T': 2.0,
    'AR480-N': 4.0,
    'AR481-': 3.0,
    'AR482-T': 4.0,
    'AR483-N': 2.0,
    'AR484-T': 4.0,
    'AR485-N': 3.0,
    'AR486-N': 3.0,
    'AR488-N': 4.0,
    'AR489-T': 2.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  ACETOPHENONE: {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 3.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 3.0,
    'AR035-T': 2.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 3.0,
    'AR046-N': 3.0,
    'AR048-N': 2.0,
    'AR050-N': 4.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 3.0,
    'AR057-T': 4.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 2.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 3.0,
    'AR071-T': 3.0,
    'AR073-T': 3.0,
    'AR075-N': 2.0,
    'AR077-N': 2.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 2.0,
    'AR098-T': 2.0,
    'AR100-N': 3.0,
    'AR102-N': 2.0,
    'AR104-T': 4.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 3.0,
    'AR118-T': 1.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 2.0,
    'AR130-T': 3.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 3.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 1.0,
    'AR154-T': 1.0,
    'AR155-N': 2.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 3.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 1.0,
    'AR186-T': 3.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 3.0,
    'AR198-T': 2.0,
    'AR200-T': 2.0,
    'AR202-T': 3.0,
    'AR204-T': 3.0,
    'AR206-T': 1.0,
    'AR208-T': 1.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 3.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 1.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 3.0,
    'AR237-T': 4.0,
    'AR238-T': 2.0,
    'AR239-T': 3.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 2.0,
    'AR249-T': 1.0,
    'AR251-T': 2.0,
    'AR252-T': 1.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 1.0,
    'AR302-T': 4.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 3.0,
    'AR323-T': 2.0,
    'AR324-N': 2.0,
    'AR327-N': 2.0,
    'AR329-N': 2.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 3.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 3.0,
    'AR381-N': 2.0,
    'AR382-N': 2.0,
    'AR384-N': 3.0,
    'AR387-N': 3.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 3.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 2.0,
    'AR406-N': 3.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 2.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 3.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 2.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 3.0,
    'AR438-T': 2.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 4.0,
    'AR443-T': 2.0,
    'AR444-T': 1.0,
    'AR445-N': 1.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 2.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 2.0,
    'AR455-T': 4.0,
    'AR456-N': 3.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 2.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 2.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 2.0,
    'AR473-N': 3.0,
    'AR474-N': 4.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 2.0,
    'AR479-T': 3.0,
    'AR480-N': 1.0,
    'AR481-': 4.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 4.0,
    'AR486-N': 4.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 2.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  ACROLEIN: {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 4.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 1.0,
    'AR033-N': 3.0,
    'AR035-T': 3.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 3.0,
    'AR046-N': 3.0,
    'AR048-N': 2.0,
    'AR050-N': 1.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 1.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 3.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 3.0,
    'AR075-N': 3.0,
    'AR077-N': 2.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 3.0,
    'AR090-N': 2.0,
    'AR098-T': 3.0,
    'AR100-N': 4.0,
    'AR102-N': 2.0,
    'AR104-T': 2.0,
    'AR108-T': 2.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 3.0,
    'AR118-T': 1.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 3.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 1.0,
    'AR155-N': 2.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 4.0,
    'AR165-T': 3.0,
    'AR167-T': 2.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 1.0,
    'AR185-T': 1.0,
    'AR186-T': 3.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 4.0,
    'AR198-T': 2.0,
    'AR200-T': 1.0,
    'AR202-T': 3.0,
    'AR204-T': 4.0,
    'AR206-T': 2.0,
    'AR208-T': 1.0,
    'AR209-T': 2.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 1.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 4.0,
    'AR237-T': 2.0,
    'AR238-T': 3.0,
    'AR239-T': 1.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 3.0,
    'AR252-T': 2.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 2.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 2.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 2.0,
    'AR302-T': 4.0,
    'AR304-T': 3.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 3.0,
    'AR321-N': 3.0,
    'AR323-T': 2.0,
    'AR324-N': 2.0,
    'AR327-N': 2.0,
    'AR329-N': 2.0,
    'AR331-T': 3.0,
    'AR333-N': 4.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 2.0,
    'AR351-N': 4.0,
    'AR353-N': 4.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 3.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 3.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 1.0,
    'AR398-N': 3.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 3.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 2.0,
    'AR422-T': 3.0,
    'AR423-N': 3.0,
    'AR424-N': 2.0,
    'AR425-T': 4.0,
    'AR426-N': 3.0,
    'AR427-N': 2.0,
    'AR428-T': 2.0,
    'AR429-T': 2.0,
    'AR430-N': 2.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 1.0,
    'AR438-T': 3.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 4.0,
    'AR443-T': 2.0,
    'AR444-T': 1.0,
    'AR445-N': 1.0,
    'AR446-N': 3.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 1.0,
    'AR455-T': 4.0,
    'AR456-N': 2.0,
    'AR457-T': 3.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 2.0,
    'AR461-T': 1.0,
    'AR462-T': 2.0,
    'AR463-T': 2.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 2.0,
    'AR468-T': 3.0,
    'AR469-N': 3.0,
    'AR470-T': 2.0,
    'AR471-T': 4.0,
    'AR472-T': 2.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 4.0,
    'AR478-T': 2.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 2.0,
    'AR483-N': 3.0,
    'AR484-T': 2.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 2.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  ACRYLAMIDE: {
    'AR001-N': 3.0,
    'AR003-T': 2.0,
    'AR008-N': 3.0,
    'AR012-T': 3.0,
    'AR014-N': 3.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 3.0,
    'AR026-T': 3.0,
    'AR028-N': 1.0,
    'AR029-N': 3.0,
    'AR031-T': 2.0,
    'AR033-N': 3.0,
    'AR035-T': 2.0,
    'AR037-T': 1.0,
    'AR042-T': 3.0,
    'AR044-N': 1.0,
    'AR046-N': 3.0,
    'AR048-N': 3.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 3.0,
    'AR056-T': 1.0,
    'AR057-T': 2.0,
    'AR059-N': 3.0,
    'AR061-T': 2.0,
    'AR063-T': 2.0,
    'AR064-T': 4.0,
    'AR066-T': 2.0,
    'AR068-N': 3.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 2.0,
    'AR075-N': 2.0,
    'AR077-N': 4.0,
    'AR079-T': 3.0,
    'AR081-T': 3.0,
    'AR083-T': 2.0,
    'AR085-N': 2.0,
    'AR088-T': 3.0,
    'AR090-N': 4.0,
    'AR098-T': 2.0,
    'AR100-N': 3.0,
    'AR102-N': 4.0,
    'AR104-T': 2.0,
    'AR108-T': 2.0,
    'AR110-T': 4.0,
    'AR112-T': 1.0,
    'AR115-T': 3.0,
    'AR118-T': 4.0,
    'AR122-N': 2.0,
    'AR124-N': 2.0,
    'AR128-T': 3.0,
    'AR130-T': 1.0,
    'AR132-T': 2.0,
    'AR136-T': 1.0,
    'AR138-T': 1.0,
    'AR140-N': 1.0,
    'AR142-T': 2.0,
    'AR144-N': 3.0,
    'AR145-N': 4.0,
    'AR146-T': 1.0,
    'AR147-N': 3.0,
    'AR148-N': 1.0,
    'AR150-T': 1.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 1.0,
    'AR158-T': 3.0,
    'AR160-T': 1.0,
    'AR162-N': 4.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 1.0,
    'AR169-T': 4.0,
    'AR170-N': 3.0,
    'AR172-N': 3.0,
    'AR174-T': 3.0,
    'AR177-T': 4.0,
    'AR178-T': 3.0,
    'AR180-T': 4.0,
    'AR182-N': 1.0,
    'AR183-T': 1.0,
    'AR185-T': 4.0,
    'AR186-T': 2.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 1.0,
    'AR190-N': 3.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 2.0,
    'AR198-T': 4.0,
    'AR200-T': 1.0,
    'AR202-T': 2.0,
    'AR204-T': 2.0,
    'AR206-T': 4.0,
    'AR208-T': 1.0,
    'AR209-T': 4.0,
    'AR210-N': 1.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 4.0,
    'AR224-N': 4.0,
    'AR226-T': 1.0,
    'AR228-T': 1.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 3.0,
    'AR232-T': 4.0,
    'AR233-T': 3.0,
    'AR237-T': 2.0,
    'AR238-T': 2.0,
    'AR239-T': 1.0,
    'AR242-N': 2.0,
    'AR243-T': 1.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 2.0,
    'AR252-T': 4.0,
    'AR254-T': 1.0,
    'AR256-T': 1.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 1.0,
    'AR266-T': 1.0,
    'AR268-N': 3.0,
    'AR271-T': 2.0,
    'AR273-T': 2.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 4.0,
    'AR281-N': 3.0,
    'AR283-T': 4.0,
    'AR285-T': 3.0,
    'AR287-T': 1.0,
    'AR289-T': 4.0,
    'AR293-T': 2.0,
    'AR297-N': 2.0,
    'AR299-N': 3.0,
    'AR300-T': 4.0,
    'AR302-T': 1.0,
    'AR304-T': 3.0,
    'AR306-N': 4.0,
    'AR309-N': 1.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 3.0,
    'AR319-N': 2.0,
    'AR321-N': 1.0,
    'AR323-T': 4.0,
    'AR324-N': 1.0,
    'AR327-N': 4.0,
    'AR329-N': 2.0,
    'AR331-T': 2.0,
    'AR333-N': 1.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 2.0,
    'AR343-N': 3.0,
    'AR345-N': 1.0,
    'AR347-T': 4.0,
    'AR349-N': 4.0,
    'AR351-N': 3.0,
    'AR353-N': 3.0,
    'AR357-N': 4.0,
    'AR363-N': 1.0,
    'AR365-T': 2.0,
    'AR367-N': 3.0,
    'AR368-N': 2.0,
    'AR370-N': 2.0,
    'AR371-T': 4.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 3.0,
    'AR376-N': 3.0,
    'AR381-N': 2.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 2.0,
    'AR389-N': 2.0,
    'AR391-T': 2.0,
    'AR392-N': 3.0,
    'AR394-N': 1.0,
    'AR398-N': 2.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 3.0,
    'AR406-N': 1.0,
    'AR407-N': 4.0,
    'AR408-N': 4.0,
    'AR409-N': 1.0,
    'AR411-N': 3.0,
    'AR412-N': 1.0,
    'AR413-N': 2.0,
    'AR414-N': 1.0,
    'AR416-N': 1.0,
    'AR418-T': 4.0,
    'AR420-N': 2.0,
    'AR421-N': 4.0,
    'AR422-T': 4.0,
    'AR423-N': 2.0,
    'AR424-N': 4.0,
    'AR425-T': 3.0,
    'AR426-N': 2.0,
    'AR427-N': 4.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 4.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 2.0,
    'AR435-N': 4.0,
    'AR436-N': 1.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 3.0,
    'AR440-N': 3.0,
    'AR441-T': 2.0,
    'AR442-T': 1.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 1.0,
    'AR446-N': 3.0,
    'AR447-N': 4.0,
    'AR448-T': 3.0,
    'AR449-T': 2.0,
    'AR450-N': 2.0,
    'AR451-N': 2.0,
    'AR452-N': 3.0,
    'AR453-N': 2.0,
    'AR454-T': 4.0,
    'AR455-T': 1.0,
    'AR456-N': 1.0,
    'AR457-T': 2.0,
    'AR458-T': 2.0,
    'AR459-T': 4.0,
    'AR460-T': 4.0,
    'AR461-T': 4.0,
    'AR462-T': 4.0,
    'AR463-T': 4.0,
    'AR464-T': 4.0,
    'AR465-T': 2.0,
    'AR466-N': 2.0,
    'AR467-N': 4.0,
    'AR468-T': 2.0,
    'AR469-N': 2.0,
    'AR470-T': 4.0,
    'AR471-T': 2.0,
    'AR472-T': 2.0,
    'AR473-N': 2.0,
    'AR474-N': 1.0,
    'AR475-T': 4.0,
    'AR476-T': 4.0,
    'AR477-T': 2.0,
    'AR478-T': 4.0,
    'AR479-T': 3.0,
    'AR480-N': 4.0,
    'AR481-': 1.0,
    'AR482-T': 4.0,
    'AR483-N': 1.0,
    'AR484-T': 4.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 3.0,
    'AR489-T': 1.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'ACRYLIC-ACID': {
    'AR001-N': 2.0,
    'AR003-T': 1.0,
    'AR008-N': 2.0,
    'AR012-T': 2.0,
    'AR014-N': 2.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 3.0,
    'AR026-T': 3.0,
    'AR028-N': 3.0,
    'AR029-N': 2.0,
    'AR031-T': 2.0,
    'AR033-N': 2.0,
    'AR035-T': 3.0,
    'AR037-T': 1.0,
    'AR042-T': 2.0,
    'AR044-N': 3.0,
    'AR046-N': 2.0,
    'AR048-N': 3.0,
    'AR050-N': 3.0,
    'AR052-T': 1.0,
    'AR054-N': 3.0,
    'AR056-T': 2.0,
    'AR057-T': 3.0,
    'AR059-N': 2.0,
    'AR061-T': 2.0,
    'AR063-T': 1.0,
    'AR064-T': 3.0,
    'AR066-T': 1.0,
    'AR068-N': 2.0,
    'AR070-N': 1.0,
    'AR071-T': 2.0,
    'AR073-T': 1.0,
    'AR075-N': 1.0,
    'AR077-N': 3.0,
    'AR079-T': 2.0,
    'AR081-T': 2.0,
    'AR083-T': 3.0,
    'AR085-N': 2.0,
    'AR088-T': 3.0,
    'AR090-N': 4.0,
    'AR098-T': 1.0,
    'AR100-N': 3.0,
    'AR102-N': 4.0,
    'AR104-T': 3.0,
    'AR108-T': 1.0,
    'AR110-T': 4.0,
    'AR112-T': 1.0,
    'AR115-T': 3.0,
    'AR118-T': 4.0,
    'AR122-N': 1.0,
    'AR124-N': 1.0,
    'AR128-T': 3.0,
    'AR130-T': 3.0,
    'AR132-T': 1.0,
    'AR136-T': 1.0,
    'AR138-T': 1.0,
    'AR140-N': 1.0,
    'AR142-T': 1.0,
    'AR144-N': 2.0,
    'AR145-N': 4.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 1.0,
    'AR150-T': 3.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 1.0,
    'AR158-T': 2.0,
    'AR160-T': 2.0,
    'AR162-N': 4.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 2.0,
    'AR169-T': 4.0,
    'AR170-N': 3.0,
    'AR172-N': 2.0,
    'AR174-T': 2.0,
    'AR177-T': 4.0,
    'AR178-T': 2.0,
    'AR180-T': 4.0,
    'AR182-N': 1.0,
    'AR183-T': 1.0,
    'AR185-T': 4.0,
    'AR186-T': 3.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 1.0,
    'AR190-N': 2.0,
    'AR192-T': 4.0,
    'AR193-T': 3.0,
    'AR197-T': 1.0,
    'AR198-T': 4.0,
    'AR200-T': 1.0,
    'AR202-T': 1.0,
    'AR204-T': 3.0,
    'AR206-T': 4.0,
    'AR208-T': 1.0,
    'AR209-T': 4.0,
    'AR210-N': 1.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 2.0,
    'AR219-T': 2.0,
    'AR220-T': 1.0,
    'AR222-N': 4.0,
    'AR224-N': 4.0,
    'AR226-T': 1.0,
    'AR228-T': 3.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 2.0,
    'AR232-T': 4.0,
    'AR233-T': 3.0,
    'AR237-T': 3.0,
    'AR238-T': 3.0,
    'AR239-T': 3.0,
    'AR242-N': 2.0,
    'AR243-T': 1.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 3.0,
    'AR251-T': 1.0,
    'AR252-T': 4.0,
    'AR254-T': 1.0,
    'AR256-T': 1.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 3.0,
    'AR264-N': 1.0,
    'AR266-T': 1.0,
    'AR268-N': 2.0,
    'AR271-T': 1.0,
    'AR273-T': 1.0,
    'AR275-T': 1.0,
    'AR277-T': 2.0,
    'AR279-T': 4.0,
    'AR281-N': 2.0,
    'AR283-T': 4.0,
    'AR285-T': 3.0,
    'AR287-T': 1.0,
    'AR289-T': 4.0,
    'AR293-T': 1.0,
    'AR297-N': 1.0,
    'AR299-N': 2.0,
    'AR300-T': 4.0,
    'AR302-T': 1.0,
    'AR304-T': 3.0,
    'AR306-N': 4.0,
    'AR309-N': 1.0,
    'AR311-N': 3.0,
    'AR313-N': 2.0,
    'AR315-N': 2.0,
    'AR319-N': 1.0,
    'AR321-N': 2.0,
    'AR323-T': 4.0,
    'AR324-N': 1.0,
    'AR327-N': 3.0,
    'AR329-N': 2.0,
    'AR331-T': 3.0,
    'AR333-N': 1.0,
    'AR335-N': 2.0,
    'AR339-N': 2.0,
    'AR341-N': 1.0,
    'AR343-N': 2.0,
    'AR345-N': 1.0,
    'AR347-T': 4.0,
    'AR349-N': 4.0,
    'AR351-N': 3.0,
    'AR353-N': 3.0,
    'AR357-N': 4.0,
    'AR363-N': 2.0,
    'AR365-T': 1.0,
    'AR367-N': 2.0,
    'AR368-N': 1.0,
    'AR370-N': 2.0,
    'AR371-T': 4.0,
    'AR372-N': 2.0,
    'AR373-N': 2.0,
    'AR374-N': 3.0,
    'AR376-N': 3.0,
    'AR381-N': 2.0,
    'AR382-N': 2.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 1.0,
    'AR391-T': 2.0,
    'AR392-N': 3.0,
    'AR394-N': 3.0,
    'AR398-N': 2.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 3.0,
    'AR406-N': 3.0,
    'AR407-N': 3.0,
    'AR408-N': 4.0,
    'AR409-N': 1.0,
    'AR411-N': 3.0,
    'AR412-N': 1.0,
    'AR413-N': 2.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 4.0,
    'AR420-N': 2.0,
    'AR421-N': 4.0,
    'AR422-T': 4.0,
    'AR423-N': 3.0,
    'AR424-N': 4.0,
    'AR425-T': 2.0,
    'AR426-N': 1.0,
    'AR427-N': 4.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 4.0,
    'AR432-N': 2.0,
    'AR433-T': 3.0,
    'AR434-N': 1.0,
    'AR435-N': 4.0,
    'AR436-N': 1.0,
    'AR437-N': 3.0,
    'AR438-T': 3.0,
    'AR439-T': 4.0,
    'AR440-N': 2.0,
    'AR441-T': 3.0,
    'AR442-T': 1.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 3.0,
    'AR446-N': 2.0,
    'AR447-N': 4.0,
    'AR448-T': 2.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 1.0,
    'AR452-N': 2.0,
    'AR453-N': 3.0,
    'AR454-T': 4.0,
    'AR455-T': 1.0,
    'AR456-N': 3.0,
    'AR457-T': 3.0,
    'AR458-T': 2.0,
    'AR459-T': 4.0,
    'AR460-T': 4.0,
    'AR461-T': 4.0,
    'AR462-T': 4.0,
    'AR463-T': 4.0,
    'AR464-T': 3.0,
    'AR465-T': 2.0,
    'AR466-N': 1.0,
    'AR467-N': 4.0,
    'AR468-T': 1.0,
    'AR469-N': 2.0,
    'AR470-T': 4.0,
    'AR471-T': 3.0,
    'AR472-T': 2.0,
    'AR473-N': 2.0,
    'AR474-N': 2.0,
    'AR475-T': 4.0,
    'AR476-T': 4.0,
    'AR477-T': 3.0,
    'AR478-T': 4.0,
    'AR479-T': 3.0,
    'AR480-N': 4.0,
    'AR481-': 4.0,
    'AR482-T': 4.0,
    'AR483-N': 1.0,
    'AR484-T': 4.0,
    'AR485-N': 4.0,
    'AR486-N': 4.0,
    'AR488-N': 3.0,
    'AR489-T': 1.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  ACRYLONITRILE: {
    'AR001-N': 3.0,
    'AR003-T': 4.0,
    'AR008-N': 4.0,
    'AR012-T': 4.0,
    'AR014-N': 3.0,
    'AR018-T': 4.0,
    'AR019-N': 2.0,
    'AR021-N': 3.0,
    'AR026-T': 3.0,
    'AR028-N': 4.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 4.0,
    'AR035-T': 2.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 3.0,
    'AR046-N': 3.0,
    'AR048-N': 2.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 3.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 4.0,
    'AR063-T': 3.0,
    'AR064-T': 2.0,
    'AR066-T': 4.0,
    'AR068-N': 3.0,
    'AR070-N': 2.0,
    'AR071-T': 4.0,
    'AR073-T': 3.0,
    'AR075-N': 4.0,
    'AR077-N': 2.0,
    'AR079-T': 4.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 3.0,
    'AR090-N': 1.0,
    'AR098-T': 4.0,
    'AR100-N': 3.0,
    'AR102-N': 1.0,
    'AR104-T': 2.0,
    'AR108-T': 2.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 2.0,
    'AR118-T': 1.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 2.0,
    'AR130-T': 4.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 2.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 4.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 2.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 3.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 2.0,
    'AR183-T': 2.0,
    'AR185-T': 1.0,
    'AR186-T': 4.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 3.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 3.0,
    'AR204-T': 3.0,
    'AR206-T': 1.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 4.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 3.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 4.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 3.0,
    'AR237-T': 2.0,
    'AR238-T': 2.0,
    'AR239-T': 2.0,
    'AR242-N': 3.0,
    'AR243-T': 3.0,
    'AR245-N': 3.0,
    'AR247-T': 4.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 2.0,
    'AR264-N': 2.0,
    'AR266-T': 2.0,
    'AR268-N': 2.0,
    'AR271-T': 4.0,
    'AR273-T': 3.0,
    'AR275-T': 2.0,
    'AR277-T': 4.0,
    'AR279-T': 1.0,
    'AR281-N': 4.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 3.0,
    'AR300-T': 1.0,
    'AR302-T': 4.0,
    'AR304-T': 3.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 4.0,
    'AR313-N': 4.0,
    'AR315-N': 4.0,
    'AR319-N': 3.0,
    'AR321-N': 3.0,
    'AR323-T': 2.0,
    'AR324-N': 4.0,
    'AR327-N': 2.0,
    'AR329-N': 2.0,
    'AR331-T': 3.0,
    'AR333-N': 4.0,
    'AR335-N': 4.0,
    'AR339-N': 4.0,
    'AR341-N': 4.0,
    'AR343-N': 3.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 3.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 4.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 4.0,
    'AR373-N': 4.0,
    'AR374-N': 4.0,
    'AR376-N': 3.0,
    'AR381-N': 3.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 4.0,
    'AR389-N': 3.0,
    'AR391-T': 2.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 2.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 3.0,
    'AR406-N': 3.0,
    'AR407-N': 2.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 4.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 3.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 3.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 4.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 2.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 4.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 3.0,
    'AR451-N': 3.0,
    'AR452-N': 3.0,
    'AR453-N': 3.0,
    'AR454-T': 2.0,
    'AR455-T': 4.0,
    'AR456-N': 4.0,
    'AR457-T': 2.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 3.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 3.0,
    'AR469-N': 2.0,
    'AR470-T': 1.0,
    'AR471-T': 4.0,
    'AR472-T': 2.0,
    'AR473-N': 2.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 3.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 2.0,
    'AR490-T': 2.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'ALLYL-CHLORIDE': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 4.0,
    'AR033-N': 3.0,
    'AR035-T': 2.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 4.0,
    'AR048-N': 2.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 2.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 2.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 1.0,
    'AR098-T': 4.0,
    'AR100-N': 3.0,
    'AR102-N': 1.0,
    'AR104-T': 2.0,
    'AR108-T': 4.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 3.0,
    'AR118-T': 1.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 2.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 2.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 1.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 3.0,
    'AR169-T': 2.0,
    'AR170-N': 4.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 2.0,
    'AR185-T': 1.0,
    'AR186-T': 3.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 3.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 1.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 2.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 1.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 3.0,
    'AR237-T': 2.0,
    'AR238-T': 2.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 4.0,
    'AR249-T': 2.0,
    'AR251-T': 4.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 2.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 2.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 1.0,
    'AR302-T': 3.0,
    'AR304-T': 4.0,
    'AR306-N': 2.0,
    'AR309-N': 4.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 2.0,
    'AR323-T': 1.0,
    'AR324-N': 2.0,
    'AR327-N': 2.0,
    'AR329-N': 4.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 2.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 4.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 3.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 2.0,
    'AR408-N': 2.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 2.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 3.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 2.0,
    'AR446-N': 4.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 2.0,
    'AR455-T': 3.0,
    'AR456-N': 2.0,
    'AR457-T': 3.0,
    'AR458-T': 3.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 3.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 4.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 1.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  ANILINE: {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 4.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 4.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 4.0,
    'AR035-T': 3.0,
    'AR037-T': 3.0,
    'AR042-T': 4.0,
    'AR044-N': 3.0,
    'AR046-N': 3.0,
    'AR048-N': 2.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 3.0,
    'AR056-T': 2.0,
    'AR057-T': 4.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 2.0,
    'AR066-T': 3.0,
    'AR068-N': 3.0,
    'AR070-N': 1.0,
    'AR071-T': 4.0,
    'AR073-T': 3.0,
    'AR075-N': 2.0,
    'AR077-N': 2.0,
    'AR079-T': 4.0,
    'AR081-T': 4.0,
    'AR083-T': 2.0,
    'AR085-N': 2.0,
    'AR088-T': 3.0,
    'AR090-N': 1.0,
    'AR098-T': 2.0,
    'AR100-N': 4.0,
    'AR102-N': 1.0,
    'AR104-T': 4.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 3.0,
    'AR118-T': 1.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 2.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 2.0,
    'AR138-T': 3.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 2.0,
    'AR148-N': 3.0,
    'AR150-T': 2.0,
    'AR152-T': 1.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 4.0,
    'AR165-T': 2.0,
    'AR167-T': 3.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 1.0,
    'AR185-T': 1.0,
    'AR186-T': 3.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 2.0,
    'AR193-T': 3.0,
    'AR197-T': 4.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 3.0,
    'AR204-T': 4.0,
    'AR206-T': 1.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 1.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 4.0,
    'AR237-T': 4.0,
    'AR238-T': 3.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 2.0,
    'AR249-T': 2.0,
    'AR251-T': 2.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 2.0,
    'AR271-T': 3.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 4.0,
    'AR279-T': 1.0,
    'AR281-N': 4.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 2.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 1.0,
    'AR302-T': 3.0,
    'AR304-T': 3.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 3.0,
    'AR313-N': 4.0,
    'AR315-N': 4.0,
    'AR319-N': 3.0,
    'AR321-N': 3.0,
    'AR323-T': 1.0,
    'AR324-N': 2.0,
    'AR327-N': 2.0,
    'AR329-N': 2.0,
    'AR331-T': 4.0,
    'AR333-N': 3.0,
    'AR335-N': 4.0,
    'AR339-N': 4.0,
    'AR341-N': 3.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 4.0,
    'AR357-N': 1.0,
    'AR363-N': 1.0,
    'AR365-T': 3.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 3.0,
    'AR371-T': 1.0,
    'AR372-N': 4.0,
    'AR373-N': 4.0,
    'AR374-N': 3.0,
    'AR376-N': 3.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 3.0,
    'AR389-N': 2.0,
    'AR391-T': 4.0,
    'AR392-N': 3.0,
    'AR394-N': 1.0,
    'AR398-N': 3.0,
    'AR400-T': 4.0,
    'AR402-N': 4.0,
    'AR404-N': 2.0,
    'AR406-N': 2.0,
    'AR407-N': 2.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 3.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 3.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 4.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 3.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 1.0,
    'AR446-N': 4.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 4.0,
    'AR450-N': 3.0,
    'AR451-N': 2.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 1.0,
    'AR455-T': 3.0,
    'AR456-N': 2.0,
    'AR457-T': 3.0,
    'AR458-T': 3.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 3.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 2.0,
    'AR473-N': 4.0,
    'AR474-N': 4.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 4.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 2.0,
    'AR489-T': 3.0,
    'AR490-T': 1.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'ANTIMONY-COMPOUNDS': {
    'AR001-N': 3.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 3.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 1.0,
    'AR029-N': 3.0,
    'AR031-T': 4.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 4.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 1.0,
    'AR088-T': 4.0,
    'AR090-N': 2.0,
    'AR098-T': 4.0,
    'AR100-N': 3.0,
    'AR102-N': 2.0,
    'AR104-T': 1.0,
    'AR108-T': 4.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 3.0,
    'AR118-T': 2.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 3.0,
    'AR155-N': 2.0,
    'AR157-N': 4.0,
    'AR158-T': 4.0,
    'AR160-T': 3.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 2.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 3.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 2.0,
    'AR182-N': 3.0,
    'AR183-T': 1.0,
    'AR185-T': 2.0,
    'AR186-T': 3.0,
    'AR187-T': 3.0,
    'AR188-T': 3.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 3.0,
    'AR198-T': 2.0,
    'AR200-T': 1.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 3.0,
    'AR208-T': 1.0,
    'AR209-T': 2.0,
    'AR210-N': 3.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 3.0,
    'AR228-T': 1.0,
    'AR229-N': 3.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 3.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 1.0,
    'AR243-T': 3.0,
    'AR245-N': 1.0,
    'AR247-T': 3.0,
    'AR249-T': 2.0,
    'AR251-T': 4.0,
    'AR252-T': 2.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 3.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 2.0,
    'AR281-N': 3.0,
    'AR283-T': 2.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 2.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 3.0,
    'AR300-T': 3.0,
    'AR302-T': 4.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 4.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 3.0,
    'AR319-N': 4.0,
    'AR321-N': 3.0,
    'AR323-T': 2.0,
    'AR324-N': 3.0,
    'AR327-N': 1.0,
    'AR329-N': 4.0,
    'AR331-T': 4.0,
    'AR333-N': 4.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 3.0,
    'AR345-N': 4.0,
    'AR347-T': 3.0,
    'AR349-N': 2.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 2.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 1.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 1.0,
    'AR382-N': 3.0,
    'AR384-N': 2.0,
    'AR387-N': 2.0,
    'AR389-N': 4.0,
    'AR391-T': 2.0,
    'AR392-N': 4.0,
    'AR394-N': 1.0,
    'AR398-N': 1.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 3.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 2.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 1.0,
    'AR421-N': 2.0,
    'AR422-T': 2.0,
    'AR423-N': 2.0,
    'AR424-N': 2.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 2.0,
    'AR428-T': 2.0,
    'AR429-T': 2.0,
    'AR430-N': 2.0,
    'AR431-T': 2.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 1.0,
    'AR438-T': 1.0,
    'AR439-T': 2.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 4.0,
    'AR443-T': 2.0,
    'AR444-T': 2.0,
    'AR445-N': 1.0,
    'AR446-N': 4.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 3.0,
    'AR453-N': 2.0,
    'AR454-T': 2.0,
    'AR455-T': 4.0,
    'AR456-N': 1.0,
    'AR457-T': 1.0,
    'AR458-T': 3.0,
    'AR459-T': 1.0,
    'AR460-T': 2.0,
    'AR461-T': 2.0,
    'AR462-T': 1.0,
    'AR463-T': 2.0,
    'AR464-T': 3.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 2.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 2.0,
    'AR471-T': 3.0,
    'AR472-T': 4.0,
    'AR473-N': 2.0,
    'AR474-N': 1.0,
    'AR475-T': 2.0,
    'AR476-T': 2.0,
    'AR477-T': 3.0,
    'AR478-T': 2.0,
    'AR479-T': 4.0,
    'AR480-N': 2.0,
    'AR481-': 4.0,
    'AR482-T': 2.0,
    'AR483-N': 3.0,
    'AR484-T': 2.0,
    'AR485-N': 4.0,
    'AR486-N': 4.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 2.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'ARSENIC-COMPOUNDS-INORGANIC-INCLUDING-ARSINE-': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 4.0,
    'AR028-N': 3.0,
    'AR029-N': 4.0,
    'AR031-T': 1.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 3.0,
    'AR075-N': 3.0,
    'AR077-N': 2.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 3.0,
    'AR088-T': 4.0,
    'AR090-N': 3.0,
    'AR098-T': 3.0,
    'AR100-N': 4.0,
    'AR102-N': 2.0,
    'AR104-T': 1.0,
    'AR108-T': 2.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 2.0,
    'AR118-T': 2.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 2.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 4.0,
    'AR154-T': 1.0,
    'AR155-N': 3.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 2.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 3.0,
    'AR178-T': 4.0,
    'AR180-T': 2.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 1.0,
    'AR186-T': 3.0,
    'AR187-T': 3.0,
    'AR188-T': 3.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 4.0,
    'AR198-T': 3.0,
    'AR200-T': 1.0,
    'AR202-T': 3.0,
    'AR204-T': 4.0,
    'AR206-T': 3.0,
    'AR208-T': 1.0,
    'AR209-T': 2.0,
    'AR210-N': 3.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 1.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 3.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 3.0,
    'AR233-T': 4.0,
    'AR237-T': 2.0,
    'AR238-T': 1.0,
    'AR239-T': 3.0,
    'AR242-N': 1.0,
    'AR243-T': 3.0,
    'AR245-N': 1.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 3.0,
    'AR252-T': 2.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 3.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 1.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 3.0,
    'AR302-T': 4.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 3.0,
    'AR323-T': 3.0,
    'AR324-N': 2.0,
    'AR327-N': 2.0,
    'AR329-N': 2.0,
    'AR331-T': 3.0,
    'AR333-N': 4.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 2.0,
    'AR351-N': 3.0,
    'AR353-N': 4.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 1.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 3.0,
    'AR381-N': 1.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 2.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 2.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 1.0,
    'AR420-N': 1.0,
    'AR421-N': 2.0,
    'AR422-T': 3.0,
    'AR423-N': 2.0,
    'AR424-N': 2.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 2.0,
    'AR428-T': 2.0,
    'AR429-T': 3.0,
    'AR430-N': 2.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 2.0,
    'AR436-N': 2.0,
    'AR437-N': 3.0,
    'AR438-T': 2.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 4.0,
    'AR443-T': 3.0,
    'AR444-T': 2.0,
    'AR445-N': 1.0,
    'AR446-N': 3.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 2.0,
    'AR454-T': 1.0,
    'AR455-T': 4.0,
    'AR456-N': 3.0,
    'AR457-T': 2.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 2.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 2.0,
    'AR464-T': 1.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 2.0,
    'AR468-T': 3.0,
    'AR469-N': 2.0,
    'AR470-T': 2.0,
    'AR471-T': 3.0,
    'AR472-T': 2.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 4.0,
    'AR478-T': 2.0,
    'AR479-T': 3.0,
    'AR480-N': 2.0,
    'AR481-': 1.0,
    'AR482-T': 3.0,
    'AR483-N': 2.0,
    'AR484-T': 2.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 4.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  BENZIDINE: {
    'AR001-N': 2.0,
    'AR003-T': 2.0,
    'AR008-N': 2.0,
    'AR012-T': 2.0,
    'AR014-N': 2.0,
    'AR018-T': 1.0,
    'AR019-N': 3.0,
    'AR021-N': 2.0,
    'AR026-T': 2.0,
    'AR028-N': 1.0,
    'AR029-N': 2.0,
    'AR031-T': 1.0,
    'AR033-N': 2.0,
    'AR035-T': 3.0,
    'AR037-T': 3.0,
    'AR042-T': 2.0,
    'AR044-N': 3.0,
    'AR046-N': 1.0,
    'AR048-N': 4.0,
    'AR050-N': 2.0,
    'AR052-T': 3.0,
    'AR054-N': 2.0,
    'AR056-T': 3.0,
    'AR057-T': 1.0,
    'AR059-N': 2.0,
    'AR061-T': 1.0,
    'AR063-T': 2.0,
    'AR064-T': 1.0,
    'AR066-T': 2.0,
    'AR068-N': 1.0,
    'AR070-N': 3.0,
    'AR071-T': 2.0,
    'AR073-T': 1.0,
    'AR075-N': 1.0,
    'AR077-N': 2.0,
    'AR079-T': 2.0,
    'AR081-T': 2.0,
    'AR083-T': 3.0,
    'AR085-N': 1.0,
    'AR088-T': 1.0,
    'AR090-N': 3.0,
    'AR098-T': 2.0,
    'AR100-N': 2.0,
    'AR102-N': 3.0,
    'AR104-T': 2.0,
    'AR108-T': 1.0,
    'AR110-T': 3.0,
    'AR112-T': 2.0,
    'AR115-T': 1.0,
    'AR118-T': 3.0,
    'AR122-N': 2.0,
    'AR124-N': 2.0,
    'AR128-T': 3.0,
    'AR130-T': 3.0,
    'AR132-T': 2.0,
    'AR136-T': 1.0,
    'AR138-T': 2.0,
    'AR140-N': 3.0,
    'AR142-T': 2.0,
    'AR144-N': 2.0,
    'AR145-N': 3.0,
    'AR146-T': 3.0,
    'AR147-N': 3.0,
    'AR148-N': 1.0,
    'AR150-T': 3.0,
    'AR152-T': 3.0,
    'AR154-T': 3.0,
    'AR155-N': 3.0,
    'AR157-N': 2.0,
    'AR158-T': 2.0,
    'AR160-T': 1.0,
    'AR162-N': 3.0,
    'AR164-T': 2.0,
    'AR165-T': 1.0,
    'AR167-T': 3.0,
    'AR169-T': 3.0,
    'AR170-N': 1.0,
    'AR172-N': 2.0,
    'AR174-T': 2.0,
    'AR177-T': 3.0,
    'AR178-T': 2.0,
    'AR180-T': 3.0,
    'AR182-N': 1.0,
    'AR183-T': 1.0,
    'AR185-T': 3.0,
    'AR186-T': 2.0,
    'AR187-T': 3.0,
    'AR188-T': 3.0,
    'AR189-N': 3.0,
    'AR190-N': 2.0,
    'AR192-T': 3.0,
    'AR193-T': 1.0,
    'AR197-T': 2.0,
    'AR198-T': 3.0,
    'AR200-T': 3.0,
    'AR202-T': 1.0,
    'AR204-T': 2.0,
    'AR206-T': 3.0,
    'AR208-T': 3.0,
    'AR209-T': 3.0,
    'AR210-N': 2.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 2.0,
    'AR219-T': 3.0,
    'AR220-T': 4.0,
    'AR222-N': 3.0,
    'AR224-N': 3.0,
    'AR226-T': 1.0,
    'AR228-T': 3.0,
    'AR229-N': 3.0,
    'AR230-N': 4.0,
    'AR231-T': 2.0,
    'AR232-T': 3.0,
    'AR233-T': 2.0,
    'AR237-T': 1.0,
    'AR238-T': 3.0,
    'AR239-T': 1.0,
    'AR242-N': 1.0,
    'AR243-T': 1.0,
    'AR245-N': 1.0,
    'AR247-T': 2.0,
    'AR249-T': 1.0,
    'AR251-T': 2.0,
    'AR252-T': 3.0,
    'AR254-T': 4.0,
    'AR256-T': 2.0,
    'AR258-T': 4.0,
    'AR259-T': 3.0,
    'AR261-N': 1.0,
    'AR262-T': 3.0,
    'AR264-N': 2.0,
    'AR266-T': 1.0,
    'AR268-N': 4.0,
    'AR271-T': 2.0,
    'AR273-T': 2.0,
    'AR275-T': 3.0,
    'AR277-T': 2.0,
    'AR279-T': 3.0,
    'AR281-N': 2.0,
    'AR283-T': 3.0,
    'AR285-T': 3.0,
    'AR287-T': 3.0,
    'AR289-T': 3.0,
    'AR293-T': 1.0,
    'AR297-N': 2.0,
    'AR299-N': 2.0,
    'AR300-T': 3.0,
    'AR302-T': 2.0,
    'AR304-T': 1.0,
    'AR306-N': 3.0,
    'AR309-N': 1.0,
    'AR311-N': 2.0,
    'AR313-N': 2.0,
    'AR315-N': 2.0,
    'AR319-N': 1.0,
    'AR321-N': 3.0,
    'AR323-T': 3.0,
    'AR324-N': 1.0,
    'AR327-N': 3.0,
    'AR329-N': 2.0,
    'AR331-T': 2.0,
    'AR333-N': 2.0,
    'AR335-N': 2.0,
    'AR339-N': 2.0,
    'AR341-N': 2.0,
    'AR343-N': 2.0,
    'AR345-N': 2.0,
    'AR347-T': 3.0,
    'AR349-N': 3.0,
    'AR351-N': 2.0,
    'AR353-N': 2.0,
    'AR357-N': 3.0,
    'AR363-N': 1.0,
    'AR365-T': 2.0,
    'AR367-N': 2.0,
    'AR368-N': 2.0,
    'AR370-N': 1.0,
    'AR371-T': 3.0,
    'AR372-N': 2.0,
    'AR373-N': 2.0,
    'AR374-N': 2.0,
    'AR376-N': 1.0,
    'AR381-N': 1.0,
    'AR382-N': 1.0,
    'AR384-N': 1.0,
    'AR387-N': 1.0,
    'AR389-N': 1.0,
    'AR391-T': 2.0,
    'AR392-N': 2.0,
    'AR394-N': 1.0,
    'AR398-N': 1.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 1.0,
    'AR406-N': 3.0,
    'AR407-N': 1.0,
    'AR408-N': 3.0,
    'AR409-N': 2.0,
    'AR411-N': 2.0,
    'AR412-N': 2.0,
    'AR413-N': 1.0,
    'AR414-N': 1.0,
    'AR416-N': 1.0,
    'AR418-T': 3.0,
    'AR420-N': 1.0,
    'AR421-N': 3.0,
    'AR422-T': 3.0,
    'AR423-N': 2.0,
    'AR424-N': 3.0,
    'AR425-T': 2.0,
    'AR426-N': 1.0,
    'AR427-N': 3.0,
    'AR428-T': 3.0,
    'AR429-T': 3.0,
    'AR430-N': 3.0,
    'AR431-T': 3.0,
    'AR432-N': 2.0,
    'AR433-T': 2.0,
    'AR434-N': 2.0,
    'AR435-N': 3.0,
    'AR436-N': 1.0,
    'AR437-N': 1.0,
    'AR438-T': 1.0,
    'AR439-T': 3.0,
    'AR440-N': 2.0,
    'AR441-T': 2.0,
    'AR442-T': 2.0,
    'AR443-T': 3.0,
    'AR444-T': 3.0,
    'AR445-N': 4.0,
    'AR446-N': 1.0,
    'AR447-N': 3.0,
    'AR448-T': 2.0,
    'AR449-T': 2.0,
    'AR450-N': 1.0,
    'AR451-N': 1.0,
    'AR452-N': 2.0,
    'AR453-N': 1.0,
    'AR454-T': 3.0,
    'AR455-T': 2.0,
    'AR456-N': 1.0,
    'AR457-T': 1.0,
    'AR458-T': 2.0,
    'AR459-T': 3.0,
    'AR460-T': 3.0,
    'AR461-T': 3.0,
    'AR462-T': 3.0,
    'AR463-T': 3.0,
    'AR464-T': 1.0,
    'AR465-T': 2.0,
    'AR466-N': 2.0,
    'AR467-N': 3.0,
    'AR468-T': 1.0,
    'AR469-N': 1.0,
    'AR470-T': 3.0,
    'AR471-T': 2.0,
    'AR472-T': 2.0,
    'AR473-N': 2.0,
    'AR474-N': 3.0,
    'AR475-T': 3.0,
    'AR476-T': 3.0,
    'AR477-T': 2.0,
    'AR478-T': 3.0,
    'AR479-T': 2.0,
    'AR480-N': 3.0,
    'AR481-': 1.0,
    'AR482-T': 3.0,
    'AR483-N': 1.0,
    'AR484-T': 3.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 3.0,
    'AR489-T': 2.0,
    'AR490-T': 3.0,
    'AR491-N': 3.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'BENZYL-CHLORIDE': {
    'AR001-N': 2.0,
    'AR003-T': 3.0,
    'AR008-N': 2.0,
    'AR012-T': 3.0,
    'AR014-N': 2.0,
    'AR018-T': 1.0,
    'AR019-N': 1.0,
    'AR021-N': 3.0,
    'AR026-T': 2.0,
    'AR028-N': 1.0,
    'AR029-N': 3.0,
    'AR031-T': 1.0,
    'AR033-N': 2.0,
    'AR035-T': 2.0,
    'AR037-T': 1.0,
    'AR042-T': 3.0,
    'AR044-N': 1.0,
    'AR046-N': 2.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 3.0,
    'AR056-T': 1.0,
    'AR057-T': 4.0,
    'AR059-N': 3.0,
    'AR061-T': 1.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 3.0,
    'AR068-N': 3.0,
    'AR070-N': 1.0,
    'AR071-T': 2.0,
    'AR073-T': 3.0,
    'AR075-N': 2.0,
    'AR077-N': 1.0,
    'AR079-T': 2.0,
    'AR081-T': 3.0,
    'AR083-T': 3.0,
    'AR085-N': 1.0,
    'AR088-T': 3.0,
    'AR090-N': 4.0,
    'AR098-T': 2.0,
    'AR100-N': 2.0,
    'AR102-N': 4.0,
    'AR104-T': 1.0,
    'AR108-T': 3.0,
    'AR110-T': 4.0,
    'AR112-T': 3.0,
    'AR115-T': 3.0,
    'AR118-T': 4.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 3.0,
    'AR130-T': 1.0,
    'AR132-T': 3.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 1.0,
    'AR142-T': 3.0,
    'AR144-N': 3.0,
    'AR145-N': 4.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 1.0,
    'AR150-T': 3.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 3.0,
    'AR158-T': 3.0,
    'AR160-T': 1.0,
    'AR162-N': 4.0,
    'AR164-T': 2.0,
    'AR165-T': 3.0,
    'AR167-T': 2.0,
    'AR169-T': 4.0,
    'AR170-N': 2.0,
    'AR172-N': 2.0,
    'AR174-T': 3.0,
    'AR177-T': 4.0,
    'AR178-T': 3.0,
    'AR180-T': 4.0,
    'AR182-N': 2.0,
    'AR183-T': 2.0,
    'AR185-T': 4.0,
    'AR186-T': 2.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 2.0,
    'AR190-N': 3.0,
    'AR192-T': 3.0,
    'AR193-T': 3.0,
    'AR197-T': 3.0,
    'AR198-T': 4.0,
    'AR200-T': 2.0,
    'AR202-T': 3.0,
    'AR204-T': 2.0,
    'AR206-T': 4.0,
    'AR208-T': 1.0,
    'AR209-T': 4.0,
    'AR210-N': 2.0,
    'AR211-N': 1.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 2.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 4.0,
    'AR224-N': 4.0,
    'AR226-T': 1.0,
    'AR228-T': 4.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 3.0,
    'AR232-T': 4.0,
    'AR233-T': 2.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 1.0,
    'AR243-T': 2.0,
    'AR245-N': 1.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 2.0,
    'AR252-T': 4.0,
    'AR254-T': 1.0,
    'AR256-T': 2.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 3.0,
    'AR264-N': 2.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 3.0,
    'AR273-T': 3.0,
    'AR275-T': 1.0,
    'AR277-T': 2.0,
    'AR279-T': 4.0,
    'AR281-N': 2.0,
    'AR283-T': 4.0,
    'AR285-T': 4.0,
    'AR287-T': 1.0,
    'AR289-T': 4.0,
    'AR293-T': 3.0,
    'AR297-N': 3.0,
    'AR299-N': 2.0,
    'AR300-T': 4.0,
    'AR302-T': 3.0,
    'AR304-T': 3.0,
    'AR306-N': 4.0,
    'AR309-N': 3.0,
    'AR311-N': 1.0,
    'AR313-N': 2.0,
    'AR315-N': 3.0,
    'AR319-N': 3.0,
    'AR321-N': 2.0,
    'AR323-T': 4.0,
    'AR324-N': 1.0,
    'AR327-N': 1.0,
    'AR329-N': 2.0,
    'AR331-T': 2.0,
    'AR333-N': 2.0,
    'AR335-N': 2.0,
    'AR339-N': 2.0,
    'AR341-N': 3.0,
    'AR343-N': 2.0,
    'AR345-N': 3.0,
    'AR347-T': 4.0,
    'AR349-N': 4.0,
    'AR351-N': 2.0,
    'AR353-N': 2.0,
    'AR357-N': 4.0,
    'AR363-N': 1.0,
    'AR365-T': 3.0,
    'AR367-N': 3.0,
    'AR368-N': 3.0,
    'AR370-N': 1.0,
    'AR371-T': 4.0,
    'AR372-N': 2.0,
    'AR373-N': 2.0,
    'AR374-N': 3.0,
    'AR376-N': 3.0,
    'AR381-N': 1.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 1.0,
    'AR389-N': 2.0,
    'AR391-T': 2.0,
    'AR392-N': 3.0,
    'AR394-N': 1.0,
    'AR398-N': 1.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 3.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 4.0,
    'AR409-N': 2.0,
    'AR411-N': 3.0,
    'AR412-N': 2.0,
    'AR413-N': 1.0,
    'AR414-N': 1.0,
    'AR416-N': 1.0,
    'AR418-T': 4.0,
    'AR420-N': 1.0,
    'AR421-N': 4.0,
    'AR422-T': 4.0,
    'AR423-N': 1.0,
    'AR424-N': 4.0,
    'AR425-T': 3.0,
    'AR426-N': 3.0,
    'AR427-N': 4.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 4.0,
    'AR432-N': 2.0,
    'AR433-T': 1.0,
    'AR434-N': 3.0,
    'AR435-N': 4.0,
    'AR436-N': 1.0,
    'AR437-N': 1.0,
    'AR438-T': 1.0,
    'AR439-T': 4.0,
    'AR440-N': 3.0,
    'AR441-T': 2.0,
    'AR442-T': 3.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 1.0,
    'AR446-N': 2.0,
    'AR447-N': 4.0,
    'AR448-T': 3.0,
    'AR449-T': 2.0,
    'AR450-N': 1.0,
    'AR451-N': 2.0,
    'AR452-N': 2.0,
    'AR453-N': 2.0,
    'AR454-T': 4.0,
    'AR455-T': 3.0,
    'AR456-N': 1.0,
    'AR457-T': 1.0,
    'AR458-T': 2.0,
    'AR459-T': 4.0,
    'AR460-T': 4.0,
    'AR461-T': 4.0,
    'AR462-T': 4.0,
    'AR463-T': 4.0,
    'AR464-T': 3.0,
    'AR465-T': 3.0,
    'AR466-N': 3.0,
    'AR467-N': 4.0,
    'AR468-T': 3.0,
    'AR469-N': 2.0,
    'AR470-T': 4.0,
    'AR471-T': 2.0,
    'AR472-T': 2.0,
    'AR473-N': 2.0,
    'AR474-N': 1.0,
    'AR475-T': 4.0,
    'AR476-T': 4.0,
    'AR477-T': 2.0,
    'AR478-T': 4.0,
    'AR479-T': 2.0,
    'AR480-N': 4.0,
    'AR481-': 2.0,
    'AR482-T': 4.0,
    'AR483-N': 2.0,
    'AR484-T': 4.0,
    'AR485-N': 2.0,
    'AR486-N': 1.0,
    'AR488-N': 4.0,
    'AR489-T': 2.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'BERYLLIUM-COMPOUNDS': {
    'AR001-N': 3.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 3.0,
    'AR018-T': 1.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 1.0,
    'AR029-N': 4.0,
    'AR031-T': 1.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 3.0,
    'AR044-N': 2.0,
    'AR046-N': 2.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 3.0,
    'AR056-T': 2.0,
    'AR057-T': 1.0,
    'AR059-N': 4.0,
    'AR061-T': 1.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 3.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 2.0,
    'AR075-N': 2.0,
    'AR077-N': 1.0,
    'AR079-T': 2.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 1.0,
    'AR088-T': 3.0,
    'AR090-N': 4.0,
    'AR098-T': 2.0,
    'AR100-N': 3.0,
    'AR102-N': 3.0,
    'AR104-T': 1.0,
    'AR108-T': 2.0,
    'AR110-T': 2.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 4.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 4.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 4.0,
    'AR154-T': 3.0,
    'AR155-N': 4.0,
    'AR157-N': 2.0,
    'AR158-T': 4.0,
    'AR160-T': 1.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 2.0,
    'AR169-T': 3.0,
    'AR170-N': 2.0,
    'AR172-N': 3.0,
    'AR174-T': 4.0,
    'AR177-T': 4.0,
    'AR178-T': 4.0,
    'AR180-T': 4.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 2.0,
    'AR186-T': 2.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 2.0,
    'AR197-T': 3.0,
    'AR198-T': 4.0,
    'AR200-T': 1.0,
    'AR202-T': 2.0,
    'AR204-T': 3.0,
    'AR206-T': 4.0,
    'AR208-T': 1.0,
    'AR209-T': 4.0,
    'AR210-N': 2.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 3.0,
    'AR224-N': 2.0,
    'AR226-T': 1.0,
    'AR228-T': 1.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 4.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 2.0,
    'AR243-T': 2.0,
    'AR245-N': 2.0,
    'AR247-T': 2.0,
    'AR249-T': 1.0,
    'AR251-T': 2.0,
    'AR252-T': 4.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 3.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 3.0,
    'AR281-N': 3.0,
    'AR283-T': 3.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 3.0,
    'AR293-T': 2.0,
    'AR297-N': 4.0,
    'AR299-N': 3.0,
    'AR300-T': 4.0,
    'AR302-T': 3.0,
    'AR304-T': 3.0,
    'AR306-N': 3.0,
    'AR309-N': 2.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 2.0,
    'AR321-N': 2.0,
    'AR323-T': 4.0,
    'AR324-N': 1.0,
    'AR327-N': 1.0,
    'AR329-N': 2.0,
    'AR331-T': 2.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 2.0,
    'AR341-N': 4.0,
    'AR343-N': 3.0,
    'AR345-N': 2.0,
    'AR347-T': 3.0,
    'AR349-N': 4.0,
    'AR351-N': 2.0,
    'AR353-N': 3.0,
    'AR357-N': 3.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 1.0,
    'AR371-T': 2.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 3.0,
    'AR376-N': 2.0,
    'AR381-N': 2.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 2.0,
    'AR389-N': 2.0,
    'AR391-T': 3.0,
    'AR392-N': 3.0,
    'AR394-N': 1.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 2.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 3.0,
    'AR409-N': 2.0,
    'AR411-N': 3.0,
    'AR412-N': 3.0,
    'AR413-N': 2.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 4.0,
    'AR422-T': 4.0,
    'AR423-N': 2.0,
    'AR424-N': 4.0,
    'AR425-T': 4.0,
    'AR426-N': 2.0,
    'AR427-N': 4.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 2.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 4.0,
    'AR436-N': 2.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 1.0,
    'AR446-N': 2.0,
    'AR447-N': 4.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 1.0,
    'AR451-N': 1.0,
    'AR452-N': 3.0,
    'AR453-N': 2.0,
    'AR454-T': 2.0,
    'AR455-T': 3.0,
    'AR456-N': 1.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 1.0,
    'AR460-T': 4.0,
    'AR461-T': 2.0,
    'AR462-T': 2.0,
    'AR463-T': 4.0,
    'AR464-T': 1.0,
    'AR465-T': 4.0,
    'AR466-N': 3.0,
    'AR467-N': 4.0,
    'AR468-T': 2.0,
    'AR469-N': 2.0,
    'AR470-T': 4.0,
    'AR471-T': 3.0,
    'AR472-T': 1.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 2.0,
    'AR476-T': 2.0,
    'AR477-T': 3.0,
    'AR478-T': 4.0,
    'AR479-T': 2.0,
    'AR480-N': 3.0,
    'AR481-': 4.0,
    'AR482-T': 4.0,
    'AR483-N': 2.0,
    'AR484-T': 4.0,
    'AR485-N': 4.0,
    'AR486-N': 4.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  BIPHENYL: {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 3.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 4.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 3.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 2.0,
    'AR098-T': 3.0,
    'AR100-N': 3.0,
    'AR102-N': 2.0,
    'AR104-T': 4.0,
    'AR108-T': 4.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 3.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 1.0,
    'AR154-T': 1.0,
    'AR155-N': 2.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 3.0,
    'AR169-T': 1.0,
    'AR170-N': 4.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 2.0,
    'AR186-T': 3.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 3.0,
    'AR198-T': 2.0,
    'AR200-T': 2.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 1.0,
    'AR208-T': 3.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 1.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 3.0,
    'AR237-T': 4.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 2.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 1.0,
    'AR302-T': 3.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 4.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 3.0,
    'AR323-T': 2.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 3.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 2.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 2.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 2.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 2.0,
    'AR430-N': 1.0,
    'AR431-T': 2.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 2.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 2.0,
    'AR444-T': 2.0,
    'AR445-N': 1.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 2.0,
    'AR455-T': 3.0,
    'AR456-N': 2.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 2.0,
    'AR460-T': 1.0,
    'AR461-T': 2.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 2.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 4.0,
    'AR475-T': 2.0,
    'AR476-T': 2.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 3.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 3.0,
    'AR486-N': 3.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 2.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'BIS-2-ETHYLHEXYL-PHTHALATE--DEHP-': {
    'AR001-N': 3.0,
    'AR003-T': 3.0,
    'AR008-N': 1.0,
    'AR012-T': 3.0,
    'AR014-N': 3.0,
    'AR018-T': 3.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 2.0,
    'AR028-N': 2.0,
    'AR029-N': 3.0,
    'AR031-T': 1.0,
    'AR033-N': 1.0,
    'AR035-T': 1.0,
    'AR037-T': 3.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 1.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 3.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 3.0,
    'AR061-T': 3.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 3.0,
    'AR068-N': 1.0,
    'AR070-N': 2.0,
    'AR071-T': 1.0,
    'AR073-T': 2.0,
    'AR075-N': 2.0,
    'AR077-N': 1.0,
    'AR079-T': 2.0,
    'AR081-T': 3.0,
    'AR083-T': 3.0,
    'AR085-N': 2.0,
    'AR088-T': 1.0,
    'AR090-N': 4.0,
    'AR098-T': 3.0,
    'AR100-N': 2.0,
    'AR102-N': 4.0,
    'AR104-T': 2.0,
    'AR108-T': 1.0,
    'AR110-T': 4.0,
    'AR112-T': 2.0,
    'AR115-T': 1.0,
    'AR118-T': 4.0,
    'AR122-N': 2.0,
    'AR124-N': 2.0,
    'AR128-T': 1.0,
    'AR130-T': 2.0,
    'AR132-T': 3.0,
    'AR136-T': 1.0,
    'AR138-T': 2.0,
    'AR140-N': 2.0,
    'AR142-T': 3.0,
    'AR144-N': 3.0,
    'AR145-N': 4.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 1.0,
    'AR150-T': 3.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 2.0,
    'AR158-T': 3.0,
    'AR160-T': 3.0,
    'AR162-N': 4.0,
    'AR164-T': 3.0,
    'AR165-T': 1.0,
    'AR167-T': 3.0,
    'AR169-T': 4.0,
    'AR170-N': 3.0,
    'AR172-N': 3.0,
    'AR174-T': 3.0,
    'AR177-T': 4.0,
    'AR178-T': 3.0,
    'AR180-T': 4.0,
    'AR182-N': 1.0,
    'AR183-T': 1.0,
    'AR185-T': 4.0,
    'AR186-T': 1.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 3.0,
    'AR190-N': 3.0,
    'AR192-T': 4.0,
    'AR193-T': 3.0,
    'AR197-T': 3.0,
    'AR198-T': 4.0,
    'AR200-T': 2.0,
    'AR202-T': 2.0,
    'AR204-T': 2.0,
    'AR206-T': 4.0,
    'AR208-T': 2.0,
    'AR209-T': 4.0,
    'AR210-N': 2.0,
    'AR211-N': 1.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 4.0,
    'AR224-N': 4.0,
    'AR226-T': 1.0,
    'AR228-T': 3.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 3.0,
    'AR232-T': 4.0,
    'AR233-T': 2.0,
    'AR237-T': 2.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 1.0,
    'AR243-T': 1.0,
    'AR245-N': 1.0,
    'AR247-T': 4.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 4.0,
    'AR254-T': 2.0,
    'AR256-T': 1.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 2.0,
    'AR264-N': 1.0,
    'AR266-T': 3.0,
    'AR268-N': 1.0,
    'AR271-T': 3.0,
    'AR273-T': 3.0,
    'AR275-T': 2.0,
    'AR277-T': 1.0,
    'AR279-T': 4.0,
    'AR281-N': 1.0,
    'AR283-T': 4.0,
    'AR285-T': 3.0,
    'AR287-T': 2.0,
    'AR289-T': 4.0,
    'AR293-T': 2.0,
    'AR297-N': 3.0,
    'AR299-N': 3.0,
    'AR300-T': 4.0,
    'AR302-T': 2.0,
    'AR304-T': 1.0,
    'AR306-N': 4.0,
    'AR309-N': 2.0,
    'AR311-N': 1.0,
    'AR313-N': 1.0,
    'AR315-N': 3.0,
    'AR319-N': 2.0,
    'AR321-N': 3.0,
    'AR323-T': 4.0,
    'AR324-N': 1.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 2.0,
    'AR333-N': 2.0,
    'AR335-N': 1.0,
    'AR339-N': 2.0,
    'AR341-N': 3.0,
    'AR343-N': 3.0,
    'AR345-N': 2.0,
    'AR347-T': 4.0,
    'AR349-N': 4.0,
    'AR351-N': 3.0,
    'AR353-N': 2.0,
    'AR357-N': 4.0,
    'AR363-N': 3.0,
    'AR365-T': 3.0,
    'AR367-N': 3.0,
    'AR368-N': 3.0,
    'AR370-N': 1.0,
    'AR371-T': 4.0,
    'AR372-N': 1.0,
    'AR373-N': 1.0,
    'AR374-N': 3.0,
    'AR376-N': 1.0,
    'AR381-N': 1.0,
    'AR382-N': 1.0,
    'AR384-N': 1.0,
    'AR387-N': 3.0,
    'AR389-N': 2.0,
    'AR391-T': 1.0,
    'AR392-N': 3.0,
    'AR394-N': 3.0,
    'AR398-N': 1.0,
    'AR400-T': 1.0,
    'AR402-N': 1.0,
    'AR404-N': 2.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 4.0,
    'AR409-N': 2.0,
    'AR411-N': 3.0,
    'AR412-N': 2.0,
    'AR413-N': 2.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 4.0,
    'AR420-N': 1.0,
    'AR421-N': 4.0,
    'AR422-T': 4.0,
    'AR423-N': 1.0,
    'AR424-N': 4.0,
    'AR425-T': 3.0,
    'AR426-N': 2.0,
    'AR427-N': 4.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 4.0,
    'AR432-N': 1.0,
    'AR433-T': 1.0,
    'AR434-N': 3.0,
    'AR435-N': 4.0,
    'AR436-N': 1.0,
    'AR437-N': 2.0,
    'AR438-T': 1.0,
    'AR439-T': 3.0,
    'AR440-N': 3.0,
    'AR441-T': 3.0,
    'AR442-T': 2.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 2.0,
    'AR446-N': 2.0,
    'AR447-N': 4.0,
    'AR448-T': 3.0,
    'AR449-T': 1.0,
    'AR450-N': 1.0,
    'AR451-N': 1.0,
    'AR452-N': 3.0,
    'AR453-N': 1.0,
    'AR454-T': 4.0,
    'AR455-T': 2.0,
    'AR456-N': 2.0,
    'AR457-T': 1.0,
    'AR458-T': 3.0,
    'AR459-T': 4.0,
    'AR460-T': 4.0,
    'AR461-T': 4.0,
    'AR462-T': 4.0,
    'AR463-T': 4.0,
    'AR464-T': 1.0,
    'AR465-T': 3.0,
    'AR466-N': 3.0,
    'AR467-N': 4.0,
    'AR468-T': 1.0,
    'AR469-N': 1.0,
    'AR470-T': 4.0,
    'AR471-T': 3.0,
    'AR472-T': 3.0,
    'AR473-N': 1.0,
    'AR474-N': 2.0,
    'AR475-T': 4.0,
    'AR476-T': 4.0,
    'AR477-T': 2.0,
    'AR478-T': 4.0,
    'AR479-T': 3.0,
    'AR480-N': 4.0,
    'AR481-': 1.0,
    'AR482-T': 4.0,
    'AR483-N': 1.0,
    'AR484-T': 4.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 3.0,
    'AR489-T': 1.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  BROMOFORM: {
    'AR001-N': 2.0,
    'AR003-T': 3.0,
    'AR008-N': 2.0,
    'AR012-T': 2.0,
    'AR014-N': 2.0,
    'AR018-T': 1.0,
    'AR019-N': 1.0,
    'AR021-N': 3.0,
    'AR026-T': 2.0,
    'AR028-N': 1.0,
    'AR029-N': 2.0,
    'AR031-T': 3.0,
    'AR033-N': 2.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 3.0,
    'AR044-N': 1.0,
    'AR046-N': 2.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 3.0,
    'AR056-T': 1.0,
    'AR057-T': 1.0,
    'AR059-N': 2.0,
    'AR061-T': 1.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 3.0,
    'AR068-N': 2.0,
    'AR070-N': 1.0,
    'AR071-T': 2.0,
    'AR073-T': 3.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 2.0,
    'AR081-T': 2.0,
    'AR083-T': 3.0,
    'AR085-N': 1.0,
    'AR088-T': 2.0,
    'AR090-N': 4.0,
    'AR098-T': 3.0,
    'AR100-N': 2.0,
    'AR102-N': 4.0,
    'AR104-T': 1.0,
    'AR108-T': 3.0,
    'AR110-T': 4.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 4.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 3.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 1.0,
    'AR142-T': 3.0,
    'AR144-N': 2.0,
    'AR145-N': 4.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 4.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 3.0,
    'AR158-T': 2.0,
    'AR160-T': 3.0,
    'AR162-N': 4.0,
    'AR164-T': 2.0,
    'AR165-T': 2.0,
    'AR167-T': 2.0,
    'AR169-T': 4.0,
    'AR170-N': 3.0,
    'AR172-N': 2.0,
    'AR174-T': 2.0,
    'AR177-T': 4.0,
    'AR178-T': 2.0,
    'AR180-T': 4.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 4.0,
    'AR186-T': 1.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 1.0,
    'AR190-N': 2.0,
    'AR192-T': 3.0,
    'AR193-T': 3.0,
    'AR197-T': 3.0,
    'AR198-T': 4.0,
    'AR200-T': 2.0,
    'AR202-T': 3.0,
    'AR204-T': 2.0,
    'AR206-T': 4.0,
    'AR208-T': 1.0,
    'AR209-T': 4.0,
    'AR210-N': 2.0,
    'AR211-N': 1.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 2.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 4.0,
    'AR224-N': 4.0,
    'AR226-T': 2.0,
    'AR228-T': 4.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 2.0,
    'AR232-T': 4.0,
    'AR233-T': 2.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 1.0,
    'AR243-T': 3.0,
    'AR245-N': 1.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 3.0,
    'AR252-T': 4.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 3.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 3.0,
    'AR273-T': 3.0,
    'AR275-T': 1.0,
    'AR277-T': 2.0,
    'AR279-T': 4.0,
    'AR281-N': 2.0,
    'AR283-T': 4.0,
    'AR285-T': 4.0,
    'AR287-T': 1.0,
    'AR289-T': 4.0,
    'AR293-T': 3.0,
    'AR297-N': 3.0,
    'AR299-N': 2.0,
    'AR300-T': 4.0,
    'AR302-T': 3.0,
    'AR304-T': 2.0,
    'AR306-N': 4.0,
    'AR309-N': 3.0,
    'AR311-N': 1.0,
    'AR313-N': 2.0,
    'AR315-N': 2.0,
    'AR319-N': 3.0,
    'AR321-N': 2.0,
    'AR323-T': 4.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 2.0,
    'AR333-N': 3.0,
    'AR335-N': 2.0,
    'AR339-N': 2.0,
    'AR341-N': 3.0,
    'AR343-N': 2.0,
    'AR345-N': 3.0,
    'AR347-T': 4.0,
    'AR349-N': 4.0,
    'AR351-N': 2.0,
    'AR353-N': 2.0,
    'AR357-N': 4.0,
    'AR363-N': 1.0,
    'AR365-T': 3.0,
    'AR367-N': 3.0,
    'AR368-N': 3.0,
    'AR370-N': 1.0,
    'AR371-T': 4.0,
    'AR372-N': 2.0,
    'AR373-N': 2.0,
    'AR374-N': 3.0,
    'AR376-N': 3.0,
    'AR381-N': 1.0,
    'AR382-N': 1.0,
    'AR384-N': 1.0,
    'AR387-N': 1.0,
    'AR389-N': 3.0,
    'AR391-T': 2.0,
    'AR392-N': 3.0,
    'AR394-N': 1.0,
    'AR398-N': 1.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 3.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 4.0,
    'AR409-N': 2.0,
    'AR411-N': 3.0,
    'AR412-N': 3.0,
    'AR413-N': 2.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 4.0,
    'AR420-N': 1.0,
    'AR421-N': 4.0,
    'AR422-T': 4.0,
    'AR423-N': 1.0,
    'AR424-N': 4.0,
    'AR425-T': 2.0,
    'AR426-N': 3.0,
    'AR427-N': 4.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 4.0,
    'AR432-N': 2.0,
    'AR433-T': 1.0,
    'AR434-N': 3.0,
    'AR435-N': 4.0,
    'AR436-N': 2.0,
    'AR437-N': 1.0,
    'AR438-T': 1.0,
    'AR439-T': 4.0,
    'AR440-N': 2.0,
    'AR441-T': 2.0,
    'AR442-T': 3.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 1.0,
    'AR446-N': 2.0,
    'AR447-N': 4.0,
    'AR448-T': 3.0,
    'AR449-T': 2.0,
    'AR450-N': 1.0,
    'AR451-N': 3.0,
    'AR452-N': 2.0,
    'AR453-N': 1.0,
    'AR454-T': 4.0,
    'AR455-T': 3.0,
    'AR456-N': 1.0,
    'AR457-T': 1.0,
    'AR458-T': 3.0,
    'AR459-T': 4.0,
    'AR460-T': 4.0,
    'AR461-T': 4.0,
    'AR462-T': 4.0,
    'AR463-T': 4.0,
    'AR464-T': 3.0,
    'AR465-T': 3.0,
    'AR466-N': 3.0,
    'AR467-N': 4.0,
    'AR468-T': 3.0,
    'AR469-N': 1.0,
    'AR470-T': 4.0,
    'AR471-T': 2.0,
    'AR472-T': 3.0,
    'AR473-N': 2.0,
    'AR474-N': 1.0,
    'AR475-T': 4.0,
    'AR476-T': 4.0,
    'AR477-T': 2.0,
    'AR478-T': 4.0,
    'AR479-T': 2.0,
    'AR480-N': 4.0,
    'AR481-': 1.0,
    'AR482-T': 4.0,
    'AR483-N': 2.0,
    'AR484-T': 4.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 4.0,
    'AR489-T': 3.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'CADMIUM-COMPOUNDS': {
    'AR001-N': 3.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 3.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 1.0,
    'AR029-N': 4.0,
    'AR031-T': 1.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 2.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 1.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 3.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 2.0,
    'AR075-N': 2.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 1.0,
    'AR088-T': 3.0,
    'AR090-N': 4.0,
    'AR098-T': 2.0,
    'AR100-N': 3.0,
    'AR102-N': 4.0,
    'AR104-T': 1.0,
    'AR108-T': 2.0,
    'AR110-T': 2.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 4.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 3.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 4.0,
    'AR154-T': 3.0,
    'AR155-N': 4.0,
    'AR157-N': 2.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 3.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 1.0,
    'AR169-T': 3.0,
    'AR170-N': 2.0,
    'AR172-N': 3.0,
    'AR174-T': 4.0,
    'AR177-T': 4.0,
    'AR178-T': 4.0,
    'AR180-T': 3.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 3.0,
    'AR186-T': 2.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 2.0,
    'AR197-T': 3.0,
    'AR198-T': 4.0,
    'AR200-T': 1.0,
    'AR202-T': 2.0,
    'AR204-T': 3.0,
    'AR206-T': 4.0,
    'AR208-T': 1.0,
    'AR209-T': 4.0,
    'AR210-N': 2.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 3.0,
    'AR224-N': 2.0,
    'AR226-T': 1.0,
    'AR228-T': 1.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 4.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 1.0,
    'AR243-T': 2.0,
    'AR245-N': 1.0,
    'AR247-T': 2.0,
    'AR249-T': 1.0,
    'AR251-T': 2.0,
    'AR252-T': 4.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 2.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 3.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 3.0,
    'AR281-N': 3.0,
    'AR283-T': 3.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 3.0,
    'AR293-T': 2.0,
    'AR297-N': 4.0,
    'AR299-N': 3.0,
    'AR300-T': 4.0,
    'AR302-T': 3.0,
    'AR304-T': 3.0,
    'AR306-N': 3.0,
    'AR309-N': 2.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 2.0,
    'AR321-N': 2.0,
    'AR323-T': 4.0,
    'AR324-N': 1.0,
    'AR327-N': 1.0,
    'AR329-N': 2.0,
    'AR331-T': 2.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 3.0,
    'AR345-N': 2.0,
    'AR347-T': 3.0,
    'AR349-N': 4.0,
    'AR351-N': 2.0,
    'AR353-N': 3.0,
    'AR357-N': 3.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 1.0,
    'AR371-T': 2.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 2.0,
    'AR381-N': 1.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 2.0,
    'AR389-N': 2.0,
    'AR391-T': 2.0,
    'AR392-N': 4.0,
    'AR394-N': 1.0,
    'AR398-N': 2.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 2.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 3.0,
    'AR409-N': 2.0,
    'AR411-N': 3.0,
    'AR412-N': 3.0,
    'AR413-N': 2.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 2.0,
    'AR420-N': 1.0,
    'AR421-N': 3.0,
    'AR422-T': 4.0,
    'AR423-N': 2.0,
    'AR424-N': 4.0,
    'AR425-T': 4.0,
    'AR426-N': 2.0,
    'AR427-N': 3.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 3.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 3.0,
    'AR436-N': 1.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 2.0,
    'AR442-T': 3.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 1.0,
    'AR446-N': 2.0,
    'AR447-N': 4.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 1.0,
    'AR451-N': 2.0,
    'AR452-N': 3.0,
    'AR453-N': 2.0,
    'AR454-T': 2.0,
    'AR455-T': 3.0,
    'AR456-N': 1.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 1.0,
    'AR460-T': 4.0,
    'AR461-T': 3.0,
    'AR462-T': 2.0,
    'AR463-T': 4.0,
    'AR464-T': 1.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 4.0,
    'AR468-T': 2.0,
    'AR469-N': 2.0,
    'AR470-T': 4.0,
    'AR471-T': 2.0,
    'AR472-T': 2.0,
    'AR473-N': 2.0,
    'AR474-N': 1.0,
    'AR475-T': 3.0,
    'AR476-T': 3.0,
    'AR477-T': 3.0,
    'AR478-T': 3.0,
    'AR479-T': 2.0,
    'AR480-N': 4.0,
    'AR481-': 4.0,
    'AR482-T': 4.0,
    'AR483-N': 2.0,
    'AR484-T': 4.0,
    'AR485-N': 3.0,
    'AR486-N': 3.0,
    'AR488-N': 1.0,
    'AR489-T': 2.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'CARBON-DISULFIDE': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 1.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 4.0,
    'AR044-N': 1.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 4.0,
    'AR056-T': 1.0,
    'AR057-T': 1.0,
    'AR059-N': 4.0,
    'AR061-T': 1.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 2.0,
    'AR085-N': 1.0,
    'AR088-T': 4.0,
    'AR090-N': 2.0,
    'AR098-T': 4.0,
    'AR100-N': 3.0,
    'AR102-N': 2.0,
    'AR104-T': 1.0,
    'AR108-T': 3.0,
    'AR110-T': 2.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 1.0,
    'AR150-T': 2.0,
    'AR152-T': 2.0,
    'AR154-T': 2.0,
    'AR155-N': 2.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 1.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 3.0,
    'AR169-T': 3.0,
    'AR170-N': 4.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 2.0,
    'AR182-N': 3.0,
    'AR183-T': 1.0,
    'AR185-T': 2.0,
    'AR186-T': 3.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 2.0,
    'AR193-T': 4.0,
    'AR197-T': 3.0,
    'AR198-T': 2.0,
    'AR200-T': 1.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 2.0,
    'AR208-T': 1.0,
    'AR209-T': 2.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 3.0,
    'AR224-N': 2.0,
    'AR226-T': 1.0,
    'AR228-T': 2.0,
    'AR229-N': 2.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 1.0,
    'AR243-T': 3.0,
    'AR245-N': 1.0,
    'AR247-T': 4.0,
    'AR249-T': 1.0,
    'AR251-T': 3.0,
    'AR252-T': 2.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 2.0,
    'AR261-N': 1.0,
    'AR262-T': 2.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 2.0,
    'AR281-N': 3.0,
    'AR283-T': 3.0,
    'AR285-T': 2.0,
    'AR287-T': 1.0,
    'AR289-T': 2.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 2.0,
    'AR302-T': 3.0,
    'AR304-T': 4.0,
    'AR306-N': 3.0,
    'AR309-N': 4.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 1.0,
    'AR323-T': 2.0,
    'AR324-N': 1.0,
    'AR327-N': 1.0,
    'AR329-N': 4.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 2.0,
    'AR349-N': 2.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 3.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 1.0,
    'AR371-T': 2.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 1.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 1.0,
    'AR389-N': 4.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 1.0,
    'AR398-N': 1.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 3.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 2.0,
    'AR420-N': 1.0,
    'AR421-N': 2.0,
    'AR422-T': 2.0,
    'AR423-N': 1.0,
    'AR424-N': 2.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 2.0,
    'AR428-T': 2.0,
    'AR429-T': 2.0,
    'AR430-N': 2.0,
    'AR431-T': 2.0,
    'AR432-N': 3.0,
    'AR433-T': 1.0,
    'AR434-N': 4.0,
    'AR435-N': 3.0,
    'AR436-N': 1.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 2.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 2.0,
    'AR444-T': 2.0,
    'AR445-N': 1.0,
    'AR446-N': 4.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 1.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 3.0,
    'AR455-T': 3.0,
    'AR456-N': 2.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 3.0,
    'AR460-T': 2.0,
    'AR461-T': 2.0,
    'AR462-T': 2.0,
    'AR463-T': 2.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 2.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 2.0,
    'AR471-T': 3.0,
    'AR472-T': 4.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 2.0,
    'AR476-T': 2.0,
    'AR477-T': 3.0,
    'AR478-T': 2.0,
    'AR479-T': 4.0,
    'AR480-N': 2.0,
    'AR481-': 1.0,
    'AR482-T': 2.0,
    'AR483-N': 3.0,
    'AR484-T': 2.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 2.0,
    'AR489-T': 3.0,
    'AR490-T': 2.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'CARBON-TETRACHLORIDE': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 1.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 4.0,
    'AR019-N': 3.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 3.0,
    'AR029-N': 4.0,
    'AR031-T': 1.0,
    'AR033-N': 1.0,
    'AR035-T': 1.0,
    'AR037-T': 4.0,
    'AR042-T': 4.0,
    'AR044-N': 4.0,
    'AR046-N': 1.0,
    'AR048-N': 1.0,
    'AR050-N': 3.0,
    'AR052-T': 4.0,
    'AR054-N': 4.0,
    'AR056-T': 4.0,
    'AR057-T': 3.0,
    'AR059-N': 4.0,
    'AR061-T': 4.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 1.0,
    'AR070-N': 3.0,
    'AR071-T': 1.0,
    'AR073-T': 2.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 2.0,
    'AR085-N': 4.0,
    'AR088-T': 1.0,
    'AR090-N': 2.0,
    'AR098-T': 4.0,
    'AR100-N': 3.0,
    'AR102-N': 3.0,
    'AR104-T': 3.0,
    'AR108-T': 1.0,
    'AR110-T': 2.0,
    'AR112-T': 2.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 3.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 2.0,
    'AR140-N': 3.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 4.0,
    'AR147-N': 1.0,
    'AR148-N': 1.0,
    'AR150-T': 2.0,
    'AR152-T': 2.0,
    'AR154-T': 2.0,
    'AR155-N': 2.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 4.0,
    'AR162-N': 3.0,
    'AR164-T': 3.0,
    'AR165-T': 1.0,
    'AR167-T': 4.0,
    'AR169-T': 3.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 2.0,
    'AR182-N': 1.0,
    'AR183-T': 1.0,
    'AR185-T': 2.0,
    'AR186-T': 1.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 3.0,
    'AR190-N': 4.0,
    'AR192-T': 2.0,
    'AR193-T': 3.0,
    'AR197-T': 4.0,
    'AR198-T': 2.0,
    'AR200-T': 3.0,
    'AR202-T': 3.0,
    'AR204-T': 3.0,
    'AR206-T': 2.0,
    'AR208-T': 3.0,
    'AR209-T': 2.0,
    'AR210-N': 3.0,
    'AR211-N': 1.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 4.0,
    'AR220-T': 3.0,
    'AR222-N': 3.0,
    'AR224-N': 3.0,
    'AR226-T': 1.0,
    'AR228-T': 2.0,
    'AR229-N': 2.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 3.0,
    'AR237-T': 3.0,
    'AR238-T': 1.0,
    'AR239-T': 4.0,
    'AR242-N': 1.0,
    'AR243-T': 1.0,
    'AR245-N': 1.0,
    'AR247-T': 4.0,
    'AR249-T': 1.0,
    'AR251-T': 3.0,
    'AR252-T': 2.0,
    'AR254-T': 3.0,
    'AR256-T': 1.0,
    'AR258-T': 3.0,
    'AR259-T': 2.0,
    'AR261-N': 1.0,
    'AR262-T': 2.0,
    'AR264-N': 1.0,
    'AR266-T': 3.0,
    'AR268-N': 1.0,
    'AR271-T': 3.0,
    'AR273-T': 4.0,
    'AR275-T': 4.0,
    'AR277-T': 1.0,
    'AR279-T': 2.0,
    'AR281-N': 1.0,
    'AR283-T': 3.0,
    'AR285-T': 2.0,
    'AR287-T': 4.0,
    'AR289-T': 2.0,
    'AR293-T': 1.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 2.0,
    'AR302-T': 2.0,
    'AR304-T': 1.0,
    'AR306-N': 3.0,
    'AR309-N': 1.0,
    'AR311-N': 1.0,
    'AR313-N': 1.0,
    'AR315-N': 4.0,
    'AR319-N': 1.0,
    'AR321-N': 4.0,
    'AR323-T': 2.0,
    'AR324-N': 1.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 1.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 2.0,
    'AR349-N': 2.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 3.0,
    'AR363-N': 3.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 1.0,
    'AR371-T': 2.0,
    'AR372-N': 1.0,
    'AR373-N': 1.0,
    'AR374-N': 4.0,
    'AR376-N': 1.0,
    'AR381-N': 1.0,
    'AR382-N': 1.0,
    'AR384-N': 1.0,
    'AR387-N': 4.0,
    'AR389-N': 3.0,
    'AR391-T': 1.0,
    'AR392-N': 4.0,
    'AR394-N': 3.0,
    'AR398-N': 1.0,
    'AR400-T': 1.0,
    'AR402-N': 1.0,
    'AR404-N': 3.0,
    'AR406-N': 4.0,
    'AR407-N': 1.0,
    'AR408-N': 3.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 3.0,
    'AR420-N': 1.0,
    'AR421-N': 2.0,
    'AR422-T': 2.0,
    'AR423-N': 2.0,
    'AR424-N': 2.0,
    'AR425-T': 4.0,
    'AR426-N': 3.0,
    'AR427-N': 2.0,
    'AR428-T': 2.0,
    'AR429-T': 2.0,
    'AR430-N': 2.0,
    'AR431-T': 2.0,
    'AR432-N': 1.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 3.0,
    'AR436-N': 1.0,
    'AR437-N': 4.0,
    'AR438-T': 1.0,
    'AR439-T': 3.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 2.0,
    'AR443-T': 2.0,
    'AR444-T': 2.0,
    'AR445-N': 3.0,
    'AR446-N': 3.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 1.0,
    'AR450-N': 1.0,
    'AR451-N': 1.0,
    'AR452-N': 4.0,
    'AR453-N': 1.0,
    'AR454-T': 3.0,
    'AR455-T': 2.0,
    'AR456-N': 3.0,
    'AR457-T': 1.0,
    'AR458-T': 4.0,
    'AR459-T': 3.0,
    'AR460-T': 2.0,
    'AR461-T': 2.0,
    'AR462-T': 2.0,
    'AR463-T': 2.0,
    'AR464-T': 1.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 2.0,
    'AR468-T': 1.0,
    'AR469-N': 1.0,
    'AR470-T': 2.0,
    'AR471-T': 4.0,
    'AR472-T': 4.0,
    'AR473-N': 1.0,
    'AR474-N': 3.0,
    'AR475-T': 2.0,
    'AR476-T': 2.0,
    'AR477-T': 3.0,
    'AR478-T': 2.0,
    'AR479-T': 4.0,
    'AR480-N': 2.0,
    'AR481-': 3.0,
    'AR482-T': 2.0,
    'AR483-N': 1.0,
    'AR484-T': 2.0,
    'AR485-N': 3.0,
    'AR486-N': 3.0,
    'AR488-N': 2.0,
    'AR489-T': 1.0,
    'AR490-T': 2.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'CARBONYL-SULFIDE': {
    'AR001-N': 2.0,
    'AR003-T': 4.0,
    'AR008-N': 2.0,
    'AR012-T': 3.0,
    'AR014-N': 2.0,
    'AR018-T': 3.0,
    'AR019-N': 2.0,
    'AR021-N': 3.0,
    'AR026-T': 2.0,
    'AR028-N': 4.0,
    'AR029-N': 2.0,
    'AR031-T': 4.0,
    'AR033-N': 2.0,
    'AR035-T': 1.0,
    'AR037-T': 4.0,
    'AR042-T': 3.0,
    'AR044-N': 3.0,
    'AR046-N': 2.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 3.0,
    'AR056-T': 3.0,
    'AR057-T': 4.0,
    'AR059-N': 3.0,
    'AR061-T': 3.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 3.0,
    'AR070-N': 2.0,
    'AR071-T': 2.0,
    'AR073-T': 4.0,
    'AR075-N': 4.0,
    'AR077-N': 1.0,
    'AR079-T': 2.0,
    'AR081-T': 3.0,
    'AR083-T': 2.0,
    'AR085-N': 3.0,
    'AR088-T': 3.0,
    'AR090-N': 1.0,
    'AR098-T': 4.0,
    'AR100-N': 2.0,
    'AR102-N': 1.0,
    'AR104-T': 2.0,
    'AR108-T': 4.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 1.0,
    'AR118-T': 1.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 1.0,
    'AR130-T': 4.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 3.0,
    'AR142-T': 4.0,
    'AR144-N': 3.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 4.0,
    'AR150-T': 1.0,
    'AR152-T': 1.0,
    'AR154-T': 2.0,
    'AR155-N': 1.0,
    'AR157-N': 4.0,
    'AR158-T': 3.0,
    'AR160-T': 4.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 4.0,
    'AR169-T': 1.0,
    'AR170-N': 2.0,
    'AR172-N': 2.0,
    'AR174-T': 3.0,
    'AR177-T': 1.0,
    'AR178-T': 3.0,
    'AR180-T': 1.0,
    'AR182-N': 4.0,
    'AR183-T': 2.0,
    'AR185-T': 2.0,
    'AR186-T': 4.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 2.0,
    'AR190-N': 3.0,
    'AR192-T': 2.0,
    'AR193-T': 2.0,
    'AR197-T': 4.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 1.0,
    'AR208-T': 3.0,
    'AR209-T': 1.0,
    'AR210-N': 4.0,
    'AR211-N': 3.0,
    'AR213-T': 3.0,
    'AR215-T': 3.0,
    'AR217-N': 2.0,
    'AR219-T': 2.0,
    'AR220-T': 3.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 4.0,
    'AR228-T': 1.0,
    'AR229-N': 1.0,
    'AR230-N': 2.0,
    'AR231-T': 3.0,
    'AR232-T': 1.0,
    'AR233-T': 2.0,
    'AR237-T': 2.0,
    'AR238-T': 1.0,
    'AR239-T': 3.0,
    'AR242-N': 3.0,
    'AR243-T': 4.0,
    'AR245-N': 3.0,
    'AR247-T': 2.0,
    'AR249-T': 3.0,
    'AR251-T': 4.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 4.0,
    'AR258-T': 2.0,
    'AR259-T': 1.0,
    'AR261-N': 3.0,
    'AR262-T': 3.0,
    'AR264-N': 4.0,
    'AR266-T': 4.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 4.0,
    'AR277-T': 2.0,
    'AR279-T': 2.0,
    'AR281-N': 2.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 3.0,
    'AR289-T': 2.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 2.0,
    'AR300-T': 1.0,
    'AR302-T': 4.0,
    'AR304-T': 3.0,
    'AR306-N': 1.0,
    'AR309-N': 4.0,
    'AR311-N': 3.0,
    'AR313-N': 2.0,
    'AR315-N': 2.0,
    'AR319-N': 4.0,
    'AR321-N': 4.0,
    'AR323-T': 1.0,
    'AR324-N': 4.0,
    'AR327-N': 1.0,
    'AR329-N': 4.0,
    'AR331-T': 3.0,
    'AR333-N': 4.0,
    'AR335-N': 2.0,
    'AR339-N': 2.0,
    'AR341-N': 4.0,
    'AR343-N': 2.0,
    'AR345-N': 4.0,
    'AR347-T': 2.0,
    'AR349-N': 1.0,
    'AR351-N': 3.0,
    'AR353-N': 2.0,
    'AR357-N': 1.0,
    'AR363-N': 4.0,
    'AR365-T': 4.0,
    'AR367-N': 3.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 2.0,
    'AR373-N': 2.0,
    'AR374-N': 3.0,
    'AR376-N': 3.0,
    'AR381-N': 3.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 3.0,
    'AR389-N': 4.0,
    'AR391-T': 2.0,
    'AR392-N': 3.0,
    'AR394-N': 3.0,
    'AR398-N': 2.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 2.0,
    'AR406-N': 3.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 4.0,
    'AR411-N': 3.0,
    'AR412-N': 4.0,
    'AR413-N': 2.0,
    'AR414-N': 4.0,
    'AR416-N': 4.0,
    'AR418-T': 2.0,
    'AR420-N': 3.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 3.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 2.0,
    'AR432-N': 2.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 4.0,
    'AR437-N': 3.0,
    'AR438-T': 3.0,
    'AR439-T': 2.0,
    'AR440-N': 3.0,
    'AR441-T': 3.0,
    'AR442-T': 4.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 3.0,
    'AR449-T': 2.0,
    'AR450-N': 3.0,
    'AR451-N': 4.0,
    'AR452-N': 2.0,
    'AR453-N': 3.0,
    'AR454-T': 1.0,
    'AR455-T': 4.0,
    'AR456-N': 4.0,
    'AR457-T': 3.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 2.0,
    'AR462-T': 2.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 4.0,
    'AR473-N': 2.0,
    'AR474-N': 3.0,
    'AR475-T': 2.0,
    'AR476-T': 2.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 3.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 1.0,
    'AR483-N': 4.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 4.0,
    'AR490-T': 1.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  CHLORINE: {
    'AR001-N': 3.0,
    'AR003-T': 2.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 3.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 1.0,
    'AR029-N': 3.0,
    'AR031-T': 2.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 4.0,
    'AR056-T': 1.0,
    'AR057-T': 4.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 2.0,
    'AR064-T': 1.0,
    'AR066-T': 2.0,
    'AR068-N': 3.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 3.0,
    'AR075-N': 2.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 4.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 4.0,
    'AR098-T': 2.0,
    'AR100-N': 3.0,
    'AR102-N': 4.0,
    'AR104-T': 1.0,
    'AR108-T': 2.0,
    'AR110-T': 4.0,
    'AR112-T': 2.0,
    'AR115-T': 1.0,
    'AR118-T': 4.0,
    'AR122-N': 2.0,
    'AR124-N': 2.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 2.0,
    'AR136-T': 1.0,
    'AR138-T': 2.0,
    'AR140-N': 1.0,
    'AR142-T': 2.0,
    'AR144-N': 4.0,
    'AR145-N': 3.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 1.0,
    'AR150-T': 3.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 1.0,
    'AR158-T': 4.0,
    'AR160-T': 1.0,
    'AR162-N': 4.0,
    'AR164-T': 2.0,
    'AR165-T': 3.0,
    'AR167-T': 1.0,
    'AR169-T': 3.0,
    'AR170-N': 3.0,
    'AR172-N': 3.0,
    'AR174-T': 4.0,
    'AR177-T': 4.0,
    'AR178-T': 4.0,
    'AR180-T': 4.0,
    'AR182-N': 1.0,
    'AR183-T': 1.0,
    'AR185-T': 4.0,
    'AR186-T': 2.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 4.0,
    'AR193-T': 4.0,
    'AR197-T': 2.0,
    'AR198-T': 4.0,
    'AR200-T': 1.0,
    'AR202-T': 2.0,
    'AR204-T': 2.0,
    'AR206-T': 4.0,
    'AR208-T': 1.0,
    'AR209-T': 3.0,
    'AR210-N': 1.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 4.0,
    'AR224-N': 3.0,
    'AR226-T': 1.0,
    'AR228-T': 2.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 4.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 2.0,
    'AR243-T': 1.0,
    'AR245-N': 2.0,
    'AR247-T': 4.0,
    'AR249-T': 1.0,
    'AR251-T': 2.0,
    'AR252-T': 3.0,
    'AR254-T': 1.0,
    'AR256-T': 1.0,
    'AR258-T': 3.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 1.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 3.0,
    'AR273-T': 2.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 4.0,
    'AR281-N': 3.0,
    'AR283-T': 4.0,
    'AR285-T': 2.0,
    'AR287-T': 1.0,
    'AR289-T': 4.0,
    'AR293-T': 2.0,
    'AR297-N': 2.0,
    'AR299-N': 3.0,
    'AR300-T': 4.0,
    'AR302-T': 2.0,
    'AR304-T': 4.0,
    'AR306-N': 3.0,
    'AR309-N': 2.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 3.0,
    'AR319-N': 2.0,
    'AR321-N': 1.0,
    'AR323-T': 4.0,
    'AR324-N': 1.0,
    'AR327-N': 1.0,
    'AR329-N': 4.0,
    'AR331-T': 3.0,
    'AR333-N': 1.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 2.0,
    'AR343-N': 3.0,
    'AR345-N': 1.0,
    'AR347-T': 4.0,
    'AR349-N': 3.0,
    'AR351-N': 3.0,
    'AR353-N': 3.0,
    'AR357-N': 4.0,
    'AR363-N': 1.0,
    'AR365-T': 2.0,
    'AR367-N': 4.0,
    'AR368-N': 2.0,
    'AR370-N': 2.0,
    'AR371-T': 3.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 2.0,
    'AR387-N': 2.0,
    'AR389-N': 2.0,
    'AR391-T': 2.0,
    'AR392-N': 4.0,
    'AR394-N': 1.0,
    'AR398-N': 2.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 4.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 3.0,
    'AR409-N': 1.0,
    'AR411-N': 4.0,
    'AR412-N': 1.0,
    'AR413-N': 2.0,
    'AR414-N': 1.0,
    'AR416-N': 1.0,
    'AR418-T': 3.0,
    'AR420-N': 2.0,
    'AR421-N': 3.0,
    'AR422-T': 3.0,
    'AR423-N': 2.0,
    'AR424-N': 3.0,
    'AR425-T': 4.0,
    'AR426-N': 2.0,
    'AR427-N': 3.0,
    'AR428-T': 3.0,
    'AR429-T': 4.0,
    'AR430-N': 3.0,
    'AR431-T': 4.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 2.0,
    'AR435-N': 3.0,
    'AR436-N': 1.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 4.0,
    'AR440-N': 4.0,
    'AR441-T': 2.0,
    'AR442-T': 2.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 1.0,
    'AR446-N': 3.0,
    'AR447-N': 3.0,
    'AR448-T': 4.0,
    'AR449-T': 2.0,
    'AR450-N': 2.0,
    'AR451-N': 2.0,
    'AR452-N': 3.0,
    'AR453-N': 2.0,
    'AR454-T': 3.0,
    'AR455-T': 2.0,
    'AR456-N': 1.0,
    'AR457-T': 2.0,
    'AR458-T': 1.0,
    'AR459-T': 2.0,
    'AR460-T': 4.0,
    'AR461-T': 4.0,
    'AR462-T': 4.0,
    'AR463-T': 3.0,
    'AR464-T': 3.0,
    'AR465-T': 3.0,
    'AR466-N': 3.0,
    'AR467-N': 3.0,
    'AR468-T': 2.0,
    'AR469-N': 2.0,
    'AR470-T': 4.0,
    'AR471-T': 2.0,
    'AR472-T': 4.0,
    'AR473-N': 2.0,
    'AR474-N': 1.0,
    'AR475-T': 4.0,
    'AR476-T': 4.0,
    'AR477-T': 2.0,
    'AR478-T': 3.0,
    'AR479-T': 3.0,
    'AR480-N': 3.0,
    'AR481-': 3.0,
    'AR482-T': 4.0,
    'AR483-N': 1.0,
    'AR484-T': 3.0,
    'AR485-N': 3.0,
    'AR486-N': 3.0,
    'AR488-N': 2.0,
    'AR489-T': 1.0,
    'AR490-T': 4.0,
    'AR491-N': 3.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'CHLOROACETIC-ACID': {
    'AR001-N': 4.0,
    'AR003-T': 3.0,
    'AR008-N': 4.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 3.0,
    'AR019-N': 2.0,
    'AR021-N': 3.0,
    'AR026-T': 4.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 4.0,
    'AR035-T': 1.0,
    'AR037-T': 3.0,
    'AR042-T': 4.0,
    'AR044-N': 3.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 3.0,
    'AR054-N': 3.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 3.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 3.0,
    'AR068-N': 3.0,
    'AR070-N': 1.0,
    'AR071-T': 4.0,
    'AR073-T': 2.0,
    'AR075-N': 2.0,
    'AR077-N': 1.0,
    'AR079-T': 4.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 3.0,
    'AR088-T': 3.0,
    'AR090-N': 1.0,
    'AR098-T': 2.0,
    'AR100-N': 4.0,
    'AR102-N': 1.0,
    'AR104-T': 1.0,
    'AR108-T': 2.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 1.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 3.0,
    'AR132-T': 3.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 2.0,
    'AR142-T': 3.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 1.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 4.0,
    'AR165-T': 3.0,
    'AR167-T': 3.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 1.0,
    'AR185-T': 1.0,
    'AR186-T': 4.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 3.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 2.0,
    'AR204-T': 4.0,
    'AR206-T': 1.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 4.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 3.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 1.0,
    'AR230-N': 3.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 4.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 4.0,
    'AR243-T': 3.0,
    'AR245-N': 4.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 2.0,
    'AR252-T': 1.0,
    'AR254-T': 3.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 1.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 3.0,
    'AR273-T': 3.0,
    'AR275-T': 2.0,
    'AR277-T': 4.0,
    'AR279-T': 1.0,
    'AR281-N': 4.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 2.0,
    'AR297-N': 3.0,
    'AR299-N': 4.0,
    'AR300-T': 1.0,
    'AR302-T': 3.0,
    'AR304-T': 3.0,
    'AR306-N': 1.0,
    'AR309-N': 2.0,
    'AR311-N': 4.0,
    'AR313-N': 4.0,
    'AR315-N': 4.0,
    'AR319-N': 2.0,
    'AR321-N': 3.0,
    'AR323-T': 1.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 2.0,
    'AR331-T': 4.0,
    'AR333-N': 3.0,
    'AR335-N': 4.0,
    'AR339-N': 4.0,
    'AR341-N': 3.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 4.0,
    'AR357-N': 1.0,
    'AR363-N': 1.0,
    'AR365-T': 3.0,
    'AR367-N': 4.0,
    'AR368-N': 3.0,
    'AR370-N': 3.0,
    'AR371-T': 1.0,
    'AR372-N': 4.0,
    'AR373-N': 4.0,
    'AR374-N': 3.0,
    'AR376-N': 3.0,
    'AR381-N': 4.0,
    'AR382-N': 4.0,
    'AR384-N': 4.0,
    'AR387-N': 3.0,
    'AR389-N': 2.0,
    'AR391-T': 4.0,
    'AR392-N': 3.0,
    'AR394-N': 1.0,
    'AR398-N': 4.0,
    'AR400-T': 4.0,
    'AR402-N': 4.0,
    'AR404-N': 3.0,
    'AR406-N': 3.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 3.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 1.0,
    'AR420-N': 4.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 4.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 2.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 4.0,
    'AR433-T': 4.0,
    'AR434-N': 3.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 4.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 3.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 1.0,
    'AR446-N': 4.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 4.0,
    'AR450-N': 3.0,
    'AR451-N': 2.0,
    'AR452-N': 4.0,
    'AR453-N': 4.0,
    'AR454-T': 1.0,
    'AR455-T': 3.0,
    'AR456-N': 2.0,
    'AR457-T': 4.0,
    'AR458-T': 3.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 3.0,
    'AR465-T': 3.0,
    'AR466-N': 3.0,
    'AR467-N': 1.0,
    'AR468-T': 2.0,
    'AR469-N': 4.0,
    'AR470-T': 1.0,
    'AR471-T': 4.0,
    'AR472-T': 2.0,
    'AR473-N': 4.0,
    'AR474-N': 1.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 4.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 1.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  CHLOROBENZENE: {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 4.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 3.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 4.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 4.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 3.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 3.0,
    'AR070-N': 2.0,
    'AR071-T': 4.0,
    'AR073-T': 3.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 3.0,
    'AR090-N': 2.0,
    'AR098-T': 3.0,
    'AR100-N': 4.0,
    'AR102-N': 1.0,
    'AR104-T': 2.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 1.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 1.0,
    'AR155-N': 2.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 4.0,
    'AR165-T': 3.0,
    'AR167-T': 2.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 2.0,
    'AR185-T': 2.0,
    'AR186-T': 3.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 4.0,
    'AR198-T': 2.0,
    'AR200-T': 2.0,
    'AR202-T': 3.0,
    'AR204-T': 4.0,
    'AR206-T': 2.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 4.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 2.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 4.0,
    'AR279-T': 1.0,
    'AR281-N': 4.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 2.0,
    'AR302-T': 4.0,
    'AR304-T': 3.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 3.0,
    'AR313-N': 4.0,
    'AR315-N': 4.0,
    'AR319-N': 3.0,
    'AR321-N': 3.0,
    'AR323-T': 2.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 4.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 3.0,
    'AR353-N': 4.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 4.0,
    'AR373-N': 4.0,
    'AR374-N': 4.0,
    'AR376-N': 3.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 3.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 3.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 2.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 4.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 3.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 4.0,
    'AR443-T': 2.0,
    'AR444-T': 2.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 4.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 1.0,
    'AR455-T': 4.0,
    'AR456-N': 2.0,
    'AR457-T': 3.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 3.0,
    'AR467-N': 1.0,
    'AR468-T': 3.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 4.0,
    'AR478-T': 1.0,
    'AR479-T': 3.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 2.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  CHLOROFORM: {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 4.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 2.0,
    'AR021-N': 3.0,
    'AR026-T': 4.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 4.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 3.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 3.0,
    'AR054-N': 4.0,
    'AR056-T': 3.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 3.0,
    'AR070-N': 2.0,
    'AR071-T': 4.0,
    'AR073-T': 3.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 4.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 3.0,
    'AR088-T': 3.0,
    'AR090-N': 1.0,
    'AR098-T': 3.0,
    'AR100-N': 4.0,
    'AR102-N': 1.0,
    'AR104-T': 2.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 1.0,
    'AR118-T': 1.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 1.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 1.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 3.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 2.0,
    'AR185-T': 1.0,
    'AR186-T': 2.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 4.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 3.0,
    'AR204-T': 4.0,
    'AR206-T': 1.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 1.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 4.0,
    'AR237-T': 2.0,
    'AR238-T': 1.0,
    'AR239-T': 3.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 2.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 4.0,
    'AR279-T': 1.0,
    'AR281-N': 4.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 3.0,
    'AR289-T': 1.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 1.0,
    'AR302-T': 4.0,
    'AR304-T': 3.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 3.0,
    'AR313-N': 4.0,
    'AR315-N': 4.0,
    'AR319-N': 3.0,
    'AR321-N': 3.0,
    'AR323-T': 1.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 4.0,
    'AR335-N': 4.0,
    'AR339-N': 4.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 2.0,
    'AR351-N': 3.0,
    'AR353-N': 4.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 4.0,
    'AR373-N': 4.0,
    'AR374-N': 4.0,
    'AR376-N': 3.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 3.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 3.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 3.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 2.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 2.0,
    'AR425-T': 4.0,
    'AR426-N': 3.0,
    'AR427-N': 2.0,
    'AR428-T': 2.0,
    'AR429-T': 1.0,
    'AR430-N': 2.0,
    'AR431-T': 1.0,
    'AR432-N': 4.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 3.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 4.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 2.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 1.0,
    'AR455-T': 4.0,
    'AR456-N': 2.0,
    'AR457-T': 3.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 3.0,
    'AR467-N': 2.0,
    'AR468-T': 3.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 4.0,
    'AR478-T': 2.0,
    'AR479-T': 3.0,
    'AR480-N': 2.0,
    'AR481-': 2.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 2.0,
    'AR485-N': 2.0,
    'AR486-N': 2.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 1.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  CHLOROPRENE: {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 3.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 1.0,
    'AR098-T': 4.0,
    'AR100-N': 3.0,
    'AR102-N': 1.0,
    'AR104-T': 2.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 1.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 1.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 3.0,
    'AR169-T': 2.0,
    'AR170-N': 4.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 2.0,
    'AR182-N': 3.0,
    'AR183-T': 2.0,
    'AR185-T': 1.0,
    'AR186-T': 3.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 3.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 1.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 2.0,
    'AR224-N': 2.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 1.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 3.0,
    'AR237-T': 2.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 4.0,
    'AR249-T': 2.0,
    'AR251-T': 4.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 2.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 1.0,
    'AR302-T': 3.0,
    'AR304-T': 4.0,
    'AR306-N': 2.0,
    'AR309-N': 4.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 2.0,
    'AR323-T': 1.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 4.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 2.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 4.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 3.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 2.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 2.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 3.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 4.0,
    'AR446-N': 4.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 2.0,
    'AR455-T': 3.0,
    'AR456-N': 2.0,
    'AR457-T': 3.0,
    'AR458-T': 3.0,
    'AR459-T': 2.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 3.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 4.0,
    'AR473-N': 3.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 2.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 2.0,
    'AR486-N': 2.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 1.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'COBALT-COMPOUNDS': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 4.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 4.0,
    'AR028-N': 3.0,
    'AR029-N': 4.0,
    'AR031-T': 1.0,
    'AR033-N': 4.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 1.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 1.0,
    'AR071-T': 4.0,
    'AR073-T': 3.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 3.0,
    'AR098-T': 2.0,
    'AR100-N': 4.0,
    'AR102-N': 2.0,
    'AR104-T': 1.0,
    'AR108-T': 2.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 3.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 4.0,
    'AR154-T': 3.0,
    'AR155-N': 2.0,
    'AR157-N': 4.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 1.0,
    'AR167-T': 3.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 2.0,
    'AR182-N': 3.0,
    'AR183-T': 1.0,
    'AR185-T': 2.0,
    'AR186-T': 3.0,
    'AR187-T': 3.0,
    'AR188-T': 3.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 3.0,
    'AR198-T': 2.0,
    'AR200-T': 1.0,
    'AR202-T': 3.0,
    'AR204-T': 3.0,
    'AR206-T': 3.0,
    'AR208-T': 1.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 1.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 3.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 3.0,
    'AR233-T': 4.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 2.0,
    'AR249-T': 1.0,
    'AR251-T': 2.0,
    'AR252-T': 2.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 3.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 2.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 3.0,
    'AR275-T': 1.0,
    'AR277-T': 4.0,
    'AR279-T': 1.0,
    'AR281-N': 4.0,
    'AR283-T': 2.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 2.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 3.0,
    'AR302-T': 3.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 4.0,
    'AR311-N': 3.0,
    'AR313-N': 4.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 3.0,
    'AR323-T': 3.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 4.0,
    'AR333-N': 3.0,
    'AR335-N': 4.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 4.0,
    'AR347-T': 3.0,
    'AR349-N': 2.0,
    'AR351-N': 4.0,
    'AR353-N': 4.0,
    'AR357-N': 2.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 4.0,
    'AR373-N': 4.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 2.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 2.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 2.0,
    'AR422-T': 2.0,
    'AR423-N': 2.0,
    'AR424-N': 2.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 2.0,
    'AR428-T': 2.0,
    'AR429-T': 2.0,
    'AR430-N': 2.0,
    'AR431-T': 2.0,
    'AR432-N': 4.0,
    'AR433-T': 2.0,
    'AR434-N': 3.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 2.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 2.0,
    'AR444-T': 3.0,
    'AR445-N': 1.0,
    'AR446-N': 4.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 2.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 1.0,
    'AR455-T': 3.0,
    'AR456-N': 3.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 1.0,
    'AR460-T': 2.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 1.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 2.0,
    'AR468-T': 3.0,
    'AR469-N': 3.0,
    'AR470-T': 2.0,
    'AR471-T': 3.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 1.0,
    'AR476-T': 2.0,
    'AR477-T': 3.0,
    'AR478-T': 2.0,
    'AR479-T': 4.0,
    'AR480-N': 2.0,
    'AR481-': 1.0,
    'AR482-T': 3.0,
    'AR483-N': 2.0,
    'AR484-T': 2.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 2.0,
    'AR490-T': 4.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'CRESOL_CRESYLIC-ACID--MIXED-ISOMERS-': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 4.0,
    'AR028-N': 1.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 4.0,
    'AR044-N': 3.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 4.0,
    'AR052-T': 3.0,
    'AR054-N': 4.0,
    'AR056-T': 3.0,
    'AR057-T': 1.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 3.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 3.0,
    'AR075-N': 2.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 4.0,
    'AR085-N': 2.0,
    'AR088-T': 3.0,
    'AR090-N': 1.0,
    'AR098-T': 3.0,
    'AR100-N': 4.0,
    'AR102-N': 2.0,
    'AR104-T': 4.0,
    'AR108-T': 3.0,
    'AR110-T': 4.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 1.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 4.0,
    'AR152-T': 2.0,
    'AR154-T': 2.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 2.0,
    'AR164-T': 4.0,
    'AR165-T': 2.0,
    'AR167-T': 3.0,
    'AR169-T': 4.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 2.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 2.0,
    'AR186-T': 2.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 4.0,
    'AR193-T': 3.0,
    'AR197-T': 4.0,
    'AR198-T': 1.0,
    'AR200-T': 1.0,
    'AR202-T': 3.0,
    'AR204-T': 3.0,
    'AR206-T': 1.0,
    'AR208-T': 1.0,
    'AR209-T': 2.0,
    'AR210-N': 3.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 1.0,
    'AR222-N': 4.0,
    'AR224-N': 4.0,
    'AR226-T': 2.0,
    'AR228-T': 4.0,
    'AR229-N': 1.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 4.0,
    'AR237-T': 4.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 1.0,
    'AR243-T': 3.0,
    'AR245-N': 1.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 1.0,
    'AR261-N': 1.0,
    'AR262-T': 4.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 2.0,
    'AR285-T': 4.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 1.0,
    'AR302-T': 3.0,
    'AR304-T': 3.0,
    'AR306-N': 4.0,
    'AR309-N': 3.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 3.0,
    'AR321-N': 3.0,
    'AR323-T': 1.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 4.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 2.0,
    'AR349-N': 2.0,
    'AR351-N': 3.0,
    'AR353-N': 4.0,
    'AR357-N': 2.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 1.0,
    'AR371-T': 4.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 3.0,
    'AR381-N': 1.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 2.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 1.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 4.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 2.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 2.0,
    'AR420-N': 1.0,
    'AR421-N': 2.0,
    'AR422-T': 2.0,
    'AR423-N': 2.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 3.0,
    'AR427-N': 2.0,
    'AR428-T': 2.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 2.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 2.0,
    'AR436-N': 1.0,
    'AR437-N': 2.0,
    'AR438-T': 2.0,
    'AR439-T': 3.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 1.0,
    'AR446-N': 3.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 2.0,
    'AR452-N': 4.0,
    'AR453-N': 2.0,
    'AR454-T': 4.0,
    'AR455-T': 3.0,
    'AR456-N': 1.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 4.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 2.0,
    'AR463-T': 2.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 3.0,
    'AR469-N': 2.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 4.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 1.0,
    'AR476-T': 2.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 3.0,
    'AR480-N': 2.0,
    'AR481-': 1.0,
    'AR482-T': 1.0,
    'AR483-N': 2.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 4.0,
    'AR489-T': 3.0,
    'AR490-T': 2.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  CUMENE: {
    'AR001-N': 3.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 3.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 4.0,
    'AR044-N': 3.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 3.0,
    'AR057-T': 4.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 2.0,
    'AR098-T': 3.0,
    'AR100-N': 3.0,
    'AR102-N': 2.0,
    'AR104-T': 1.0,
    'AR108-T': 2.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 3.0,
    'AR154-T': 2.0,
    'AR155-N': 2.0,
    'AR157-N': 4.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 2.0,
    'AR169-T': 1.0,
    'AR170-N': 4.0,
    'AR172-N': 3.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 1.0,
    'AR185-T': 2.0,
    'AR186-T': 4.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 3.0,
    'AR198-T': 2.0,
    'AR200-T': 2.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 2.0,
    'AR208-T': 1.0,
    'AR209-T': 1.0,
    'AR210-N': 4.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 2.0,
    'AR220-T': 1.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 4.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 2.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 2.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 2.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 3.0,
    'AR300-T': 2.0,
    'AR302-T': 4.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 4.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 4.0,
    'AR323-T': 2.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 4.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 3.0,
    'AR345-N': 4.0,
    'AR347-T': 2.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 4.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 2.0,
    'AR430-N': 1.0,
    'AR431-T': 2.0,
    'AR432-N': 3.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 3.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 4.0,
    'AR443-T': 2.0,
    'AR444-T': 2.0,
    'AR445-N': 1.0,
    'AR446-N': 4.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 3.0,
    'AR453-N': 3.0,
    'AR454-T': 2.0,
    'AR455-T': 4.0,
    'AR456-N': 2.0,
    'AR457-T': 3.0,
    'AR458-T': 3.0,
    'AR459-T': 1.0,
    'AR460-T': 2.0,
    'AR461-T': 1.0,
    'AR462-T': 2.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 2.0,
    'AR471-T': 4.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 1.0,
    'AR476-T': 2.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 2.0,
    'AR481-': 2.0,
    'AR482-T': 2.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 2.0,
    'AR486-N': 2.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 4.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'CYANIDE-COMPOUNDS': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 4.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 3.0,
    'AR073-T': 3.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 2.0,
    'AR098-T': 3.0,
    'AR100-N': 4.0,
    'AR102-N': 2.0,
    'AR104-T': 2.0,
    'AR108-T': 4.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 1.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 1.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 3.0,
    'AR169-T': 1.0,
    'AR170-N': 4.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 2.0,
    'AR185-T': 1.0,
    'AR186-T': 3.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 4.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 4.0,
    'AR204-T': 4.0,
    'AR206-T': 1.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 1.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 4.0,
    'AR237-T': 2.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 4.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 2.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 1.0,
    'AR302-T': 3.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 3.0,
    'AR323-T': 2.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 4.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 3.0,
    'AR389-N': 4.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 3.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 2.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 2.0,
    'AR432-N': 3.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 3.0,
    'AR439-T': 2.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 3.0,
    'AR443-T': 1.0,
    'AR444-T': 2.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 2.0,
    'AR455-T': 3.0,
    'AR456-N': 2.0,
    'AR457-T': 3.0,
    'AR458-T': 3.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 3.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 4.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 2.0,
    'AR475-T': 2.0,
    'AR476-T': 2.0,
    'AR477-T': 4.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 2.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 2.0,
    'AR486-N': 2.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 2.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  DIBUTYLPHTHALATE: {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 1.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 3.0,
    'AR098-T': 3.0,
    'AR100-N': 3.0,
    'AR102-N': 2.0,
    'AR104-T': 1.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 3.0,
    'AR154-T': 2.0,
    'AR155-N': 2.0,
    'AR157-N': 4.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 2.0,
    'AR169-T': 1.0,
    'AR170-N': 4.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 2.0,
    'AR182-N': 3.0,
    'AR183-T': 1.0,
    'AR185-T': 2.0,
    'AR186-T': 3.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 2.0,
    'AR193-T': 4.0,
    'AR197-T': 3.0,
    'AR198-T': 2.0,
    'AR200-T': 1.0,
    'AR202-T': 4.0,
    'AR204-T': 4.0,
    'AR206-T': 2.0,
    'AR208-T': 1.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 1.0,
    'AR222-N': 2.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 2.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 2.0,
    'AR281-N': 3.0,
    'AR283-T': 2.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 2.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 2.0,
    'AR302-T': 3.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 4.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 3.0,
    'AR323-T': 3.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 4.0,
    'AR331-T': 4.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 4.0,
    'AR347-T': 2.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 3.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 2.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 2.0,
    'AR430-N': 1.0,
    'AR431-T': 2.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 1.0,
    'AR438-T': 3.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 3.0,
    'AR443-T': 2.0,
    'AR444-T': 2.0,
    'AR445-N': 1.0,
    'AR446-N': 3.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 2.0,
    'AR455-T': 3.0,
    'AR456-N': 2.0,
    'AR457-T': 3.0,
    'AR458-T': 3.0,
    'AR459-T': 1.0,
    'AR460-T': 2.0,
    'AR461-T': 1.0,
    'AR462-T': 2.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 2.0,
    'AR471-T': 4.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 2.0,
    'AR476-T': 2.0,
    'AR477-T': 4.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 3.0,
    'AR482-T': 2.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 3.0,
    'AR486-N': 3.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 4.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'DICHLOROETHYL-ETHER--BIS-2-CHLOROETHYL-ETHER-': {
    'AR001-N': 3.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 3.0,
    'AR014-N': 3.0,
    'AR018-T': 3.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 1.0,
    'AR029-N': 3.0,
    'AR031-T': 4.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 3.0,
    'AR042-T': 3.0,
    'AR044-N': 2.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 1.0,
    'AR059-N': 3.0,
    'AR061-T': 3.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 3.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 4.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 3.0,
    'AR083-T': 1.0,
    'AR085-N': 3.0,
    'AR088-T': 3.0,
    'AR090-N': 2.0,
    'AR098-T': 4.0,
    'AR100-N': 3.0,
    'AR102-N': 2.0,
    'AR104-T': 1.0,
    'AR108-T': 4.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 3.0,
    'AR145-N': 1.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 2.0,
    'AR155-N': 2.0,
    'AR157-N': 4.0,
    'AR158-T': 3.0,
    'AR160-T': 4.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 4.0,
    'AR167-T': 3.0,
    'AR169-T': 1.0,
    'AR170-N': 4.0,
    'AR172-N': 3.0,
    'AR174-T': 3.0,
    'AR177-T': 2.0,
    'AR178-T': 3.0,
    'AR180-T': 1.0,
    'AR182-N': 4.0,
    'AR183-T': 1.0,
    'AR185-T': 2.0,
    'AR186-T': 2.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 1.0,
    'AR190-N': 3.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 4.0,
    'AR198-T': 2.0,
    'AR200-T': 1.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 2.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 4.0,
    'AR211-N': 2.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 3.0,
    'AR219-T': 1.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 3.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 1.0,
    'AR231-T': 3.0,
    'AR232-T': 2.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 4.0,
    'AR245-N': 2.0,
    'AR247-T': 4.0,
    'AR249-T': 2.0,
    'AR251-T': 4.0,
    'AR252-T': 2.0,
    'AR254-T': 1.0,
    'AR256-T': 4.0,
    'AR258-T': 1.0,
    'AR259-T': 2.0,
    'AR261-N': 3.0,
    'AR262-T': 1.0,
    'AR264-N': 4.0,
    'AR266-T': 4.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 3.0,
    'AR277-T': 3.0,
    'AR279-T': 2.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 1.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 3.0,
    'AR300-T': 2.0,
    'AR302-T': 4.0,
    'AR304-T': 3.0,
    'AR306-N': 1.0,
    'AR309-N': 4.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 3.0,
    'AR319-N': 4.0,
    'AR321-N': 3.0,
    'AR323-T': 2.0,
    'AR324-N': 3.0,
    'AR327-N': 1.0,
    'AR329-N': 4.0,
    'AR331-T': 3.0,
    'AR333-N': 4.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 3.0,
    'AR345-N': 4.0,
    'AR347-T': 2.0,
    'AR349-N': 2.0,
    'AR351-N': 3.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 2.0,
    'AR387-N': 3.0,
    'AR389-N': 4.0,
    'AR391-T': 2.0,
    'AR392-N': 4.0,
    'AR394-N': 1.0,
    'AR398-N': 2.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 4.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 4.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 3.0,
    'AR414-N': 4.0,
    'AR416-N': 4.0,
    'AR418-T': 2.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 2.0,
    'AR424-N': 2.0,
    'AR425-T': 3.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 2.0,
    'AR429-T': 2.0,
    'AR430-N': 2.0,
    'AR431-T': 2.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 3.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 2.0,
    'AR440-N': 3.0,
    'AR441-T': 3.0,
    'AR442-T': 4.0,
    'AR443-T': 2.0,
    'AR444-T': 2.0,
    'AR445-N': 1.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 4.0,
    'AR452-N': 3.0,
    'AR453-N': 2.0,
    'AR454-T': 2.0,
    'AR455-T': 4.0,
    'AR456-N': 1.0,
    'AR457-T': 2.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 2.0,
    'AR461-T': 2.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 4.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 2.0,
    'AR468-T': 4.0,
    'AR469-N': 2.0,
    'AR470-T': 2.0,
    'AR471-T': 3.0,
    'AR472-T': 4.0,
    'AR473-N': 2.0,
    'AR474-N': 1.0,
    'AR475-T': 2.0,
    'AR476-T': 2.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 3.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 2.0,
    'AR483-N': 3.0,
    'AR484-T': 2.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 4.0,
    'AR490-T': 2.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  DIETHANOLAMINE: {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 3.0,
    'AR014-N': 4.0,
    'AR018-T': 1.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 1.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 4.0,
    'AR044-N': 1.0,
    'AR046-N': 2.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 4.0,
    'AR056-T': 1.0,
    'AR057-T': 2.0,
    'AR059-N': 3.0,
    'AR061-T': 1.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 3.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 3.0,
    'AR075-N': 2.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 3.0,
    'AR083-T': 2.0,
    'AR085-N': 1.0,
    'AR088-T': 4.0,
    'AR090-N': 4.0,
    'AR098-T': 2.0,
    'AR100-N': 3.0,
    'AR102-N': 4.0,
    'AR104-T': 1.0,
    'AR108-T': 4.0,
    'AR110-T': 3.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 4.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 3.0,
    'AR145-N': 3.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 1.0,
    'AR150-T': 1.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 2.0,
    'AR158-T': 3.0,
    'AR160-T': 1.0,
    'AR162-N': 3.0,
    'AR164-T': 3.0,
    'AR165-T': 1.0,
    'AR167-T': 1.0,
    'AR169-T': 2.0,
    'AR170-N': 2.0,
    'AR172-N': 4.0,
    'AR174-T': 3.0,
    'AR177-T': 4.0,
    'AR178-T': 3.0,
    'AR180-T': 3.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 4.0,
    'AR186-T': 2.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 1.0,
    'AR190-N': 3.0,
    'AR192-T': 2.0,
    'AR193-T': 2.0,
    'AR197-T': 2.0,
    'AR198-T': 4.0,
    'AR200-T': 1.0,
    'AR202-T': 2.0,
    'AR204-T': 3.0,
    'AR206-T': 4.0,
    'AR208-T': 1.0,
    'AR209-T': 3.0,
    'AR210-N': 1.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 2.0,
    'AR224-N': 2.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 3.0,
    'AR232-T': 4.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 2.0,
    'AR243-T': 2.0,
    'AR245-N': 2.0,
    'AR247-T': 1.0,
    'AR249-T': 2.0,
    'AR251-T': 2.0,
    'AR252-T': 4.0,
    'AR254-T': 1.0,
    'AR256-T': 2.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 2.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 3.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 3.0,
    'AR281-N': 3.0,
    'AR283-T': 3.0,
    'AR285-T': 2.0,
    'AR287-T': 1.0,
    'AR289-T': 4.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 4.0,
    'AR302-T': 3.0,
    'AR304-T': 4.0,
    'AR306-N': 2.0,
    'AR309-N': 2.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 3.0,
    'AR321-N': 1.0,
    'AR323-T': 4.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 2.0,
    'AR331-T': 2.0,
    'AR333-N': 2.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 2.0,
    'AR347-T': 4.0,
    'AR349-N': 4.0,
    'AR351-N': 2.0,
    'AR353-N': 3.0,
    'AR357-N': 3.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 2.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 2.0,
    'AR381-N': 2.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 1.0,
    'AR389-N': 2.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 1.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 1.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 2.0,
    'AR409-N': 1.0,
    'AR411-N': 4.0,
    'AR412-N': 2.0,
    'AR413-N': 2.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 2.0,
    'AR420-N': 2.0,
    'AR421-N': 3.0,
    'AR422-T': 3.0,
    'AR423-N': 2.0,
    'AR424-N': 4.0,
    'AR425-T': 3.0,
    'AR426-N': 3.0,
    'AR427-N': 3.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 3.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 2.0,
    'AR436-N': 1.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 2.0,
    'AR440-N': 3.0,
    'AR441-T': 2.0,
    'AR442-T': 3.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 1.0,
    'AR446-N': 2.0,
    'AR447-N': 4.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 2.0,
    'AR452-N': 4.0,
    'AR453-N': 2.0,
    'AR454-T': 4.0,
    'AR455-T': 3.0,
    'AR456-N': 1.0,
    'AR457-T': 2.0,
    'AR458-T': 2.0,
    'AR459-T': 3.0,
    'AR460-T': 4.0,
    'AR461-T': 3.0,
    'AR462-T': 3.0,
    'AR463-T': 3.0,
    'AR464-T': 1.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 4.0,
    'AR468-T': 3.0,
    'AR469-N': 2.0,
    'AR470-T': 4.0,
    'AR471-T': 2.0,
    'AR472-T': 2.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 3.0,
    'AR476-T': 3.0,
    'AR477-T': 3.0,
    'AR478-T': 3.0,
    'AR479-T': 2.0,
    'AR480-N': 4.0,
    'AR481-': 1.0,
    'AR482-T': 4.0,
    'AR483-N': 1.0,
    'AR484-T': 4.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 2.0,
    'AR489-T': 2.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'DIMETHYL-FORMAMIDE': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 3.0,
    'AR073-T': 3.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 1.0,
    'AR098-T': 3.0,
    'AR100-N': 3.0,
    'AR102-N': 1.0,
    'AR104-T': 2.0,
    'AR108-T': 4.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 1.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 2.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 3.0,
    'AR169-T': 1.0,
    'AR170-N': 4.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 2.0,
    'AR185-T': 1.0,
    'AR186-T': 4.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 3.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 4.0,
    'AR204-T': 4.0,
    'AR206-T': 2.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 3.0,
    'AR237-T': 2.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 4.0,
    'AR245-N': 2.0,
    'AR247-T': 4.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 2.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 2.0,
    'AR302-T': 3.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 4.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 3.0,
    'AR323-T': 1.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 2.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 3.0,
    'AR389-N': 4.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 3.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 4.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 2.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 3.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 3.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 1.0,
    'AR455-T': 3.0,
    'AR456-N': 2.0,
    'AR457-T': 3.0,
    'AR458-T': 3.0,
    'AR459-T': 2.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 3.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 4.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 4.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 2.0,
    'AR482-T': 2.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 2.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 1.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'DIMETHYL-PHTHALATE': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 1.0,
    'AR029-N': 4.0,
    'AR031-T': 4.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 1.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 2.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 1.0,
    'AR088-T': 3.0,
    'AR090-N': 4.0,
    'AR098-T': 3.0,
    'AR100-N': 3.0,
    'AR102-N': 2.0,
    'AR104-T': 1.0,
    'AR108-T': 4.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 3.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 3.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 3.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 2.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 2.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 4.0,
    'AR186-T': 3.0,
    'AR187-T': 3.0,
    'AR188-T': 3.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 4.0,
    'AR198-T': 3.0,
    'AR200-T': 1.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 3.0,
    'AR208-T': 1.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 2.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 3.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 4.0,
    'AR252-T': 2.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 3.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 2.0,
    'AR281-N': 3.0,
    'AR283-T': 2.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 3.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 3.0,
    'AR302-T': 3.0,
    'AR304-T': 3.0,
    'AR306-N': 1.0,
    'AR309-N': 4.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 2.0,
    'AR323-T': 4.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 4.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 2.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 4.0,
    'AR349-N': 2.0,
    'AR351-N': 3.0,
    'AR353-N': 3.0,
    'AR357-N': 2.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 1.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 3.0,
    'AR381-N': 2.0,
    'AR382-N': 2.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 4.0,
    'AR391-T': 2.0,
    'AR392-N': 4.0,
    'AR394-N': 1.0,
    'AR398-N': 2.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 2.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 2.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 2.0,
    'AR422-T': 1.0,
    'AR423-N': 2.0,
    'AR424-N': 2.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 2.0,
    'AR428-T': 2.0,
    'AR429-T': 3.0,
    'AR430-N': 2.0,
    'AR431-T': 3.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 2.0,
    'AR436-N': 2.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 2.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 3.0,
    'AR444-T': 3.0,
    'AR445-N': 1.0,
    'AR446-N': 2.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 2.0,
    'AR450-N': 1.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 2.0,
    'AR455-T': 3.0,
    'AR456-N': 1.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 1.0,
    'AR460-T': 2.0,
    'AR461-T': 2.0,
    'AR462-T': 2.0,
    'AR463-T': 1.0,
    'AR464-T': 1.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 2.0,
    'AR468-T': 4.0,
    'AR469-N': 2.0,
    'AR470-T': 2.0,
    'AR471-T': 3.0,
    'AR472-T': 4.0,
    'AR473-N': 2.0,
    'AR474-N': 1.0,
    'AR475-T': 2.0,
    'AR476-T': 3.0,
    'AR477-T': 3.0,
    'AR478-T': 2.0,
    'AR479-T': 3.0,
    'AR480-N': 2.0,
    'AR481-': 4.0,
    'AR482-T': 2.0,
    'AR483-N': 3.0,
    'AR484-T': 2.0,
    'AR485-N': 4.0,
    'AR486-N': 4.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 4.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'DIMETHYL-SULFATE': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 1.0,
    'AR029-N': 4.0,
    'AR031-T': 3.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 3.0,
    'AR098-T': 3.0,
    'AR100-N': 3.0,
    'AR102-N': 3.0,
    'AR104-T': 1.0,
    'AR108-T': 4.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 3.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 2.0,
    'AR155-N': 2.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 3.0,
    'AR169-T': 1.0,
    'AR170-N': 4.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 2.0,
    'AR185-T': 2.0,
    'AR186-T': 3.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 4.0,
    'AR198-T': 2.0,
    'AR200-T': 3.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 2.0,
    'AR208-T': 1.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 4.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 2.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 2.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 2.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 2.0,
    'AR302-T': 3.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 4.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 3.0,
    'AR323-T': 3.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 2.0,
    'AR349-N': 2.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 4.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 1.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 2.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 2.0,
    'AR424-N': 2.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 2.0,
    'AR429-T': 2.0,
    'AR430-N': 2.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 2.0,
    'AR444-T': 3.0,
    'AR445-N': 1.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 2.0,
    'AR455-T': 3.0,
    'AR456-N': 1.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 2.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 3.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 2.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 4.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 2.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 2.0,
    'AR482-T': 2.0,
    'AR483-N': 3.0,
    'AR484-T': 2.0,
    'AR485-N': 2.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 3.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  EPICHLOROHYDRIN: {
    'AR001-N': 3.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 3.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 1.0,
    'AR029-N': 4.0,
    'AR031-T': 3.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 4.0,
    'AR044-N': 1.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 1.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 3.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 1.0,
    'AR088-T': 4.0,
    'AR090-N': 4.0,
    'AR098-T': 4.0,
    'AR100-N': 3.0,
    'AR102-N': 4.0,
    'AR104-T': 1.0,
    'AR108-T': 4.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 1.0,
    'AR118-T': 3.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 3.0,
    'AR154-T': 2.0,
    'AR155-N': 3.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 3.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 2.0,
    'AR169-T': 1.0,
    'AR170-N': 4.0,
    'AR172-N': 3.0,
    'AR174-T': 4.0,
    'AR177-T': 3.0,
    'AR178-T': 4.0,
    'AR180-T': 2.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 3.0,
    'AR186-T': 2.0,
    'AR187-T': 3.0,
    'AR188-T': 3.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 4.0,
    'AR198-T': 3.0,
    'AR200-T': 1.0,
    'AR202-T': 4.0,
    'AR204-T': 2.0,
    'AR206-T': 2.0,
    'AR208-T': 1.0,
    'AR209-T': 2.0,
    'AR210-N': 2.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 2.0,
    'AR220-T': 1.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 3.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 3.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 1.0,
    'AR243-T': 3.0,
    'AR245-N': 1.0,
    'AR247-T': 4.0,
    'AR249-T': 1.0,
    'AR251-T': 4.0,
    'AR252-T': 2.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 3.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 4.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 3.0,
    'AR281-N': 3.0,
    'AR283-T': 2.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 2.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 3.0,
    'AR300-T': 3.0,
    'AR302-T': 4.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 2.0,
    'AR323-T': 3.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 4.0,
    'AR331-T': 3.0,
    'AR333-N': 4.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 3.0,
    'AR345-N': 3.0,
    'AR347-T': 2.0,
    'AR349-N': 2.0,
    'AR351-N': 3.0,
    'AR353-N': 3.0,
    'AR357-N': 2.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 1.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 1.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 2.0,
    'AR389-N': 4.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 1.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 2.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 2.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 2.0,
    'AR420-N': 1.0,
    'AR421-N': 2.0,
    'AR422-T': 2.0,
    'AR423-N': 1.0,
    'AR424-N': 2.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 2.0,
    'AR428-T': 2.0,
    'AR429-T': 3.0,
    'AR430-N': 2.0,
    'AR431-T': 3.0,
    'AR432-N': 3.0,
    'AR433-T': 1.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 4.0,
    'AR440-N': 4.0,
    'AR441-T': 2.0,
    'AR442-T': 4.0,
    'AR443-T': 3.0,
    'AR444-T': 3.0,
    'AR445-N': 1.0,
    'AR446-N': 3.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 2.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 3.0,
    'AR453-N': 2.0,
    'AR454-T': 3.0,
    'AR455-T': 4.0,
    'AR456-N': 1.0,
    'AR457-T': 2.0,
    'AR458-T': 4.0,
    'AR459-T': 2.0,
    'AR460-T': 2.0,
    'AR461-T': 2.0,
    'AR462-T': 2.0,
    'AR463-T': 2.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 2.0,
    'AR468-T': 4.0,
    'AR469-N': 2.0,
    'AR470-T': 2.0,
    'AR471-T': 2.0,
    'AR472-T': 4.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 3.0,
    'AR476-T': 3.0,
    'AR477-T': 2.0,
    'AR478-T': 2.0,
    'AR479-T': 3.0,
    'AR480-N': 2.0,
    'AR481-': 2.0,
    'AR482-T': 2.0,
    'AR483-N': 2.0,
    'AR484-T': 2.0,
    'AR485-N': 2.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 4.0,
    'AR490-T': 4.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'ETHYL-ACRYLATE': {
    'AR001-N': 3.0,
    'AR003-T': 3.0,
    'AR008-N': 2.0,
    'AR012-T': 3.0,
    'AR014-N': 3.0,
    'AR018-T': 1.0,
    'AR019-N': 1.0,
    'AR021-N': 3.0,
    'AR026-T': 2.0,
    'AR028-N': 1.0,
    'AR029-N': 3.0,
    'AR031-T': 1.0,
    'AR033-N': 2.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 3.0,
    'AR044-N': 1.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 3.0,
    'AR052-T': 1.0,
    'AR054-N': 3.0,
    'AR056-T': 1.0,
    'AR057-T': 4.0,
    'AR059-N': 3.0,
    'AR061-T': 1.0,
    'AR063-T': 2.0,
    'AR064-T': 1.0,
    'AR066-T': 3.0,
    'AR068-N': 3.0,
    'AR070-N': 1.0,
    'AR071-T': 2.0,
    'AR073-T': 3.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 2.0,
    'AR081-T': 3.0,
    'AR083-T': 3.0,
    'AR085-N': 1.0,
    'AR088-T': 3.0,
    'AR090-N': 4.0,
    'AR098-T': 2.0,
    'AR100-N': 2.0,
    'AR102-N': 4.0,
    'AR104-T': 2.0,
    'AR108-T': 2.0,
    'AR110-T': 4.0,
    'AR112-T': 2.0,
    'AR115-T': 1.0,
    'AR118-T': 4.0,
    'AR122-N': 2.0,
    'AR124-N': 2.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 3.0,
    'AR136-T': 1.0,
    'AR138-T': 2.0,
    'AR140-N': 1.0,
    'AR142-T': 3.0,
    'AR144-N': 3.0,
    'AR145-N': 4.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 1.0,
    'AR150-T': 3.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 2.0,
    'AR158-T': 3.0,
    'AR160-T': 2.0,
    'AR162-N': 4.0,
    'AR164-T': 2.0,
    'AR165-T': 3.0,
    'AR167-T': 2.0,
    'AR169-T': 4.0,
    'AR170-N': 3.0,
    'AR172-N': 3.0,
    'AR174-T': 3.0,
    'AR177-T': 4.0,
    'AR178-T': 3.0,
    'AR180-T': 4.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 4.0,
    'AR186-T': 2.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 1.0,
    'AR190-N': 3.0,
    'AR192-T': 4.0,
    'AR193-T': 3.0,
    'AR197-T': 2.0,
    'AR198-T': 4.0,
    'AR200-T': 1.0,
    'AR202-T': 3.0,
    'AR204-T': 2.0,
    'AR206-T': 4.0,
    'AR208-T': 1.0,
    'AR209-T': 4.0,
    'AR210-N': 2.0,
    'AR211-N': 1.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 2.0,
    'AR220-T': 1.0,
    'AR222-N': 4.0,
    'AR224-N': 4.0,
    'AR226-T': 1.0,
    'AR228-T': 4.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 3.0,
    'AR232-T': 4.0,
    'AR233-T': 2.0,
    'AR237-T': 3.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 1.0,
    'AR243-T': 2.0,
    'AR245-N': 1.0,
    'AR247-T': 3.0,
    'AR249-T': 3.0,
    'AR251-T': 2.0,
    'AR252-T': 4.0,
    'AR254-T': 1.0,
    'AR256-T': 2.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 3.0,
    'AR264-N': 2.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 3.0,
    'AR273-T': 2.0,
    'AR275-T': 1.0,
    'AR277-T': 2.0,
    'AR279-T': 4.0,
    'AR281-N': 2.0,
    'AR283-T': 4.0,
    'AR285-T': 3.0,
    'AR287-T': 1.0,
    'AR289-T': 4.0,
    'AR293-T': 3.0,
    'AR297-N': 3.0,
    'AR299-N': 3.0,
    'AR300-T': 4.0,
    'AR302-T': 2.0,
    'AR304-T': 3.0,
    'AR306-N': 4.0,
    'AR309-N': 3.0,
    'AR311-N': 1.0,
    'AR313-N': 2.0,
    'AR315-N': 3.0,
    'AR319-N': 3.0,
    'AR321-N': 1.0,
    'AR323-T': 4.0,
    'AR324-N': 1.0,
    'AR327-N': 1.0,
    'AR329-N': 2.0,
    'AR331-T': 2.0,
    'AR333-N': 2.0,
    'AR335-N': 2.0,
    'AR339-N': 2.0,
    'AR341-N': 3.0,
    'AR343-N': 3.0,
    'AR345-N': 2.0,
    'AR347-T': 4.0,
    'AR349-N': 4.0,
    'AR351-N': 2.0,
    'AR353-N': 2.0,
    'AR357-N': 4.0,
    'AR363-N': 1.0,
    'AR365-T': 3.0,
    'AR367-N': 3.0,
    'AR368-N': 3.0,
    'AR370-N': 1.0,
    'AR371-T': 4.0,
    'AR372-N': 2.0,
    'AR373-N': 2.0,
    'AR374-N': 3.0,
    'AR376-N': 3.0,
    'AR381-N': 1.0,
    'AR382-N': 1.0,
    'AR384-N': 2.0,
    'AR387-N': 1.0,
    'AR389-N': 2.0,
    'AR391-T': 2.0,
    'AR392-N': 3.0,
    'AR394-N': 1.0,
    'AR398-N': 1.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 3.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 4.0,
    'AR409-N': 2.0,
    'AR411-N': 3.0,
    'AR412-N': 2.0,
    'AR413-N': 1.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 4.0,
    'AR420-N': 1.0,
    'AR421-N': 4.0,
    'AR422-T': 4.0,
    'AR423-N': 1.0,
    'AR424-N': 4.0,
    'AR425-T': 3.0,
    'AR426-N': 3.0,
    'AR427-N': 4.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 4.0,
    'AR432-N': 2.0,
    'AR433-T': 1.0,
    'AR434-N': 3.0,
    'AR435-N': 4.0,
    'AR436-N': 1.0,
    'AR437-N': 3.0,
    'AR438-T': 1.0,
    'AR439-T': 4.0,
    'AR440-N': 3.0,
    'AR441-T': 2.0,
    'AR442-T': 2.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 4.0,
    'AR448-T': 3.0,
    'AR449-T': 2.0,
    'AR450-N': 1.0,
    'AR451-N': 2.0,
    'AR452-N': 3.0,
    'AR453-N': 1.0,
    'AR454-T': 4.0,
    'AR455-T': 2.0,
    'AR456-N': 1.0,
    'AR457-T': 1.0,
    'AR458-T': 2.0,
    'AR459-T': 4.0,
    'AR460-T': 4.0,
    'AR461-T': 4.0,
    'AR462-T': 4.0,
    'AR463-T': 4.0,
    'AR464-T': 3.0,
    'AR465-T': 3.0,
    'AR466-N': 3.0,
    'AR467-N': 4.0,
    'AR468-T': 3.0,
    'AR469-N': 1.0,
    'AR470-T': 4.0,
    'AR471-T': 2.0,
    'AR472-T': 2.0,
    'AR473-N': 2.0,
    'AR474-N': 1.0,
    'AR475-T': 4.0,
    'AR476-T': 4.0,
    'AR477-T': 2.0,
    'AR478-T': 4.0,
    'AR479-T': 2.0,
    'AR480-N': 4.0,
    'AR481-': 1.0,
    'AR482-T': 4.0,
    'AR483-N': 2.0,
    'AR484-T': 4.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 4.0,
    'AR489-T': 2.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'ETHYL-CHLORIDE': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 1.0,
    'AR098-T': 3.0,
    'AR100-N': 3.0,
    'AR102-N': 2.0,
    'AR104-T': 2.0,
    'AR108-T': 4.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 1.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 3.0,
    'AR169-T': 1.0,
    'AR170-N': 4.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 2.0,
    'AR185-T': 2.0,
    'AR186-T': 4.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 2.0,
    'AR193-T': 4.0,
    'AR197-T': 4.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 4.0,
    'AR204-T': 4.0,
    'AR206-T': 1.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 1.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 3.0,
    'AR237-T': 2.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 4.0,
    'AR249-T': 2.0,
    'AR251-T': 4.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 2.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 2.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 2.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 1.0,
    'AR302-T': 3.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 4.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 3.0,
    'AR323-T': 1.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 3.0,
    'AR389-N': 4.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 3.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 2.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 3.0,
    'AR439-T': 2.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 3.0,
    'AR443-T': 1.0,
    'AR444-T': 2.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 1.0,
    'AR455-T': 3.0,
    'AR456-N': 2.0,
    'AR457-T': 3.0,
    'AR458-T': 3.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 2.0,
    'AR462-T': 2.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 4.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 2.0,
    'AR475-T': 2.0,
    'AR476-T': 1.0,
    'AR477-T': 4.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 2.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 2.0,
    'AR486-N': 2.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 1.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  ETHYLBENZENE: {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 1.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 1.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 2.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 1.0,
    'AR088-T': 4.0,
    'AR090-N': 3.0,
    'AR098-T': 3.0,
    'AR100-N': 3.0,
    'AR102-N': 2.0,
    'AR104-T': 1.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 3.0,
    'AR154-T': 1.0,
    'AR155-N': 3.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 2.0,
    'AR169-T': 2.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 2.0,
    'AR182-N': 3.0,
    'AR183-T': 1.0,
    'AR185-T': 2.0,
    'AR186-T': 3.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 4.0,
    'AR198-T': 3.0,
    'AR200-T': 1.0,
    'AR202-T': 3.0,
    'AR204-T': 4.0,
    'AR206-T': 2.0,
    'AR208-T': 1.0,
    'AR209-T': 3.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 1.0,
    'AR222-N': 2.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 3.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 2.0,
    'AR252-T': 2.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 2.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 2.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 1.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 2.0,
    'AR302-T': 4.0,
    'AR304-T': 4.0,
    'AR306-N': 2.0,
    'AR309-N': 3.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 3.0,
    'AR321-N': 3.0,
    'AR323-T': 3.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 4.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 2.0,
    'AR351-N': 3.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 1.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 3.0,
    'AR389-N': 2.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 2.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 3.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 3.0,
    'AR423-N': 3.0,
    'AR424-N': 2.0,
    'AR425-T': 4.0,
    'AR426-N': 3.0,
    'AR427-N': 1.0,
    'AR428-T': 2.0,
    'AR429-T': 3.0,
    'AR430-N': 2.0,
    'AR431-T': 2.0,
    'AR432-N': 3.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 2.0,
    'AR436-N': 2.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 4.0,
    'AR443-T': 3.0,
    'AR444-T': 2.0,
    'AR445-N': 1.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 2.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 2.0,
    'AR455-T': 4.0,
    'AR456-N': 2.0,
    'AR457-T': 2.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 2.0,
    'AR461-T': 2.0,
    'AR462-T': 1.0,
    'AR463-T': 3.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 2.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 2.0,
    'AR471-T': 3.0,
    'AR472-T': 4.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 2.0,
    'AR476-T': 2.0,
    'AR477-T': 4.0,
    'AR478-T': 2.0,
    'AR479-T': 3.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 2.0,
    'AR483-N': 3.0,
    'AR484-T': 2.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 3.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'ETHYLENE-DIBROMIDE--DIBROMOETHANE-': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 3.0,
    'AR019-N': 3.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 3.0,
    'AR029-N': 4.0,
    'AR031-T': 4.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 4.0,
    'AR042-T': 4.0,
    'AR044-N': 3.0,
    'AR046-N': 2.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 3.0,
    'AR054-N': 4.0,
    'AR056-T': 3.0,
    'AR057-T': 4.0,
    'AR059-N': 4.0,
    'AR061-T': 3.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 2.0,
    'AR070-N': 3.0,
    'AR071-T': 3.0,
    'AR073-T': 3.0,
    'AR075-N': 4.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 3.0,
    'AR088-T': 2.0,
    'AR090-N': 2.0,
    'AR098-T': 4.0,
    'AR100-N': 3.0,
    'AR102-N': 2.0,
    'AR104-T': 2.0,
    'AR108-T': 2.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 3.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 4.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 3.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 1.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 4.0,
    'AR162-N': 2.0,
    'AR164-T': 4.0,
    'AR165-T': 2.0,
    'AR167-T': 4.0,
    'AR169-T': 1.0,
    'AR170-N': 4.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 2.0,
    'AR183-T': 2.0,
    'AR185-T': 2.0,
    'AR186-T': 3.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 3.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 4.0,
    'AR198-T': 1.0,
    'AR200-T': 3.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 1.0,
    'AR208-T': 4.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 3.0,
    'AR220-T': 4.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 1.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 3.0,
    'AR237-T': 2.0,
    'AR238-T': 1.0,
    'AR239-T': 3.0,
    'AR242-N': 2.0,
    'AR243-T': 2.0,
    'AR245-N': 2.0,
    'AR247-T': 4.0,
    'AR249-T': 2.0,
    'AR251-T': 4.0,
    'AR252-T': 1.0,
    'AR254-T': 4.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 2.0,
    'AR266-T': 4.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 4.0,
    'AR277-T': 3.0,
    'AR279-T': 2.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 3.0,
    'AR289-T': 1.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 1.0,
    'AR302-T': 3.0,
    'AR304-T': 2.0,
    'AR306-N': 1.0,
    'AR309-N': 2.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 3.0,
    'AR321-N': 3.0,
    'AR323-T': 2.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 4.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 2.0,
    'AR381-N': 2.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 3.0,
    'AR389-N': 4.0,
    'AR391-T': 2.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 2.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 3.0,
    'AR406-N': 3.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 2.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 2.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 3.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 2.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 3.0,
    'AR438-T': 2.0,
    'AR439-T': 2.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 3.0,
    'AR443-T': 1.0,
    'AR444-T': 2.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 2.0,
    'AR454-T': 2.0,
    'AR455-T': 3.0,
    'AR456-N': 3.0,
    'AR457-T': 2.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 2.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 3.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 2.0,
    'AR469-N': 2.0,
    'AR470-T': 1.0,
    'AR471-T': 4.0,
    'AR472-T': 4.0,
    'AR473-N': 2.0,
    'AR474-N': 3.0,
    'AR475-T': 2.0,
    'AR476-T': 2.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 1.0,
    'AR483-N': 2.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 2.0,
    'AR490-T': 2.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'ETHYLENE-DICHLORIDE--1-2-DICHLOROETHANE-': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 4.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 3.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 4.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 4.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 3.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 3.0,
    'AR057-T': 3.0,
    'AR059-N': 4.0,
    'AR061-T': 3.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 4.0,
    'AR073-T': 3.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 3.0,
    'AR088-T': 3.0,
    'AR090-N': 2.0,
    'AR098-T': 3.0,
    'AR100-N': 3.0,
    'AR102-N': 2.0,
    'AR104-T': 2.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 1.0,
    'AR118-T': 1.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 3.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 4.0,
    'AR154-T': 1.0,
    'AR155-N': 2.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 3.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 3.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 2.0,
    'AR186-T': 4.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 4.0,
    'AR198-T': 2.0,
    'AR200-T': 2.0,
    'AR202-T': 3.0,
    'AR204-T': 3.0,
    'AR206-T': 2.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 3.0,
    'AR237-T': 2.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 2.0,
    'AR245-N': 2.0,
    'AR247-T': 4.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 2.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 4.0,
    'AR279-T': 1.0,
    'AR281-N': 4.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 2.0,
    'AR302-T': 4.0,
    'AR304-T': 3.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 3.0,
    'AR313-N': 4.0,
    'AR315-N': 4.0,
    'AR319-N': 3.0,
    'AR321-N': 3.0,
    'AR323-T': 3.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 4.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 3.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 4.0,
    'AR373-N': 4.0,
    'AR374-N': 4.0,
    'AR376-N': 3.0,
    'AR381-N': 2.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 3.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 2.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 2.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 4.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 2.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 4.0,
    'AR443-T': 2.0,
    'AR444-T': 1.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 2.0,
    'AR454-T': 1.0,
    'AR455-T': 4.0,
    'AR456-N': 4.0,
    'AR457-T': 2.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 3.0,
    'AR469-N': 2.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 2.0,
    'AR483-N': 2.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 4.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'ETHYLENE-GLYCOL': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 3.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 3.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 3.0,
    'AR090-N': 2.0,
    'AR098-T': 3.0,
    'AR100-N': 3.0,
    'AR102-N': 2.0,
    'AR104-T': 2.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 1.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 4.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 2.0,
    'AR169-T': 1.0,
    'AR170-N': 4.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 2.0,
    'AR185-T': 1.0,
    'AR186-T': 3.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 3.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 1.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 3.0,
    'AR237-T': 2.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 2.0,
    'AR261-N': 2.0,
    'AR262-T': 2.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 4.0,
    'AR289-T': 1.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 1.0,
    'AR302-T': 3.0,
    'AR304-T': 3.0,
    'AR306-N': 1.0,
    'AR309-N': 4.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 4.0,
    'AR323-T': 2.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 2.0,
    'AR331-T': 4.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 4.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 2.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 4.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 4.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 3.0,
    'AR439-T': 2.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 3.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 2.0,
    'AR455-T': 3.0,
    'AR456-N': 2.0,
    'AR457-T': 3.0,
    'AR458-T': 3.0,
    'AR459-T': 2.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 4.0,
    'AR472-T': 2.0,
    'AR473-N': 3.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 4.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 4.0,
    'AR486-N': 4.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 2.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'ETHYLENE-OXIDE': {
    'AR001-N': 4.0,
    'AR003-T': 3.0,
    'AR008-N': 4.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 3.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 4.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 4.0,
    'AR035-T': 1.0,
    'AR037-T': 3.0,
    'AR042-T': 4.0,
    'AR044-N': 3.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 3.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 3.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 3.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 4.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 4.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 3.0,
    'AR090-N': 1.0,
    'AR098-T': 3.0,
    'AR100-N': 4.0,
    'AR102-N': 1.0,
    'AR104-T': 2.0,
    'AR108-T': 4.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 1.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 2.0,
    'AR132-T': 3.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 3.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 1.0,
    'AR154-T': 2.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 3.0,
    'AR162-N': 1.0,
    'AR164-T': 4.0,
    'AR165-T': 3.0,
    'AR167-T': 4.0,
    'AR169-T': 1.0,
    'AR170-N': 4.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 2.0,
    'AR183-T': 2.0,
    'AR185-T': 2.0,
    'AR186-T': 3.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 4.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 2.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 3.0,
    'AR222-N': 1.0,
    'AR224-N': 2.0,
    'AR226-T': 2.0,
    'AR228-T': 2.0,
    'AR229-N': 1.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 4.0,
    'AR237-T': 2.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 2.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 3.0,
    'AR277-T': 4.0,
    'AR279-T': 1.0,
    'AR281-N': 4.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 2.0,
    'AR302-T': 3.0,
    'AR304-T': 3.0,
    'AR306-N': 2.0,
    'AR309-N': 3.0,
    'AR311-N': 3.0,
    'AR313-N': 4.0,
    'AR315-N': 4.0,
    'AR319-N': 3.0,
    'AR321-N': 4.0,
    'AR323-T': 1.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 4.0,
    'AR339-N': 4.0,
    'AR341-N': 3.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 2.0,
    'AR349-N': 1.0,
    'AR351-N': 3.0,
    'AR353-N': 4.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 3.0,
    'AR367-N': 4.0,
    'AR368-N': 3.0,
    'AR370-N': 2.0,
    'AR371-T': 2.0,
    'AR372-N': 4.0,
    'AR373-N': 4.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 2.0,
    'AR384-N': 3.0,
    'AR387-N': 3.0,
    'AR389-N': 4.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 2.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 4.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 4.0,
    'AR433-T': 4.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 2.0,
    'AR439-T': 2.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 1.0,
    'AR455-T': 3.0,
    'AR456-N': 2.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 2.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 2.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 3.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 2.0,
    'AR483-N': 2.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 1.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'ETHYLIDENE-DICHLORIDE--1-1-DICHLOROETHANE-': {
    'AR001-N': 3.0,
    'AR003-T': 3.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 3.0,
    'AR018-T': 4.0,
    'AR019-N': 3.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 4.0,
    'AR029-N': 3.0,
    'AR031-T': 3.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 4.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 3.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 3.0,
    'AR057-T': 1.0,
    'AR059-N': 4.0,
    'AR061-T': 4.0,
    'AR063-T': 2.0,
    'AR064-T': 1.0,
    'AR066-T': 3.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 3.0,
    'AR073-T': 2.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 2.0,
    'AR085-N': 4.0,
    'AR088-T': 4.0,
    'AR090-N': 1.0,
    'AR098-T': 4.0,
    'AR100-N': 3.0,
    'AR102-N': 1.0,
    'AR104-T': 3.0,
    'AR108-T': 2.0,
    'AR110-T': 1.0,
    'AR112-T': 2.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 2.0,
    'AR124-N': 2.0,
    'AR128-T': 1.0,
    'AR130-T': 4.0,
    'AR132-T': 3.0,
    'AR136-T': 1.0,
    'AR138-T': 2.0,
    'AR140-N': 2.0,
    'AR142-T': 3.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 1.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 4.0,
    'AR162-N': 2.0,
    'AR164-T': 4.0,
    'AR165-T': 2.0,
    'AR167-T': 2.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 3.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 2.0,
    'AR185-T': 2.0,
    'AR186-T': 4.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 2.0,
    'AR193-T': 3.0,
    'AR197-T': 2.0,
    'AR198-T': 1.0,
    'AR200-T': 3.0,
    'AR202-T': 2.0,
    'AR204-T': 4.0,
    'AR206-T': 1.0,
    'AR208-T': 1.0,
    'AR209-T': 1.0,
    'AR210-N': 2.0,
    'AR211-N': 4.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 3.0,
    'AR219-T': 3.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 4.0,
    'AR228-T': 1.0,
    'AR229-N': 1.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 3.0,
    'AR237-T': 2.0,
    'AR238-T': 1.0,
    'AR239-T': 4.0,
    'AR242-N': 4.0,
    'AR243-T': 2.0,
    'AR245-N': 4.0,
    'AR247-T': 2.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 2.0,
    'AR258-T': 4.0,
    'AR259-T': 1.0,
    'AR261-N': 4.0,
    'AR262-T': 4.0,
    'AR264-N': 2.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 3.0,
    'AR273-T': 2.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 2.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 4.0,
    'AR289-T': 2.0,
    'AR293-T': 2.0,
    'AR297-N': 3.0,
    'AR299-N': 3.0,
    'AR300-T': 1.0,
    'AR302-T': 2.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 4.0,
    'AR313-N': 3.0,
    'AR315-N': 3.0,
    'AR319-N': 2.0,
    'AR321-N': 2.0,
    'AR323-T': 1.0,
    'AR324-N': 4.0,
    'AR327-N': 1.0,
    'AR329-N': 2.0,
    'AR331-T': 4.0,
    'AR333-N': 2.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 3.0,
    'AR343-N': 3.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 4.0,
    'AR365-T': 3.0,
    'AR367-N': 4.0,
    'AR368-N': 3.0,
    'AR370-N': 3.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 4.0,
    'AR382-N': 4.0,
    'AR384-N': 4.0,
    'AR387-N': 4.0,
    'AR389-N': 2.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 4.0,
    'AR398-N': 3.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 2.0,
    'AR406-N': 4.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 2.0,
    'AR411-N': 4.0,
    'AR412-N': 2.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 2.0,
    'AR420-N': 4.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 4.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 2.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 2.0,
    'AR432-N': 3.0,
    'AR433-T': 4.0,
    'AR434-N': 3.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 4.0,
    'AR438-T': 4.0,
    'AR439-T': 2.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 2.0,
    'AR443-T': 1.0,
    'AR444-T': 2.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 4.0,
    'AR451-N': 3.0,
    'AR452-N': 3.0,
    'AR453-N': 4.0,
    'AR454-T': 1.0,
    'AR455-T': 2.0,
    'AR456-N': 4.0,
    'AR457-T': 4.0,
    'AR458-T': 2.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 2.0,
    'AR462-T': 2.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 3.0,
    'AR466-N': 3.0,
    'AR467-N': 1.0,
    'AR468-T': 2.0,
    'AR469-N': 4.0,
    'AR470-T': 1.0,
    'AR471-T': 4.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 4.0,
    'AR475-T': 2.0,
    'AR476-T': 2.0,
    'AR477-T': 4.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 1.0,
    'AR483-N': 2.0,
    'AR484-T': 1.0,
    'AR485-N': 2.0,
    'AR486-N': 2.0,
    'AR488-N': 1.0,
    'AR489-T': 2.0,
    'AR490-T': 1.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  FORMALDEHYDE: {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 4.0,
    'AR028-N': 3.0,
    'AR029-N': 4.0,
    'AR031-T': 1.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 2.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 3.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 3.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 3.0,
    'AR090-N': 3.0,
    'AR098-T': 3.0,
    'AR100-N': 4.0,
    'AR102-N': 2.0,
    'AR104-T': 1.0,
    'AR108-T': 2.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 1.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 3.0,
    'AR154-T': 2.0,
    'AR155-N': 3.0,
    'AR157-N': 4.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 2.0,
    'AR164-T': 4.0,
    'AR165-T': 2.0,
    'AR167-T': 2.0,
    'AR169-T': 2.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 3.0,
    'AR178-T': 4.0,
    'AR180-T': 2.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 2.0,
    'AR186-T': 1.0,
    'AR187-T': 3.0,
    'AR188-T': 3.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 4.0,
    'AR198-T': 3.0,
    'AR200-T': 1.0,
    'AR202-T': 3.0,
    'AR204-T': 4.0,
    'AR206-T': 3.0,
    'AR208-T': 1.0,
    'AR209-T': 3.0,
    'AR210-N': 3.0,
    'AR211-N': 1.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 2.0,
    'AR224-N': 2.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 3.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 3.0,
    'AR233-T': 4.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 1.0,
    'AR243-T': 3.0,
    'AR245-N': 1.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 3.0,
    'AR252-T': 3.0,
    'AR254-T': 1.0,
    'AR256-T': 4.0,
    'AR258-T': 1.0,
    'AR259-T': 3.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 4.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 2.0,
    'AR281-N': 3.0,
    'AR283-T': 2.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 2.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 3.0,
    'AR302-T': 4.0,
    'AR304-T': 3.0,
    'AR306-N': 2.0,
    'AR309-N': 3.0,
    'AR311-N': 1.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 3.0,
    'AR321-N': 3.0,
    'AR323-T': 3.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 2.0,
    'AR331-T': 2.0,
    'AR333-N': 4.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 4.0,
    'AR347-T': 2.0,
    'AR349-N': 2.0,
    'AR351-N': 2.0,
    'AR353-N': 4.0,
    'AR357-N': 2.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 1.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 3.0,
    'AR381-N': 1.0,
    'AR382-N': 1.0,
    'AR384-N': 1.0,
    'AR387-N': 2.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 1.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 2.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 2.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 1.0,
    'AR420-N': 1.0,
    'AR421-N': 2.0,
    'AR422-T': 4.0,
    'AR423-N': 1.0,
    'AR424-N': 3.0,
    'AR425-T': 4.0,
    'AR426-N': 3.0,
    'AR427-N': 2.0,
    'AR428-T': 2.0,
    'AR429-T': 3.0,
    'AR430-N': 3.0,
    'AR431-T': 2.0,
    'AR432-N': 3.0,
    'AR433-T': 1.0,
    'AR434-N': 4.0,
    'AR435-N': 2.0,
    'AR436-N': 1.0,
    'AR437-N': 1.0,
    'AR438-T': 1.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 2.0,
    'AR442-T': 4.0,
    'AR443-T': 3.0,
    'AR444-T': 2.0,
    'AR445-N': 1.0,
    'AR446-N': 3.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 1.0,
    'AR451-N': 2.0,
    'AR452-N': 4.0,
    'AR453-N': 1.0,
    'AR454-T': 2.0,
    'AR455-T': 4.0,
    'AR456-N': 3.0,
    'AR457-T': 1.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 3.0,
    'AR461-T': 2.0,
    'AR462-T': 1.0,
    'AR463-T': 3.0,
    'AR464-T': 1.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 3.0,
    'AR468-T': 3.0,
    'AR469-N': 1.0,
    'AR470-T': 3.0,
    'AR471-T': 2.0,
    'AR472-T': 2.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 2.0,
    'AR476-T': 2.0,
    'AR477-T': 4.0,
    'AR478-T': 3.0,
    'AR479-T': 2.0,
    'AR480-N': 2.0,
    'AR481-': 1.0,
    'AR482-T': 3.0,
    'AR483-N': 3.0,
    'AR484-T': 3.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 4.0,
    'AR490-T': 3.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'GLYCOL-ETHERS': {
    'AR001-N': 3.0,
    'AR003-T': 2.0,
    'AR008-N': 3.0,
    'AR012-T': 3.0,
    'AR014-N': 3.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 3.0,
    'AR026-T': 3.0,
    'AR028-N': 3.0,
    'AR029-N': 3.0,
    'AR031-T': 2.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 3.0,
    'AR044-N': 2.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 1.0,
    'AR054-N': 3.0,
    'AR056-T': 1.0,
    'AR057-T': 1.0,
    'AR059-N': 3.0,
    'AR061-T': 2.0,
    'AR063-T': 2.0,
    'AR064-T': 1.0,
    'AR066-T': 2.0,
    'AR068-N': 3.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 2.0,
    'AR075-N': 2.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 3.0,
    'AR083-T': 3.0,
    'AR085-N': 2.0,
    'AR088-T': 3.0,
    'AR090-N': 4.0,
    'AR098-T': 2.0,
    'AR100-N': 3.0,
    'AR102-N': 4.0,
    'AR104-T': 1.0,
    'AR108-T': 2.0,
    'AR110-T': 4.0,
    'AR112-T': 1.0,
    'AR115-T': 1.0,
    'AR118-T': 4.0,
    'AR122-N': 1.0,
    'AR124-N': 1.0,
    'AR128-T': 1.0,
    'AR130-T': 2.0,
    'AR132-T': 2.0,
    'AR136-T': 1.0,
    'AR138-T': 1.0,
    'AR140-N': 1.0,
    'AR142-T': 2.0,
    'AR144-N': 3.0,
    'AR145-N': 4.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 1.0,
    'AR150-T': 3.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 1.0,
    'AR158-T': 3.0,
    'AR160-T': 1.0,
    'AR162-N': 4.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 1.0,
    'AR169-T': 4.0,
    'AR170-N': 3.0,
    'AR172-N': 3.0,
    'AR174-T': 3.0,
    'AR177-T': 4.0,
    'AR178-T': 3.0,
    'AR180-T': 4.0,
    'AR182-N': 1.0,
    'AR183-T': 1.0,
    'AR185-T': 4.0,
    'AR186-T': 2.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 1.0,
    'AR190-N': 3.0,
    'AR192-T': 4.0,
    'AR193-T': 3.0,
    'AR197-T': 1.0,
    'AR198-T': 4.0,
    'AR200-T': 1.0,
    'AR202-T': 2.0,
    'AR204-T': 2.0,
    'AR206-T': 4.0,
    'AR208-T': 1.0,
    'AR209-T': 4.0,
    'AR210-N': 1.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 4.0,
    'AR224-N': 4.0,
    'AR226-T': 1.0,
    'AR228-T': 3.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 3.0,
    'AR232-T': 4.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 1.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 2.0,
    'AR252-T': 4.0,
    'AR254-T': 1.0,
    'AR256-T': 1.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 3.0,
    'AR264-N': 1.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 2.0,
    'AR273-T': 2.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 4.0,
    'AR281-N': 3.0,
    'AR283-T': 4.0,
    'AR285-T': 3.0,
    'AR287-T': 1.0,
    'AR289-T': 4.0,
    'AR293-T': 2.0,
    'AR297-N': 2.0,
    'AR299-N': 3.0,
    'AR300-T': 4.0,
    'AR302-T': 1.0,
    'AR304-T': 3.0,
    'AR306-N': 4.0,
    'AR309-N': 2.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 3.0,
    'AR319-N': 2.0,
    'AR321-N': 1.0,
    'AR323-T': 4.0,
    'AR324-N': 1.0,
    'AR327-N': 1.0,
    'AR329-N': 2.0,
    'AR331-T': 2.0,
    'AR333-N': 1.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 2.0,
    'AR343-N': 3.0,
    'AR345-N': 1.0,
    'AR347-T': 4.0,
    'AR349-N': 4.0,
    'AR351-N': 2.0,
    'AR353-N': 3.0,
    'AR357-N': 4.0,
    'AR363-N': 2.0,
    'AR365-T': 2.0,
    'AR367-N': 3.0,
    'AR368-N': 2.0,
    'AR370-N': 2.0,
    'AR371-T': 4.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 3.0,
    'AR376-N': 3.0,
    'AR381-N': 2.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 2.0,
    'AR389-N': 2.0,
    'AR391-T': 3.0,
    'AR392-N': 3.0,
    'AR394-N': 2.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 4.0,
    'AR409-N': 1.0,
    'AR411-N': 3.0,
    'AR412-N': 1.0,
    'AR413-N': 2.0,
    'AR414-N': 1.0,
    'AR416-N': 1.0,
    'AR418-T': 4.0,
    'AR420-N': 2.0,
    'AR421-N': 4.0,
    'AR422-T': 4.0,
    'AR423-N': 2.0,
    'AR424-N': 4.0,
    'AR425-T': 3.0,
    'AR426-N': 2.0,
    'AR427-N': 4.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 4.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 2.0,
    'AR435-N': 4.0,
    'AR436-N': 1.0,
    'AR437-N': 3.0,
    'AR438-T': 2.0,
    'AR439-T': 4.0,
    'AR440-N': 3.0,
    'AR441-T': 2.0,
    'AR442-T': 1.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 1.0,
    'AR446-N': 3.0,
    'AR447-N': 4.0,
    'AR448-T': 3.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 2.0,
    'AR452-N': 3.0,
    'AR453-N': 2.0,
    'AR454-T': 4.0,
    'AR455-T': 1.0,
    'AR456-N': 3.0,
    'AR457-T': 2.0,
    'AR458-T': 1.0,
    'AR459-T': 4.0,
    'AR460-T': 4.0,
    'AR461-T': 4.0,
    'AR462-T': 4.0,
    'AR463-T': 4.0,
    'AR464-T': 2.0,
    'AR465-T': 2.0,
    'AR466-N': 2.0,
    'AR467-N': 4.0,
    'AR468-T': 2.0,
    'AR469-N': 2.0,
    'AR470-T': 4.0,
    'AR471-T': 2.0,
    'AR472-T': 2.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 4.0,
    'AR476-T': 4.0,
    'AR477-T': 2.0,
    'AR478-T': 4.0,
    'AR479-T': 2.0,
    'AR480-N': 4.0,
    'AR481-': 4.0,
    'AR482-T': 4.0,
    'AR483-N': 1.0,
    'AR484-T': 4.0,
    'AR485-N': 4.0,
    'AR486-N': 4.0,
    'AR488-N': 3.0,
    'AR489-T': 1.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  HEXACHLOROBENZENE: {
    'AR001-N': 2.0,
    'AR003-T': 1.0,
    'AR008-N': 2.0,
    'AR012-T': 1.0,
    'AR014-N': 2.0,
    'AR018-T': 2.0,
    'AR019-N': 4.0,
    'AR021-N': 1.0,
    'AR026-T': 3.0,
    'AR028-N': 3.0,
    'AR029-N': 2.0,
    'AR031-T': 1.0,
    'AR033-N': 2.0,
    'AR035-T': 1.0,
    'AR037-T': 3.0,
    'AR042-T': 1.0,
    'AR044-N': 3.0,
    'AR046-N': 1.0,
    'AR048-N': 1.0,
    'AR050-N': 3.0,
    'AR052-T': 3.0,
    'AR054-N': 1.0,
    'AR056-T': 3.0,
    'AR057-T': 4.0,
    'AR059-N': 1.0,
    'AR061-T': 2.0,
    'AR063-T': 2.0,
    'AR064-T': 1.0,
    'AR066-T': 1.0,
    'AR068-N': 1.0,
    'AR070-N': 4.0,
    'AR071-T': 2.0,
    'AR073-T': 1.0,
    'AR075-N': 1.0,
    'AR077-N': 1.0,
    'AR079-T': 2.0,
    'AR081-T': 1.0,
    'AR083-T': 2.0,
    'AR085-N': 2.0,
    'AR088-T': 1.0,
    'AR090-N': 4.0,
    'AR098-T': 1.0,
    'AR100-N': 3.0,
    'AR102-N': 4.0,
    'AR104-T': 3.0,
    'AR108-T': 1.0,
    'AR110-T': 3.0,
    'AR112-T': 2.0,
    'AR115-T': 1.0,
    'AR118-T': 4.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 3.0,
    'AR132-T': 1.0,
    'AR136-T': 1.0,
    'AR138-T': 2.0,
    'AR140-N': 3.0,
    'AR142-T': 1.0,
    'AR144-N': 1.0,
    'AR145-N': 4.0,
    'AR146-T': 3.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 2.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 2.0,
    'AR158-T': 1.0,
    'AR160-T': 2.0,
    'AR162-N': 4.0,
    'AR164-T': 3.0,
    'AR165-T': 1.0,
    'AR167-T': 3.0,
    'AR169-T': 4.0,
    'AR170-N': 1.0,
    'AR172-N': 2.0,
    'AR174-T': 1.0,
    'AR177-T': 4.0,
    'AR178-T': 1.0,
    'AR180-T': 4.0,
    'AR182-N': 2.0,
    'AR183-T': 4.0,
    'AR185-T': 4.0,
    'AR186-T': 2.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 4.0,
    'AR190-N': 1.0,
    'AR192-T': 2.0,
    'AR193-T': 1.0,
    'AR197-T': 3.0,
    'AR198-T': 4.0,
    'AR200-T': 3.0,
    'AR202-T': 1.0,
    'AR204-T': 3.0,
    'AR206-T': 4.0,
    'AR208-T': 3.0,
    'AR209-T': 4.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 2.0,
    'AR219-T': 3.0,
    'AR220-T': 3.0,
    'AR222-N': 3.0,
    'AR224-N': 3.0,
    'AR226-T': 2.0,
    'AR228-T': 4.0,
    'AR229-N': 4.0,
    'AR230-N': 4.0,
    'AR231-T': 1.0,
    'AR232-T': 4.0,
    'AR233-T': 3.0,
    'AR237-T': 3.0,
    'AR238-T': 1.0,
    'AR239-T': 3.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 1.0,
    'AR249-T': 3.0,
    'AR251-T': 1.0,
    'AR252-T': 4.0,
    'AR254-T': 4.0,
    'AR256-T': 3.0,
    'AR258-T': 3.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 4.0,
    'AR264-N': 2.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 1.0,
    'AR273-T': 2.0,
    'AR275-T': 3.0,
    'AR277-T': 2.0,
    'AR279-T': 4.0,
    'AR281-N': 2.0,
    'AR283-T': 4.0,
    'AR285-T': 3.0,
    'AR287-T': 3.0,
    'AR289-T': 4.0,
    'AR293-T': 1.0,
    'AR297-N': 1.0,
    'AR299-N': 2.0,
    'AR300-T': 4.0,
    'AR302-T': 2.0,
    'AR304-T': 1.0,
    'AR306-N': 3.0,
    'AR309-N': 1.0,
    'AR311-N': 3.0,
    'AR313-N': 2.0,
    'AR315-N': 2.0,
    'AR319-N': 1.0,
    'AR321-N': 3.0,
    'AR323-T': 4.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 1.0,
    'AR331-T': 2.0,
    'AR333-N': 2.0,
    'AR335-N': 2.0,
    'AR339-N': 2.0,
    'AR341-N': 1.0,
    'AR343-N': 2.0,
    'AR345-N': 2.0,
    'AR347-T': 4.0,
    'AR349-N': 4.0,
    'AR351-N': 2.0,
    'AR353-N': 3.0,
    'AR357-N': 3.0,
    'AR363-N': 3.0,
    'AR365-T': 1.0,
    'AR367-N': 1.0,
    'AR368-N': 1.0,
    'AR370-N': 2.0,
    'AR371-T': 3.0,
    'AR372-N': 2.0,
    'AR373-N': 2.0,
    'AR374-N': 1.0,
    'AR376-N': 1.0,
    'AR381-N': 2.0,
    'AR382-N': 2.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 1.0,
    'AR391-T': 2.0,
    'AR392-N': 1.0,
    'AR394-N': 3.0,
    'AR398-N': 2.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 1.0,
    'AR406-N': 3.0,
    'AR407-N': 1.0,
    'AR408-N': 3.0,
    'AR409-N': 3.0,
    'AR411-N': 1.0,
    'AR412-N': 3.0,
    'AR413-N': 2.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 4.0,
    'AR420-N': 2.0,
    'AR421-N': 4.0,
    'AR422-T': 4.0,
    'AR423-N': 3.0,
    'AR424-N': 4.0,
    'AR425-T': 1.0,
    'AR426-N': 1.0,
    'AR427-N': 4.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 4.0,
    'AR432-N': 2.0,
    'AR433-T': 3.0,
    'AR434-N': 2.0,
    'AR435-N': 4.0,
    'AR436-N': 2.0,
    'AR437-N': 3.0,
    'AR438-T': 2.0,
    'AR439-T': 4.0,
    'AR440-N': 1.0,
    'AR441-T': 3.0,
    'AR442-T': 2.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 4.0,
    'AR446-N': 2.0,
    'AR447-N': 4.0,
    'AR448-T': 1.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 1.0,
    'AR452-N': 2.0,
    'AR453-N': 3.0,
    'AR454-T': 4.0,
    'AR455-T': 2.0,
    'AR456-N': 3.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 4.0,
    'AR460-T': 4.0,
    'AR461-T': 4.0,
    'AR462-T': 4.0,
    'AR463-T': 4.0,
    'AR464-T': 1.0,
    'AR465-T': 1.0,
    'AR466-N': 1.0,
    'AR467-N': 4.0,
    'AR468-T': 1.0,
    'AR469-N': 2.0,
    'AR470-T': 4.0,
    'AR471-T': 3.0,
    'AR472-T': 2.0,
    'AR473-N': 2.0,
    'AR474-N': 4.0,
    'AR475-T': 4.0,
    'AR476-T': 4.0,
    'AR477-T': 3.0,
    'AR478-T': 4.0,
    'AR479-T': 2.0,
    'AR480-N': 4.0,
    'AR481-': 3.0,
    'AR482-T': 4.0,
    'AR483-N': 3.0,
    'AR484-T': 4.0,
    'AR485-N': 3.0,
    'AR486-N': 3.0,
    'AR488-N': 3.0,
    'AR489-T': 2.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  HEXACHLOROBUTADIENE: {
    'AR001-N': 3.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 3.0,
    'AR014-N': 3.0,
    'AR018-T': 3.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 1.0,
    'AR029-N': 3.0,
    'AR031-T': 4.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 3.0,
    'AR042-T': 3.0,
    'AR044-N': 2.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 1.0,
    'AR059-N': 3.0,
    'AR061-T': 3.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 4.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 3.0,
    'AR083-T': 1.0,
    'AR085-N': 3.0,
    'AR088-T': 3.0,
    'AR090-N': 2.0,
    'AR098-T': 4.0,
    'AR100-N': 3.0,
    'AR102-N': 2.0,
    'AR104-T': 1.0,
    'AR108-T': 4.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 3.0,
    'AR145-N': 1.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 2.0,
    'AR155-N': 2.0,
    'AR157-N': 4.0,
    'AR158-T': 3.0,
    'AR160-T': 4.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 4.0,
    'AR167-T': 3.0,
    'AR169-T': 1.0,
    'AR170-N': 4.0,
    'AR172-N': 3.0,
    'AR174-T': 3.0,
    'AR177-T': 2.0,
    'AR178-T': 3.0,
    'AR180-T': 1.0,
    'AR182-N': 4.0,
    'AR183-T': 1.0,
    'AR185-T': 2.0,
    'AR186-T': 2.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 1.0,
    'AR190-N': 3.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 4.0,
    'AR198-T': 2.0,
    'AR200-T': 1.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 2.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 2.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 3.0,
    'AR219-T': 1.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 3.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 1.0,
    'AR231-T': 3.0,
    'AR232-T': 2.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 4.0,
    'AR245-N': 2.0,
    'AR247-T': 4.0,
    'AR249-T': 2.0,
    'AR251-T': 4.0,
    'AR252-T': 2.0,
    'AR254-T': 1.0,
    'AR256-T': 4.0,
    'AR258-T': 1.0,
    'AR259-T': 2.0,
    'AR261-N': 3.0,
    'AR262-T': 1.0,
    'AR264-N': 4.0,
    'AR266-T': 4.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 3.0,
    'AR277-T': 3.0,
    'AR279-T': 2.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 1.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 3.0,
    'AR300-T': 2.0,
    'AR302-T': 4.0,
    'AR304-T': 3.0,
    'AR306-N': 1.0,
    'AR309-N': 4.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 3.0,
    'AR319-N': 4.0,
    'AR321-N': 3.0,
    'AR323-T': 2.0,
    'AR324-N': 3.0,
    'AR327-N': 1.0,
    'AR329-N': 4.0,
    'AR331-T': 3.0,
    'AR333-N': 4.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 3.0,
    'AR345-N': 4.0,
    'AR347-T': 2.0,
    'AR349-N': 2.0,
    'AR351-N': 3.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 2.0,
    'AR387-N': 3.0,
    'AR389-N': 4.0,
    'AR391-T': 2.0,
    'AR392-N': 4.0,
    'AR394-N': 1.0,
    'AR398-N': 2.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 4.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 3.0,
    'AR414-N': 4.0,
    'AR416-N': 4.0,
    'AR418-T': 2.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 2.0,
    'AR424-N': 2.0,
    'AR425-T': 3.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 2.0,
    'AR429-T': 2.0,
    'AR430-N': 2.0,
    'AR431-T': 2.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 3.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 2.0,
    'AR440-N': 3.0,
    'AR441-T': 3.0,
    'AR442-T': 4.0,
    'AR443-T': 2.0,
    'AR444-T': 2.0,
    'AR445-N': 1.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 4.0,
    'AR452-N': 3.0,
    'AR453-N': 2.0,
    'AR454-T': 2.0,
    'AR455-T': 4.0,
    'AR456-N': 1.0,
    'AR457-T': 2.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 2.0,
    'AR461-T': 2.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 4.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 2.0,
    'AR468-T': 4.0,
    'AR469-N': 2.0,
    'AR470-T': 2.0,
    'AR471-T': 3.0,
    'AR472-T': 4.0,
    'AR473-N': 2.0,
    'AR474-N': 1.0,
    'AR475-T': 2.0,
    'AR476-T': 2.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 3.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 2.0,
    'AR483-N': 3.0,
    'AR484-T': 2.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 4.0,
    'AR490-T': 2.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  HEXACHLOROCYCLOPENTADIENE: {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 3.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 1.0,
    'AR098-T': 4.0,
    'AR100-N': 3.0,
    'AR102-N': 1.0,
    'AR104-T': 2.0,
    'AR108-T': 4.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 1.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 1.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 3.0,
    'AR169-T': 2.0,
    'AR170-N': 4.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 2.0,
    'AR182-N': 3.0,
    'AR183-T': 2.0,
    'AR185-T': 1.0,
    'AR186-T': 3.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 3.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 1.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 2.0,
    'AR224-N': 2.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 1.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 3.0,
    'AR237-T': 2.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 4.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 2.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 1.0,
    'AR302-T': 3.0,
    'AR304-T': 4.0,
    'AR306-N': 2.0,
    'AR309-N': 4.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 2.0,
    'AR323-T': 1.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 4.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 2.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 3.0,
    'AR389-N': 4.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 3.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 2.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 2.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 3.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 2.0,
    'AR446-N': 4.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 2.0,
    'AR455-T': 3.0,
    'AR456-N': 2.0,
    'AR457-T': 3.0,
    'AR458-T': 3.0,
    'AR459-T': 2.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 3.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 4.0,
    'AR473-N': 3.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 2.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 2.0,
    'AR486-N': 2.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 1.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'HEXAMETHYLENE-DIISOCYANATE': {
    'AR001-N': 2.0,
    'AR003-T': 3.0,
    'AR008-N': 3.0,
    'AR012-T': 3.0,
    'AR014-N': 2.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 3.0,
    'AR026-T': 3.0,
    'AR028-N': 1.0,
    'AR029-N': 2.0,
    'AR031-T': 1.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 2.0,
    'AR044-N': 2.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 2.0,
    'AR056-T': 1.0,
    'AR057-T': 2.0,
    'AR059-N': 3.0,
    'AR061-T': 2.0,
    'AR063-T': 2.0,
    'AR064-T': 1.0,
    'AR066-T': 3.0,
    'AR068-N': 3.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 1.0,
    'AR075-N': 2.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 3.0,
    'AR083-T': 4.0,
    'AR085-N': 2.0,
    'AR088-T': 2.0,
    'AR090-N': 4.0,
    'AR098-T': 1.0,
    'AR100-N': 3.0,
    'AR102-N': 4.0,
    'AR104-T': 1.0,
    'AR108-T': 1.0,
    'AR110-T': 4.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 4.0,
    'AR122-N': 2.0,
    'AR124-N': 2.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 3.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 1.0,
    'AR142-T': 3.0,
    'AR144-N': 3.0,
    'AR145-N': 4.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 1.0,
    'AR150-T': 3.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 3.0,
    'AR158-T': 3.0,
    'AR160-T': 2.0,
    'AR162-N': 4.0,
    'AR164-T': 3.0,
    'AR165-T': 1.0,
    'AR167-T': 1.0,
    'AR169-T': 4.0,
    'AR170-N': 2.0,
    'AR172-N': 2.0,
    'AR174-T': 3.0,
    'AR177-T': 4.0,
    'AR178-T': 3.0,
    'AR180-T': 4.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 4.0,
    'AR186-T': 3.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 1.0,
    'AR190-N': 3.0,
    'AR192-T': 4.0,
    'AR193-T': 1.0,
    'AR197-T': 2.0,
    'AR198-T': 4.0,
    'AR200-T': 1.0,
    'AR202-T': 1.0,
    'AR204-T': 2.0,
    'AR206-T': 4.0,
    'AR208-T': 1.0,
    'AR209-T': 4.0,
    'AR210-N': 1.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 2.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 4.0,
    'AR224-N': 3.0,
    'AR226-T': 2.0,
    'AR228-T': 4.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 3.0,
    'AR232-T': 4.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 2.0,
    'AR245-N': 2.0,
    'AR247-T': 1.0,
    'AR249-T': 1.0,
    'AR251-T': 1.0,
    'AR252-T': 4.0,
    'AR254-T': 1.0,
    'AR256-T': 2.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 4.0,
    'AR264-N': 2.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 2.0,
    'AR273-T': 2.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 4.0,
    'AR281-N': 3.0,
    'AR283-T': 4.0,
    'AR285-T': 3.0,
    'AR287-T': 1.0,
    'AR289-T': 4.0,
    'AR293-T': 2.0,
    'AR297-N': 3.0,
    'AR299-N': 2.0,
    'AR300-T': 4.0,
    'AR302-T': 3.0,
    'AR304-T': 2.0,
    'AR306-N': 4.0,
    'AR309-N': 3.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 2.0,
    'AR319-N': 2.0,
    'AR321-N': 1.0,
    'AR323-T': 4.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 1.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 3.0,
    'AR343-N': 2.0,
    'AR345-N': 3.0,
    'AR347-T': 4.0,
    'AR349-N': 4.0,
    'AR351-N': 3.0,
    'AR353-N': 3.0,
    'AR357-N': 4.0,
    'AR363-N': 1.0,
    'AR365-T': 3.0,
    'AR367-N': 3.0,
    'AR368-N': 3.0,
    'AR370-N': 2.0,
    'AR371-T': 4.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 2.0,
    'AR376-N': 2.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 2.0,
    'AR387-N': 2.0,
    'AR389-N': 1.0,
    'AR391-T': 2.0,
    'AR392-N': 2.0,
    'AR394-N': 1.0,
    'AR398-N': 2.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 1.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 4.0,
    'AR409-N': 1.0,
    'AR411-N': 2.0,
    'AR412-N': 2.0,
    'AR413-N': 3.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 3.0,
    'AR420-N': 2.0,
    'AR421-N': 4.0,
    'AR422-T': 4.0,
    'AR423-N': 2.0,
    'AR424-N': 4.0,
    'AR425-T': 3.0,
    'AR426-N': 2.0,
    'AR427-N': 4.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 4.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 3.0,
    'AR435-N': 4.0,
    'AR436-N': 2.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 4.0,
    'AR440-N': 3.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 1.0,
    'AR446-N': 4.0,
    'AR447-N': 4.0,
    'AR448-T': 3.0,
    'AR449-T': 2.0,
    'AR450-N': 3.0,
    'AR451-N': 1.0,
    'AR452-N': 2.0,
    'AR453-N': 2.0,
    'AR454-T': 4.0,
    'AR455-T': 3.0,
    'AR456-N': 1.0,
    'AR457-T': 2.0,
    'AR458-T': 1.0,
    'AR459-T': 4.0,
    'AR460-T': 4.0,
    'AR461-T': 4.0,
    'AR462-T': 4.0,
    'AR463-T': 4.0,
    'AR464-T': 1.0,
    'AR465-T': 2.0,
    'AR466-N': 2.0,
    'AR467-N': 4.0,
    'AR468-T': 1.0,
    'AR469-N': 3.0,
    'AR470-T': 4.0,
    'AR471-T': 3.0,
    'AR472-T': 1.0,
    'AR473-N': 2.0,
    'AR474-N': 1.0,
    'AR475-T': 4.0,
    'AR476-T': 4.0,
    'AR477-T': 2.0,
    'AR478-T': 4.0,
    'AR479-T': 3.0,
    'AR480-N': 4.0,
    'AR481-': 3.0,
    'AR482-T': 4.0,
    'AR483-N': 1.0,
    'AR484-T': 4.0,
    'AR485-N': 3.0,
    'AR486-N': 3.0,
    'AR488-N': 4.0,
    'AR489-T': 2.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  HEXANE: {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 1.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 4.0,
    'AR044-N': 1.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 4.0,
    'AR056-T': 1.0,
    'AR057-T': 1.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 1.0,
    'AR088-T': 4.0,
    'AR090-N': 4.0,
    'AR098-T': 3.0,
    'AR100-N': 3.0,
    'AR102-N': 3.0,
    'AR104-T': 1.0,
    'AR108-T': 2.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 1.0,
    'AR118-T': 3.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 4.0,
    'AR154-T': 2.0,
    'AR155-N': 3.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 1.0,
    'AR169-T': 2.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 3.0,
    'AR178-T': 4.0,
    'AR180-T': 2.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 3.0,
    'AR186-T': 3.0,
    'AR187-T': 3.0,
    'AR188-T': 3.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 3.0,
    'AR198-T': 3.0,
    'AR200-T': 1.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 3.0,
    'AR208-T': 1.0,
    'AR209-T': 2.0,
    'AR210-N': 3.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 2.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 3.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 3.0,
    'AR252-T': 2.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 3.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 2.0,
    'AR281-N': 3.0,
    'AR283-T': 2.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 2.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 3.0,
    'AR302-T': 4.0,
    'AR304-T': 4.0,
    'AR306-N': 2.0,
    'AR309-N': 4.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 2.0,
    'AR323-T': 4.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 2.0,
    'AR349-N': 2.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 2.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 1.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 2.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 3.0,
    'AR391-T': 2.0,
    'AR392-N': 4.0,
    'AR394-N': 1.0,
    'AR398-N': 2.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 3.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 2.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 2.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 2.0,
    'AR422-T': 3.0,
    'AR423-N': 2.0,
    'AR424-N': 2.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 2.0,
    'AR428-T': 2.0,
    'AR429-T': 3.0,
    'AR430-N': 2.0,
    'AR431-T': 2.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 2.0,
    'AR436-N': 2.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 4.0,
    'AR443-T': 3.0,
    'AR444-T': 3.0,
    'AR445-N': 1.0,
    'AR446-N': 3.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 1.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 4.0,
    'AR455-T': 4.0,
    'AR456-N': 1.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 3.0,
    'AR460-T': 2.0,
    'AR461-T': 2.0,
    'AR462-T': 1.0,
    'AR463-T': 2.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 2.0,
    'AR468-T': 4.0,
    'AR469-N': 2.0,
    'AR470-T': 2.0,
    'AR471-T': 3.0,
    'AR472-T': 3.0,
    'AR473-N': 2.0,
    'AR474-N': 1.0,
    'AR475-T': 2.0,
    'AR476-T': 2.0,
    'AR477-T': 3.0,
    'AR478-T': 2.0,
    'AR479-T': 4.0,
    'AR480-N': 2.0,
    'AR481-': 1.0,
    'AR482-T': 3.0,
    'AR483-N': 2.0,
    'AR484-T': 2.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 4.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  HYDRAZINE: {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 3.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 1.0,
    'AR029-N': 4.0,
    'AR031-T': 3.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 3.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 1.0,
    'AR059-N': 4.0,
    'AR061-T': 3.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 3.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 2.0,
    'AR098-T': 3.0,
    'AR100-N': 3.0,
    'AR102-N': 2.0,
    'AR104-T': 1.0,
    'AR108-T': 4.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 3.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 2.0,
    'AR155-N': 2.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 3.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 3.0,
    'AR169-T': 1.0,
    'AR170-N': 4.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 1.0,
    'AR185-T': 1.0,
    'AR186-T': 3.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 4.0,
    'AR198-T': 2.0,
    'AR200-T': 1.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 2.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 3.0,
    'AR228-T': 3.0,
    'AR229-N': 2.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 3.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 4.0,
    'AR252-T': 2.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 2.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 2.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 1.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 2.0,
    'AR302-T': 4.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 2.0,
    'AR323-T': 2.0,
    'AR324-N': 3.0,
    'AR327-N': 1.0,
    'AR329-N': 4.0,
    'AR331-T': 3.0,
    'AR333-N': 4.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 2.0,
    'AR349-N': 2.0,
    'AR351-N': 3.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 3.0,
    'AR389-N': 4.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 1.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 2.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 2.0,
    'AR424-N': 2.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 2.0,
    'AR429-T': 2.0,
    'AR430-N': 2.0,
    'AR431-T': 2.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 2.0,
    'AR439-T': 3.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 4.0,
    'AR443-T': 2.0,
    'AR444-T': 2.0,
    'AR445-N': 1.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 2.0,
    'AR454-T': 2.0,
    'AR455-T': 4.0,
    'AR456-N': 1.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 2.0,
    'AR460-T': 2.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 2.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 2.0,
    'AR471-T': 3.0,
    'AR472-T': 4.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 1.0,
    'AR476-T': 2.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 3.0,
    'AR480-N': 2.0,
    'AR481-': 1.0,
    'AR482-T': 2.0,
    'AR483-N': 3.0,
    'AR484-T': 2.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 2.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'HYDROCHLORIC-ACID--HYDROGEN-CHLORIDE--GAS-ONLY--': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 1.0,
    'AR029-N': 4.0,
    'AR031-T': 1.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 3.0,
    'AR057-T': 1.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 2.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 1.0,
    'AR098-T': 3.0,
    'AR100-N': 3.0,
    'AR102-N': 1.0,
    'AR104-T': 1.0,
    'AR108-T': 3.0,
    'AR110-T': 2.0,
    'AR112-T': 4.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 2.0,
    'AR152-T': 2.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 4.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 3.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 2.0,
    'AR169-T': 2.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 2.0,
    'AR182-N': 3.0,
    'AR183-T': 1.0,
    'AR185-T': 2.0,
    'AR186-T': 3.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 2.0,
    'AR193-T': 4.0,
    'AR197-T': 3.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 2.0,
    'AR208-T': 1.0,
    'AR209-T': 2.0,
    'AR210-N': 3.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 1.0,
    'AR222-N': 2.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 3.0,
    'AR252-T': 3.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 2.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 2.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 2.0,
    'AR302-T': 4.0,
    'AR304-T': 4.0,
    'AR306-N': 2.0,
    'AR309-N': 4.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 4.0,
    'AR323-T': 1.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 2.0,
    'AR331-T': 4.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 4.0,
    'AR347-T': 1.0,
    'AR349-N': 3.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 1.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 2.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 2.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 2.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 2.0,
    'AR420-N': 2.0,
    'AR421-N': 2.0,
    'AR422-T': 2.0,
    'AR423-N': 2.0,
    'AR424-N': 3.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 2.0,
    'AR428-T': 3.0,
    'AR429-T': 1.0,
    'AR430-N': 3.0,
    'AR431-T': 3.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 3.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 4.0,
    'AR443-T': 1.0,
    'AR444-T': 2.0,
    'AR445-N': 1.0,
    'AR446-N': 4.0,
    'AR447-N': 3.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 2.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 1.0,
    'AR455-T': 4.0,
    'AR456-N': 1.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 1.0,
    'AR460-T': 3.0,
    'AR461-T': 2.0,
    'AR462-T': 3.0,
    'AR463-T': 2.0,
    'AR464-T': 1.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 3.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 3.0,
    'AR471-T': 3.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 2.0,
    'AR476-T': 3.0,
    'AR477-T': 3.0,
    'AR478-T': 2.0,
    'AR479-T': 4.0,
    'AR480-N': 3.0,
    'AR481-': 1.0,
    'AR482-T': 2.0,
    'AR483-N': 3.0,
    'AR484-T': 3.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 1.0,
    'AR491-N': 3.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'HYDROGEN-FLUORIDE--HYDROFLUORIC-ACID-': {
    'AR001-N': 3.0,
    'AR003-T': 4.0,
    'AR008-N': 4.0,
    'AR012-T': 4.0,
    'AR014-N': 3.0,
    'AR018-T': 2.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 4.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 3.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 3.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 4.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 2.0,
    'AR085-N': 3.0,
    'AR088-T': 4.0,
    'AR090-N': 1.0,
    'AR098-T': 3.0,
    'AR100-N': 3.0,
    'AR102-N': 1.0,
    'AR104-T': 2.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 1.0,
    'AR118-T': 1.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 2.0,
    'AR152-T': 1.0,
    'AR154-T': 2.0,
    'AR155-N': 1.0,
    'AR157-N': 4.0,
    'AR158-T': 4.0,
    'AR160-T': 3.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 2.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 3.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 2.0,
    'AR185-T': 1.0,
    'AR186-T': 3.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 3.0,
    'AR198-T': 1.0,
    'AR200-T': 3.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 1.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 3.0,
    'AR219-T': 3.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 1.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 3.0,
    'AR237-T': 2.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 2.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 2.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 3.0,
    'AR275-T': 2.0,
    'AR277-T': 4.0,
    'AR279-T': 1.0,
    'AR281-N': 4.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 3.0,
    'AR289-T': 1.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 3.0,
    'AR300-T': 1.0,
    'AR302-T': 4.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 4.0,
    'AR311-N': 3.0,
    'AR313-N': 4.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 4.0,
    'AR323-T': 1.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 2.0,
    'AR331-T': 4.0,
    'AR333-N': 3.0,
    'AR335-N': 4.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 3.0,
    'AR345-N': 4.0,
    'AR347-T': 2.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 3.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 2.0,
    'AR372-N': 4.0,
    'AR373-N': 4.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 3.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 3.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 2.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 2.0,
    'AR431-T': 1.0,
    'AR432-N': 4.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 2.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 4.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 2.0,
    'AR446-N': 4.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 2.0,
    'AR452-N': 3.0,
    'AR453-N': 3.0,
    'AR454-T': 1.0,
    'AR455-T': 4.0,
    'AR456-N': 2.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 2.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 2.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 2.0,
    'AR481-': 2.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 2.0,
    'AR485-N': 2.0,
    'AR486-N': 2.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 1.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  HYDROQUINONE: {
    'AR001-N': 2.0,
    'AR003-T': 3.0,
    'AR008-N': 2.0,
    'AR012-T': 1.0,
    'AR014-N': 2.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 1.0,
    'AR026-T': 2.0,
    'AR028-N': 3.0,
    'AR029-N': 1.0,
    'AR031-T': 2.0,
    'AR033-N': 2.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 1.0,
    'AR044-N': 2.0,
    'AR046-N': 1.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 1.0,
    'AR056-T': 3.0,
    'AR057-T': 4.0,
    'AR059-N': 2.0,
    'AR061-T': 2.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 3.0,
    'AR068-N': 1.0,
    'AR070-N': 1.0,
    'AR071-T': 2.0,
    'AR073-T': 3.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 2.0,
    'AR081-T': 2.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 1.0,
    'AR090-N': 4.0,
    'AR098-T': 3.0,
    'AR100-N': 2.0,
    'AR102-N': 4.0,
    'AR104-T': 1.0,
    'AR108-T': 2.0,
    'AR110-T': 3.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 4.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 3.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 1.0,
    'AR145-N': 4.0,
    'AR146-T': 3.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 3.0,
    'AR158-T': 2.0,
    'AR160-T': 3.0,
    'AR162-N': 3.0,
    'AR164-T': 2.0,
    'AR165-T': 1.0,
    'AR167-T': 2.0,
    'AR169-T': 4.0,
    'AR170-N': 1.0,
    'AR172-N': 2.0,
    'AR174-T': 2.0,
    'AR177-T': 4.0,
    'AR178-T': 2.0,
    'AR180-T': 4.0,
    'AR182-N': 3.0,
    'AR183-T': 1.0,
    'AR185-T': 4.0,
    'AR186-T': 4.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 1.0,
    'AR190-N': 1.0,
    'AR192-T': 1.0,
    'AR193-T': 1.0,
    'AR197-T': 3.0,
    'AR198-T': 4.0,
    'AR200-T': 1.0,
    'AR202-T': 3.0,
    'AR204-T': 2.0,
    'AR206-T': 4.0,
    'AR208-T': 1.0,
    'AR209-T': 4.0,
    'AR210-N': 3.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 2.0,
    'AR219-T': 2.0,
    'AR220-T': 1.0,
    'AR222-N': 4.0,
    'AR224-N': 4.0,
    'AR226-T': 3.0,
    'AR228-T': 1.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 2.0,
    'AR232-T': 4.0,
    'AR233-T': 2.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 3.0,
    'AR242-N': 3.0,
    'AR243-T': 3.0,
    'AR245-N': 3.0,
    'AR247-T': 1.0,
    'AR249-T': 1.0,
    'AR251-T': 3.0,
    'AR252-T': 4.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 3.0,
    'AR275-T': 1.0,
    'AR277-T': 2.0,
    'AR279-T': 3.0,
    'AR281-N': 2.0,
    'AR283-T': 4.0,
    'AR285-T': 3.0,
    'AR287-T': 1.0,
    'AR289-T': 4.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 2.0,
    'AR300-T': 4.0,
    'AR302-T': 3.0,
    'AR304-T': 1.0,
    'AR306-N': 4.0,
    'AR309-N': 4.0,
    'AR311-N': 2.0,
    'AR313-N': 2.0,
    'AR315-N': 1.0,
    'AR319-N': 4.0,
    'AR321-N': 3.0,
    'AR323-T': 4.0,
    'AR324-N': 3.0,
    'AR327-N': 1.0,
    'AR329-N': 2.0,
    'AR331-T': 2.0,
    'AR333-N': 3.0,
    'AR335-N': 2.0,
    'AR339-N': 2.0,
    'AR341-N': 3.0,
    'AR343-N': 2.0,
    'AR345-N': 3.0,
    'AR347-T': 4.0,
    'AR349-N': 4.0,
    'AR351-N': 2.0,
    'AR353-N': 2.0,
    'AR357-N': 4.0,
    'AR363-N': 2.0,
    'AR365-T': 3.0,
    'AR367-N': 1.0,
    'AR368-N': 3.0,
    'AR370-N': 1.0,
    'AR371-T': 3.0,
    'AR372-N': 2.0,
    'AR373-N': 2.0,
    'AR374-N': 1.0,
    'AR376-N': 1.0,
    'AR381-N': 3.0,
    'AR382-N': 3.0,
    'AR384-N': 2.0,
    'AR387-N': 2.0,
    'AR389-N': 3.0,
    'AR391-T': 1.0,
    'AR392-N': 1.0,
    'AR394-N': 2.0,
    'AR398-N': 1.0,
    'AR400-T': 1.0,
    'AR402-N': 1.0,
    'AR404-N': 1.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 4.0,
    'AR409-N': 3.0,
    'AR411-N': 1.0,
    'AR412-N': 3.0,
    'AR413-N': 2.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 3.0,
    'AR420-N': 3.0,
    'AR421-N': 4.0,
    'AR422-T': 4.0,
    'AR423-N': 2.0,
    'AR424-N': 4.0,
    'AR425-T': 2.0,
    'AR426-N': 4.0,
    'AR427-N': 4.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 3.0,
    'AR432-N': 2.0,
    'AR433-T': 2.0,
    'AR434-N': 3.0,
    'AR435-N': 4.0,
    'AR436-N': 3.0,
    'AR437-N': 4.0,
    'AR438-T': 1.0,
    'AR439-T': 3.0,
    'AR440-N': 2.0,
    'AR441-T': 2.0,
    'AR442-T': 3.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 1.0,
    'AR446-N': 2.0,
    'AR447-N': 4.0,
    'AR448-T': 1.0,
    'AR449-T': 2.0,
    'AR450-N': 3.0,
    'AR451-N': 3.0,
    'AR452-N': 2.0,
    'AR453-N': 2.0,
    'AR454-T': 4.0,
    'AR455-T': 3.0,
    'AR456-N': 3.0,
    'AR457-T': 1.0,
    'AR458-T': 3.0,
    'AR459-T': 4.0,
    'AR460-T': 4.0,
    'AR461-T': 4.0,
    'AR462-T': 3.0,
    'AR463-T': 4.0,
    'AR464-T': 1.0,
    'AR465-T': 3.0,
    'AR466-N': 3.0,
    'AR467-N': 4.0,
    'AR468-T': 3.0,
    'AR469-N': 3.0,
    'AR470-T': 4.0,
    'AR471-T': 2.0,
    'AR472-T': 2.0,
    'AR473-N': 1.0,
    'AR474-N': 1.0,
    'AR475-T': 4.0,
    'AR476-T': 3.0,
    'AR477-T': 2.0,
    'AR478-T': 4.0,
    'AR479-T': 2.0,
    'AR480-N': 4.0,
    'AR481-': 4.0,
    'AR482-T': 4.0,
    'AR483-N': 2.0,
    'AR484-T': 4.0,
    'AR485-N': 4.0,
    'AR486-N': 4.0,
    'AR488-N': 3.0,
    'AR489-T': 3.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  ISOPHORONE: {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 4.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 3.0,
    'AR019-N': 2.0,
    'AR021-N': 3.0,
    'AR026-T': 4.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 4.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 3.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 3.0,
    'AR070-N': 2.0,
    'AR071-T': 4.0,
    'AR073-T': 3.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 2.0,
    'AR098-T': 3.0,
    'AR100-N': 4.0,
    'AR102-N': 2.0,
    'AR104-T': 1.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 1.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 2.0,
    'AR155-N': 2.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 2.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 2.0,
    'AR185-T': 1.0,
    'AR186-T': 3.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 4.0,
    'AR198-T': 2.0,
    'AR200-T': 2.0,
    'AR202-T': 3.0,
    'AR204-T': 4.0,
    'AR206-T': 2.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 4.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 2.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 4.0,
    'AR279-T': 1.0,
    'AR281-N': 4.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 2.0,
    'AR302-T': 4.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 3.0,
    'AR313-N': 4.0,
    'AR315-N': 4.0,
    'AR319-N': 3.0,
    'AR321-N': 3.0,
    'AR323-T': 2.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 4.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 2.0,
    'AR349-N': 1.0,
    'AR351-N': 3.0,
    'AR353-N': 4.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 4.0,
    'AR373-N': 4.0,
    'AR374-N': 4.0,
    'AR376-N': 3.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 3.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 3.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 2.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 4.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 3.0,
    'AR437-N': 2.0,
    'AR438-T': 3.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 4.0,
    'AR443-T': 2.0,
    'AR444-T': 2.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 2.0,
    'AR455-T': 4.0,
    'AR456-N': 2.0,
    'AR457-T': 3.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 3.0,
    'AR466-N': 3.0,
    'AR467-N': 1.0,
    'AR468-T': 3.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 4.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 4.0,
    'AR478-T': 1.0,
    'AR479-T': 3.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 2.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 2.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'LEAD-COMPOUNDS': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 4.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 3.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 4.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 1.0,
    'AR033-N': 4.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 4.0,
    'AR044-N': 3.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 3.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 1.0,
    'AR071-T': 4.0,
    'AR073-T': 3.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 4.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 3.0,
    'AR088-T': 4.0,
    'AR090-N': 2.0,
    'AR098-T': 3.0,
    'AR100-N': 4.0,
    'AR102-N': 1.0,
    'AR104-T': 2.0,
    'AR108-T': 2.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 3.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 1.0,
    'AR155-N': 2.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 4.0,
    'AR165-T': 2.0,
    'AR167-T': 2.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 1.0,
    'AR185-T': 1.0,
    'AR186-T': 3.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 3.0,
    'AR198-T': 2.0,
    'AR200-T': 2.0,
    'AR202-T': 3.0,
    'AR204-T': 3.0,
    'AR206-T': 2.0,
    'AR208-T': 1.0,
    'AR209-T': 2.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 4.0,
    'AR237-T': 2.0,
    'AR238-T': 1.0,
    'AR239-T': 3.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 3.0,
    'AR252-T': 2.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 2.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 3.0,
    'AR275-T': 1.0,
    'AR277-T': 4.0,
    'AR279-T': 1.0,
    'AR281-N': 4.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 2.0,
    'AR302-T': 3.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 3.0,
    'AR313-N': 4.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 3.0,
    'AR323-T': 2.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 2.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 4.0,
    'AR339-N': 4.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 2.0,
    'AR351-N': 4.0,
    'AR353-N': 4.0,
    'AR357-N': 1.0,
    'AR363-N': 4.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 4.0,
    'AR373-N': 4.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 3.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 3.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 3.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 2.0,
    'AR422-T': 2.0,
    'AR423-N': 3.0,
    'AR424-N': 2.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 2.0,
    'AR428-T': 2.0,
    'AR429-T': 2.0,
    'AR430-N': 2.0,
    'AR431-T': 1.0,
    'AR432-N': 4.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 2.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 2.0,
    'AR444-T': 2.0,
    'AR445-N': 1.0,
    'AR446-N': 4.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 3.0,
    'AR451-N': 2.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 1.0,
    'AR455-T': 3.0,
    'AR456-N': 2.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 2.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 2.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 2.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 2.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 2.0,
    'AR483-N': 2.0,
    'AR484-T': 2.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 3.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'MALEIC-ANHYDRIDE': {
    'AR001-N': 4.0,
    'AR003-T': 3.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 4.0,
    'AR028-N': 1.0,
    'AR029-N': 4.0,
    'AR031-T': 4.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 3.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 4.0,
    'AR056-T': 1.0,
    'AR057-T': 4.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 3.0,
    'AR068-N': 4.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 3.0,
    'AR098-T': 4.0,
    'AR100-N': 4.0,
    'AR102-N': 3.0,
    'AR104-T': 1.0,
    'AR108-T': 4.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 3.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 3.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 3.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 1.0,
    'AR150-T': 1.0,
    'AR152-T': 3.0,
    'AR154-T': 2.0,
    'AR155-N': 2.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 1.0,
    'AR162-N': 3.0,
    'AR164-T': 4.0,
    'AR165-T': 4.0,
    'AR167-T': 1.0,
    'AR169-T': 2.0,
    'AR170-N': 4.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 2.0,
    'AR182-N': 1.0,
    'AR183-T': 1.0,
    'AR185-T': 3.0,
    'AR186-T': 2.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 3.0,
    'AR198-T': 2.0,
    'AR200-T': 1.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 2.0,
    'AR208-T': 2.0,
    'AR209-T': 2.0,
    'AR210-N': 1.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 2.0,
    'AR224-N': 1.0,
    'AR226-T': 1.0,
    'AR228-T': 3.0,
    'AR229-N': 3.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 4.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 1.0,
    'AR243-T': 1.0,
    'AR245-N': 1.0,
    'AR247-T': 4.0,
    'AR249-T': 4.0,
    'AR251-T': 4.0,
    'AR252-T': 2.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 2.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 4.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 3.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 3.0,
    'AR281-N': 3.0,
    'AR283-T': 2.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 2.0,
    'AR293-T': 4.0,
    'AR297-N': 3.0,
    'AR299-N': 4.0,
    'AR300-T': 2.0,
    'AR302-T': 3.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 3.0,
    'AR321-N': 1.0,
    'AR323-T': 3.0,
    'AR324-N': 1.0,
    'AR327-N': 1.0,
    'AR329-N': 4.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 3.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 2.0,
    'AR349-N': 2.0,
    'AR351-N': 3.0,
    'AR353-N': 4.0,
    'AR357-N': 2.0,
    'AR363-N': 1.0,
    'AR365-T': 3.0,
    'AR367-N': 4.0,
    'AR368-N': 3.0,
    'AR370-N': 1.0,
    'AR371-T': 2.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 1.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 2.0,
    'AR389-N': 4.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 1.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 4.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 1.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 1.0,
    'AR416-N': 1.0,
    'AR418-T': 3.0,
    'AR420-N': 1.0,
    'AR421-N': 2.0,
    'AR422-T': 2.0,
    'AR423-N': 2.0,
    'AR424-N': 2.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 2.0,
    'AR428-T': 2.0,
    'AR429-T': 2.0,
    'AR430-N': 2.0,
    'AR431-T': 3.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 2.0,
    'AR436-N': 1.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 4.0,
    'AR440-N': 4.0,
    'AR441-T': 2.0,
    'AR442-T': 3.0,
    'AR443-T': 2.0,
    'AR444-T': 3.0,
    'AR445-N': 1.0,
    'AR446-N': 4.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 4.0,
    'AR452-N': 4.0,
    'AR453-N': 2.0,
    'AR454-T': 2.0,
    'AR455-T': 3.0,
    'AR456-N': 1.0,
    'AR457-T': 2.0,
    'AR458-T': 2.0,
    'AR459-T': 2.0,
    'AR460-T': 2.0,
    'AR461-T': 3.0,
    'AR462-T': 2.0,
    'AR463-T': 2.0,
    'AR464-T': 4.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 2.0,
    'AR468-T': 4.0,
    'AR469-N': 2.0,
    'AR470-T': 2.0,
    'AR471-T': 2.0,
    'AR472-T': 4.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 3.0,
    'AR476-T': 3.0,
    'AR477-T': 3.0,
    'AR478-T': 2.0,
    'AR479-T': 3.0,
    'AR480-N': 2.0,
    'AR481-': 2.0,
    'AR482-T': 2.0,
    'AR483-N': 1.0,
    'AR484-T': 2.0,
    'AR485-N': 2.0,
    'AR486-N': 2.0,
    'AR488-N': 3.0,
    'AR489-T': 3.0,
    'AR490-T': 3.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'MANGANESE-COMPOUNDS': {
    'AR001-N': 3.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 3.0,
    'AR018-T': 1.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 1.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 1.0,
    'AR059-N': 4.0,
    'AR061-T': 1.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 4.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 3.0,
    'AR090-N': 4.0,
    'AR098-T': 3.0,
    'AR100-N': 3.0,
    'AR102-N': 3.0,
    'AR104-T': 1.0,
    'AR108-T': 2.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 3.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 4.0,
    'AR154-T': 3.0,
    'AR155-N': 4.0,
    'AR157-N': 4.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 1.0,
    'AR169-T': 2.0,
    'AR170-N': 3.0,
    'AR172-N': 3.0,
    'AR174-T': 4.0,
    'AR177-T': 4.0,
    'AR178-T': 4.0,
    'AR180-T': 2.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 3.0,
    'AR186-T': 3.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 3.0,
    'AR198-T': 4.0,
    'AR200-T': 1.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 4.0,
    'AR208-T': 1.0,
    'AR209-T': 3.0,
    'AR210-N': 2.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 2.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 4.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 1.0,
    'AR243-T': 3.0,
    'AR245-N': 1.0,
    'AR247-T': 2.0,
    'AR249-T': 1.0,
    'AR251-T': 3.0,
    'AR252-T': 2.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 2.0,
    'AR281-N': 3.0,
    'AR283-T': 2.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 2.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 3.0,
    'AR300-T': 4.0,
    'AR302-T': 3.0,
    'AR304-T': 3.0,
    'AR306-N': 2.0,
    'AR309-N': 4.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 3.0,
    'AR323-T': 4.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 4.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 4.0,
    'AR341-N': 4.0,
    'AR343-N': 3.0,
    'AR345-N': 4.0,
    'AR347-T': 3.0,
    'AR349-N': 2.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 2.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 1.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 1.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 2.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 1.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 2.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 2.0,
    'AR409-N': 2.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 2.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 1.0,
    'AR420-N': 1.0,
    'AR421-N': 2.0,
    'AR422-T': 4.0,
    'AR423-N': 2.0,
    'AR424-N': 2.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 2.0,
    'AR428-T': 2.0,
    'AR429-T': 4.0,
    'AR430-N': 2.0,
    'AR431-T': 2.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 2.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 1.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 4.0,
    'AR444-T': 3.0,
    'AR445-N': 1.0,
    'AR446-N': 3.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 1.0,
    'AR451-N': 2.0,
    'AR452-N': 3.0,
    'AR453-N': 2.0,
    'AR454-T': 2.0,
    'AR455-T': 3.0,
    'AR456-N': 2.0,
    'AR457-T': 1.0,
    'AR458-T': 3.0,
    'AR459-T': 1.0,
    'AR460-T': 2.0,
    'AR461-T': 2.0,
    'AR462-T': 2.0,
    'AR463-T': 3.0,
    'AR464-T': 1.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 2.0,
    'AR468-T': 4.0,
    'AR469-N': 2.0,
    'AR470-T': 2.0,
    'AR471-T': 3.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 2.0,
    'AR476-T': 2.0,
    'AR477-T': 3.0,
    'AR478-T': 3.0,
    'AR479-T': 4.0,
    'AR480-N': 2.0,
    'AR481-': 2.0,
    'AR482-T': 3.0,
    'AR483-N': 2.0,
    'AR484-T': 2.0,
    'AR485-N': 2.0,
    'AR486-N': 2.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 4.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'MERCURY-COMPOUNDS': {
    'AR001-N': 3.0,
    'AR003-T': 3.0,
    'AR008-N': 3.0,
    'AR012-T': 3.0,
    'AR014-N': 3.0,
    'AR018-T': 1.0,
    'AR019-N': 1.0,
    'AR021-N': 3.0,
    'AR026-T': 2.0,
    'AR028-N': 1.0,
    'AR029-N': 3.0,
    'AR031-T': 1.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 3.0,
    'AR044-N': 1.0,
    'AR046-N': 2.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 3.0,
    'AR056-T': 1.0,
    'AR057-T': 1.0,
    'AR059-N': 3.0,
    'AR061-T': 1.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 3.0,
    'AR068-N': 3.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 2.0,
    'AR075-N': 2.0,
    'AR077-N': 1.0,
    'AR079-T': 2.0,
    'AR081-T': 3.0,
    'AR083-T': 4.0,
    'AR085-N': 1.0,
    'AR088-T': 2.0,
    'AR090-N': 4.0,
    'AR098-T': 2.0,
    'AR100-N': 2.0,
    'AR102-N': 4.0,
    'AR104-T': 1.0,
    'AR108-T': 2.0,
    'AR110-T': 4.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 4.0,
    'AR122-N': 2.0,
    'AR124-N': 2.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 3.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 1.0,
    'AR142-T': 3.0,
    'AR144-N': 3.0,
    'AR145-N': 4.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 1.0,
    'AR150-T': 3.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 2.0,
    'AR158-T': 3.0,
    'AR160-T': 2.0,
    'AR162-N': 4.0,
    'AR164-T': 2.0,
    'AR165-T': 2.0,
    'AR167-T': 1.0,
    'AR169-T': 4.0,
    'AR170-N': 2.0,
    'AR172-N': 3.0,
    'AR174-T': 3.0,
    'AR177-T': 4.0,
    'AR178-T': 3.0,
    'AR180-T': 4.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 4.0,
    'AR186-T': 2.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 1.0,
    'AR190-N': 3.0,
    'AR192-T': 4.0,
    'AR193-T': 2.0,
    'AR197-T': 3.0,
    'AR198-T': 4.0,
    'AR200-T': 1.0,
    'AR202-T': 2.0,
    'AR204-T': 3.0,
    'AR206-T': 4.0,
    'AR208-T': 1.0,
    'AR209-T': 4.0,
    'AR210-N': 2.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 4.0,
    'AR224-N': 3.0,
    'AR226-T': 1.0,
    'AR228-T': 3.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 3.0,
    'AR232-T': 4.0,
    'AR233-T': 2.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 1.0,
    'AR243-T': 2.0,
    'AR245-N': 1.0,
    'AR247-T': 2.0,
    'AR249-T': 1.0,
    'AR251-T': 2.0,
    'AR252-T': 4.0,
    'AR254-T': 1.0,
    'AR256-T': 2.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 3.0,
    'AR264-N': 2.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 3.0,
    'AR273-T': 3.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 4.0,
    'AR281-N': 3.0,
    'AR283-T': 4.0,
    'AR285-T': 3.0,
    'AR287-T': 1.0,
    'AR289-T': 4.0,
    'AR293-T': 2.0,
    'AR297-N': 3.0,
    'AR299-N': 3.0,
    'AR300-T': 4.0,
    'AR302-T': 3.0,
    'AR304-T': 2.0,
    'AR306-N': 4.0,
    'AR309-N': 2.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 3.0,
    'AR319-N': 2.0,
    'AR321-N': 2.0,
    'AR323-T': 4.0,
    'AR324-N': 1.0,
    'AR327-N': 1.0,
    'AR329-N': 2.0,
    'AR331-T': 2.0,
    'AR333-N': 2.0,
    'AR335-N': 3.0,
    'AR339-N': 2.0,
    'AR341-N': 3.0,
    'AR343-N': 3.0,
    'AR345-N': 2.0,
    'AR347-T': 4.0,
    'AR349-N': 4.0,
    'AR351-N': 2.0,
    'AR353-N': 2.0,
    'AR357-N': 4.0,
    'AR363-N': 1.0,
    'AR365-T': 3.0,
    'AR367-N': 3.0,
    'AR368-N': 3.0,
    'AR370-N': 1.0,
    'AR371-T': 4.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 3.0,
    'AR376-N': 2.0,
    'AR381-N': 1.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 2.0,
    'AR389-N': 2.0,
    'AR391-T': 2.0,
    'AR392-N': 3.0,
    'AR394-N': 1.0,
    'AR398-N': 1.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 2.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 4.0,
    'AR409-N': 2.0,
    'AR411-N': 2.0,
    'AR412-N': 3.0,
    'AR413-N': 2.0,
    'AR414-N': 1.0,
    'AR416-N': 1.0,
    'AR418-T': 3.0,
    'AR420-N': 1.0,
    'AR421-N': 4.0,
    'AR422-T': 4.0,
    'AR423-N': 2.0,
    'AR424-N': 4.0,
    'AR425-T': 3.0,
    'AR426-N': 2.0,
    'AR427-N': 4.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 4.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 3.0,
    'AR435-N': 4.0,
    'AR436-N': 1.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 4.0,
    'AR440-N': 3.0,
    'AR441-T': 2.0,
    'AR442-T': 3.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 1.0,
    'AR446-N': 2.0,
    'AR447-N': 4.0,
    'AR448-T': 3.0,
    'AR449-T': 2.0,
    'AR450-N': 1.0,
    'AR451-N': 2.0,
    'AR452-N': 3.0,
    'AR453-N': 2.0,
    'AR454-T': 4.0,
    'AR455-T': 3.0,
    'AR456-N': 1.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 4.0,
    'AR460-T': 4.0,
    'AR461-T': 4.0,
    'AR462-T': 4.0,
    'AR463-T': 4.0,
    'AR464-T': 1.0,
    'AR465-T': 3.0,
    'AR466-N': 3.0,
    'AR467-N': 4.0,
    'AR468-T': 2.0,
    'AR469-N': 1.0,
    'AR470-T': 4.0,
    'AR471-T': 2.0,
    'AR472-T': 2.0,
    'AR473-N': 2.0,
    'AR474-N': 1.0,
    'AR475-T': 4.0,
    'AR476-T': 4.0,
    'AR477-T': 3.0,
    'AR478-T': 4.0,
    'AR479-T': 2.0,
    'AR480-N': 4.0,
    'AR481-': 4.0,
    'AR482-T': 4.0,
    'AR483-N': 2.0,
    'AR484-T': 4.0,
    'AR485-N': 4.0,
    'AR486-N': 4.0,
    'AR488-N': 4.0,
    'AR489-T': 2.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  METHANOL: {
    'AR001-N': 3.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 3.0,
    'AR014-N': 3.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 3.0,
    'AR026-T': 3.0,
    'AR028-N': 1.0,
    'AR029-N': 3.0,
    'AR031-T': 1.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 3.0,
    'AR044-N': 2.0,
    'AR046-N': 2.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 3.0,
    'AR056-T': 2.0,
    'AR057-T': 1.0,
    'AR059-N': 3.0,
    'AR061-T': 2.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 3.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 2.0,
    'AR075-N': 2.0,
    'AR077-N': 1.0,
    'AR079-T': 2.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 1.0,
    'AR088-T': 2.0,
    'AR090-N': 4.0,
    'AR098-T': 2.0,
    'AR100-N': 3.0,
    'AR102-N': 2.0,
    'AR104-T': 1.0,
    'AR108-T': 1.0,
    'AR110-T': 2.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 4.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 1.0,
    'AR142-T': 3.0,
    'AR144-N': 3.0,
    'AR145-N': 4.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 2.0,
    'AR158-T': 3.0,
    'AR160-T': 1.0,
    'AR162-N': 4.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 1.0,
    'AR169-T': 4.0,
    'AR170-N': 3.0,
    'AR172-N': 3.0,
    'AR174-T': 4.0,
    'AR177-T': 4.0,
    'AR178-T': 3.0,
    'AR180-T': 4.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 4.0,
    'AR186-T': 2.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 1.0,
    'AR190-N': 3.0,
    'AR192-T': 1.0,
    'AR193-T': 2.0,
    'AR197-T': 3.0,
    'AR198-T': 4.0,
    'AR200-T': 1.0,
    'AR202-T': 2.0,
    'AR204-T': 3.0,
    'AR206-T': 4.0,
    'AR208-T': 1.0,
    'AR209-T': 4.0,
    'AR210-N': 2.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 4.0,
    'AR224-N': 2.0,
    'AR226-T': 1.0,
    'AR228-T': 1.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 3.0,
    'AR232-T': 4.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 1.0,
    'AR243-T': 2.0,
    'AR245-N': 1.0,
    'AR247-T': 2.0,
    'AR249-T': 1.0,
    'AR251-T': 2.0,
    'AR252-T': 4.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 2.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 3.0,
    'AR273-T': 3.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 4.0,
    'AR281-N': 3.0,
    'AR283-T': 4.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 4.0,
    'AR293-T': 2.0,
    'AR297-N': 3.0,
    'AR299-N': 3.0,
    'AR300-T': 4.0,
    'AR302-T': 3.0,
    'AR304-T': 2.0,
    'AR306-N': 4.0,
    'AR309-N': 2.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 3.0,
    'AR319-N': 2.0,
    'AR321-N': 2.0,
    'AR323-T': 4.0,
    'AR324-N': 1.0,
    'AR327-N': 1.0,
    'AR329-N': 2.0,
    'AR331-T': 2.0,
    'AR333-N': 2.0,
    'AR335-N': 3.0,
    'AR339-N': 2.0,
    'AR341-N': 4.0,
    'AR343-N': 3.0,
    'AR345-N': 2.0,
    'AR347-T': 4.0,
    'AR349-N': 4.0,
    'AR351-N': 3.0,
    'AR353-N': 3.0,
    'AR357-N': 4.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 3.0,
    'AR368-N': 4.0,
    'AR370-N': 1.0,
    'AR371-T': 2.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 3.0,
    'AR376-N': 2.0,
    'AR381-N': 1.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 2.0,
    'AR389-N': 2.0,
    'AR391-T': 2.0,
    'AR392-N': 3.0,
    'AR394-N': 1.0,
    'AR398-N': 2.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 2.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 4.0,
    'AR409-N': 2.0,
    'AR411-N': 3.0,
    'AR412-N': 3.0,
    'AR413-N': 2.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 3.0,
    'AR420-N': 1.0,
    'AR421-N': 4.0,
    'AR422-T': 4.0,
    'AR423-N': 2.0,
    'AR424-N': 4.0,
    'AR425-T': 4.0,
    'AR426-N': 3.0,
    'AR427-N': 4.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 4.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 3.0,
    'AR435-N': 4.0,
    'AR436-N': 2.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 2.0,
    'AR440-N': 3.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 1.0,
    'AR446-N': 2.0,
    'AR447-N': 4.0,
    'AR448-T': 3.0,
    'AR449-T': 2.0,
    'AR450-N': 1.0,
    'AR451-N': 2.0,
    'AR452-N': 3.0,
    'AR453-N': 2.0,
    'AR454-T': 1.0,
    'AR455-T': 3.0,
    'AR456-N': 1.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 1.0,
    'AR460-T': 4.0,
    'AR461-T': 4.0,
    'AR462-T': 3.0,
    'AR463-T': 4.0,
    'AR464-T': 1.0,
    'AR465-T': 3.0,
    'AR466-N': 3.0,
    'AR467-N': 4.0,
    'AR468-T': 2.0,
    'AR469-N': 2.0,
    'AR470-T': 4.0,
    'AR471-T': 3.0,
    'AR472-T': 2.0,
    'AR473-N': 2.0,
    'AR474-N': 1.0,
    'AR475-T': 4.0,
    'AR476-T': 4.0,
    'AR477-T': 3.0,
    'AR478-T': 4.0,
    'AR479-T': 3.0,
    'AR480-N': 4.0,
    'AR481-': 4.0,
    'AR482-T': 4.0,
    'AR483-N': 2.0,
    'AR484-T': 4.0,
    'AR485-N': 4.0,
    'AR486-N': 4.0,
    'AR488-N': 1.0,
    'AR489-T': 2.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'METHYL-BROMIDE--BROMOMETHANE-': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 4.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 3.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 4.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 4.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 3.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 3.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 3.0,
    'AR070-N': 2.0,
    'AR071-T': 4.0,
    'AR073-T': 3.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 3.0,
    'AR090-N': 2.0,
    'AR098-T': 3.0,
    'AR100-N': 4.0,
    'AR102-N': 2.0,
    'AR104-T': 2.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 1.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 1.0,
    'AR155-N': 2.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 2.0,
    'AR164-T': 4.0,
    'AR165-T': 3.0,
    'AR167-T': 2.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 2.0,
    'AR185-T': 2.0,
    'AR186-T': 3.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 4.0,
    'AR198-T': 2.0,
    'AR200-T': 2.0,
    'AR202-T': 3.0,
    'AR204-T': 4.0,
    'AR206-T': 1.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 4.0,
    'AR237-T': 2.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 4.0,
    'AR279-T': 1.0,
    'AR281-N': 4.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 1.0,
    'AR302-T': 3.0,
    'AR304-T': 3.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 3.0,
    'AR313-N': 4.0,
    'AR315-N': 4.0,
    'AR319-N': 3.0,
    'AR321-N': 3.0,
    'AR323-T': 2.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 4.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 3.0,
    'AR353-N': 4.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 4.0,
    'AR373-N': 4.0,
    'AR374-N': 4.0,
    'AR376-N': 3.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 3.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 3.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 2.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 4.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 3.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 3.0,
    'AR443-T': 2.0,
    'AR444-T': 2.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 2.0,
    'AR455-T': 3.0,
    'AR456-N': 2.0,
    'AR457-T': 3.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 3.0,
    'AR467-N': 1.0,
    'AR468-T': 3.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 4.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 4.0,
    'AR478-T': 1.0,
    'AR479-T': 3.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 2.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'METHYL-CHLORIDE--CHLOROMETHANE-': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 1.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 4.0,
    'AR019-N': 3.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 4.0,
    'AR029-N': 4.0,
    'AR031-T': 1.0,
    'AR033-N': 1.0,
    'AR035-T': 1.0,
    'AR037-T': 4.0,
    'AR042-T': 4.0,
    'AR044-N': 4.0,
    'AR046-N': 1.0,
    'AR048-N': 1.0,
    'AR050-N': 3.0,
    'AR052-T': 4.0,
    'AR054-N': 4.0,
    'AR056-T': 4.0,
    'AR057-T': 3.0,
    'AR059-N': 4.0,
    'AR061-T': 4.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 1.0,
    'AR070-N': 3.0,
    'AR071-T': 1.0,
    'AR073-T': 1.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 2.0,
    'AR081-T': 4.0,
    'AR083-T': 2.0,
    'AR085-N': 4.0,
    'AR088-T': 1.0,
    'AR090-N': 3.0,
    'AR098-T': 4.0,
    'AR100-N': 3.0,
    'AR102-N': 3.0,
    'AR104-T': 4.0,
    'AR108-T': 1.0,
    'AR110-T': 2.0,
    'AR112-T': 2.0,
    'AR115-T': 1.0,
    'AR118-T': 3.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 3.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 2.0,
    'AR140-N': 3.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 3.0,
    'AR147-N': 1.0,
    'AR148-N': 1.0,
    'AR150-T': 2.0,
    'AR152-T': 3.0,
    'AR154-T': 2.0,
    'AR155-N': 2.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 4.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 1.0,
    'AR167-T': 4.0,
    'AR169-T': 2.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 2.0,
    'AR182-N': 1.0,
    'AR183-T': 1.0,
    'AR185-T': 3.0,
    'AR186-T': 1.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 3.0,
    'AR190-N': 4.0,
    'AR192-T': 2.0,
    'AR193-T': 3.0,
    'AR197-T': 4.0,
    'AR198-T': 2.0,
    'AR200-T': 3.0,
    'AR202-T': 3.0,
    'AR204-T': 3.0,
    'AR206-T': 2.0,
    'AR208-T': 3.0,
    'AR209-T': 3.0,
    'AR210-N': 3.0,
    'AR211-N': 1.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 3.0,
    'AR220-T': 3.0,
    'AR222-N': 2.0,
    'AR224-N': 2.0,
    'AR226-T': 1.0,
    'AR228-T': 2.0,
    'AR229-N': 2.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 3.0,
    'AR237-T': 3.0,
    'AR238-T': 1.0,
    'AR239-T': 4.0,
    'AR242-N': 1.0,
    'AR243-T': 1.0,
    'AR245-N': 1.0,
    'AR247-T': 4.0,
    'AR249-T': 1.0,
    'AR251-T': 3.0,
    'AR252-T': 2.0,
    'AR254-T': 3.0,
    'AR256-T': 1.0,
    'AR258-T': 3.0,
    'AR259-T': 2.0,
    'AR261-N': 1.0,
    'AR262-T': 2.0,
    'AR264-N': 1.0,
    'AR266-T': 3.0,
    'AR268-N': 1.0,
    'AR271-T': 3.0,
    'AR273-T': 4.0,
    'AR275-T': 3.0,
    'AR277-T': 1.0,
    'AR279-T': 2.0,
    'AR281-N': 1.0,
    'AR283-T': 2.0,
    'AR285-T': 2.0,
    'AR287-T': 4.0,
    'AR289-T': 2.0,
    'AR293-T': 1.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 2.0,
    'AR302-T': 2.0,
    'AR304-T': 1.0,
    'AR306-N': 2.0,
    'AR309-N': 1.0,
    'AR311-N': 1.0,
    'AR313-N': 1.0,
    'AR315-N': 4.0,
    'AR319-N': 1.0,
    'AR321-N': 4.0,
    'AR323-T': 3.0,
    'AR324-N': 1.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 2.0,
    'AR335-N': 1.0,
    'AR339-N': 2.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 2.0,
    'AR349-N': 2.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 2.0,
    'AR363-N': 3.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 1.0,
    'AR371-T': 2.0,
    'AR372-N': 1.0,
    'AR373-N': 1.0,
    'AR374-N': 4.0,
    'AR376-N': 1.0,
    'AR381-N': 1.0,
    'AR382-N': 1.0,
    'AR384-N': 1.0,
    'AR387-N': 4.0,
    'AR389-N': 3.0,
    'AR391-T': 1.0,
    'AR392-N': 4.0,
    'AR394-N': 3.0,
    'AR398-N': 1.0,
    'AR400-T': 1.0,
    'AR402-N': 1.0,
    'AR404-N': 3.0,
    'AR406-N': 4.0,
    'AR407-N': 1.0,
    'AR408-N': 2.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 2.0,
    'AR420-N': 1.0,
    'AR421-N': 3.0,
    'AR422-T': 3.0,
    'AR423-N': 2.0,
    'AR424-N': 2.0,
    'AR425-T': 4.0,
    'AR426-N': 3.0,
    'AR427-N': 3.0,
    'AR428-T': 2.0,
    'AR429-T': 2.0,
    'AR430-N': 2.0,
    'AR431-T': 2.0,
    'AR432-N': 1.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 2.0,
    'AR436-N': 1.0,
    'AR437-N': 3.0,
    'AR438-T': 1.0,
    'AR439-T': 2.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 2.0,
    'AR443-T': 2.0,
    'AR444-T': 3.0,
    'AR445-N': 3.0,
    'AR446-N': 3.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 1.0,
    'AR450-N': 1.0,
    'AR451-N': 1.0,
    'AR452-N': 4.0,
    'AR453-N': 1.0,
    'AR454-T': 3.0,
    'AR455-T': 2.0,
    'AR456-N': 4.0,
    'AR457-T': 1.0,
    'AR458-T': 4.0,
    'AR459-T': 2.0,
    'AR460-T': 2.0,
    'AR461-T': 3.0,
    'AR462-T': 3.0,
    'AR463-T': 3.0,
    'AR464-T': 1.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 2.0,
    'AR468-T': 1.0,
    'AR469-N': 1.0,
    'AR470-T': 2.0,
    'AR471-T': 4.0,
    'AR472-T': 4.0,
    'AR473-N': 1.0,
    'AR474-N': 3.0,
    'AR475-T': 3.0,
    'AR476-T': 2.0,
    'AR477-T': 3.0,
    'AR478-T': 3.0,
    'AR479-T': 4.0,
    'AR480-N': 2.0,
    'AR481-': 4.0,
    'AR482-T': 2.0,
    'AR483-N': 1.0,
    'AR484-T': 2.0,
    'AR485-N': 4.0,
    'AR486-N': 1.0,
    'AR488-N': 2.0,
    'AR489-T': 1.0,
    'AR490-T': 3.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'METHYL-ISOBUTYL-KETONE--HEXONE-': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 3.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 3.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 3.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 2.0,
    'AR098-T': 3.0,
    'AR100-N': 3.0,
    'AR102-N': 2.0,
    'AR104-T': 1.0,
    'AR108-T': 4.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 3.0,
    'AR154-T': 2.0,
    'AR155-N': 2.0,
    'AR157-N': 4.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 4.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 2.0,
    'AR185-T': 2.0,
    'AR186-T': 3.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 3.0,
    'AR198-T': 2.0,
    'AR200-T': 2.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 2.0,
    'AR208-T': 1.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 2.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 2.0,
    'AR285-T': 1.0,
    'AR287-T': 3.0,
    'AR289-T': 2.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 2.0,
    'AR302-T': 3.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 4.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 4.0,
    'AR323-T': 2.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 4.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 4.0,
    'AR347-T': 2.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 3.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 4.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 2.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 2.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 2.0,
    'AR444-T': 2.0,
    'AR445-N': 1.0,
    'AR446-N': 4.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 2.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 2.0,
    'AR455-T': 3.0,
    'AR456-N': 3.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 2.0,
    'AR460-T': 2.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 2.0,
    'AR471-T': 3.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 2.0,
    'AR481-': 1.0,
    'AR482-T': 2.0,
    'AR483-N': 2.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 3.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'METHYL-METHACRYLATE': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 4.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 4.0,
    'AR029-N': 4.0,
    'AR031-T': 1.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 4.0,
    'AR044-N': 4.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 4.0,
    'AR052-T': 1.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 4.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 3.0,
    'AR085-N': 4.0,
    'AR088-T': 3.0,
    'AR090-N': 2.0,
    'AR098-T': 2.0,
    'AR100-N': 3.0,
    'AR102-N': 2.0,
    'AR104-T': 4.0,
    'AR108-T': 4.0,
    'AR110-T': 2.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 1.0,
    'AR150-T': 2.0,
    'AR152-T': 2.0,
    'AR154-T': 2.0,
    'AR155-N': 2.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 3.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 2.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 2.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 2.0,
    'AR186-T': 4.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 4.0,
    'AR193-T': 3.0,
    'AR197-T': 3.0,
    'AR198-T': 2.0,
    'AR200-T': 1.0,
    'AR202-T': 3.0,
    'AR204-T': 3.0,
    'AR206-T': 2.0,
    'AR208-T': 1.0,
    'AR209-T': 1.0,
    'AR210-N': 2.0,
    'AR211-N': 4.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 1.0,
    'AR222-N': 2.0,
    'AR224-N': 2.0,
    'AR226-T': 2.0,
    'AR228-T': 2.0,
    'AR229-N': 2.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 3.0,
    'AR242-N': 3.0,
    'AR243-T': 2.0,
    'AR245-N': 3.0,
    'AR247-T': 2.0,
    'AR249-T': 1.0,
    'AR251-T': 2.0,
    'AR252-T': 1.0,
    'AR254-T': 1.0,
    'AR256-T': 2.0,
    'AR258-T': 1.0,
    'AR259-T': 2.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 2.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 3.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 2.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 2.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 2.0,
    'AR302-T': 3.0,
    'AR304-T': 3.0,
    'AR306-N': 2.0,
    'AR309-N': 3.0,
    'AR311-N': 4.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 2.0,
    'AR323-T': 2.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 2.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 2.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 4.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 2.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 3.0,
    'AR381-N': 3.0,
    'AR382-N': 4.0,
    'AR384-N': 3.0,
    'AR387-N': 4.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 4.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 2.0,
    'AR406-N': 4.0,
    'AR407-N': 1.0,
    'AR408-N': 2.0,
    'AR409-N': 2.0,
    'AR411-N': 4.0,
    'AR412-N': 2.0,
    'AR413-N': 4.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 3.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 3.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 2.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 3.0,
    'AR434-N': 3.0,
    'AR435-N': 2.0,
    'AR436-N': 2.0,
    'AR437-N': 4.0,
    'AR438-T': 3.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 3.0,
    'AR443-T': 2.0,
    'AR444-T': 2.0,
    'AR445-N': 1.0,
    'AR446-N': 4.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 3.0,
    'AR451-N': 2.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 1.0,
    'AR455-T': 3.0,
    'AR456-N': 4.0,
    'AR457-T': 3.0,
    'AR458-T': 2.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 2.0,
    'AR463-T': 1.0,
    'AR464-T': 1.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 4.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 2.0,
    'AR482-T': 2.0,
    'AR483-N': 2.0,
    'AR484-T': 1.0,
    'AR485-N': 2.0,
    'AR486-N': 2.0,
    'AR488-N': 2.0,
    'AR489-T': 2.0,
    'AR490-T': 3.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'METHYL-TERT-BUTYL-ETHER': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 4.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 3.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 4.0,
    'AR028-N': 3.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 4.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 3.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 3.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 4.0,
    'AR073-T': 3.0,
    'AR075-N': 2.0,
    'AR077-N': 1.0,
    'AR079-T': 4.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 3.0,
    'AR088-T': 3.0,
    'AR090-N': 2.0,
    'AR098-T': 2.0,
    'AR100-N': 4.0,
    'AR102-N': 1.0,
    'AR104-T': 3.0,
    'AR108-T': 2.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 3.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 2.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 4.0,
    'AR165-T': 3.0,
    'AR167-T': 3.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 2.0,
    'AR183-T': 2.0,
    'AR185-T': 2.0,
    'AR186-T': 3.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 4.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 2.0,
    'AR204-T': 4.0,
    'AR206-T': 2.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 4.0,
    'AR237-T': 2.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 3.0,
    'AR243-T': 3.0,
    'AR245-N': 3.0,
    'AR247-T': 3.0,
    'AR249-T': 2.0,
    'AR251-T': 2.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 2.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 4.0,
    'AR279-T': 1.0,
    'AR281-N': 4.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 2.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 2.0,
    'AR302-T': 3.0,
    'AR304-T': 3.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 3.0,
    'AR313-N': 4.0,
    'AR315-N': 4.0,
    'AR319-N': 3.0,
    'AR321-N': 3.0,
    'AR323-T': 2.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 2.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 4.0,
    'AR339-N': 4.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 2.0,
    'AR349-N': 1.0,
    'AR351-N': 3.0,
    'AR353-N': 4.0,
    'AR357-N': 1.0,
    'AR363-N': 3.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 4.0,
    'AR373-N': 4.0,
    'AR374-N': 4.0,
    'AR376-N': 3.0,
    'AR381-N': 3.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 4.0,
    'AR389-N': 2.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 3.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 3.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 3.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 1.0,
    'AR420-N': 3.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 3.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 4.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 3.0,
    'AR439-T': 2.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 3.0,
    'AR443-T': 1.0,
    'AR444-T': 2.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 3.0,
    'AR451-N': 2.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 1.0,
    'AR455-T': 3.0,
    'AR456-N': 3.0,
    'AR457-T': 3.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 3.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 3.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 4.0,
    'AR472-T': 2.0,
    'AR473-N': 3.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 4.0,
    'AR478-T': 1.0,
    'AR479-T': 3.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 1.0,
    'AR483-N': 2.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 2.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  METHYLHYDRAZINE: {
    'AR001-N': 3.0,
    'AR003-T': 4.0,
    'AR008-N': 2.0,
    'AR012-T': 3.0,
    'AR014-N': 3.0,
    'AR018-T': 1.0,
    'AR019-N': 4.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 1.0,
    'AR029-N': 3.0,
    'AR031-T': 1.0,
    'AR033-N': 2.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 2.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 4.0,
    'AR059-N': 3.0,
    'AR061-T': 1.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 2.0,
    'AR070-N': 3.0,
    'AR071-T': 2.0,
    'AR073-T': 3.0,
    'AR075-N': 2.0,
    'AR077-N': 1.0,
    'AR079-T': 2.0,
    'AR081-T': 3.0,
    'AR083-T': 1.0,
    'AR085-N': 1.0,
    'AR088-T': 3.0,
    'AR090-N': 4.0,
    'AR098-T': 2.0,
    'AR100-N': 3.0,
    'AR102-N': 4.0,
    'AR104-T': 1.0,
    'AR108-T': 4.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 4.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 3.0,
    'AR145-N': 3.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 3.0,
    'AR158-T': 3.0,
    'AR160-T': 1.0,
    'AR162-N': 4.0,
    'AR164-T': 3.0,
    'AR165-T': 1.0,
    'AR167-T': 2.0,
    'AR169-T': 2.0,
    'AR170-N': 2.0,
    'AR172-N': 3.0,
    'AR174-T': 3.0,
    'AR177-T': 4.0,
    'AR178-T': 3.0,
    'AR180-T': 3.0,
    'AR182-N': 2.0,
    'AR183-T': 4.0,
    'AR185-T': 4.0,
    'AR186-T': 2.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 4.0,
    'AR190-N': 3.0,
    'AR192-T': 1.0,
    'AR193-T': 2.0,
    'AR197-T': 4.0,
    'AR198-T': 4.0,
    'AR200-T': 4.0,
    'AR202-T': 3.0,
    'AR204-T': 2.0,
    'AR206-T': 4.0,
    'AR208-T': 2.0,
    'AR209-T': 3.0,
    'AR210-N': 3.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 2.0,
    'AR220-T': 1.0,
    'AR222-N': 2.0,
    'AR224-N': 2.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 3.0,
    'AR232-T': 4.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 1.0,
    'AR243-T': 3.0,
    'AR245-N': 1.0,
    'AR247-T': 1.0,
    'AR249-T': 2.0,
    'AR251-T': 2.0,
    'AR252-T': 4.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 1.0,
    'AR277-T': 2.0,
    'AR279-T': 4.0,
    'AR281-N': 2.0,
    'AR283-T': 2.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 3.0,
    'AR293-T': 2.0,
    'AR297-N': 4.0,
    'AR299-N': 3.0,
    'AR300-T': 4.0,
    'AR302-T': 3.0,
    'AR304-T': 3.0,
    'AR306-N': 2.0,
    'AR309-N': 2.0,
    'AR311-N': 2.0,
    'AR313-N': 2.0,
    'AR315-N': 3.0,
    'AR319-N': 3.0,
    'AR321-N': 3.0,
    'AR323-T': 4.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 1.0,
    'AR331-T': 2.0,
    'AR333-N': 3.0,
    'AR335-N': 2.0,
    'AR339-N': 2.0,
    'AR341-N': 4.0,
    'AR343-N': 3.0,
    'AR345-N': 3.0,
    'AR347-T': 4.0,
    'AR349-N': 4.0,
    'AR351-N': 3.0,
    'AR353-N': 3.0,
    'AR357-N': 2.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 3.0,
    'AR368-N': 4.0,
    'AR370-N': 1.0,
    'AR371-T': 2.0,
    'AR372-N': 2.0,
    'AR373-N': 2.0,
    'AR374-N': 4.0,
    'AR376-N': 2.0,
    'AR381-N': 1.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 1.0,
    'AR389-N': 2.0,
    'AR391-T': 2.0,
    'AR392-N': 4.0,
    'AR394-N': 1.0,
    'AR398-N': 1.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 1.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 2.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 1.0,
    'AR414-N': 1.0,
    'AR416-N': 1.0,
    'AR418-T': 3.0,
    'AR420-N': 1.0,
    'AR421-N': 3.0,
    'AR422-T': 3.0,
    'AR423-N': 2.0,
    'AR424-N': 4.0,
    'AR425-T': 3.0,
    'AR426-N': 2.0,
    'AR427-N': 3.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 3.0,
    'AR432-N': 2.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 2.0,
    'AR436-N': 1.0,
    'AR437-N': 1.0,
    'AR438-T': 1.0,
    'AR439-T': 1.0,
    'AR440-N': 3.0,
    'AR441-T': 2.0,
    'AR442-T': 3.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 1.0,
    'AR446-N': 2.0,
    'AR447-N': 3.0,
    'AR448-T': 3.0,
    'AR449-T': 2.0,
    'AR450-N': 1.0,
    'AR451-N': 1.0,
    'AR452-N': 3.0,
    'AR453-N': 1.0,
    'AR454-T': 4.0,
    'AR455-T': 3.0,
    'AR456-N': 1.0,
    'AR457-T': 1.0,
    'AR458-T': 4.0,
    'AR459-T': 2.0,
    'AR460-T': 3.0,
    'AR461-T': 4.0,
    'AR462-T': 3.0,
    'AR463-T': 3.0,
    'AR464-T': 1.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 4.0,
    'AR468-T': 2.0,
    'AR469-N': 2.0,
    'AR470-T': 3.0,
    'AR471-T': 2.0,
    'AR472-T': 1.0,
    'AR473-N': 2.0,
    'AR474-N': 3.0,
    'AR475-T': 4.0,
    'AR476-T': 3.0,
    'AR477-T': 2.0,
    'AR478-T': 3.0,
    'AR479-T': 3.0,
    'AR480-N': 3.0,
    'AR481-': 4.0,
    'AR482-T': 4.0,
    'AR483-N': 2.0,
    'AR484-T': 4.0,
    'AR485-N': 4.0,
    'AR486-N': 3.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'N-N-DIMETHYLANILINE': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 3.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 1.0,
    'AR098-T': 4.0,
    'AR100-N': 3.0,
    'AR102-N': 1.0,
    'AR104-T': 2.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 1.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 1.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 3.0,
    'AR169-T': 2.0,
    'AR170-N': 4.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 2.0,
    'AR182-N': 3.0,
    'AR183-T': 2.0,
    'AR185-T': 1.0,
    'AR186-T': 3.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 3.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 1.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 2.0,
    'AR224-N': 2.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 1.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 3.0,
    'AR237-T': 2.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 4.0,
    'AR249-T': 2.0,
    'AR251-T': 4.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 2.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 1.0,
    'AR302-T': 3.0,
    'AR304-T': 4.0,
    'AR306-N': 2.0,
    'AR309-N': 4.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 2.0,
    'AR323-T': 1.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 4.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 2.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 3.0,
    'AR389-N': 4.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 3.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 2.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 2.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 3.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 2.0,
    'AR446-N': 4.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 2.0,
    'AR455-T': 3.0,
    'AR456-N': 2.0,
    'AR457-T': 3.0,
    'AR458-T': 3.0,
    'AR459-T': 2.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 3.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 4.0,
    'AR473-N': 3.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 2.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 2.0,
    'AR486-N': 2.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 1.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  NAPHTHALENE: {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 3.0,
    'AR057-T': 1.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 2.0,
    'AR098-T': 3.0,
    'AR100-N': 3.0,
    'AR102-N': 2.0,
    'AR104-T': 1.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 1.0,
    'AR155-N': 2.0,
    'AR157-N': 4.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 2.0,
    'AR169-T': 2.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 1.0,
    'AR185-T': 1.0,
    'AR186-T': 3.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 4.0,
    'AR198-T': 2.0,
    'AR200-T': 2.0,
    'AR202-T': 3.0,
    'AR204-T': 4.0,
    'AR206-T': 2.0,
    'AR208-T': 1.0,
    'AR209-T': 3.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 4.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 2.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 2.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 2.0,
    'AR302-T': 4.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 3.0,
    'AR323-T': 2.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 4.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 4.0,
    'AR347-T': 1.0,
    'AR349-N': 2.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 3.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 3.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 3.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 3.0,
    'AR427-N': 1.0,
    'AR428-T': 2.0,
    'AR429-T': 2.0,
    'AR430-N': 1.0,
    'AR431-T': 2.0,
    'AR432-N': 3.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 2.0,
    'AR436-N': 3.0,
    'AR437-N': 2.0,
    'AR438-T': 3.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 4.0,
    'AR443-T': 2.0,
    'AR444-T': 2.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 2.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 1.0,
    'AR455-T': 4.0,
    'AR456-N': 2.0,
    'AR457-T': 3.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 3.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 4.0,
    'AR472-T': 4.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 1.0,
    'AR476-T': 2.0,
    'AR477-T': 4.0,
    'AR478-T': 2.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 2.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 2.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'NICKEL-COMPOUNDS': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 4.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 4.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 1.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 1.0,
    'AR071-T': 4.0,
    'AR073-T': 3.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 4.0,
    'AR098-T': 3.0,
    'AR100-N': 3.0,
    'AR102-N': 3.0,
    'AR104-T': 1.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 1.0,
    'AR118-T': 3.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 4.0,
    'AR154-T': 2.0,
    'AR155-N': 3.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 1.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 3.0,
    'AR178-T': 4.0,
    'AR180-T': 2.0,
    'AR182-N': 3.0,
    'AR183-T': 1.0,
    'AR185-T': 2.0,
    'AR186-T': 3.0,
    'AR187-T': 3.0,
    'AR188-T': 3.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 3.0,
    'AR198-T': 3.0,
    'AR200-T': 1.0,
    'AR202-T': 3.0,
    'AR204-T': 3.0,
    'AR206-T': 3.0,
    'AR208-T': 1.0,
    'AR209-T': 2.0,
    'AR210-N': 3.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 1.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 3.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 3.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 2.0,
    'AR249-T': 1.0,
    'AR251-T': 2.0,
    'AR252-T': 2.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 3.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 1.0,
    'AR277-T': 4.0,
    'AR279-T': 1.0,
    'AR281-N': 4.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 1.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 3.0,
    'AR302-T': 4.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 2.0,
    'AR313-N': 4.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 3.0,
    'AR323-T': 4.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 4.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 2.0,
    'AR349-N': 2.0,
    'AR351-N': 3.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 4.0,
    'AR373-N': 4.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 2.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 2.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 2.0,
    'AR422-T': 2.0,
    'AR423-N': 2.0,
    'AR424-N': 2.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 2.0,
    'AR428-T': 2.0,
    'AR429-T': 3.0,
    'AR430-N': 2.0,
    'AR431-T': 1.0,
    'AR432-N': 4.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 2.0,
    'AR439-T': 2.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 4.0,
    'AR443-T': 3.0,
    'AR444-T': 3.0,
    'AR445-N': 1.0,
    'AR446-N': 4.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 2.0,
    'AR452-N': 4.0,
    'AR453-N': 2.0,
    'AR454-T': 1.0,
    'AR455-T': 4.0,
    'AR456-N': 2.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 1.0,
    'AR460-T': 2.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 2.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 2.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 2.0,
    'AR471-T': 3.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 2.0,
    'AR479-T': 3.0,
    'AR480-N': 2.0,
    'AR481-': 2.0,
    'AR482-T': 3.0,
    'AR483-N': 3.0,
    'AR484-T': 2.0,
    'AR485-N': 2.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 4.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  NITROBENZENE: {
    'AR001-N': 3.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 3.0,
    'AR018-T': 2.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 4.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 3.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 4.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 4.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 2.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 1.0,
    'AR098-T': 4.0,
    'AR100-N': 3.0,
    'AR102-N': 1.0,
    'AR104-T': 4.0,
    'AR108-T': 4.0,
    'AR110-T': 2.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 1.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 2.0,
    'AR152-T': 1.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 3.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 4.0,
    'AR167-T': 3.0,
    'AR169-T': 2.0,
    'AR170-N': 4.0,
    'AR172-N': 3.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 2.0,
    'AR185-T': 1.0,
    'AR186-T': 3.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 2.0,
    'AR193-T': 4.0,
    'AR197-T': 3.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 1.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 3.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 2.0,
    'AR224-N': 2.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 1.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 3.0,
    'AR237-T': 2.0,
    'AR238-T': 1.0,
    'AR239-T': 3.0,
    'AR242-N': 2.0,
    'AR243-T': 2.0,
    'AR245-N': 2.0,
    'AR247-T': 4.0,
    'AR249-T': 4.0,
    'AR251-T': 4.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 4.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 3.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 2.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 3.0,
    'AR300-T': 1.0,
    'AR302-T': 3.0,
    'AR304-T': 4.0,
    'AR306-N': 2.0,
    'AR309-N': 4.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 2.0,
    'AR323-T': 1.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 4.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 3.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 2.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 2.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 3.0,
    'AR389-N': 4.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 3.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 4.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 2.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 2.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 2.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 2.0,
    'AR436-N': 2.0,
    'AR437-N': 4.0,
    'AR438-T': 2.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 2.0,
    'AR446-N': 4.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 4.0,
    'AR452-N': 3.0,
    'AR453-N': 3.0,
    'AR454-T': 1.0,
    'AR455-T': 3.0,
    'AR456-N': 2.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 4.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 4.0,
    'AR473-N': 3.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 2.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 2.0,
    'AR486-N': 2.0,
    'AR488-N': 2.0,
    'AR489-T': 3.0,
    'AR490-T': 1.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'O-TOLUIDINE': {
    'AR001-N': 3.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 3.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 1.0,
    'AR029-N': 4.0,
    'AR031-T': 3.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 4.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 1.0,
    'AR098-T': 4.0,
    'AR100-N': 3.0,
    'AR102-N': 1.0,
    'AR104-T': 2.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 1.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 1.0,
    'AR154-T': 2.0,
    'AR155-N': 1.0,
    'AR157-N': 4.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 4.0,
    'AR167-T': 4.0,
    'AR169-T': 2.0,
    'AR170-N': 4.0,
    'AR172-N': 3.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 2.0,
    'AR182-N': 3.0,
    'AR183-T': 2.0,
    'AR185-T': 1.0,
    'AR186-T': 3.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 3.0,
    'AR198-T': 1.0,
    'AR200-T': 1.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 1.0,
    'AR208-T': 2.0,
    'AR209-T': 2.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 3.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 2.0,
    'AR224-N': 2.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 1.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 4.0,
    'AR249-T': 1.0,
    'AR251-T': 4.0,
    'AR252-T': 1.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 3.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 2.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 2.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 3.0,
    'AR300-T': 1.0,
    'AR302-T': 3.0,
    'AR304-T': 4.0,
    'AR306-N': 2.0,
    'AR309-N': 4.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 2.0,
    'AR323-T': 1.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 4.0,
    'AR331-T': 4.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 3.0,
    'AR345-N': 4.0,
    'AR347-T': 2.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 2.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 3.0,
    'AR389-N': 4.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 3.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 4.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 2.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 2.0,
    'AR420-N': 2.0,
    'AR421-N': 2.0,
    'AR422-T': 2.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 2.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 2.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 3.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 2.0,
    'AR446-N': 4.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 4.0,
    'AR452-N': 3.0,
    'AR453-N': 3.0,
    'AR454-T': 2.0,
    'AR455-T': 3.0,
    'AR456-N': 1.0,
    'AR457-T': 3.0,
    'AR458-T': 3.0,
    'AR459-T': 2.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 2.0,
    'AR463-T': 2.0,
    'AR464-T': 3.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 4.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 2.0,
    'AR479-T': 4.0,
    'AR480-N': 2.0,
    'AR481-': 2.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 2.0,
    'AR486-N': 2.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 1.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'P-PHENYLENEDIAMINE': {
    'AR001-N': 3.0,
    'AR003-T': 3.0,
    'AR008-N': 2.0,
    'AR012-T': 3.0,
    'AR014-N': 3.0,
    'AR018-T': 1.0,
    'AR019-N': 1.0,
    'AR021-N': 3.0,
    'AR026-T': 2.0,
    'AR028-N': 1.0,
    'AR029-N': 3.0,
    'AR031-T': 3.0,
    'AR033-N': 2.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 3.0,
    'AR044-N': 1.0,
    'AR046-N': 2.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 3.0,
    'AR056-T': 1.0,
    'AR057-T': 4.0,
    'AR059-N': 2.0,
    'AR061-T': 1.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 3.0,
    'AR068-N': 2.0,
    'AR070-N': 1.0,
    'AR071-T': 2.0,
    'AR073-T': 3.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 2.0,
    'AR081-T': 3.0,
    'AR083-T': 1.0,
    'AR085-N': 1.0,
    'AR088-T': 3.0,
    'AR090-N': 4.0,
    'AR098-T': 3.0,
    'AR100-N': 2.0,
    'AR102-N': 4.0,
    'AR104-T': 1.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 4.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 3.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 2.0,
    'AR142-T': 3.0,
    'AR144-N': 3.0,
    'AR145-N': 4.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 2.0,
    'AR158-T': 2.0,
    'AR160-T': 1.0,
    'AR162-N': 4.0,
    'AR164-T': 2.0,
    'AR165-T': 2.0,
    'AR167-T': 2.0,
    'AR169-T': 4.0,
    'AR170-N': 3.0,
    'AR172-N': 3.0,
    'AR174-T': 3.0,
    'AR177-T': 4.0,
    'AR178-T': 2.0,
    'AR180-T': 4.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 4.0,
    'AR186-T': 1.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 1.0,
    'AR190-N': 3.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 3.0,
    'AR198-T': 4.0,
    'AR200-T': 1.0,
    'AR202-T': 3.0,
    'AR204-T': 2.0,
    'AR206-T': 4.0,
    'AR208-T': 1.0,
    'AR209-T': 4.0,
    'AR210-N': 2.0,
    'AR211-N': 1.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 4.0,
    'AR224-N': 4.0,
    'AR226-T': 2.0,
    'AR228-T': 4.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 2.0,
    'AR232-T': 4.0,
    'AR233-T': 2.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 1.0,
    'AR243-T': 2.0,
    'AR245-N': 1.0,
    'AR247-T': 3.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 4.0,
    'AR254-T': 1.0,
    'AR256-T': 2.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 2.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 3.0,
    'AR273-T': 3.0,
    'AR275-T': 2.0,
    'AR277-T': 2.0,
    'AR279-T': 4.0,
    'AR281-N': 2.0,
    'AR283-T': 4.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 4.0,
    'AR293-T': 3.0,
    'AR297-N': 3.0,
    'AR299-N': 3.0,
    'AR300-T': 4.0,
    'AR302-T': 3.0,
    'AR304-T': 3.0,
    'AR306-N': 4.0,
    'AR309-N': 3.0,
    'AR311-N': 1.0,
    'AR313-N': 2.0,
    'AR315-N': 3.0,
    'AR319-N': 3.0,
    'AR321-N': 1.0,
    'AR323-T': 4.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 4.0,
    'AR331-T': 2.0,
    'AR333-N': 3.0,
    'AR335-N': 2.0,
    'AR339-N': 2.0,
    'AR341-N': 3.0,
    'AR343-N': 3.0,
    'AR345-N': 2.0,
    'AR347-T': 4.0,
    'AR349-N': 4.0,
    'AR351-N': 2.0,
    'AR353-N': 2.0,
    'AR357-N': 4.0,
    'AR363-N': 1.0,
    'AR365-T': 3.0,
    'AR367-N': 3.0,
    'AR368-N': 3.0,
    'AR370-N': 1.0,
    'AR371-T': 4.0,
    'AR372-N': 2.0,
    'AR373-N': 2.0,
    'AR374-N': 3.0,
    'AR376-N': 3.0,
    'AR381-N': 1.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 1.0,
    'AR389-N': 3.0,
    'AR391-T': 2.0,
    'AR392-N': 3.0,
    'AR394-N': 1.0,
    'AR398-N': 2.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 2.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 4.0,
    'AR409-N': 2.0,
    'AR411-N': 3.0,
    'AR412-N': 2.0,
    'AR413-N': 2.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 4.0,
    'AR420-N': 1.0,
    'AR421-N': 4.0,
    'AR422-T': 4.0,
    'AR423-N': 1.0,
    'AR424-N': 4.0,
    'AR425-T': 3.0,
    'AR426-N': 3.0,
    'AR427-N': 4.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 4.0,
    'AR432-N': 2.0,
    'AR433-T': 1.0,
    'AR434-N': 3.0,
    'AR435-N': 4.0,
    'AR436-N': 2.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 4.0,
    'AR440-N': 2.0,
    'AR441-T': 2.0,
    'AR442-T': 3.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 1.0,
    'AR446-N': 2.0,
    'AR447-N': 4.0,
    'AR448-T': 3.0,
    'AR449-T': 2.0,
    'AR450-N': 1.0,
    'AR451-N': 3.0,
    'AR452-N': 3.0,
    'AR453-N': 2.0,
    'AR454-T': 4.0,
    'AR455-T': 3.0,
    'AR456-N': 1.0,
    'AR457-T': 2.0,
    'AR458-T': 2.0,
    'AR459-T': 4.0,
    'AR460-T': 4.0,
    'AR461-T': 4.0,
    'AR462-T': 4.0,
    'AR463-T': 4.0,
    'AR464-T': 3.0,
    'AR465-T': 4.0,
    'AR466-N': 3.0,
    'AR467-N': 4.0,
    'AR468-T': 3.0,
    'AR469-N': 2.0,
    'AR470-T': 4.0,
    'AR471-T': 2.0,
    'AR472-T': 4.0,
    'AR473-N': 2.0,
    'AR474-N': 1.0,
    'AR475-T': 4.0,
    'AR476-T': 4.0,
    'AR477-T': 2.0,
    'AR478-T': 4.0,
    'AR479-T': 2.0,
    'AR480-N': 4.0,
    'AR481-': 1.0,
    'AR482-T': 4.0,
    'AR483-N': 2.0,
    'AR484-T': 4.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 2.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  PAHPOM: {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 3.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 3.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 4.0,
    'AR052-T': 3.0,
    'AR054-N': 4.0,
    'AR056-T': 3.0,
    'AR057-T': 3.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 4.0,
    'AR071-T': 3.0,
    'AR073-T': 3.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 3.0,
    'AR090-N': 2.0,
    'AR098-T': 3.0,
    'AR100-N': 3.0,
    'AR102-N': 2.0,
    'AR104-T': 4.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 1.0,
    'AR118-T': 1.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 1.0,
    'AR130-T': 3.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 3.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 1.0,
    'AR155-N': 2.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 3.0,
    'AR169-T': 2.0,
    'AR170-N': 2.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 3.0,
    'AR185-T': 1.0,
    'AR186-T': 2.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 4.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 4.0,
    'AR198-T': 2.0,
    'AR200-T': 3.0,
    'AR202-T': 3.0,
    'AR204-T': 4.0,
    'AR206-T': 1.0,
    'AR208-T': 1.0,
    'AR209-T': 2.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 3.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 3.0,
    'AR237-T': 4.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 2.0,
    'AR249-T': 2.0,
    'AR251-T': 2.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 4.0,
    'AR259-T': 2.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 3.0,
    'AR289-T': 2.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 1.0,
    'AR302-T': 4.0,
    'AR304-T': 3.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 3.0,
    'AR323-T': 2.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 2.0,
    'AR331-T': 3.0,
    'AR333-N': 4.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 3.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 3.0,
    'AR381-N': 2.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 3.0,
    'AR389-N': 2.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 3.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 2.0,
    'AR406-N': 3.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 2.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 3.0,
    'AR423-N': 2.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 2.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 2.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 4.0,
    'AR443-T': 2.0,
    'AR444-T': 1.0,
    'AR445-N': 4.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 2.0,
    'AR452-N': 4.0,
    'AR453-N': 2.0,
    'AR454-T': 1.0,
    'AR455-T': 4.0,
    'AR456-N': 2.0,
    'AR457-T': 2.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 2.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 2.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 4.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 4.0,
    'AR478-T': 2.0,
    'AR479-T': 3.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 1.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 2.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  PHENOL: {
    'AR001-N': 2.0,
    'AR003-T': 3.0,
    'AR008-N': 2.0,
    'AR012-T': 3.0,
    'AR014-N': 2.0,
    'AR018-T': 2.0,
    'AR019-N': 3.0,
    'AR021-N': 3.0,
    'AR026-T': 2.0,
    'AR028-N': 3.0,
    'AR029-N': 2.0,
    'AR031-T': 2.0,
    'AR033-N': 2.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 3.0,
    'AR044-N': 2.0,
    'AR046-N': 2.0,
    'AR048-N': 1.0,
    'AR050-N': 4.0,
    'AR052-T': 3.0,
    'AR054-N': 3.0,
    'AR056-T': 3.0,
    'AR057-T': 3.0,
    'AR059-N': 3.0,
    'AR061-T': 2.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 3.0,
    'AR068-N': 3.0,
    'AR070-N': 3.0,
    'AR071-T': 2.0,
    'AR073-T': 2.0,
    'AR075-N': 1.0,
    'AR077-N': 1.0,
    'AR079-T': 1.0,
    'AR081-T': 3.0,
    'AR083-T': 4.0,
    'AR085-N': 1.0,
    'AR088-T': 2.0,
    'AR090-N': 4.0,
    'AR098-T': 1.0,
    'AR100-N': 2.0,
    'AR102-N': 4.0,
    'AR104-T': 4.0,
    'AR108-T': 2.0,
    'AR110-T': 4.0,
    'AR112-T': 2.0,
    'AR115-T': 1.0,
    'AR118-T': 4.0,
    'AR122-N': 2.0,
    'AR124-N': 2.0,
    'AR128-T': 1.0,
    'AR130-T': 3.0,
    'AR132-T': 3.0,
    'AR136-T': 1.0,
    'AR138-T': 2.0,
    'AR140-N': 1.0,
    'AR142-T': 3.0,
    'AR144-N': 3.0,
    'AR145-N': 4.0,
    'AR146-T': 3.0,
    'AR147-N': 1.0,
    'AR148-N': 1.0,
    'AR150-T': 4.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 2.0,
    'AR158-T': 3.0,
    'AR160-T': 2.0,
    'AR162-N': 4.0,
    'AR164-T': 1.0,
    'AR165-T': 1.0,
    'AR167-T': 2.0,
    'AR169-T': 4.0,
    'AR170-N': 1.0,
    'AR172-N': 2.0,
    'AR174-T': 3.0,
    'AR177-T': 4.0,
    'AR178-T': 3.0,
    'AR180-T': 4.0,
    'AR182-N': 2.0,
    'AR183-T': 3.0,
    'AR185-T': 4.0,
    'AR186-T': 1.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 3.0,
    'AR190-N': 3.0,
    'AR192-T': 4.0,
    'AR193-T': 1.0,
    'AR197-T': 3.0,
    'AR198-T': 4.0,
    'AR200-T': 2.0,
    'AR202-T': 2.0,
    'AR204-T': 1.0,
    'AR206-T': 3.0,
    'AR208-T': 1.0,
    'AR209-T': 4.0,
    'AR210-N': 2.0,
    'AR211-N': 1.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 2.0,
    'AR219-T': 3.0,
    'AR220-T': 1.0,
    'AR222-N': 4.0,
    'AR224-N': 4.0,
    'AR226-T': 2.0,
    'AR228-T': 4.0,
    'AR229-N': 4.0,
    'AR230-N': 3.0,
    'AR231-T': 3.0,
    'AR232-T': 4.0,
    'AR233-T': 2.0,
    'AR237-T': 4.0,
    'AR238-T': 1.0,
    'AR239-T': 3.0,
    'AR242-N': 1.0,
    'AR243-T': 2.0,
    'AR245-N': 1.0,
    'AR247-T': 1.0,
    'AR249-T': 1.0,
    'AR251-T': 1.0,
    'AR252-T': 4.0,
    'AR254-T': 3.0,
    'AR256-T': 2.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 4.0,
    'AR264-N': 2.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 3.0,
    'AR273-T': 3.0,
    'AR275-T': 1.0,
    'AR277-T': 2.0,
    'AR279-T': 3.0,
    'AR281-N': 2.0,
    'AR283-T': 4.0,
    'AR285-T': 4.0,
    'AR287-T': 2.0,
    'AR289-T': 4.0,
    'AR293-T': 2.0,
    'AR297-N': 3.0,
    'AR299-N': 2.0,
    'AR300-T': 3.0,
    'AR302-T': 2.0,
    'AR304-T': 2.0,
    'AR306-N': 4.0,
    'AR309-N': 3.0,
    'AR311-N': 1.0,
    'AR313-N': 2.0,
    'AR315-N': 2.0,
    'AR319-N': 3.0,
    'AR321-N': 3.0,
    'AR323-T': 4.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 2.0,
    'AR331-T': 1.0,
    'AR333-N': 2.0,
    'AR335-N': 2.0,
    'AR339-N': 1.0,
    'AR341-N': 3.0,
    'AR343-N': 2.0,
    'AR345-N': 2.0,
    'AR347-T': 4.0,
    'AR349-N': 4.0,
    'AR351-N': 2.0,
    'AR353-N': 2.0,
    'AR357-N': 4.0,
    'AR363-N': 2.0,
    'AR365-T': 3.0,
    'AR367-N': 3.0,
    'AR368-N': 3.0,
    'AR370-N': 1.0,
    'AR371-T': 4.0,
    'AR372-N': 2.0,
    'AR373-N': 2.0,
    'AR374-N': 3.0,
    'AR376-N': 1.0,
    'AR381-N': 1.0,
    'AR382-N': 1.0,
    'AR384-N': 1.0,
    'AR387-N': 2.0,
    'AR389-N': 1.0,
    'AR391-T': 1.0,
    'AR392-N': 3.0,
    'AR394-N': 3.0,
    'AR398-N': 1.0,
    'AR400-T': 1.0,
    'AR402-N': 1.0,
    'AR404-N': 1.0,
    'AR406-N': 3.0,
    'AR407-N': 1.0,
    'AR408-N': 4.0,
    'AR409-N': 2.0,
    'AR411-N': 3.0,
    'AR412-N': 2.0,
    'AR413-N': 1.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 4.0,
    'AR420-N': 1.0,
    'AR421-N': 4.0,
    'AR422-T': 4.0,
    'AR423-N': 1.0,
    'AR424-N': 4.0,
    'AR425-T': 3.0,
    'AR426-N': 2.0,
    'AR427-N': 4.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 4.0,
    'AR432-N': 2.0,
    'AR433-T': 1.0,
    'AR434-N': 3.0,
    'AR435-N': 4.0,
    'AR436-N': 1.0,
    'AR437-N': 3.0,
    'AR438-T': 1.0,
    'AR439-T': 4.0,
    'AR440-N': 3.0,
    'AR441-T': 1.0,
    'AR442-T': 2.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 3.0,
    'AR446-N': 2.0,
    'AR447-N': 4.0,
    'AR448-T': 3.0,
    'AR449-T': 1.0,
    'AR450-N': 1.0,
    'AR451-N': 1.0,
    'AR452-N': 2.0,
    'AR453-N': 1.0,
    'AR454-T': 4.0,
    'AR455-T': 2.0,
    'AR456-N': 3.0,
    'AR457-T': 1.0,
    'AR458-T': 2.0,
    'AR459-T': 4.0,
    'AR460-T': 4.0,
    'AR461-T': 3.0,
    'AR462-T': 4.0,
    'AR463-T': 4.0,
    'AR464-T': 1.0,
    'AR465-T': 3.0,
    'AR466-N': 3.0,
    'AR467-N': 4.0,
    'AR468-T': 3.0,
    'AR469-N': 1.0,
    'AR470-T': 4.0,
    'AR471-T': 1.0,
    'AR472-T': 3.0,
    'AR473-N': 1.0,
    'AR474-N': 3.0,
    'AR475-T': 3.0,
    'AR476-T': 4.0,
    'AR477-T': 1.0,
    'AR478-T': 4.0,
    'AR479-T': 2.0,
    'AR480-N': 4.0,
    'AR481-': 3.0,
    'AR482-T': 3.0,
    'AR483-N': 1.0,
    'AR484-T': 4.0,
    'AR485-N': 3.0,
    'AR486-N': 3.0,
    'AR488-N': 4.0,
    'AR489-T': 2.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  PHOSPHINE: {
    'AR001-N': 3.0,
    'AR003-T': 2.0,
    'AR008-N': 2.0,
    'AR012-T': 2.0,
    'AR014-N': 3.0,
    'AR018-T': 1.0,
    'AR019-N': 3.0,
    'AR021-N': 3.0,
    'AR026-T': 2.0,
    'AR028-N': 1.0,
    'AR029-N': 3.0,
    'AR031-T': 3.0,
    'AR033-N': 2.0,
    'AR035-T': 1.0,
    'AR037-T': 3.0,
    'AR042-T': 3.0,
    'AR044-N': 1.0,
    'AR046-N': 2.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 3.0,
    'AR056-T': 1.0,
    'AR057-T': 3.0,
    'AR059-N': 2.0,
    'AR061-T': 1.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 2.0,
    'AR068-N': 2.0,
    'AR070-N': 3.0,
    'AR071-T': 2.0,
    'AR073-T': 3.0,
    'AR075-N': 2.0,
    'AR077-N': 1.0,
    'AR079-T': 2.0,
    'AR081-T': 2.0,
    'AR083-T': 3.0,
    'AR085-N': 1.0,
    'AR088-T': 3.0,
    'AR090-N': 4.0,
    'AR098-T': 3.0,
    'AR100-N': 2.0,
    'AR102-N': 4.0,
    'AR104-T': 1.0,
    'AR108-T': 3.0,
    'AR110-T': 4.0,
    'AR112-T': 2.0,
    'AR115-T': 1.0,
    'AR118-T': 4.0,
    'AR122-N': 2.0,
    'AR124-N': 2.0,
    'AR128-T': 1.0,
    'AR130-T': 4.0,
    'AR132-T': 2.0,
    'AR136-T': 1.0,
    'AR138-T': 2.0,
    'AR140-N': 3.0,
    'AR142-T': 3.0,
    'AR144-N': 2.0,
    'AR145-N': 4.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 1.0,
    'AR150-T': 1.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 2.0,
    'AR158-T': 2.0,
    'AR160-T': 1.0,
    'AR162-N': 4.0,
    'AR164-T': 2.0,
    'AR165-T': 3.0,
    'AR167-T': 3.0,
    'AR169-T': 4.0,
    'AR170-N': 3.0,
    'AR172-N': 3.0,
    'AR174-T': 2.0,
    'AR177-T': 4.0,
    'AR178-T': 2.0,
    'AR180-T': 4.0,
    'AR182-N': 1.0,
    'AR183-T': 4.0,
    'AR185-T': 4.0,
    'AR186-T': 1.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 3.0,
    'AR190-N': 2.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 2.0,
    'AR198-T': 4.0,
    'AR200-T': 3.0,
    'AR202-T': 3.0,
    'AR204-T': 2.0,
    'AR206-T': 4.0,
    'AR208-T': 3.0,
    'AR209-T': 4.0,
    'AR210-N': 1.0,
    'AR211-N': 1.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 1.0,
    'AR220-T': 3.0,
    'AR222-N': 4.0,
    'AR224-N': 4.0,
    'AR226-T': 1.0,
    'AR228-T': 1.0,
    'AR229-N': 4.0,
    'AR230-N': 3.0,
    'AR231-T': 2.0,
    'AR232-T': 4.0,
    'AR233-T': 2.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 1.0,
    'AR243-T': 2.0,
    'AR245-N': 1.0,
    'AR247-T': 3.0,
    'AR249-T': 3.0,
    'AR251-T': 3.0,
    'AR252-T': 4.0,
    'AR254-T': 4.0,
    'AR256-T': 2.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 3.0,
    'AR264-N': 2.0,
    'AR266-T': 3.0,
    'AR268-N': 1.0,
    'AR271-T': 3.0,
    'AR273-T': 3.0,
    'AR275-T': 3.0,
    'AR277-T': 2.0,
    'AR279-T': 4.0,
    'AR281-N': 2.0,
    'AR283-T': 4.0,
    'AR285-T': 3.0,
    'AR287-T': 3.0,
    'AR289-T': 4.0,
    'AR293-T': 3.0,
    'AR297-N': 2.0,
    'AR299-N': 3.0,
    'AR300-T': 4.0,
    'AR302-T': 2.0,
    'AR304-T': 3.0,
    'AR306-N': 4.0,
    'AR309-N': 2.0,
    'AR311-N': 1.0,
    'AR313-N': 2.0,
    'AR315-N': 3.0,
    'AR319-N': 2.0,
    'AR321-N': 1.0,
    'AR323-T': 4.0,
    'AR324-N': 1.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 2.0,
    'AR333-N': 2.0,
    'AR335-N': 2.0,
    'AR339-N': 2.0,
    'AR341-N': 2.0,
    'AR343-N': 3.0,
    'AR345-N': 2.0,
    'AR347-T': 4.0,
    'AR349-N': 4.0,
    'AR351-N': 2.0,
    'AR353-N': 2.0,
    'AR357-N': 4.0,
    'AR363-N': 1.0,
    'AR365-T': 2.0,
    'AR367-N': 3.0,
    'AR368-N': 2.0,
    'AR370-N': 1.0,
    'AR371-T': 4.0,
    'AR372-N': 2.0,
    'AR373-N': 2.0,
    'AR374-N': 3.0,
    'AR376-N': 3.0,
    'AR381-N': 1.0,
    'AR382-N': 1.0,
    'AR384-N': 1.0,
    'AR387-N': 1.0,
    'AR389-N': 3.0,
    'AR391-T': 2.0,
    'AR392-N': 3.0,
    'AR394-N': 1.0,
    'AR398-N': 1.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 3.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 4.0,
    'AR409-N': 1.0,
    'AR411-N': 3.0,
    'AR412-N': 2.0,
    'AR413-N': 1.0,
    'AR414-N': 1.0,
    'AR416-N': 1.0,
    'AR418-T': 4.0,
    'AR420-N': 1.0,
    'AR421-N': 4.0,
    'AR422-T': 4.0,
    'AR423-N': 1.0,
    'AR424-N': 4.0,
    'AR425-T': 2.0,
    'AR426-N': 3.0,
    'AR427-N': 4.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 4.0,
    'AR432-N': 2.0,
    'AR433-T': 1.0,
    'AR434-N': 3.0,
    'AR435-N': 4.0,
    'AR436-N': 1.0,
    'AR437-N': 1.0,
    'AR438-T': 1.0,
    'AR439-T': 3.0,
    'AR440-N': 2.0,
    'AR441-T': 2.0,
    'AR442-T': 2.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 1.0,
    'AR446-N': 2.0,
    'AR447-N': 4.0,
    'AR448-T': 3.0,
    'AR449-T': 2.0,
    'AR450-N': 1.0,
    'AR451-N': 3.0,
    'AR452-N': 3.0,
    'AR453-N': 1.0,
    'AR454-T': 4.0,
    'AR455-T': 2.0,
    'AR456-N': 1.0,
    'AR457-T': 1.0,
    'AR458-T': 2.0,
    'AR459-T': 3.0,
    'AR460-T': 4.0,
    'AR461-T': 4.0,
    'AR462-T': 4.0,
    'AR463-T': 4.0,
    'AR464-T': 3.0,
    'AR465-T': 3.0,
    'AR466-N': 3.0,
    'AR467-N': 4.0,
    'AR468-T': 3.0,
    'AR469-N': 1.0,
    'AR470-T': 4.0,
    'AR471-T': 2.0,
    'AR472-T': 3.0,
    'AR473-N': 2.0,
    'AR474-N': 3.0,
    'AR475-T': 4.0,
    'AR476-T': 4.0,
    'AR477-T': 2.0,
    'AR478-T': 4.0,
    'AR479-T': 2.0,
    'AR480-N': 4.0,
    'AR481-': 4.0,
    'AR482-T': 4.0,
    'AR483-N': 1.0,
    'AR484-T': 4.0,
    'AR485-N': 4.0,
    'AR486-N': 4.0,
    'AR488-N': 3.0,
    'AR489-T': 2.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  PHOSPHORUS: {
    'AR001-N': 2.0,
    'AR003-T': 3.0,
    'AR008-N': 3.0,
    'AR012-T': 3.0,
    'AR014-N': 2.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 2.0,
    'AR028-N': 2.0,
    'AR029-N': 3.0,
    'AR031-T': 1.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 3.0,
    'AR044-N': 2.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 4.0,
    'AR056-T': 1.0,
    'AR057-T': 1.0,
    'AR059-N': 3.0,
    'AR061-T': 2.0,
    'AR063-T': 2.0,
    'AR064-T': 1.0,
    'AR066-T': 2.0,
    'AR068-N': 4.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 3.0,
    'AR075-N': 2.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 3.0,
    'AR090-N': 4.0,
    'AR098-T': 2.0,
    'AR100-N': 2.0,
    'AR102-N': 4.0,
    'AR104-T': 1.0,
    'AR108-T': 1.0,
    'AR110-T': 3.0,
    'AR112-T': 2.0,
    'AR115-T': 1.0,
    'AR118-T': 4.0,
    'AR122-N': 2.0,
    'AR124-N': 2.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 3.0,
    'AR136-T': 1.0,
    'AR138-T': 2.0,
    'AR140-N': 1.0,
    'AR142-T': 3.0,
    'AR144-N': 3.0,
    'AR145-N': 3.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 1.0,
    'AR150-T': 1.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 3.0,
    'AR158-T': 3.0,
    'AR160-T': 1.0,
    'AR162-N': 4.0,
    'AR164-T': 3.0,
    'AR165-T': 1.0,
    'AR167-T': 1.0,
    'AR169-T': 3.0,
    'AR170-N': 3.0,
    'AR172-N': 2.0,
    'AR174-T': 4.0,
    'AR177-T': 4.0,
    'AR178-T': 3.0,
    'AR180-T': 3.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 4.0,
    'AR186-T': 3.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 1.0,
    'AR190-N': 3.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 1.0,
    'AR198-T': 4.0,
    'AR200-T': 1.0,
    'AR202-T': 3.0,
    'AR204-T': 3.0,
    'AR206-T': 4.0,
    'AR208-T': 1.0,
    'AR209-T': 3.0,
    'AR210-N': 2.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 2.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 3.0,
    'AR224-N': 3.0,
    'AR226-T': 1.0,
    'AR228-T': 3.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 3.0,
    'AR232-T': 4.0,
    'AR233-T': 2.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 2.0,
    'AR243-T': 2.0,
    'AR245-N': 2.0,
    'AR247-T': 2.0,
    'AR249-T': 1.0,
    'AR251-T': 1.0,
    'AR252-T': 4.0,
    'AR254-T': 1.0,
    'AR256-T': 1.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 1.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 2.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 4.0,
    'AR281-N': 3.0,
    'AR283-T': 3.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 4.0,
    'AR293-T': 3.0,
    'AR297-N': 3.0,
    'AR299-N': 2.0,
    'AR300-T': 4.0,
    'AR302-T': 2.0,
    'AR304-T': 3.0,
    'AR306-N': 3.0,
    'AR309-N': 3.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 3.0,
    'AR319-N': 4.0,
    'AR321-N': 2.0,
    'AR323-T': 4.0,
    'AR324-N': 1.0,
    'AR327-N': 1.0,
    'AR329-N': 2.0,
    'AR331-T': 4.0,
    'AR333-N': 2.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 2.0,
    'AR343-N': 2.0,
    'AR345-N': 3.0,
    'AR347-T': 4.0,
    'AR349-N': 4.0,
    'AR351-N': 4.0,
    'AR353-N': 2.0,
    'AR357-N': 3.0,
    'AR363-N': 2.0,
    'AR365-T': 2.0,
    'AR367-N': 4.0,
    'AR368-N': 3.0,
    'AR370-N': 2.0,
    'AR371-T': 3.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 2.0,
    'AR387-N': 2.0,
    'AR389-N': 2.0,
    'AR391-T': 2.0,
    'AR392-N': 4.0,
    'AR394-N': 1.0,
    'AR398-N': 2.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 2.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 3.0,
    'AR409-N': 2.0,
    'AR411-N': 3.0,
    'AR412-N': 2.0,
    'AR413-N': 2.0,
    'AR414-N': 1.0,
    'AR416-N': 1.0,
    'AR418-T': 4.0,
    'AR420-N': 2.0,
    'AR421-N': 3.0,
    'AR422-T': 3.0,
    'AR423-N': 2.0,
    'AR424-N': 4.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 3.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 4.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 2.0,
    'AR435-N': 3.0,
    'AR436-N': 1.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 4.0,
    'AR440-N': 3.0,
    'AR441-T': 3.0,
    'AR442-T': 2.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 1.0,
    'AR446-N': 4.0,
    'AR447-N': 4.0,
    'AR448-T': 4.0,
    'AR449-T': 2.0,
    'AR450-N': 2.0,
    'AR451-N': 1.0,
    'AR452-N': 2.0,
    'AR453-N': 2.0,
    'AR454-T': 4.0,
    'AR455-T': 2.0,
    'AR456-N': 2.0,
    'AR457-T': 2.0,
    'AR458-T': 1.0,
    'AR459-T': 3.0,
    'AR460-T': 4.0,
    'AR461-T': 4.0,
    'AR462-T': 3.0,
    'AR463-T': 3.0,
    'AR464-T': 1.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 4.0,
    'AR468-T': 3.0,
    'AR469-N': 3.0,
    'AR470-T': 4.0,
    'AR471-T': 3.0,
    'AR472-T': 2.0,
    'AR473-N': 2.0,
    'AR474-N': 1.0,
    'AR475-T': 4.0,
    'AR476-T': 4.0,
    'AR477-T': 3.0,
    'AR478-T': 3.0,
    'AR479-T': 4.0,
    'AR480-N': 4.0,
    'AR481-': 4.0,
    'AR482-T': 4.0,
    'AR483-N': 2.0,
    'AR484-T': 4.0,
    'AR485-N': 4.0,
    'AR486-N': 4.0,
    'AR488-N': 2.0,
    'AR489-T': 1.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'PHTHALIC-ANHYDRIDE': {
    'AR001-N': 3.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 3.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 1.0,
    'AR029-N': 4.0,
    'AR031-T': 3.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 1.0,
    'AR046-N': 2.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 4.0,
    'AR059-N': 3.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 3.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 4.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 3.0,
    'AR083-T': 1.0,
    'AR085-N': 1.0,
    'AR088-T': 4.0,
    'AR090-N': 3.0,
    'AR098-T': 4.0,
    'AR100-N': 3.0,
    'AR102-N': 4.0,
    'AR104-T': 1.0,
    'AR108-T': 4.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 1.0,
    'AR118-T': 3.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 3.0,
    'AR154-T': 2.0,
    'AR155-N': 3.0,
    'AR157-N': 4.0,
    'AR158-T': 3.0,
    'AR160-T': 2.0,
    'AR162-N': 3.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 3.0,
    'AR169-T': 1.0,
    'AR170-N': 4.0,
    'AR172-N': 3.0,
    'AR174-T': 3.0,
    'AR177-T': 2.0,
    'AR178-T': 3.0,
    'AR180-T': 2.0,
    'AR182-N': 3.0,
    'AR183-T': 1.0,
    'AR185-T': 3.0,
    'AR186-T': 2.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 4.0,
    'AR198-T': 3.0,
    'AR200-T': 1.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 2.0,
    'AR208-T': 1.0,
    'AR209-T': 2.0,
    'AR210-N': 3.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 2.0,
    'AR220-T': 1.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 1.0,
    'AR231-T': 3.0,
    'AR232-T': 2.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 1.0,
    'AR243-T': 3.0,
    'AR245-N': 1.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 4.0,
    'AR252-T': 2.0,
    'AR254-T': 1.0,
    'AR256-T': 4.0,
    'AR258-T': 1.0,
    'AR259-T': 2.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 4.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 3.0,
    'AR281-N': 3.0,
    'AR283-T': 2.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 2.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 3.0,
    'AR300-T': 2.0,
    'AR302-T': 4.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 4.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 2.0,
    'AR323-T': 3.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 4.0,
    'AR331-T': 3.0,
    'AR333-N': 4.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 3.0,
    'AR345-N': 4.0,
    'AR347-T': 2.0,
    'AR349-N': 2.0,
    'AR351-N': 3.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 1.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 1.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 1.0,
    'AR389-N': 4.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 1.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 2.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 2.0,
    'AR420-N': 1.0,
    'AR421-N': 2.0,
    'AR422-T': 2.0,
    'AR423-N': 1.0,
    'AR424-N': 2.0,
    'AR425-T': 3.0,
    'AR426-N': 4.0,
    'AR427-N': 2.0,
    'AR428-T': 2.0,
    'AR429-T': 3.0,
    'AR430-N': 2.0,
    'AR431-T': 3.0,
    'AR432-N': 3.0,
    'AR433-T': 1.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 2.0,
    'AR439-T': 3.0,
    'AR440-N': 3.0,
    'AR441-T': 2.0,
    'AR442-T': 4.0,
    'AR443-T': 3.0,
    'AR444-T': 3.0,
    'AR445-N': 1.0,
    'AR446-N': 3.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 3.0,
    'AR453-N': 2.0,
    'AR454-T': 3.0,
    'AR455-T': 4.0,
    'AR456-N': 1.0,
    'AR457-T': 2.0,
    'AR458-T': 4.0,
    'AR459-T': 2.0,
    'AR460-T': 2.0,
    'AR461-T': 2.0,
    'AR462-T': 2.0,
    'AR463-T': 2.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 2.0,
    'AR468-T': 4.0,
    'AR469-N': 2.0,
    'AR470-T': 2.0,
    'AR471-T': 2.0,
    'AR472-T': 4.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 2.0,
    'AR476-T': 3.0,
    'AR477-T': 3.0,
    'AR478-T': 2.0,
    'AR479-T': 3.0,
    'AR480-N': 2.0,
    'AR481-': 1.0,
    'AR482-T': 2.0,
    'AR483-N': 3.0,
    'AR484-T': 2.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 4.0,
    'AR490-T': 3.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'POLYCHLORINATED-BIPHENYLS--AROCLORS-': {
    'AR001-N': 3.0,
    'AR003-T': 1.0,
    'AR008-N': 2.0,
    'AR012-T': 1.0,
    'AR014-N': 3.0,
    'AR018-T': 2.0,
    'AR019-N': 4.0,
    'AR021-N': 1.0,
    'AR026-T': 3.0,
    'AR028-N': 2.0,
    'AR029-N': 2.0,
    'AR031-T': 1.0,
    'AR033-N': 2.0,
    'AR035-T': 1.0,
    'AR037-T': 3.0,
    'AR042-T': 1.0,
    'AR044-N': 3.0,
    'AR046-N': 1.0,
    'AR048-N': 1.0,
    'AR050-N': 3.0,
    'AR052-T': 3.0,
    'AR054-N': 1.0,
    'AR056-T': 3.0,
    'AR057-T': 3.0,
    'AR059-N': 2.0,
    'AR061-T': 2.0,
    'AR063-T': 2.0,
    'AR064-T': 1.0,
    'AR066-T': 1.0,
    'AR068-N': 1.0,
    'AR070-N': 4.0,
    'AR071-T': 2.0,
    'AR073-T': 1.0,
    'AR075-N': 1.0,
    'AR077-N': 1.0,
    'AR079-T': 2.0,
    'AR081-T': 1.0,
    'AR083-T': 2.0,
    'AR085-N': 2.0,
    'AR088-T': 1.0,
    'AR090-N': 4.0,
    'AR098-T': 1.0,
    'AR100-N': 3.0,
    'AR102-N': 4.0,
    'AR104-T': 3.0,
    'AR108-T': 1.0,
    'AR110-T': 3.0,
    'AR112-T': 1.0,
    'AR115-T': 1.0,
    'AR118-T': 4.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 4.0,
    'AR132-T': 1.0,
    'AR136-T': 1.0,
    'AR138-T': 1.0,
    'AR140-N': 4.0,
    'AR142-T': 1.0,
    'AR144-N': 1.0,
    'AR145-N': 3.0,
    'AR146-T': 3.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 2.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 2.0,
    'AR158-T': 2.0,
    'AR160-T': 2.0,
    'AR162-N': 4.0,
    'AR164-T': 3.0,
    'AR165-T': 1.0,
    'AR167-T': 3.0,
    'AR169-T': 3.0,
    'AR170-N': 1.0,
    'AR172-N': 3.0,
    'AR174-T': 1.0,
    'AR177-T': 4.0,
    'AR178-T': 2.0,
    'AR180-T': 3.0,
    'AR182-N': 2.0,
    'AR183-T': 4.0,
    'AR185-T': 4.0,
    'AR186-T': 2.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 4.0,
    'AR190-N': 1.0,
    'AR192-T': 2.0,
    'AR193-T': 1.0,
    'AR197-T': 3.0,
    'AR198-T': 4.0,
    'AR200-T': 4.0,
    'AR202-T': 1.0,
    'AR204-T': 3.0,
    'AR206-T': 4.0,
    'AR208-T': 4.0,
    'AR209-T': 3.0,
    'AR210-N': 3.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 3.0,
    'AR220-T': 4.0,
    'AR222-N': 3.0,
    'AR224-N': 3.0,
    'AR226-T': 2.0,
    'AR228-T': 4.0,
    'AR229-N': 4.0,
    'AR230-N': 4.0,
    'AR231-T': 2.0,
    'AR232-T': 4.0,
    'AR233-T': 3.0,
    'AR237-T': 3.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 2.0,
    'AR245-N': 2.0,
    'AR247-T': 1.0,
    'AR249-T': 3.0,
    'AR251-T': 1.0,
    'AR252-T': 4.0,
    'AR254-T': 4.0,
    'AR256-T': 3.0,
    'AR258-T': 4.0,
    'AR259-T': 4.0,
    'AR261-N': 2.0,
    'AR262-T': 4.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 1.0,
    'AR273-T': 2.0,
    'AR275-T': 4.0,
    'AR277-T': 2.0,
    'AR279-T': 4.0,
    'AR281-N': 2.0,
    'AR283-T': 3.0,
    'AR285-T': 4.0,
    'AR287-T': 4.0,
    'AR289-T': 4.0,
    'AR293-T': 1.0,
    'AR297-N': 1.0,
    'AR299-N': 3.0,
    'AR300-T': 4.0,
    'AR302-T': 1.0,
    'AR304-T': 1.0,
    'AR306-N': 3.0,
    'AR309-N': 1.0,
    'AR311-N': 2.0,
    'AR313-N': 2.0,
    'AR315-N': 2.0,
    'AR319-N': 1.0,
    'AR321-N': 3.0,
    'AR323-T': 4.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 1.0,
    'AR331-T': 2.0,
    'AR333-N': 2.0,
    'AR335-N': 2.0,
    'AR339-N': 2.0,
    'AR341-N': 1.0,
    'AR343-N': 3.0,
    'AR345-N': 2.0,
    'AR347-T': 4.0,
    'AR349-N': 4.0,
    'AR351-N': 2.0,
    'AR353-N': 3.0,
    'AR357-N': 3.0,
    'AR363-N': 3.0,
    'AR365-T': 1.0,
    'AR367-N': 1.0,
    'AR368-N': 1.0,
    'AR370-N': 2.0,
    'AR371-T': 3.0,
    'AR372-N': 2.0,
    'AR373-N': 2.0,
    'AR374-N': 1.0,
    'AR376-N': 1.0,
    'AR381-N': 2.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 2.0,
    'AR389-N': 1.0,
    'AR391-T': 3.0,
    'AR392-N': 1.0,
    'AR394-N': 3.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 1.0,
    'AR406-N': 3.0,
    'AR407-N': 1.0,
    'AR408-N': 3.0,
    'AR409-N': 3.0,
    'AR411-N': 1.0,
    'AR412-N': 3.0,
    'AR413-N': 2.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 4.0,
    'AR420-N': 2.0,
    'AR421-N': 4.0,
    'AR422-T': 3.0,
    'AR423-N': 3.0,
    'AR424-N': 4.0,
    'AR425-T': 1.0,
    'AR426-N': 1.0,
    'AR427-N': 4.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 4.0,
    'AR432-N': 2.0,
    'AR433-T': 3.0,
    'AR434-N': 2.0,
    'AR435-N': 3.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 2.0,
    'AR439-T': 4.0,
    'AR440-N': 2.0,
    'AR441-T': 3.0,
    'AR442-T': 1.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 4.0,
    'AR446-N': 2.0,
    'AR447-N': 4.0,
    'AR448-T': 1.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 1.0,
    'AR452-N': 3.0,
    'AR453-N': 2.0,
    'AR454-T': 4.0,
    'AR455-T': 1.0,
    'AR456-N': 2.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 4.0,
    'AR460-T': 4.0,
    'AR461-T': 4.0,
    'AR462-T': 4.0,
    'AR463-T': 3.0,
    'AR464-T': 1.0,
    'AR465-T': 1.0,
    'AR466-N': 1.0,
    'AR467-N': 4.0,
    'AR468-T': 1.0,
    'AR469-N': 2.0,
    'AR470-T': 4.0,
    'AR471-T': 3.0,
    'AR472-T': 2.0,
    'AR473-N': 3.0,
    'AR474-N': 4.0,
    'AR475-T': 4.0,
    'AR476-T': 4.0,
    'AR477-T': 3.0,
    'AR478-T': 4.0,
    'AR479-T': 2.0,
    'AR480-N': 4.0,
    'AR481-': 3.0,
    'AR482-T': 4.0,
    'AR483-N': 2.0,
    'AR484-T': 4.0,
    'AR485-N': 3.0,
    'AR486-N': 3.0,
    'AR488-N': 3.0,
    'AR489-T': 3.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  PROPIONALDEHYDE: {
    'AR001-N': 4.0,
    'AR003-T': 3.0,
    'AR008-N': 4.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 3.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 4.0,
    'AR028-N': 3.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 4.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 4.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 1.0,
    'AR059-N': 4.0,
    'AR061-T': 3.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 3.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 4.0,
    'AR073-T': 3.0,
    'AR075-N': 2.0,
    'AR077-N': 1.0,
    'AR079-T': 4.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 3.0,
    'AR088-T': 4.0,
    'AR090-N': 2.0,
    'AR098-T': 2.0,
    'AR100-N': 4.0,
    'AR102-N': 2.0,
    'AR104-T': 3.0,
    'AR108-T': 2.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 1.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 3.0,
    'AR132-T': 3.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 2.0,
    'AR142-T': 3.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 1.0,
    'AR155-N': 2.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 4.0,
    'AR165-T': 3.0,
    'AR167-T': 2.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 2.0,
    'AR183-T': 2.0,
    'AR185-T': 1.0,
    'AR186-T': 3.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 3.0,
    'AR198-T': 2.0,
    'AR200-T': 1.0,
    'AR202-T': 2.0,
    'AR204-T': 4.0,
    'AR206-T': 2.0,
    'AR208-T': 1.0,
    'AR209-T': 2.0,
    'AR210-N': 2.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 1.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 4.0,
    'AR237-T': 3.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 3.0,
    'AR243-T': 3.0,
    'AR245-N': 3.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 2.0,
    'AR252-T': 1.0,
    'AR254-T': 3.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 2.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 3.0,
    'AR273-T': 3.0,
    'AR275-T': 1.0,
    'AR277-T': 4.0,
    'AR279-T': 1.0,
    'AR281-N': 4.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 1.0,
    'AR293-T': 2.0,
    'AR297-N': 3.0,
    'AR299-N': 4.0,
    'AR300-T': 2.0,
    'AR302-T': 3.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 3.0,
    'AR313-N': 4.0,
    'AR315-N': 4.0,
    'AR319-N': 3.0,
    'AR321-N': 2.0,
    'AR323-T': 2.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 2.0,
    'AR331-T': 4.0,
    'AR333-N': 3.0,
    'AR335-N': 4.0,
    'AR339-N': 4.0,
    'AR341-N': 3.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 4.0,
    'AR357-N': 1.0,
    'AR363-N': 3.0,
    'AR365-T': 3.0,
    'AR367-N': 4.0,
    'AR368-N': 3.0,
    'AR370-N': 3.0,
    'AR371-T': 1.0,
    'AR372-N': 4.0,
    'AR373-N': 4.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 3.0,
    'AR382-N': 3.0,
    'AR384-N': 4.0,
    'AR387-N': 4.0,
    'AR389-N': 2.0,
    'AR391-T': 4.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 3.0,
    'AR400-T': 4.0,
    'AR402-N': 4.0,
    'AR404-N': 3.0,
    'AR406-N': 3.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 2.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 1.0,
    'AR420-N': 3.0,
    'AR421-N': 1.0,
    'AR422-T': 2.0,
    'AR423-N': 4.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 3.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 2.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 4.0,
    'AR433-T': 4.0,
    'AR434-N': 3.0,
    'AR435-N': 2.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 3.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 3.0,
    'AR443-T': 2.0,
    'AR444-T': 1.0,
    'AR445-N': 2.0,
    'AR446-N': 4.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 4.0,
    'AR450-N': 3.0,
    'AR451-N': 2.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 2.0,
    'AR455-T': 3.0,
    'AR456-N': 3.0,
    'AR457-T': 3.0,
    'AR458-T': 3.0,
    'AR459-T': 2.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 2.0,
    'AR464-T': 3.0,
    'AR465-T': 3.0,
    'AR466-N': 3.0,
    'AR467-N': 1.0,
    'AR468-T': 3.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 4.0,
    'AR472-T': 3.0,
    'AR473-N': 4.0,
    'AR474-N': 1.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 4.0,
    'AR478-T': 2.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 2.0,
    'AR482-T': 2.0,
    'AR483-N': 2.0,
    'AR484-T': 1.0,
    'AR485-N': 2.0,
    'AR486-N': 2.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 2.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'PROPYLENE-DICHLORIDE--1-2-DICHLOROPROPANE-': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 4.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 4.0,
    'AR029-N': 4.0,
    'AR031-T': 3.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 4.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 4.0,
    'AR059-N': 4.0,
    'AR061-T': 4.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 3.0,
    'AR070-N': 2.0,
    'AR071-T': 3.0,
    'AR073-T': 3.0,
    'AR075-N': 4.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 3.0,
    'AR088-T': 2.0,
    'AR090-N': 1.0,
    'AR098-T': 4.0,
    'AR100-N': 3.0,
    'AR102-N': 1.0,
    'AR104-T': 2.0,
    'AR108-T': 2.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 1.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 3.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 4.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 2.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 4.0,
    'AR162-N': 1.0,
    'AR164-T': 4.0,
    'AR165-T': 3.0,
    'AR167-T': 3.0,
    'AR169-T': 1.0,
    'AR170-N': 4.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 2.0,
    'AR182-N': 2.0,
    'AR183-T': 2.0,
    'AR185-T': 1.0,
    'AR186-T': 4.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 4.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 2.0,
    'AR208-T': 4.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 2.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 3.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 2.0,
    'AR229-N': 2.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 3.0,
    'AR237-T': 2.0,
    'AR238-T': 1.0,
    'AR239-T': 3.0,
    'AR242-N': 2.0,
    'AR243-T': 2.0,
    'AR245-N': 2.0,
    'AR247-T': 4.0,
    'AR249-T': 4.0,
    'AR251-T': 4.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 2.0,
    'AR261-N': 2.0,
    'AR262-T': 2.0,
    'AR264-N': 2.0,
    'AR266-T': 4.0,
    'AR268-N': 1.0,
    'AR271-T': 3.0,
    'AR273-T': 4.0,
    'AR275-T': 4.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 3.0,
    'AR289-T': 1.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 2.0,
    'AR302-T': 3.0,
    'AR304-T': 2.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 3.0,
    'AR321-N': 2.0,
    'AR323-T': 2.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 4.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 2.0,
    'AR349-N': 1.0,
    'AR351-N': 3.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 3.0,
    'AR381-N': 2.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 4.0,
    'AR389-N': 4.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 2.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 3.0,
    'AR438-T': 2.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 2.0,
    'AR454-T': 1.0,
    'AR455-T': 3.0,
    'AR456-N': 4.0,
    'AR457-T': 2.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 3.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 2.0,
    'AR469-N': 2.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 4.0,
    'AR473-N': 3.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 3.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 2.0,
    'AR483-N': 2.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 2.0,
    'AR490-T': 1.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'PROPYLENE-OXIDE': {
    'AR001-N': 3.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 3.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 1.0,
    'AR029-N': 4.0,
    'AR031-T': 3.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 1.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 2.0,
    'AR098-T': 3.0,
    'AR100-N': 3.0,
    'AR102-N': 1.0,
    'AR104-T': 1.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 3.0,
    'AR155-N': 2.0,
    'AR157-N': 4.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 3.0,
    'AR169-T': 1.0,
    'AR170-N': 4.0,
    'AR172-N': 3.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 2.0,
    'AR182-N': 3.0,
    'AR183-T': 1.0,
    'AR185-T': 2.0,
    'AR186-T': 3.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 3.0,
    'AR198-T': 2.0,
    'AR200-T': 1.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 2.0,
    'AR208-T': 1.0,
    'AR209-T': 2.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 1.0,
    'AR220-T': 2.0,
    'AR222-N': 2.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 4.0,
    'AR249-T': 1.0,
    'AR251-T': 3.0,
    'AR252-T': 2.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 2.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 3.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 2.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 3.0,
    'AR300-T': 2.0,
    'AR302-T': 4.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 4.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 2.0,
    'AR323-T': 2.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 4.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 3.0,
    'AR345-N': 4.0,
    'AR347-T': 3.0,
    'AR349-N': 2.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 2.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 4.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 4.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 2.0,
    'AR422-T': 2.0,
    'AR423-N': 2.0,
    'AR424-N': 2.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 2.0,
    'AR428-T': 2.0,
    'AR429-T': 2.0,
    'AR430-N': 2.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 2.0,
    'AR436-N': 2.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 4.0,
    'AR443-T': 2.0,
    'AR444-T': 2.0,
    'AR445-N': 3.0,
    'AR446-N': 4.0,
    'AR447-N': 3.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 3.0,
    'AR453-N': 3.0,
    'AR454-T': 1.0,
    'AR455-T': 4.0,
    'AR456-N': 1.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 1.0,
    'AR460-T': 2.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 2.0,
    'AR464-T': 3.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 2.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 2.0,
    'AR471-T': 3.0,
    'AR472-T': 4.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 2.0,
    'AR479-T': 4.0,
    'AR480-N': 3.0,
    'AR481-': 2.0,
    'AR482-T': 2.0,
    'AR483-N': 3.0,
    'AR484-T': 2.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 2.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  QUINOLINE: {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 3.0,
    'AR019-N': 3.0,
    'AR021-N': 4.0,
    'AR026-T': 4.0,
    'AR028-N': 2.0,
    'AR029-N': 4.0,
    'AR031-T': 3.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 4.0,
    'AR042-T': 4.0,
    'AR044-N': 3.0,
    'AR046-N': 2.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 3.0,
    'AR054-N': 4.0,
    'AR056-T': 3.0,
    'AR057-T': 4.0,
    'AR059-N': 4.0,
    'AR061-T': 3.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 2.0,
    'AR070-N': 3.0,
    'AR071-T': 3.0,
    'AR073-T': 2.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 2.0,
    'AR090-N': 2.0,
    'AR098-T': 4.0,
    'AR100-N': 4.0,
    'AR102-N': 2.0,
    'AR104-T': 2.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 1.0,
    'AR130-T': 3.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 4.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 3.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 3.0,
    'AR162-N': 2.0,
    'AR164-T': 4.0,
    'AR165-T': 2.0,
    'AR167-T': 4.0,
    'AR169-T': 1.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 2.0,
    'AR183-T': 2.0,
    'AR185-T': 2.0,
    'AR186-T': 2.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 3.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 4.0,
    'AR198-T': 1.0,
    'AR200-T': 3.0,
    'AR202-T': 3.0,
    'AR204-T': 3.0,
    'AR206-T': 1.0,
    'AR208-T': 4.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 2.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 4.0,
    'AR219-T': 3.0,
    'AR220-T': 4.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 3.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 4.0,
    'AR237-T': 2.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 2.0,
    'AR245-N': 2.0,
    'AR247-T': 4.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 4.0,
    'AR256-T': 3.0,
    'AR258-T': 4.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 4.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 4.0,
    'AR277-T': 3.0,
    'AR279-T': 2.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 4.0,
    'AR289-T': 1.0,
    'AR293-T': 2.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 1.0,
    'AR302-T': 3.0,
    'AR304-T': 2.0,
    'AR306-N': 1.0,
    'AR309-N': 2.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 2.0,
    'AR321-N': 3.0,
    'AR323-T': 2.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 4.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 3.0,
    'AR353-N': 4.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 2.0,
    'AR381-N': 2.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 3.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 3.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 2.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 2.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 3.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 2.0,
    'AR439-T': 2.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 3.0,
    'AR443-T': 1.0,
    'AR444-T': 2.0,
    'AR445-N': 2.0,
    'AR446-N': 2.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 2.0,
    'AR454-T': 1.0,
    'AR455-T': 3.0,
    'AR456-N': 2.0,
    'AR457-T': 2.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 3.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 2.0,
    'AR469-N': 2.0,
    'AR470-T': 1.0,
    'AR471-T': 4.0,
    'AR472-T': 4.0,
    'AR473-N': 3.0,
    'AR474-N': 3.0,
    'AR475-T': 2.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 3.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 1.0,
    'AR483-N': 2.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 2.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'QUINONE--P-BENZOQUINONE-': {
    'AR001-N': 3.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 3.0,
    'AR018-T': 4.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 3.0,
    'AR029-N': 3.0,
    'AR031-T': 2.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 3.0,
    'AR044-N': 3.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 1.0,
    'AR059-N': 4.0,
    'AR061-T': 4.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 3.0,
    'AR075-N': 4.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 4.0,
    'AR088-T': 4.0,
    'AR090-N': 1.0,
    'AR098-T': 4.0,
    'AR100-N': 3.0,
    'AR102-N': 1.0,
    'AR104-T': 1.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 1.0,
    'AR118-T': 1.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 1.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 4.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 2.0,
    'AR169-T': 1.0,
    'AR170-N': 4.0,
    'AR172-N': 3.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 1.0,
    'AR185-T': 1.0,
    'AR186-T': 4.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 3.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 3.0,
    'AR204-T': 3.0,
    'AR206-T': 1.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 2.0,
    'AR211-N': 3.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 3.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 4.0,
    'AR228-T': 1.0,
    'AR229-N': 1.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 4.0,
    'AR242-N': 3.0,
    'AR243-T': 2.0,
    'AR245-N': 3.0,
    'AR247-T': 3.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 1.0,
    'AR261-N': 3.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 3.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 3.0,
    'AR300-T': 1.0,
    'AR302-T': 4.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 4.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 3.0,
    'AR319-N': 4.0,
    'AR321-N': 2.0,
    'AR323-T': 1.0,
    'AR324-N': 4.0,
    'AR327-N': 1.0,
    'AR329-N': 2.0,
    'AR331-T': 4.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 3.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 3.0,
    'AR382-N': 4.0,
    'AR384-N': 3.0,
    'AR387-N': 4.0,
    'AR389-N': 3.0,
    'AR391-T': 2.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 2.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 2.0,
    'AR411-N': 4.0,
    'AR412-N': 2.0,
    'AR413-N': 4.0,
    'AR414-N': 4.0,
    'AR416-N': 4.0,
    'AR418-T': 1.0,
    'AR420-N': 3.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 4.0,
    'AR438-T': 2.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 4.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 1.0,
    'AR446-N': 4.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 4.0,
    'AR451-N': 3.0,
    'AR452-N': 3.0,
    'AR453-N': 3.0,
    'AR454-T': 1.0,
    'AR455-T': 4.0,
    'AR456-N': 3.0,
    'AR457-T': 2.0,
    'AR458-T': 2.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 3.0,
    'AR469-N': 4.0,
    'AR470-T': 1.0,
    'AR471-T': 4.0,
    'AR472-T': 2.0,
    'AR473-N': 2.0,
    'AR474-N': 1.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 1.0,
    'AR483-N': 2.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 1.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'SELENIUM-COMPOUNDS': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 1.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 1.0,
    'AR029-N': 4.0,
    'AR031-T': 1.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 2.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 1.0,
    'AR059-N': 4.0,
    'AR061-T': 1.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 3.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 2.0,
    'AR075-N': 2.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 1.0,
    'AR088-T': 3.0,
    'AR090-N': 4.0,
    'AR098-T': 2.0,
    'AR100-N': 3.0,
    'AR102-N': 4.0,
    'AR104-T': 1.0,
    'AR108-T': 2.0,
    'AR110-T': 2.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 4.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 4.0,
    'AR154-T': 3.0,
    'AR155-N': 4.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 1.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 2.0,
    'AR169-T': 2.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 4.0,
    'AR178-T': 4.0,
    'AR180-T': 2.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 3.0,
    'AR186-T': 3.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 2.0,
    'AR197-T': 4.0,
    'AR198-T': 4.0,
    'AR200-T': 1.0,
    'AR202-T': 2.0,
    'AR204-T': 4.0,
    'AR206-T': 4.0,
    'AR208-T': 1.0,
    'AR209-T': 2.0,
    'AR210-N': 3.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 2.0,
    'AR224-N': 2.0,
    'AR226-T': 1.0,
    'AR228-T': 1.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 4.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 2.0,
    'AR249-T': 1.0,
    'AR251-T': 2.0,
    'AR252-T': 3.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 2.0,
    'AR281-N': 3.0,
    'AR283-T': 2.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 2.0,
    'AR293-T': 2.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 4.0,
    'AR302-T': 3.0,
    'AR304-T': 3.0,
    'AR306-N': 2.0,
    'AR309-N': 2.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 2.0,
    'AR321-N': 3.0,
    'AR323-T': 4.0,
    'AR324-N': 1.0,
    'AR327-N': 1.0,
    'AR329-N': 2.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 3.0,
    'AR349-N': 3.0,
    'AR351-N': 3.0,
    'AR353-N': 3.0,
    'AR357-N': 2.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 1.0,
    'AR371-T': 2.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 2.0,
    'AR381-N': 2.0,
    'AR382-N': 2.0,
    'AR384-N': 3.0,
    'AR387-N': 2.0,
    'AR389-N': 2.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 1.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 2.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 2.0,
    'AR409-N': 3.0,
    'AR411-N': 3.0,
    'AR412-N': 4.0,
    'AR413-N': 2.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 2.0,
    'AR420-N': 2.0,
    'AR421-N': 2.0,
    'AR422-T': 3.0,
    'AR423-N': 2.0,
    'AR424-N': 3.0,
    'AR425-T': 4.0,
    'AR426-N': 3.0,
    'AR427-N': 2.0,
    'AR428-T': 3.0,
    'AR429-T': 4.0,
    'AR430-N': 3.0,
    'AR431-T': 2.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 2.0,
    'AR436-N': 2.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 2.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 1.0,
    'AR446-N': 3.0,
    'AR447-N': 3.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 1.0,
    'AR451-N': 1.0,
    'AR452-N': 4.0,
    'AR453-N': 2.0,
    'AR454-T': 3.0,
    'AR455-T': 3.0,
    'AR456-N': 1.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 2.0,
    'AR460-T': 3.0,
    'AR461-T': 2.0,
    'AR462-T': 2.0,
    'AR463-T': 2.0,
    'AR464-T': 1.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 3.0,
    'AR468-T': 2.0,
    'AR469-N': 2.0,
    'AR470-T': 3.0,
    'AR471-T': 3.0,
    'AR472-T': 1.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 2.0,
    'AR476-T': 2.0,
    'AR477-T': 4.0,
    'AR478-T': 3.0,
    'AR479-T': 3.0,
    'AR480-N': 3.0,
    'AR481-': 1.0,
    'AR482-T': 4.0,
    'AR483-N': 3.0,
    'AR484-T': 3.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 4.0,
    'AR491-N': 3.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  STYRENE: {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 3.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 3.0,
    'AR057-T': 2.0,
    'AR059-N': 3.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 2.0,
    'AR098-T': 3.0,
    'AR100-N': 3.0,
    'AR102-N': 2.0,
    'AR104-T': 1.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 1.0,
    'AR118-T': 1.0,
    'AR122-N': 4.0,
    'AR124-N': 4.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 3.0,
    'AR147-N': 1.0,
    'AR148-N': 3.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 1.0,
    'AR155-N': 2.0,
    'AR157-N': 4.0,
    'AR158-T': 3.0,
    'AR160-T': 3.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 3.0,
    'AR169-T': 2.0,
    'AR170-N': 2.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 3.0,
    'AR180-T': 1.0,
    'AR182-N': 4.0,
    'AR183-T': 1.0,
    'AR185-T': 1.0,
    'AR186-T': 3.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 4.0,
    'AR198-T': 2.0,
    'AR200-T': 1.0,
    'AR202-T': 3.0,
    'AR204-T': 4.0,
    'AR206-T': 2.0,
    'AR208-T': 1.0,
    'AR209-T': 2.0,
    'AR210-N': 4.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 3.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 3.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 1.0,
    'AR231-T': 3.0,
    'AR232-T': 2.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 4.0,
    'AR245-N': 2.0,
    'AR247-T': 2.0,
    'AR249-T': 1.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 1.0,
    'AR256-T': 4.0,
    'AR258-T': 1.0,
    'AR259-T': 2.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 4.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 2.0,
    'AR302-T': 4.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 4.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 3.0,
    'AR323-T': 2.0,
    'AR324-N': 3.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 4.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 4.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 3.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 3.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 3.0,
    'AR389-N': 3.0,
    'AR391-T': 2.0,
    'AR392-N': 4.0,
    'AR394-N': 3.0,
    'AR398-N': 3.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 2.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 4.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 2.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 2.0,
    'AR430-N': 1.0,
    'AR431-T': 2.0,
    'AR432-N': 3.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 2.0,
    'AR436-N': 3.0,
    'AR437-N': 2.0,
    'AR438-T': 2.0,
    'AR439-T': 1.0,
    'AR440-N': 3.0,
    'AR441-T': 3.0,
    'AR442-T': 4.0,
    'AR443-T': 2.0,
    'AR444-T': 1.0,
    'AR445-N': 2.0,
    'AR446-N': 3.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 2.0,
    'AR455-T': 4.0,
    'AR456-N': 3.0,
    'AR457-T': 2.0,
    'AR458-T': 4.0,
    'AR459-T': 2.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 2.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 1.0,
    'AR471-T': 3.0,
    'AR472-T': 4.0,
    'AR473-N': 2.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 2.0,
    'AR477-T': 4.0,
    'AR478-T': 2.0,
    'AR479-T': 3.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 2.0,
    'AR483-N': 4.0,
    'AR484-T': 1.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 4.0,
    'AR490-T': 2.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  TOLUENE: {
    'AR001-N': 3.0,
    'AR003-T': 3.0,
    'AR008-N': 3.0,
    'AR012-T': 3.0,
    'AR014-N': 3.0,
    'AR018-T': 1.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 2.0,
    'AR028-N': 2.0,
    'AR029-N': 3.0,
    'AR031-T': 1.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 3.0,
    'AR044-N': 2.0,
    'AR046-N': 2.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 1.0,
    'AR059-N': 3.0,
    'AR061-T': 1.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 3.0,
    'AR068-N': 3.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 3.0,
    'AR075-N': 2.0,
    'AR077-N': 1.0,
    'AR079-T': 2.0,
    'AR081-T': 3.0,
    'AR083-T': 1.0,
    'AR085-N': 1.0,
    'AR088-T': 4.0,
    'AR090-N': 4.0,
    'AR098-T': 2.0,
    'AR100-N': 2.0,
    'AR102-N': 3.0,
    'AR104-T': 1.0,
    'AR108-T': 2.0,
    'AR110-T': 3.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 4.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 3.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 1.0,
    'AR142-T': 3.0,
    'AR144-N': 3.0,
    'AR145-N': 4.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 1.0,
    'AR150-T': 1.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 2.0,
    'AR158-T': 3.0,
    'AR160-T': 2.0,
    'AR162-N': 4.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 2.0,
    'AR169-T': 4.0,
    'AR170-N': 2.0,
    'AR172-N': 3.0,
    'AR174-T': 3.0,
    'AR177-T': 4.0,
    'AR178-T': 3.0,
    'AR180-T': 4.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 4.0,
    'AR186-T': 2.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 1.0,
    'AR190-N': 3.0,
    'AR192-T': 1.0,
    'AR193-T': 3.0,
    'AR197-T': 3.0,
    'AR198-T': 4.0,
    'AR200-T': 1.0,
    'AR202-T': 2.0,
    'AR204-T': 3.0,
    'AR206-T': 4.0,
    'AR208-T': 1.0,
    'AR209-T': 4.0,
    'AR210-N': 2.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 4.0,
    'AR224-N': 3.0,
    'AR226-T': 1.0,
    'AR228-T': 1.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 3.0,
    'AR232-T': 4.0,
    'AR233-T': 2.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 1.0,
    'AR243-T': 2.0,
    'AR245-N': 1.0,
    'AR247-T': 2.0,
    'AR249-T': 1.0,
    'AR251-T': 2.0,
    'AR252-T': 4.0,
    'AR254-T': 1.0,
    'AR256-T': 2.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 2.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 3.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 4.0,
    'AR281-N': 3.0,
    'AR283-T': 4.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 4.0,
    'AR293-T': 2.0,
    'AR297-N': 3.0,
    'AR299-N': 3.0,
    'AR300-T': 4.0,
    'AR302-T': 3.0,
    'AR304-T': 4.0,
    'AR306-N': 4.0,
    'AR309-N': 2.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 3.0,
    'AR319-N': 3.0,
    'AR321-N': 2.0,
    'AR323-T': 4.0,
    'AR324-N': 1.0,
    'AR327-N': 1.0,
    'AR329-N': 2.0,
    'AR331-T': 2.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 2.0,
    'AR341-N': 3.0,
    'AR343-N': 3.0,
    'AR345-N': 2.0,
    'AR347-T': 4.0,
    'AR349-N': 4.0,
    'AR351-N': 2.0,
    'AR353-N': 2.0,
    'AR357-N': 4.0,
    'AR363-N': 1.0,
    'AR365-T': 3.0,
    'AR367-N': 4.0,
    'AR368-N': 3.0,
    'AR370-N': 1.0,
    'AR371-T': 3.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 3.0,
    'AR381-N': 1.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 2.0,
    'AR389-N': 2.0,
    'AR391-T': 2.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 1.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 2.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 4.0,
    'AR409-N': 2.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 2.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 3.0,
    'AR420-N': 1.0,
    'AR421-N': 4.0,
    'AR422-T': 4.0,
    'AR423-N': 2.0,
    'AR424-N': 4.0,
    'AR425-T': 3.0,
    'AR426-N': 2.0,
    'AR427-N': 4.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 4.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 3.0,
    'AR435-N': 4.0,
    'AR436-N': 1.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 2.0,
    'AR440-N': 3.0,
    'AR441-T': 2.0,
    'AR442-T': 3.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 1.0,
    'AR446-N': 2.0,
    'AR447-N': 4.0,
    'AR448-T': 4.0,
    'AR449-T': 2.0,
    'AR450-N': 1.0,
    'AR451-N': 2.0,
    'AR452-N': 3.0,
    'AR453-N': 2.0,
    'AR454-T': 3.0,
    'AR455-T': 3.0,
    'AR456-N': 2.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 2.0,
    'AR460-T': 4.0,
    'AR461-T': 4.0,
    'AR462-T': 4.0,
    'AR463-T': 4.0,
    'AR464-T': 1.0,
    'AR465-T': 4.0,
    'AR466-N': 3.0,
    'AR467-N': 4.0,
    'AR468-T': 3.0,
    'AR469-N': 2.0,
    'AR470-T': 4.0,
    'AR471-T': 2.0,
    'AR472-T': 4.0,
    'AR473-N': 2.0,
    'AR474-N': 1.0,
    'AR475-T': 4.0,
    'AR476-T': 4.0,
    'AR477-T': 3.0,
    'AR478-T': 4.0,
    'AR479-T': 2.0,
    'AR480-N': 4.0,
    'AR481-': 1.0,
    'AR482-T': 4.0,
    'AR483-N': 2.0,
    'AR484-T': 4.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 2.0,
    'AR489-T': 2.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  TRICHLOROETHYLENE: {
    'AR001-N': 3.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 3.0,
    'AR018-T': 3.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 4.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 3.0,
    'AR042-T': 4.0,
    'AR044-N': 3.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 3.0,
    'AR054-N': 4.0,
    'AR056-T': 3.0,
    'AR057-T': 3.0,
    'AR059-N': 4.0,
    'AR061-T': 3.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 4.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 3.0,
    'AR088-T': 4.0,
    'AR090-N': 3.0,
    'AR098-T': 4.0,
    'AR100-N': 3.0,
    'AR102-N': 2.0,
    'AR104-T': 2.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 3.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 1.0,
    'AR155-N': 2.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 3.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 4.0,
    'AR169-T': 1.0,
    'AR170-N': 4.0,
    'AR172-N': 3.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 2.0,
    'AR183-T': 2.0,
    'AR185-T': 2.0,
    'AR186-T': 4.0,
    'AR187-T': 1.0,
    'AR188-T': 1.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 3.0,
    'AR198-T': 2.0,
    'AR200-T': 2.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 1.0,
    'AR208-T': 2.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 2.0,
    'AR220-T': 3.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 1.0,
    'AR233-T': 3.0,
    'AR237-T': 2.0,
    'AR238-T': 1.0,
    'AR239-T': 3.0,
    'AR242-N': 2.0,
    'AR243-T': 2.0,
    'AR245-N': 2.0,
    'AR247-T': 4.0,
    'AR249-T': 2.0,
    'AR251-T': 4.0,
    'AR252-T': 1.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 1.0,
    'AR261-N': 2.0,
    'AR262-T': 1.0,
    'AR264-N': 2.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 3.0,
    'AR275-T': 3.0,
    'AR277-T': 3.0,
    'AR279-T': 1.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 4.0,
    'AR289-T': 1.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 3.0,
    'AR300-T': 1.0,
    'AR302-T': 3.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 4.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 4.0,
    'AR323-T': 2.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 3.0,
    'AR345-N': 3.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 3.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 3.0,
    'AR389-N': 4.0,
    'AR391-T': 2.0,
    'AR392-N': 4.0,
    'AR394-N': 3.0,
    'AR398-N': 2.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 3.0,
    'AR406-N': 3.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 2.0,
    'AR430-N': 1.0,
    'AR431-T': 1.0,
    'AR432-N': 3.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 2.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 4.0,
    'AR442-T': 3.0,
    'AR443-T': 2.0,
    'AR444-T': 2.0,
    'AR445-N': 2.0,
    'AR446-N': 4.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 3.0,
    'AR453-N': 2.0,
    'AR454-T': 1.0,
    'AR455-T': 3.0,
    'AR456-N': 4.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 1.0,
    'AR460-T': 1.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 2.0,
    'AR470-T': 1.0,
    'AR471-T': 4.0,
    'AR472-T': 3.0,
    'AR473-N': 2.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 2.0,
    'AR482-T': 1.0,
    'AR483-N': 2.0,
    'AR484-T': 1.0,
    'AR485-N': 2.0,
    'AR486-N': 2.0,
    'AR488-N': 1.0,
    'AR489-T': 2.0,
    'AR490-T': 2.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  TRIETHYLAMINE: {
    'AR001-N': 3.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 3.0,
    'AR014-N': 3.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 3.0,
    'AR026-T': 3.0,
    'AR028-N': 1.0,
    'AR029-N': 3.0,
    'AR031-T': 1.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 3.0,
    'AR044-N': 1.0,
    'AR046-N': 2.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 3.0,
    'AR056-T': 1.0,
    'AR057-T': 1.0,
    'AR059-N': 3.0,
    'AR061-T': 2.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 2.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 2.0,
    'AR075-N': 2.0,
    'AR077-N': 1.0,
    'AR079-T': 2.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 1.0,
    'AR088-T': 2.0,
    'AR090-N': 4.0,
    'AR098-T': 2.0,
    'AR100-N': 3.0,
    'AR102-N': 4.0,
    'AR104-T': 1.0,
    'AR108-T': 2.0,
    'AR110-T': 3.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 4.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 1.0,
    'AR142-T': 3.0,
    'AR144-N': 3.0,
    'AR145-N': 4.0,
    'AR146-T': 1.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 4.0,
    'AR154-T': 4.0,
    'AR155-N': 4.0,
    'AR157-N': 2.0,
    'AR158-T': 3.0,
    'AR160-T': 1.0,
    'AR162-N': 4.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 2.0,
    'AR169-T': 4.0,
    'AR170-N': 3.0,
    'AR172-N': 3.0,
    'AR174-T': 4.0,
    'AR177-T': 4.0,
    'AR178-T': 3.0,
    'AR180-T': 4.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 4.0,
    'AR186-T': 2.0,
    'AR187-T': 4.0,
    'AR188-T': 4.0,
    'AR189-N': 1.0,
    'AR190-N': 3.0,
    'AR192-T': 1.0,
    'AR193-T': 2.0,
    'AR197-T': 3.0,
    'AR198-T': 4.0,
    'AR200-T': 1.0,
    'AR202-T': 2.0,
    'AR204-T': 3.0,
    'AR206-T': 4.0,
    'AR208-T': 1.0,
    'AR209-T': 4.0,
    'AR210-N': 2.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 3.0,
    'AR219-T': 1.0,
    'AR220-T': 1.0,
    'AR222-N': 4.0,
    'AR224-N': 3.0,
    'AR226-T': 1.0,
    'AR228-T': 1.0,
    'AR229-N': 4.0,
    'AR230-N': 1.0,
    'AR231-T': 3.0,
    'AR232-T': 4.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 1.0,
    'AR243-T': 2.0,
    'AR245-N': 1.0,
    'AR247-T': 2.0,
    'AR249-T': 1.0,
    'AR251-T': 2.0,
    'AR252-T': 4.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 4.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 2.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 3.0,
    'AR273-T': 3.0,
    'AR275-T': 1.0,
    'AR277-T': 3.0,
    'AR279-T': 4.0,
    'AR281-N': 3.0,
    'AR283-T': 4.0,
    'AR285-T': 2.0,
    'AR287-T': 1.0,
    'AR289-T': 4.0,
    'AR293-T': 2.0,
    'AR297-N': 3.0,
    'AR299-N': 3.0,
    'AR300-T': 4.0,
    'AR302-T': 3.0,
    'AR304-T': 2.0,
    'AR306-N': 4.0,
    'AR309-N': 2.0,
    'AR311-N': 2.0,
    'AR313-N': 3.0,
    'AR315-N': 3.0,
    'AR319-N': 2.0,
    'AR321-N': 2.0,
    'AR323-T': 4.0,
    'AR324-N': 1.0,
    'AR327-N': 1.0,
    'AR329-N': 2.0,
    'AR331-T': 2.0,
    'AR333-N': 2.0,
    'AR335-N': 3.0,
    'AR339-N': 2.0,
    'AR341-N': 4.0,
    'AR343-N': 3.0,
    'AR345-N': 2.0,
    'AR347-T': 4.0,
    'AR349-N': 4.0,
    'AR351-N': 2.0,
    'AR353-N': 3.0,
    'AR357-N': 4.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 3.0,
    'AR368-N': 4.0,
    'AR370-N': 1.0,
    'AR371-T': 3.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 3.0,
    'AR376-N': 2.0,
    'AR381-N': 1.0,
    'AR382-N': 2.0,
    'AR384-N': 2.0,
    'AR387-N': 2.0,
    'AR389-N': 2.0,
    'AR391-T': 2.0,
    'AR392-N': 3.0,
    'AR394-N': 1.0,
    'AR398-N': 2.0,
    'AR400-T': 2.0,
    'AR402-N': 2.0,
    'AR404-N': 2.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 4.0,
    'AR409-N': 2.0,
    'AR411-N': 3.0,
    'AR412-N': 3.0,
    'AR413-N': 2.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 4.0,
    'AR420-N': 1.0,
    'AR421-N': 4.0,
    'AR422-T': 4.0,
    'AR423-N': 2.0,
    'AR424-N': 4.0,
    'AR425-T': 4.0,
    'AR426-N': 2.0,
    'AR427-N': 4.0,
    'AR428-T': 4.0,
    'AR429-T': 4.0,
    'AR430-N': 4.0,
    'AR431-T': 4.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 3.0,
    'AR435-N': 4.0,
    'AR436-N': 1.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 3.0,
    'AR440-N': 3.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 4.0,
    'AR444-T': 4.0,
    'AR445-N': 1.0,
    'AR446-N': 2.0,
    'AR447-N': 4.0,
    'AR448-T': 3.0,
    'AR449-T': 2.0,
    'AR450-N': 1.0,
    'AR451-N': 2.0,
    'AR452-N': 3.0,
    'AR453-N': 2.0,
    'AR454-T': 4.0,
    'AR455-T': 3.0,
    'AR456-N': 1.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 3.0,
    'AR460-T': 4.0,
    'AR461-T': 4.0,
    'AR462-T': 4.0,
    'AR463-T': 4.0,
    'AR464-T': 1.0,
    'AR465-T': 3.0,
    'AR466-N': 3.0,
    'AR467-N': 4.0,
    'AR468-T': 2.0,
    'AR469-N': 2.0,
    'AR470-T': 4.0,
    'AR471-T': 3.0,
    'AR472-T': 2.0,
    'AR473-N': 2.0,
    'AR474-N': 1.0,
    'AR475-T': 4.0,
    'AR476-T': 4.0,
    'AR477-T': 3.0,
    'AR478-T': 4.0,
    'AR479-T': 2.0,
    'AR480-N': 4.0,
    'AR481-': 1.0,
    'AR482-T': 4.0,
    'AR483-N': 2.0,
    'AR484-T': 4.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 2.0,
    'AR489-T': 2.0,
    'AR490-T': 4.0,
    'AR491-N': 4.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'VINYL-ACETATE': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 3.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 4.0,
    'AR028-N': 1.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 3.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 4.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 1.0,
    'AR071-T': 3.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 2.0,
    'AR098-T': 3.0,
    'AR100-N': 4.0,
    'AR102-N': 2.0,
    'AR104-T': 1.0,
    'AR108-T': 4.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 2.0,
    'AR155-N': 2.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 4.0,
    'AR167-T': 2.0,
    'AR169-T': 1.0,
    'AR170-N': 4.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 2.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 2.0,
    'AR186-T': 3.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 3.0,
    'AR198-T': 2.0,
    'AR200-T': 1.0,
    'AR202-T': 4.0,
    'AR204-T': 4.0,
    'AR206-T': 2.0,
    'AR208-T': 1.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 2.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 4.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 4.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 1.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 2.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 3.0,
    'AR275-T': 2.0,
    'AR277-T': 3.0,
    'AR279-T': 2.0,
    'AR281-N': 3.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 2.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 2.0,
    'AR302-T': 3.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 3.0,
    'AR311-N': 3.0,
    'AR313-N': 3.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 2.0,
    'AR323-T': 2.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 3.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 2.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 4.0,
    'AR357-N': 1.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 3.0,
    'AR373-N': 3.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 3.0,
    'AR389-N': 3.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 1.0,
    'AR398-N': 3.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 4.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 2.0,
    'AR420-N': 2.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 2.0,
    'AR424-N': 1.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 2.0,
    'AR430-N': 1.0,
    'AR431-T': 2.0,
    'AR432-N': 3.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 1.0,
    'AR438-T': 3.0,
    'AR439-T': 3.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 2.0,
    'AR444-T': 2.0,
    'AR445-N': 4.0,
    'AR446-N': 3.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 2.0,
    'AR455-T': 3.0,
    'AR456-N': 1.0,
    'AR457-T': 3.0,
    'AR458-T': 3.0,
    'AR459-T': 2.0,
    'AR460-T': 2.0,
    'AR461-T': 2.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 4.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 1.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 2.0,
    'AR471-T': 3.0,
    'AR472-T': 4.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 2.0,
    'AR476-T': 2.0,
    'AR477-T': 4.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 3.0,
    'AR482-T': 2.0,
    'AR483-N': 3.0,
    'AR484-T': 1.0,
    'AR485-N': 3.0,
    'AR486-N': 3.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 3.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'VINYL-CHLORIDE': {
    'AR001-N': 3.0,
    'AR003-T': 4.0,
    'AR008-N': 4.0,
    'AR012-T': 4.0,
    'AR014-N': 3.0,
    'AR018-T': 2.0,
    'AR019-N': 2.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 4.0,
    'AR029-N': 4.0,
    'AR031-T': 2.0,
    'AR033-N': 4.0,
    'AR035-T': 1.0,
    'AR037-T': 2.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 4.0,
    'AR048-N': 1.0,
    'AR050-N': 2.0,
    'AR052-T': 2.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 2.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 3.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 2.0,
    'AR071-T': 4.0,
    'AR073-T': 4.0,
    'AR075-N': 3.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 2.0,
    'AR088-T': 4.0,
    'AR090-N': 1.0,
    'AR098-T': 4.0,
    'AR100-N': 3.0,
    'AR102-N': 1.0,
    'AR104-T': 1.0,
    'AR108-T': 2.0,
    'AR110-T': 1.0,
    'AR112-T': 3.0,
    'AR115-T': 1.0,
    'AR118-T': 1.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 2.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 3.0,
    'AR140-N': 2.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 1.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 2.0,
    'AR154-T': 1.0,
    'AR155-N': 1.0,
    'AR157-N': 4.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 1.0,
    'AR164-T': 3.0,
    'AR165-T': 3.0,
    'AR167-T': 2.0,
    'AR169-T': 1.0,
    'AR170-N': 4.0,
    'AR172-N': 3.0,
    'AR174-T': 4.0,
    'AR177-T': 1.0,
    'AR178-T': 4.0,
    'AR180-T': 1.0,
    'AR182-N': 3.0,
    'AR183-T': 2.0,
    'AR185-T': 1.0,
    'AR186-T': 4.0,
    'AR187-T': 2.0,
    'AR188-T': 2.0,
    'AR189-N': 2.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 3.0,
    'AR198-T': 1.0,
    'AR200-T': 2.0,
    'AR202-T': 4.0,
    'AR204-T': 3.0,
    'AR206-T': 2.0,
    'AR208-T': 1.0,
    'AR209-T': 1.0,
    'AR210-N': 3.0,
    'AR211-N': 3.0,
    'AR213-T': 2.0,
    'AR215-T': 2.0,
    'AR217-N': 3.0,
    'AR219-T': 2.0,
    'AR220-T': 2.0,
    'AR222-N': 1.0,
    'AR224-N': 1.0,
    'AR226-T': 3.0,
    'AR228-T': 2.0,
    'AR229-N': 2.0,
    'AR230-N': 2.0,
    'AR231-T': 4.0,
    'AR232-T': 2.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 2.0,
    'AR242-N': 3.0,
    'AR243-T': 3.0,
    'AR245-N': 3.0,
    'AR247-T': 4.0,
    'AR249-T': 2.0,
    'AR251-T': 3.0,
    'AR252-T': 2.0,
    'AR254-T': 2.0,
    'AR256-T': 3.0,
    'AR258-T': 2.0,
    'AR259-T': 2.0,
    'AR261-N': 2.0,
    'AR262-T': 2.0,
    'AR264-N': 3.0,
    'AR266-T': 2.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 3.0,
    'AR275-T': 2.0,
    'AR277-T': 4.0,
    'AR279-T': 1.0,
    'AR281-N': 4.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 2.0,
    'AR289-T': 1.0,
    'AR293-T': 4.0,
    'AR297-N': 4.0,
    'AR299-N': 3.0,
    'AR300-T': 2.0,
    'AR302-T': 3.0,
    'AR304-T': 4.0,
    'AR306-N': 1.0,
    'AR309-N': 4.0,
    'AR311-N': 3.0,
    'AR313-N': 4.0,
    'AR315-N': 4.0,
    'AR319-N': 4.0,
    'AR321-N': 2.0,
    'AR323-T': 2.0,
    'AR324-N': 3.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 4.0,
    'AR333-N': 3.0,
    'AR335-N': 4.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 3.0,
    'AR345-N': 4.0,
    'AR347-T': 1.0,
    'AR349-N': 1.0,
    'AR351-N': 4.0,
    'AR353-N': 3.0,
    'AR357-N': 1.0,
    'AR363-N': 2.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 2.0,
    'AR371-T': 1.0,
    'AR372-N': 4.0,
    'AR373-N': 4.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 3.0,
    'AR382-N': 3.0,
    'AR384-N': 3.0,
    'AR387-N': 3.0,
    'AR389-N': 4.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 2.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 2.0,
    'AR407-N': 1.0,
    'AR408-N': 1.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 3.0,
    'AR413-N': 3.0,
    'AR414-N': 3.0,
    'AR416-N': 3.0,
    'AR418-T': 1.0,
    'AR420-N': 3.0,
    'AR421-N': 1.0,
    'AR422-T': 1.0,
    'AR423-N': 3.0,
    'AR424-N': 2.0,
    'AR425-T': 4.0,
    'AR426-N': 4.0,
    'AR427-N': 1.0,
    'AR428-T': 1.0,
    'AR429-T': 1.0,
    'AR430-N': 2.0,
    'AR431-T': 1.0,
    'AR432-N': 4.0,
    'AR433-T': 3.0,
    'AR434-N': 4.0,
    'AR435-N': 1.0,
    'AR436-N': 2.0,
    'AR437-N': 2.0,
    'AR438-T': 2.0,
    'AR439-T': 2.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 3.0,
    'AR443-T': 1.0,
    'AR444-T': 1.0,
    'AR445-N': 2.0,
    'AR446-N': 4.0,
    'AR447-N': 1.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 3.0,
    'AR452-N': 3.0,
    'AR453-N': 3.0,
    'AR454-T': 1.0,
    'AR455-T': 3.0,
    'AR456-N': 4.0,
    'AR457-T': 2.0,
    'AR458-T': 3.0,
    'AR459-T': 1.0,
    'AR460-T': 2.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 1.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 2.0,
    'AR468-T': 4.0,
    'AR469-N': 3.0,
    'AR470-T': 2.0,
    'AR471-T': 3.0,
    'AR472-T': 3.0,
    'AR473-N': 3.0,
    'AR474-N': 2.0,
    'AR475-T': 1.0,
    'AR476-T': 1.0,
    'AR477-T': 3.0,
    'AR478-T': 1.0,
    'AR479-T': 4.0,
    'AR480-N': 1.0,
    'AR481-': 1.0,
    'AR482-T': 2.0,
    'AR483-N': 3.0,
    'AR484-T': 2.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 1.0,
    'AR491-N': 1.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  },
  'XYLENES--MIXED-ISOMERS-': {
    'AR001-N': 4.0,
    'AR003-T': 4.0,
    'AR008-N': 4.0,
    'AR012-T': 4.0,
    'AR014-N': 4.0,
    'AR018-T': 2.0,
    'AR019-N': 1.0,
    'AR021-N': 4.0,
    'AR026-T': 3.0,
    'AR028-N': 1.0,
    'AR029-N': 4.0,
    'AR031-T': 1.0,
    'AR033-N': 4.0,
    'AR035-T': 1.0,
    'AR037-T': 1.0,
    'AR042-T': 4.0,
    'AR044-N': 2.0,
    'AR046-N': 3.0,
    'AR048-N': 1.0,
    'AR050-N': 1.0,
    'AR052-T': 1.0,
    'AR054-N': 4.0,
    'AR056-T': 2.0,
    'AR057-T': 1.0,
    'AR059-N': 4.0,
    'AR061-T': 2.0,
    'AR063-T': 4.0,
    'AR064-T': 1.0,
    'AR066-T': 4.0,
    'AR068-N': 4.0,
    'AR070-N': 1.0,
    'AR071-T': 4.0,
    'AR073-T': 4.0,
    'AR075-N': 2.0,
    'AR077-N': 1.0,
    'AR079-T': 3.0,
    'AR081-T': 4.0,
    'AR083-T': 1.0,
    'AR085-N': 1.0,
    'AR088-T': 4.0,
    'AR090-N': 3.0,
    'AR098-T': 2.0,
    'AR100-N': 3.0,
    'AR102-N': 2.0,
    'AR104-T': 1.0,
    'AR108-T': 3.0,
    'AR110-T': 1.0,
    'AR112-T': 4.0,
    'AR115-T': 1.0,
    'AR118-T': 2.0,
    'AR122-N': 3.0,
    'AR124-N': 3.0,
    'AR128-T': 1.0,
    'AR130-T': 1.0,
    'AR132-T': 4.0,
    'AR136-T': 1.0,
    'AR138-T': 4.0,
    'AR140-N': 1.0,
    'AR142-T': 4.0,
    'AR144-N': 4.0,
    'AR145-N': 2.0,
    'AR146-T': 2.0,
    'AR147-N': 1.0,
    'AR148-N': 2.0,
    'AR150-T': 1.0,
    'AR152-T': 3.0,
    'AR154-T': 2.0,
    'AR155-N': 3.0,
    'AR157-N': 3.0,
    'AR158-T': 4.0,
    'AR160-T': 2.0,
    'AR162-N': 2.0,
    'AR164-T': 3.0,
    'AR165-T': 2.0,
    'AR167-T': 2.0,
    'AR169-T': 2.0,
    'AR170-N': 3.0,
    'AR172-N': 4.0,
    'AR174-T': 4.0,
    'AR177-T': 3.0,
    'AR178-T': 4.0,
    'AR180-T': 2.0,
    'AR182-N': 2.0,
    'AR183-T': 1.0,
    'AR185-T': 2.0,
    'AR186-T': 3.0,
    'AR187-T': 3.0,
    'AR188-T': 3.0,
    'AR189-N': 1.0,
    'AR190-N': 4.0,
    'AR192-T': 1.0,
    'AR193-T': 4.0,
    'AR197-T': 3.0,
    'AR198-T': 3.0,
    'AR200-T': 1.0,
    'AR202-T': 3.0,
    'AR204-T': 4.0,
    'AR206-T': 3.0,
    'AR208-T': 1.0,
    'AR209-T': 3.0,
    'AR210-N': 3.0,
    'AR211-N': 2.0,
    'AR213-T': 1.0,
    'AR215-T': 1.0,
    'AR217-N': 4.0,
    'AR219-T': 2.0,
    'AR220-T': 1.0,
    'AR222-N': 2.0,
    'AR224-N': 1.0,
    'AR226-T': 2.0,
    'AR228-T': 1.0,
    'AR229-N': 3.0,
    'AR230-N': 1.0,
    'AR231-T': 4.0,
    'AR232-T': 3.0,
    'AR233-T': 3.0,
    'AR237-T': 1.0,
    'AR238-T': 1.0,
    'AR239-T': 1.0,
    'AR242-N': 2.0,
    'AR243-T': 3.0,
    'AR245-N': 2.0,
    'AR247-T': 3.0,
    'AR249-T': 1.0,
    'AR251-T': 2.0,
    'AR252-T': 2.0,
    'AR254-T': 1.0,
    'AR256-T': 3.0,
    'AR258-T': 1.0,
    'AR259-T': 3.0,
    'AR261-N': 1.0,
    'AR262-T': 1.0,
    'AR264-N': 3.0,
    'AR266-T': 1.0,
    'AR268-N': 1.0,
    'AR271-T': 4.0,
    'AR273-T': 4.0,
    'AR275-T': 1.0,
    'AR277-T': 4.0,
    'AR279-T': 2.0,
    'AR281-N': 4.0,
    'AR283-T': 1.0,
    'AR285-T': 1.0,
    'AR287-T': 1.0,
    'AR289-T': 2.0,
    'AR293-T': 3.0,
    'AR297-N': 4.0,
    'AR299-N': 4.0,
    'AR300-T': 3.0,
    'AR302-T': 4.0,
    'AR304-T': 4.0,
    'AR306-N': 2.0,
    'AR309-N': 3.0,
    'AR311-N': 2.0,
    'AR313-N': 4.0,
    'AR315-N': 4.0,
    'AR319-N': 3.0,
    'AR321-N': 3.0,
    'AR323-T': 3.0,
    'AR324-N': 2.0,
    'AR327-N': 1.0,
    'AR329-N': 3.0,
    'AR331-T': 3.0,
    'AR333-N': 3.0,
    'AR335-N': 4.0,
    'AR339-N': 3.0,
    'AR341-N': 4.0,
    'AR343-N': 4.0,
    'AR345-N': 3.0,
    'AR347-T': 2.0,
    'AR349-N': 2.0,
    'AR351-N': 3.0,
    'AR353-N': 3.0,
    'AR357-N': 2.0,
    'AR363-N': 1.0,
    'AR365-T': 4.0,
    'AR367-N': 4.0,
    'AR368-N': 4.0,
    'AR370-N': 1.0,
    'AR371-T': 1.0,
    'AR372-N': 4.0,
    'AR373-N': 4.0,
    'AR374-N': 4.0,
    'AR376-N': 4.0,
    'AR381-N': 2.0,
    'AR382-N': 2.0,
    'AR384-N': 3.0,
    'AR387-N': 3.0,
    'AR389-N': 2.0,
    'AR391-T': 3.0,
    'AR392-N': 4.0,
    'AR394-N': 1.0,
    'AR398-N': 2.0,
    'AR400-T': 3.0,
    'AR402-N': 3.0,
    'AR404-N': 3.0,
    'AR406-N': 1.0,
    'AR407-N': 1.0,
    'AR408-N': 2.0,
    'AR409-N': 3.0,
    'AR411-N': 4.0,
    'AR412-N': 4.0,
    'AR413-N': 3.0,
    'AR414-N': 2.0,
    'AR416-N': 2.0,
    'AR418-T': 1.0,
    'AR420-N': 2.0,
    'AR421-N': 2.0,
    'AR422-T': 3.0,
    'AR423-N': 2.0,
    'AR424-N': 2.0,
    'AR425-T': 4.0,
    'AR426-N': 3.0,
    'AR427-N': 2.0,
    'AR428-T': 2.0,
    'AR429-T': 3.0,
    'AR430-N': 2.0,
    'AR431-T': 2.0,
    'AR432-N': 4.0,
    'AR433-T': 2.0,
    'AR434-N': 4.0,
    'AR435-N': 2.0,
    'AR436-N': 2.0,
    'AR437-N': 1.0,
    'AR438-T': 2.0,
    'AR439-T': 1.0,
    'AR440-N': 4.0,
    'AR441-T': 3.0,
    'AR442-T': 4.0,
    'AR443-T': 3.0,
    'AR444-T': 2.0,
    'AR445-N': 1.0,
    'AR446-N': 3.0,
    'AR447-N': 2.0,
    'AR448-T': 4.0,
    'AR449-T': 3.0,
    'AR450-N': 2.0,
    'AR451-N': 2.0,
    'AR452-N': 4.0,
    'AR453-N': 3.0,
    'AR454-T': 1.0,
    'AR455-T': 4.0,
    'AR456-N': 1.0,
    'AR457-T': 2.0,
    'AR458-T': 4.0,
    'AR459-T': 1.0,
    'AR460-T': 2.0,
    'AR461-T': 1.0,
    'AR462-T': 1.0,
    'AR463-T': 3.0,
    'AR464-T': 2.0,
    'AR465-T': 4.0,
    'AR466-N': 4.0,
    'AR467-N': 2.0,
    'AR468-T': 4.0,
    'AR469-N': 2.0,
    'AR470-T': 2.0,
    'AR471-T': 3.0,
    'AR472-T': 4.0,
    'AR473-N': 3.0,
    'AR474-N': 1.0,
    'AR475-T': 1.0,
    'AR476-T': 2.0,
    'AR477-T': 4.0,
    'AR478-T': 3.0,
    'AR479-T': 3.0,
    'AR480-N': 2.0,
    'AR481-': 1.0,
    'AR482-T': 3.0,
    'AR483-N': 2.0,
    'AR484-T': 2.0,
    'AR485-N': 1.0,
    'AR486-N': 1.0,
    'AR488-N': 1.0,
    'AR489-T': 3.0,
    'AR490-T': 3.0,
    'AR491-N': 2.0,
    'AR004-N': null,
    'AR006-T': null,
    'AR010-N': null,
    'AR016-T': null,
    'AR039-N': null,
    'AR040-T': null,
    'AR093-N': null,
    'AR095-N': null,
    'AR097-T': null,
    'AR106-T': null,
    'AR134-N': null,
    'AR151-N': null,
    'AR176-T': null,
    'AR195-T': null,
    'AR234-T': null,
    'AR235-T': null,
    'AR241-T': null,
    'AR291-T': null,
    'AR295-T': null,
    'AR317-N': null,
    'AR325-N': null,
    'AR337-N': null,
    'AR355-N': null,
    'AR361-N': null,
    'AR375-N': null,
    'AR377-N': null,
    'AR379-N': null,
    'AR380-N': null,
    'AR383-N': null,
    'AR385-N': null,
    'AR386-N': null,
    'AR396-N': null,
    'AR415-T': null,
    'AR487-N': null,
    'AR492-T': null,
    'AR493-N': null,
    'AR494-N': null,
    'AR495-T': null,
    'AR496-T': null,
    'AR497-T': null,
    'AR498-T': null,
    'AR499-T': null,
    'AR500-T': null,
    'AR501-T': null,
    'AR502-N': null,
    'AR503-T': null,
    'AR504-T': null,
    'AR505-T': null,
    'AR506-N': null,
    'AR002-T': null,
    'AR005-N': null,
    'AR007-T': null,
    'AR009-N': null,
    'AR011-N': null,
    'AR013-N': null,
    'AR015-N': null,
    'AR017-N': null,
    'AR020-N': null,
    'AR022-N': null,
    'AR023-N': null,
    'AR024-N': null,
    'AR025-T': null,
    'AR027-T': null,
    'AR030-N': null,
    'AR032-N': null,
    'AR034-N': null,
    'AR036-T': null,
    'AR038-T': null,
    'AR041-N': null,
    'AR043-N': null,
    'AR045-T': null,
    'AR047-N': null,
    'AR049-T': null,
    'AR051-N': null,
    'AR053-N': null,
    'AR055-T': null,
    'AR058-T': null,
    'AR060-T': null,
    'AR062-T': null,
    'AR065-N': null,
    'AR067-N': null,
    'AR069-N': null,
    'AR072-T': null,
    'AR074-T': null,
    'AR076-T': null,
    'AR078-N': null,
    'AR080-N': null,
    'AR082-T': null,
    'AR084-T': null,
    'AR086-T': null,
    'AR087-T': null,
    'AR089-N': null,
    'AR091-T': null,
    'AR092-T': null,
    'AR094-N': null,
    'AR096-N': null,
    'AR099-N': null,
    'AR101-T': null,
    'AR103-T': null,
    'AR105-T': null,
    'AR107-N': null,
    'AR109-T': null,
    'AR111-T': null,
    'AR113-T': null,
    'AR114-N': null,
    'AR116-T': null,
    'AR117-N': null,
    'AR119-N': null,
    'AR120-T': null,
    'AR121-T': null,
    'AR123-T': null,
    'AR125-T': null,
    'AR126-T': null,
    'AR127-T': null,
    'AR129-N': null,
    'AR131-T': null,
    'AR133-N': null,
    'AR135-T': null,
    'AR137-N': null,
    'AR139-T': null,
    'AR141-T': null,
    'AR143-T': null,
    'AR149-T': null,
    'AR153-N': null,
    'AR156-N': null,
    'AR159-T': null,
    'AR161-N': null,
    'AR163-N': null,
    'AR166-N': null,
    'AR168-T': null,
    'AR171-N': null,
    'AR173-T': null,
    'AR175-N': null,
    'AR179-N': null,
    'AR181-T': null,
    'AR184-N': null,
    'AR191-T': null,
    'AR194-T': null,
    'AR196-N': null,
    'AR199-T': null,
    'AR201-T': null,
    'AR203-N': null,
    'AR205-N': null,
    'AR207-N': null,
    'AR212-T': null,
    'AR214-T': null,
    'AR216-N': null,
    'AR218-N': null,
    'AR221-T': null,
    'AR223-T': null,
    'AR225-T': null,
    'AR227-T': null,
    'AR236-T': null,
    'AR240-N': null,
    'AR244-T': null,
    'AR246-T': null,
    'AR248-N': null,
    'AR250-T': null,
    'AR253-T': null,
    'AR255-T': null,
    'AR257-T': null,
    'AR260-T': null,
    'AR263-T': null,
    'AR265-T': null,
    'AR267-T': null,
    'AR269-N': null,
    'AR270-N': null,
    'AR272-T': null,
    'AR274-N': null,
    'AR276-T': null,
    'AR278-T': null,
    'AR280-N': null,
    'AR282-N': null,
    'AR284-T': null,
    'AR286-T': null,
    'AR288-T': null,
    'AR290-N': null,
    'AR292-T': null,
    'AR294-T': null,
    'AR296-T': null,
    'AR298-T': null,
    'AR301-T': null,
    'AR303-N': null,
    'AR305-N': null,
    'AR307-N': null,
    'AR308-N': null,
    'AR310-T': null,
    'AR312-T': null,
    'AR314-N': null,
    'AR316-T': null,
    'AR318-T': null,
    'AR320-N': null,
    'AR322-N': null,
    'AR326-N': null,
    'AR328-T': null,
    'AR330-N': null,
    'AR332-T': null,
    'AR334-T': null,
    'AR336-N': null,
    'AR338-T': null,
    'AR340-N': null,
    'AR342-T': null,
    'AR344-T': null,
    'AR346-N': null,
    'AR348-T': null,
    'AR350-T': null,
    'AR352-T': null,
    'AR354-T': null,
    'AR356-T': null,
    'AR358-N': null,
    'AR359-N': null,
    'AR360-T': null,
    'AR362-T': null,
    'AR364-T': null,
    'AR366-T': null,
    'AR369-T': null,
    'AR378-T': null,
    'AR388-T': null,
    'AR390-T': null,
    'AR393-N': null,
    'AR395-T': null,
    'AR397-T': null,
    'AR399-T': null,
    'AR401-T': null,
    'AR403-T': null,
    'AR405-T': null,
    'AR410-T': null,
    'AR417-N': null,
    'AR419-N': null
  }
}
